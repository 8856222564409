import { UrlService } from './../Services/UrlService';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import lozad from 'lozad';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit,OnChanges {

  constructor(private urlService: UrlService) { }

  @Input() url;
  @Input() class;
  @Input() onload = false;
  @Output() loadEvent = new EventEmitter();
  ngOnInit(): void {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
  }
  ngOnChanges() : void{
    this.url = this.url ? this.urlService.updateVideoUrl(this.url) : '';
  }
  onLoadIframe(){
    if(this.onload && this.url){
      this.loadEvent.emit(this.url.changingThisBreaksApplicationSecurity.split('/').pop());
    }
  }
}
