<div class="chart_container" *ngIf="graph_data">
  <div class="yaxis_label_container">
    <ng-container *ngFor="let y_axis of graph_data['y_axis_data'];let i=index;">
      <div class="yaxis_label" [ngStyle]="{'height': i==this.totalLengthYaxis-1?'2px':(100/(this.totalLengthYaxis-1)+'%')}" [data-labelvalue]="y_axis">
        {{y_axis}}</div>
    </ng-container>
  </div>
  <div class="bubble_chart_container">
    <div class="title_overlay">
      <div class="logo_div"><img [src]="graph_data['gmat_club_text_data']['logo_url']"><span
          class="highlight_logo">{{graph_data['gmat_club_text_data']['score']}}</span></div>
      <div class="inline_block chart_title_text">{{graph_data['gmat_club_text_data']['text']}}</div>
    </div>
    <div class="title_overlay title_overlay_year"><div class="year_info">{{graph_data['gmat_club_text_data']['sub_text']}}</div></div>
    <ng-container *ngFor="let company of companies;let i = index;">
      <div class="bubble inline_block" [ngClass]="company['bubble_class']" [ngStyle]="{
          '--i': (i+1),
          'background-color': company['background-color'],
          'height': company['width_height']+'px',
          'width':company['width_height']+'px',
          'transform': 'scale(1) translateX(-50%)',
          'top': company['translate_y'],
          'left':company['left'] + '%',
          'font-size': company['font_size']
        }">
        <div class="bubble_wrap">
          <span class="competitor_logo_container">
            <img class="competitor_logo" [src]="company['logo_url']">
          </span>
          <span class="review" [ngStyle]="{'top': company['small_circle_top']}">
            <span class="num_reviews">{{company['reviews']}}</span>
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="competitor_labels">
    <ng-container *ngFor="let company of companies">
      <span [ngStyle]="{'left': company['left'] + '%'}">
        {{company['name']}}
      </span>
    </ng-container>
  </div>
</div>

<div id="torrent-scanner-popup" style="display: none;"></div>
