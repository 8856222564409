<div class="section section_testimonial_pad section_testimonials" data-aos="zoom-in" *ngIf="testimonial_data">
  <div class="page_container">
    <div class="testimonial_carousel_container lozad" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/brick_wall_bg-jpg.jpg)'}">
      <div class="testimonial_inner_wrap">
        <div class="testimonial_slides active fade">
          <div class="testimonial_slides_inner_wrap">
            <div class="testimonial_image_container inline_block">
              <div class="testimonial_img_wrap">
                <ng-container *ngFor="let slide of testimonial_data['slides']">
                  <div [ngStyle]="{'left': (slide['left']+'px')}" class="testimonial_img">
                    <img class="lozad slide_image"
                      [src]="slide['image_url']">
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="testimonial_detail_container inline_block">
              <div class="testimonial_detail_inner_wrap">
                <ng-container *ngFor="let slide of testimonial_data['slides']">
                  <div [ngStyle]="{'left': (slide['left']+'px')}" class="testimonial_detail">
                    <div class="student_name desktop">
                      {{slide['student_name']}} | {{slide['student_score']}}
                    </div>
                    <div class="student_name mobile">
                      <div class="name">{{slide['student_name']}}</div>
                      <div class="score">{{slide['student_score']}}</div>
                    </div>
                    <div class="testimonial_comment">
                      {{slide['testimonial_comment']}}
                    </div>
                  </div>
                </ng-container>
              </div>
              <div style="text-align:center" class="testimonial_arrow_container">
                <div class="mobile">
                  <a class="prev icon_container" (click)="nextPreviousTestimonialSlide(-1)"
                    [ngStyle]="{'display': (active_slide==0)?'none':'block'}">
                    <span class="">
                      <img class="icon_default"
                        [src]="cdn_url+'/assets/images/left_arrow_default_sm.png'">
                      <img class="icon_hover"
                        [src]="cdn_url+'/assets/images/left_arrow_yellow_hover.png'">
                    </span>
                    <span class="arrow_label">Prev</span>
                  </a>
                </div>
                <div>
                  <ng-container *ngFor="let slide of testimonial_data['slides'];let i = index;">
                    <span class="dot" [ngClass]="(i == active_slide) ? 'active_testimonial' : ''" (click)="showTestimonialSlide(i)"></span>
                  </ng-container>
                </div>
                <div class="mobile">
                  <a class="next icon_container" (click)="nextPreviousTestimonialSlide(1)" [ngStyle]="{'display': (active_slide==(testimonial_data['slides'].length-1))?'none':'block'}">
                    <span class="arrow_label">Next</span>
                    <span class="">
                      <img class="icon_default"
                        [src]="cdn_url+'/assets/images/right_arrow_default_sm.png'">
                      <img class="icon_hover"
                        [src]="cdn_url+'/assets/images/right_arrow_yellow_hover.png'">
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="arrow_container desktop">
            <a class="prev" (click)="nextPreviousTestimonialSlide(-1)" [ngStyle]="{'display': (active_slide==0)?'none':'block'}">
              <span class="icon_container">
                <img class="icon_default" [src]="cdn_url+'/assets/images/left_arrow_default_new.png'">
                <img class="icon_hover" [src]="cdn_url+'/assets/images/left_arrow_hover_new.png'">
              </span>
            </a>
            <a class="next" (click)="nextPreviousTestimonialSlide(1)" [ngStyle]="{'display': (active_slide==(testimonial_data['slides'].length-1))?'none':'block'}">
              <span class="icon_container">
                <img class="icon_default" [src]="cdn_url+'/assets/images/right_arrow_default_new.png'">
                <img class="icon_hover" [src]="cdn_url+'/assets/images/right_arrow_hover_new.png'">
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
