<div id="page" class="hfeed site">
  <div id="main" class="site-main">
    <div class="cart_login_heaer background_config"
      [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/bg_cart_login.png)'}">
      <div class="page_container" *ngIf="!isLearningLogin">
        <div class="form_outer_container">
          <div class="form_container">
            <div class="row">
              <div class="col-md-4 col_timeline">
                <div class="timeline_head"><img class="img_egmat"
                    [src]="cdn_url+'/assets/icons/egmat_logo_cart_login_timeline.png'">
                  <div class="timeline_mobile_container">
                    <div class="mt_sub_2">
                      <div class="timeline_step">STEP 02 / 04</div>
                    </div>
                    <div>
                      <div class="timeline_content">Login / Sign Up</div>
                    </div>
                  </div>
                </div>
                <div class="timeline_container">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_rect">01</div>
                    </div>
                    <div class="col-md-8">
                      <div class="mt_sub_2">
                        <div class="timeline_step">STEP 01</div>
                      </div>
                      <div>
                        <div class="timeline_content">Checkout</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_line_container">
                        <div class="vertical_line_timeline"></div>
                      </div>
                    </div>
                    <div class="col-md-8"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_rect">02</div>
                    </div>
                    <div class="col-md-8">
                      <div class="mt_sub_2">
                        <div class="timeline_step">STEP 02</div>
                      </div>
                      <div>
                        <div class="timeline_content">Login / Sign Up</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_line_container">
                        <div class="vertical_line_timeline col_timeline_fade"></div>
                      </div>
                    </div>
                    <div class="col-md-8"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_rect col_timeline_content_fade">03</div>
                    </div>
                    <div class="col-md-8">
                      <div class="mt_sub_2">
                        <div class="timeline_step col_timeline_content_fade">STEP 03</div>
                      </div>
                      <div>
                        <div class="timeline_content col_timeline_content_fade">Review</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_line_container">
                        <div class="vertical_line_timeline col_timeline_fade"></div>
                      </div>
                    </div>
                    <div class="col-md-8"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="timeline_rect col_timeline_content_fade">04</div>
                    </div>
                    <div class="col-md-8">
                      <div class="mt_sub_2">
                        <div class="timeline_step col_timeline_content_fade">STEP 04</div>
                      </div>
                      <div>
                        <div class="timeline_content col_timeline_content_fade">Payment</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="login_reg_login_body col_form" *ngIf="isLogin">
                  <div class="login_heading form_head">Login</div>
                  <div class="form_sub_head">Don’t have an account? <span id="showSignUpForm" class="text_change_form" (click)="isLogin = false;">
                      Sign Up</span></div>
                  <app-sign-in></app-sign-in>
                </div>
                <div class="login_reg_login_body col_form" *ngIf="!isLogin">
                  <div class="login_heading form_head">Sign Up</div>
                  <div class="form_sub_head">Already have an account? <span id="showSignUpForm" class="text_change_form" (click)="isLogin = true;">
                      Login</span></div>
                  <app-sign-up></app-sign-up>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_container" *ngIf="isLearningLogin">
        <div class="form_outer_container form_outer_container_2">
          <div class="form_container_2">
            <div class="row">
              <div class="col-md-12">
                <div class="login_reg_login_body col_form_1">
                  <div class="timeline_head"><img class="img_egmat"
                    [src]="cdn_url+'/assets/icons/egmat_logo_cart_login_timeline.png'">
                  </div>
                  <div class="col_form_2">
                    <div class="login_heading form_head form_head_2">Login to continue learning</div>
                    <app-sign-in [isLearningLogin]="isLearningLogin"></app-sign-in>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
