<div class="as_section2_div" *ngIf="score_seven_forty_plus_data">
  <img class="mobile_line_circle lozad mobile_show" [src]="cdn_url+'/assets/images/mobile_line_circle.png'">
  <div class="white_div mobile_show"></div>
  <div class="as_section2_subdiv" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/dark_background_sec2.png)'}">
    <img class="section2_main_background lozad" [src]="cdn_url+'/assets/images/dark_background_sec2.png'">
    <div class="as_section2_header" data-aos="slide-up">{{score_seven_forty_plus_data['header']['first_line']}}</div>
    <div class="as_section2_description" data-aos="slide-up">{{score_seven_forty_plus_data['header']['second_line']}}
    </div>
    <ng-container *ngFor="let tab of score_seven_forty_plus_data['tabs'];let i = index;">
      <div [ngClass]="'section2_0'+(i+1)+'_div'">
        <ng-container *ngIf="i%2==0">
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 1">
            <div [ngClass]="'section2_0'+(i+1)+'_left_div mobile_hide'" data-aos="fade-right">
              <div [ngClass]="'section2_0'+(score_seven_forty_plus_data['cards'][tab['card']]['card_type'])+'_card'">
                <div class="section2_card_header">{{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                <div [ngClass]="'section2_card_description sec2_0'+(i+1)+'_card_description_width'">
                  <ng-container
                    *ngFor="let description_card of score_seven_forty_plus_data['cards'][tab['card']]['description']">
                    <ng-container *ngIf="description_card['class']=='white_description'">
                      <span class="white_description">
                        {{description_card['text']}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="description_card['class']!='white_description'">
                      {{description_card['text']}}
                    </ng-container>
                  </ng-container>
                </div><img [ngClass]="'section2_0'+(i+1)+'_graph lozad'"
                  [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url']">
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 4">
            <div [ngClass]="'section2_0'+(i+1)+'_left_div'" data-aos="fade-right">
              <div [ngClass]="'section2_0'+(i+1)+'_card'">
                <div class="section2_card_header">{{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                <div [ngClass]="'section2_card_description sec2_0'+(i+1)+'_card_description_width'">
                  <ng-container
                    *ngFor="let description_card of score_seven_forty_plus_data['cards'][tab['card']]['description']">
                    <ng-container *ngIf="description_card['class']=='white_description'">
                      <span class="white_description">
                        {{description_card['text']}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="description_card['class']!='white_description'">
                      {{description_card['text']}}
                    </ng-container>
                  </ng-container>
                </div>
                <div [ngClass]="'sec2_0'+(i+1)+'_img_div'">
                  <div [ngClass]="'sec2_0'+(i+1)+'_img_left'">
                    <img [ngClass]="'section2_0'+(i+1)+'_logo lozad'"
                      [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url_2']">
                  </div>
                  <div [ngClass]="'sec2_0'+(i+1)+'_img_right'">
                    <img [ngClass]="'section2_0'+(i+1)+'_graph lozad'"
                      [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url']">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 2">
            <div [ngClass]="'section2_0'+(i+1)+'_left_div'" data-aos="fade-right">
              <div class="section2_toggle_div">
                <ng-container
                  *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];let j = index;">
                  <div class="half_width">
                    <div class="section2_toggle_buttons"
                      [ngClass]="{'float_right':j==0,'float_left':j>0,'toggle_button_active': card_tab['is_active']}"
                      (click)="markActiveTab(j,tab['card'])">
                      <span class="toggle_text"
                        [ngClass]="{'toggle_text_active': card_tab['is_active']}">{{card_tab['name']}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div [ngClass]="'section2_0'+(i+1)+'_card'">
                <div [ngClass]="'section2_0'+(i+1)+'_card_flex'">
                  <div [ngClass]="'section2_0'+(i+1)+'_card_header_left'">
                    <div [ngClass]="'section2_0'+(i+1)+'_card_text_left'">
                      {{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                    <div class="human_statistics">
                      <ng-container
                        *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                        <img class="human_740_img lozad"
                          [ngClass]="{'human_740_img': card_tab['type']==2,'human_700_730_img': card_tab['type']==1, 'display_none': !card_tab['is_active']}"
                          [src]="card_tab['data']['image_url']">
                      </ng-container>
                    </div>
                  </div>
                  <div [ngClass]="'section2_0'+(i+1)+'_card_header_right'">
                    <ng-container
                      *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                      <div
                        [ngClass]="{'section2_02_card_text_right':true,'text_right_740': card_tab['type']==2, 'text_right_700': card_tab['type']==1, 'display_none': !card_tab['is_active']}">
                        {{card_tab['data']['heading']}}</div>
                    </ng-container>
                    <ng-container
                      *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                      <div
                        [ngClass]="{'section2_02_card_details': true,'section2_740_details': card_tab['type']==2, 'section2_700_details': card_tab['type']==1, 'display_none': !card_tab['is_active']}">
                        <ng-container *ngFor="let score of card_tab['data']['scores']; let k = index;">
                          <div [ngClass]="{'sec2_stat_1': true, 'stat2_margin': k!=0, 'stat3_color': k==2}">
                            {{score['score']}}
                            <span
                              [ngClass]="{'sec2_stat_1_desc': true,'stat3_desc_color': k==2}">{{score['text']}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div [ngClass]="'section2_0'+(i+1)+'_right_div'" data-aos="fade-left">
            <div class="section2_content_header">0{{i+1}}</div>
            <div [ngClass]="'section2_content_subheader section2_0'+(i+1)+'_subheader_width'">{{tab['name']}}</div>
            <div [ngClass]="'section2_content_description section2_0'+(i+1)+'_description_width'">
              <ng-container *ngFor="let description of tab['description']">
                <ng-container *ngIf="description['class']=='white_description'">
                  <span class="white_description">
                    {{description['text']}}
                  </span>
                </ng-container>
                <ng-container *ngIf="description['class']!='white_description'">
                  {{description['text']}}
                </ng-container>
              </ng-container>
            </div>
            <div class="section2_cta_button_div">
              <button class="m7_bschool_click section2_cta_button" type="button"
                (click)="redirectToUnbounce(tab['view_more_url'])">
                <span class="section2_cta_button_text">Learn more</span>
              </button>
              <img [ngClass]="'background_screen_0'+(i+1)+' mobile_show lozad'" [src]="tab['background_screen_url']">
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="i%2!=0">
          <div [ngClass]="'section2_0'+(i+1)+'_left_div'" data-aos="fade-right">
            <div class="section2_content_header">0{{i+1}}</div>
            <div [ngClass]="'section2_content_subheader section2_0'+(i+1)+'_subheader_width'">{{tab['name']}}</div>
            <div [ngClass]="'section2_content_description section2_0'+(i+1)+'_description_width'">
              <ng-container *ngFor="let description of tab['description']">
                <ng-container *ngIf="description['class']=='white_description'">
                  <span class="white_description">
                    {{description['text']}}
                  </span>
                </ng-container>
                <ng-container *ngIf="description['class']!='white_description'">
                  {{description['text']}}
                </ng-container>
              </ng-container>
            </div>
            <div class="section2_cta_button_div">
              <button class="m7_bschool_click section2_cta_button" type="button"
                (click)="redirectToUnbounce(tab['view_more_url'])">
                <span class="section2_cta_button_text">Learn more</span>
              </button>
              <img [ngClass]="'background_screen_0'+(i+1)+' mobile_show lozad'" [src]="tab['background_screen_url']">
            </div>
          </div>
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 1">
            <div [ngClass]="'section2_0'+(i+1)+'_right_div mobile_hide'" data-aos="fade-left">
              <div [ngClass]="'section2_0'+(score_seven_forty_plus_data['cards'][tab['card']]['card_type'])+'_card'">
                <div class="section2_card_header">{{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                <div [ngClass]="'section2_card_description sec2_0'+(i+1)+'_card_description_width'">
                  <ng-container
                    *ngFor="let description_card of score_seven_forty_plus_data['cards'][tab['card']]['description']">
                    <ng-container *ngIf="description_card['class']=='white_description'">
                      <span class="white_description">
                        {{description_card['text']}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="description_card['class']!='white_description'">
                      {{description_card['text']}}
                    </ng-container>
                  </ng-container>
                </div><img [ngClass]="'section2_0'+(i+1)+'_graph lozad'"
                  [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url']">
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 4">
            <div [ngClass]="'section2_0'+(i+1)+'_right_div'" data-aos="fade-left">
              <div [ngClass]="'section2_0'+(i+1)+'_card'">
                <div class="section2_card_header">{{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                <div [ngClass]="'section2_card_description sec2_0'+(i+1)+'_card_description_width'">
                  <ng-container
                    *ngFor="let description_card of score_seven_forty_plus_data['cards'][tab['card']]['description']">
                    <ng-container *ngIf="description_card['class']=='white_description'">
                      <span class="white_description">
                        {{description_card['text']}}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="description_card['class']!='white_description'">
                      {{description_card['text']}}
                    </ng-container>
                  </ng-container>
                </div>
                <div [ngClass]="'sec2_0'+(i+1)+'_img_div'">
                  <div [ngClass]="'sec2_0'+(i+1)+'_img_left'">
                    <img [ngClass]="'section2_0'+(i+1)+'_logo lozad'"
                      [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url_2']">
                  </div>
                  <div [ngClass]="'sec2_0'+(i+1)+'_img_right'">
                    <img [ngClass]="'section2_0'+(i+1)+'_graph lozad'"
                      [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url']">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 2">
            <div [ngClass]="'section2_0'+(i+1)+'_right_div'" data-aos="fade-left">
              <div class="section2_toggle_div">
                <ng-container
                  *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];let j = index;">
                  <div class="half_width">
                    <div class="section2_toggle_buttons"
                      [ngClass]="{'float_right':j==0,'float_left':j>0,'toggle_button_active': card_tab['is_active']}"
                      (click)="markActiveTab(j,tab['card'])">
                      <span class="toggle_text"
                        [ngClass]="{'toggle_text_active': card_tab['is_active']}">{{card_tab['name']}}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div [ngClass]="'section2_0'+(i+1)+'_card'">
                <div [ngClass]="'section2_0'+(i+1)+'_card_flex'">
                  <div [ngClass]="'section2_0'+(i+1)+'_card_header_left'">
                    <div [ngClass]="'section2_0'+(i+1)+'_card_text_left'">
                      {{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
                    <div class="human_statistics">
                      <ng-container
                        *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                        <img class="human_740_img lozad"
                          [ngClass]="{'human_740_img': card_tab['type']==2,'human_700_730_img': card_tab['type']==1, 'display_none': !card_tab['is_active']}"
                          [src]="card_tab['data']['image_url']">
                      </ng-container>
                    </div>
                  </div>
                  <div [ngClass]="'section2_0'+(i+1)+'_card_header_right'">
                    <ng-container
                      *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                      <div
                        [ngClass]="{'section2_02_card_text_right':true,'text_right_740': card_tab['type']==2, 'text_right_700': card_tab['type']==1, 'display_none': !card_tab['is_active']}">
                        {{card_tab['data']['heading']}}</div>
                    </ng-container>
                    <ng-container
                      *ngFor="let card_tab of score_seven_forty_plus_data['cards'][tab['card']]['card_tabs'];">
                      <div
                        [ngClass]="{'section2_02_card_details': true,'section2_740_details': card_tab['type']==2, 'section2_700_details': card_tab['type']==1, 'display_none': !card_tab['is_active']}">
                        <ng-container *ngFor="let score of card_tab['data']['scores']; let k = index;">
                          <div [ngClass]="{'sec2_stat_1': true, 'stat2_margin': k!=0, 'stat3_color': k==2}">
                            {{score['score']}}
                            <span
                              [ngClass]="{'sec2_stat_1_desc': true,'stat3_desc_color': k==2}">{{score['text']}}</span>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="score_seven_forty_plus_data['cards'][tab['card']]['card_type'] == 1">
          <div [ngClass]="'section2_0'+(i+1)+'_left_div mobile_show'">
            <div [ngClass]="'section2_0'+(score_seven_forty_plus_data['cards'][tab['card']]['card_type'])+'_card'">
              <div class="section2_card_header">{{score_seven_forty_plus_data['cards'][tab['card']]['heading']}}</div>
              <div [ngClass]="'section2_card_description sec2_0'+(i+1)+'_card_description_width'">
                <ng-container
                  *ngFor="let description_card of score_seven_forty_plus_data['cards'][tab['card']]['description']">
                  <ng-container *ngIf="description_card['class']=='white_description'">
                    <span class="white_description">
                      {{description_card['text']}}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="description_card['class']!='white_description'">
                    {{description_card['text']}}
                  </ng-container>
                </ng-container>
              </div><img [ngClass]="'section2_0'+(i+1)+'_graph lozad'"
                [src]="score_seven_forty_plus_data['cards'][tab['card']]['image_url']">
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
