<div class="card rounded-[10px] shadow-[0px_0px_18px_1px_#0000001A] mb-[5px] mt-[24px] ml-[30px]" [ngClass]="tileClass" *ngIf="tileData">
  <div class="card-inner rounded-[10px]">
    <div class="front_view rounded-[10px] bg-[#ffffff] p-[26px] py-[55px]" [ngClass]="tileClass ? 'flex justify-between items-center':''">
      <div class="mb-[25px] flex justify-center items-center w-[57px] h-[57px] rounded-full bg-tileIcon" [ngClass]="tileClass ? 'mb-0':''">
        <img [src]="tileData['icon']">
      </div>
      <div>
        <div class="font-extrabold text-[26px]">{{tileData['boldText1']}}</div>
      <div class=" text-[26px]">{{tileData['normalText1']}}</div>
      <div class="font-extrabold text-[26px]">{{tileData['boldText2']}}</div>
      <div class=" text-[26px] mb-[30px]">{{tileData['normalText2']}}</div>
      </div>
      <div class="text-gfe text-[20px] font-bold" >Learn more <span >&#8594;</span></div>
    </div>
    <div class="rear_view bg-gfeBg flex flex-col justify-between items-center rounded-[10px] px-[24px] py-[58px]">
      <div class="px-[30px] font-[600 ] text-top3tileRearText">{{tileData['backViewText']}}</div>
      <div class="text-center cursor-pointer transition-all duration-500 font-bold border-2 border-top3tileRearText text-top3tileRearText hover:text-black border-solid py-[3px] w-[220px] m-auto rounded-[25px] bg-transparent hover:bg-[#ffffff] mt-[30px]" (click)="routeToCart('CTA_course_offerings_tile')">Buy Now</div>
    </div>
  </div>
</div>
