import {
  Component,
  OnInit,
  VERSION,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { formatDate } from '@angular/common';

import { BehaviorSubject, timer } from 'rxjs';
import { switchMap, map, takeWhile, pairwise } from 'rxjs/operators';

import {
  trigger,
  transition,
  animate,
  keyframes,
  style,
} from '@angular/animations';

@Component({
  selector: 'app-webinar-details',
  templateUrl: './webinar-details.component.html',
  styleUrls: ['./webinar-details.component.css'],
})
export class WebinarDetailsComponent implements OnInit, OnChanges {
  DaysTensPlace: number = 0;
  DaysOnesPlace: number = 0;
  HoursTensPlace: number = 0;
  HoursOnesPlace: number = 0;
  MinutesTensPlace: number = 0;
  MinutesOnesPlace: number = 0;
  SecondsTensPlace: number = 0;
  SecondsOnesPlace: number = 0;
  date = new Date();

  @Output() openModalEvent = new EventEmitter<string>();

  @Input() webinarDetail;

  ngOnChanges(): void {
    if (this.webinarDetail) {
      this.date = new Date(this.webinarDetail.date_time);
      this.countdownStart();
    }
  }

  nth() {
    const d = this.date.getDate();
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  ngOnInit() {}

  countdownStart() {
    setInterval((_) => {
      let t = this.date.getTime() - new Date().getTime();
      if (t >= 0) {
        let days = Math.floor(t / (1000 * 60 * 60 * 24));
        let hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        let secs = Math.floor((t % (1000 * 60)) / 1000);
        this.DaysTensPlace = Math.floor(days / 10);
        this.DaysOnesPlace = days % 10;
        this.HoursTensPlace = Math.floor(hours / 10);
        this.HoursOnesPlace = hours % 10;
        this.MinutesTensPlace = Math.floor(mins / 10);
        this.MinutesOnesPlace = mins % 10;
        this.SecondsTensPlace = Math.floor(secs / 10);
        this.SecondsOnesPlace = secs % 10;
      }
    }, 1000);
  }
  openModal() {
    this.openModalEvent.emit();
  }
}
