import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-scholarship',
  templateUrl: './scholarship.component.html',
  styleUrls: ['./scholarship.component.css'],
})
export class ScholarshipComponent implements OnInit {
  cdn_url = environment.cdn_url;
  intervalCount = 0;
  active_slide_class = 'active_slide';
  active_index = 0;
  opacity_target_slide = 1;
  constructor() {}

  @Input() scholarship_data;

  ngOnInit(): void {
    // setInterval(() => {
    //   this.arrowClick();
    // }, 8000);
  }

  student_pic_click(index) {
    this.intervalCount = index;
    this.showSlideAtIndex(index);
  }

  checkIndexAndShowSlideAtIndex(index) {
    let numberOfSlides = this.scholarship_data['slides'].length;
    if (index > numberOfSlides - 1) {
      this.intervalCount = 0;
      index = 0;
    }
    if (index < 0) {
      index = numberOfSlides - 1;
      this.intervalCount = numberOfSlides - 1;
    }
    this.showSlideAtIndex(index);
  }

  showSlideAtIndex(index) {
    this.opacity_target_slide = 0;
    setTimeout(()=>{
      this.active_index = index;
      this.opacity_target_slide = 1;
    }, 300);
  }

  arrowClick(next = true) {
    if(next){
      this.intervalCount += 1;
    }else{
      this.intervalCount -= 1;
    }
    this.checkIndexAndShowSlideAtIndex(this.intervalCount);
  }
}
