import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.css']
})
export class SolutionDetailComponent implements OnInit {

  cdn_url = environment.cdn_url;
  //liveSessionSolutionView:boolean = false;
  constructor() { }

  @Input() solution_detail_data;
  @Output() sendLiveSessionClick = new EventEmitter<Boolean>();

  ngOnInit(): void {
  }

  displayLiveSessionSolutionView(value){
    console.log(value);
    if(!value){
      this.solution_detail_data["liveSessionSolutionView"] = false;
      this.sendLiveSessionClick.emit(false);
    }else{
      this.solution_detail_data["liveSessionSolutionView"] = true;
      this.sendLiveSessionClick.emit(true);
    } 
  }

}
