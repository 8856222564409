<div class="section" [ngStyle]="{'background-image':'url(&quot;'+cdn_url+'/assets/images/'+backgroundImage+'.png&quot;)'}" *ngIf="most_successfull_and_trusted_data">
  <div class="page_container">
    <div class="row">
      <div class="col-12">
        <div class="md:hidden text-center heading">{{most_successfull_and_trusted_data.sub_heading.first}}<span class="highlight"><span>{{most_successfull_and_trusted_data.sub_heading.second}}</span></span>{{most_successfull_and_trusted_data.sub_heading.third}}​</div>
        <div class="text-center large-heading"><span class="first">{{most_successfull_and_trusted_data.heading.first}}</span>
          <span class="relative whitespace-nowrap md:w-max md:m-auto"> {{most_successfull_and_trusted_data.heading.second}}
            <img class="absolute bottom-0 right-0 w-[250px] md:w-[170px]" [src]="cdn_url+'/assets/images/img-egmat underline.png'" alt="">
          </span>
        </div>
      </div>
    </div>
    <div class="row tab-1">
      <div class="col-6 pl-[30px]">
        <div class="point-number">
          <img class="block md:w-[80px]" [src]="most_successfull_and_trusted_data.tab_1.point_image_url" alt="">
        </div>
        <div class="point-heading">{{most_successfull_and_trusted_data.tab_1.heading}}<br><span>{{most_successfull_and_trusted_data.tab_1.sub_heading}}</span></div>
        <div class="point-desc" [innerHTML]="most_successfull_and_trusted_data.tab_1.description"></div>
      </div>
      <div class="col-6">
        <img class="image-1" [src]="most_successfull_and_trusted_data.tab_1.image_url" alt="">
      </div>
      <div class="col-6 point-desc-mob">
        <div class="point-desc point-desc-mob" [innerHTML]="most_successfull_and_trusted_data.tab_1.description"></div>
      </div>
    </div>
    <div class="row tab-2">
      <div class="col-6">
        <div class="point-number">
          <img class="block md:w-[80px]" [src]="most_successfull_and_trusted_data.tab_2.point_image_url" alt="">
        </div>
        <div class="point-heading">{{most_successfull_and_trusted_data.tab_2.heading}}<span [innerHTML]="most_successfull_and_trusted_data.tab_2.sub_heading">​</span></div>
        <div class="point-desc">{{most_successfull_and_trusted_data.tab_2.description}}​</div>
      </div>
      <div class="col-6">
        <img class="image-2" [src]="most_successfull_and_trusted_data.tab_2.image_url" alt="">
        <img class="image-2-mob" [src]="most_successfull_and_trusted_data.tab_2.mobile_image_url" alt="">
      </div>
      <div class="col-6 point-desc-mob">
        <div class="point-desc point-desc-mob">{{most_successfull_and_trusted_data.tab_2.description}}</div>
      </div>
    </div>
    <div class="row tab-3">
      <div class="col-6">
        <div class="point-number">
          <img class="md:hidden block" [src]="most_successfull_and_trusted_data.tab_3.point_image_url" alt="">
          <img class="hidden md:block" [src]="most_successfull_and_trusted_data.tab_3.point_mobile_image_url" alt="">
        </div>
        <div class="point-heading">{{most_successfull_and_trusted_data.tab_3.heading}}<span>{{most_successfull_and_trusted_data.tab_3.sub_heading}}​</span></div>
        <div class="point-desc">{{most_successfull_and_trusted_data.tab_3.description}}​​</div>
      </div>
      <div class="col-6">
        <img class="image-3" [src]="most_successfull_and_trusted_data.tab_3.image_url" alt="">
        <img class="image-3-mob" [src]="most_successfull_and_trusted_data.tab_3.mobile_image_url" alt="">
      </div>
      <div class="col-6 point-desc-mob">
        <div class="point-desc point-desc-mob">{{most_successfull_and_trusted_data.tab_3.description}}​</div>
      </div>
    </div>
    <div class="description italic">
      <span>{{most_successfull_and_trusted_data.description.first}}</span>
      <span class="description-2">{{most_successfull_and_trusted_data.description.second}}</span>
    </div>
    <a class="styled_blue_button main_btn"  (click)="openModal(most_successfull_and_trusted_data.btn_name)"><span class="label">{{most_successfull_and_trusted_data.btn_name.toUpperCase()}}</span></a>
  </div>
</div>
