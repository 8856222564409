<div *ngIf="concerns_data" class="concerns_addressed_section lozad concerned_addressed_background_config"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/concerns_addressed_section_bg.png)'}">
  <div class="clouds_container">
    <div class="clouds_wrap">
      <div class="clouds cloud_1">
        <img class="lozad" [src]="cdn_url+'/assets/images/cloud_1.png'">
      </div>
      <div class="clouds cloud_2">
        <img class="lozad" [src]="cdn_url+'/assets/images/cloud_2.png'">
      </div>
      <div class="clouds cloud_3">
        <img class="lozad" [src]="cdn_url+'/assets/images/cloud_3.png'">
      </div>
      <div class="clouds cloud_4">
        <img class="lozad" [src]="cdn_url+'/assets/images/cloud_4.png'">
      </div>
    </div>
  </div>
  <div class="page_container overlay_section">
    <h1 class="page_head extra_bold_font" data-aos="slide-up">{{concerns_data['header']['first_line']}}<span
        class="highlight_text">{{concerns_data['header']['second_line']}}</span></h1>
    <div class="egmat_tab_button_view_container button_view_container">
      <div class="tab_button_view_button_container">
        <div class="tab_buttons" data-aos="slide-up">
          <div class="tab_button" [ngClass]="tab_type == 'support'?'active_btn':''" (click)="tab_type = 'support'">
            <div class="tab_button_wrap"> <span class="tab_button_icon"> <img class="lozad"
                  [src]="cdn_url+'/assets/images/support_icon.png'"> </span>
              <span> Support </span>
            </div>
          </div>
          <div class="tab_button" [ngClass]="tab_type == 'general'?'active_btn':''" (click)="tab_type = 'general'">
            <div class="tab_button_wrap"> <span class="tab_button_icon"> <img class="lozad"
                  [src]="cdn_url+'/assets/images/general_icon.png'"> </span>
              <span> General </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_button_view_content_container" data-aos="slide-up">
        <div class="tab_button_view_content">
          <div class="tab_button_content" [ngClass]="tab_type == 'support'?'active_btn':''">
            <div class="faq_container">
              <ng-container *ngFor="let tab of concerns_data['support_tabs'];let i=index;">
                <app-concern-card [tab_data]="tab" [index]="i" [is_last_tab]="(i == concerns_data['support_tabs'].length-1)?true:false" (open_card)="open_card($event,'support_tabs')"></app-concern-card>
              </ng-container>
            </div>
          </div>
          <div class="tab_button_content" [ngClass]="tab_type == 'general'?'active_btn':''">
            <div class="faq_container">
              <ng-container *ngFor="let tab of concerns_data['general_tabs'];let i=index;">
                <app-concern-card [tab_data]="tab" [index]="i" [is_last_tab]="(i == concerns_data['support_tabs'].length-1)?true:false" (open_card)="open_card($event,'general_tabs')"></app-concern-card>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
