import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-what-students-says-about',
  templateUrl: './what-students-says-about.component.html',
  styleUrls: ['./what-students-says-about.component.css'],
})
export class WhatStudentsSaysAboutComponent implements OnInit {
  cdn_url = environment.cdn_url;
  tab_background_name = 'students-says-background';
  active_index = 0;

  @Input() what_students_says_data;

  constructor() {}

  ngOnInit(): void {}

  slideChange(flag) {
    if (flag) {
      if (
        this.active_index ==
        this.what_students_says_data['slides'].length - 1
      ) {
        this.active_index = 0;
      } else {
        this.active_index += 1;
      }
    } else {
      if (this.active_index == 0) {
        this.active_index = this.what_students_says_data['slides'].length - 1;
      } else {
        this.active_index -= 1;
      }
    }
  }
}
