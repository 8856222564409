<div class="section section_pad section_case_study_view" id="section_case_study_view" [ngClass]="{'hide':!is_open}">
  <div class="page_container">
    <div class="case_study_view egmat_tab_view" data-numberoftabs="3" data-aos="slide-up">
      <div class="tab_view_container">
        <div class="tab_content_container">
          <ng-container *ngFor="let card of cards; let i = index;">
            <div class="tab_content" [ngClass]="(i == opened_index)?'active_tab':''">
              <div class="content_wrap">
                <div class="content_placeholder inline_block">
                  <div class="iframe_video_wrapper">
                    <app-iframe [url]="card['video_url']" [class]="'psp_wistia_video lozad wistia_embed'"></app-iframe>
                  </div>
                </div>
                <div class="content_details background_config inline_block lozad" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/quote.png)'}">
                  <div class="content_text">
                    {{card['card_full_text']}}
                  </div>
                  <div class="author_container">
                    <div class="author_image">
                      <img class="lozad" [src]="card['image_url']">
                    </div>
                    <div class="author_detail">
                      <div class="author_name">{{card['author_name']}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="tab_head_container">
          <ng-container *ngFor="let card of cards; let i = index;">
            <div class="tab_head" [ngClass]="(i == opened_index)?'active_tab':''" (click)="opened_index = i;markActive()">
              <span class="tab_text">
                <span class="label">{{card['card_sub_head']}}</span>
                <span class="duration_label">{{card['card_head']['first_line']}}
                  {{card['card_head']['second_line']}}</span>
              </span>
            </div>
          </ng-container>
          <div class="tab_select" [ngStyle]="{'left': left_tab_select+'%'}">

          </div>
        </div>
      </div>
    </div>
    <div class="learn_more_btn_container close_btn semibold_font" data-aos="slide-up" (click)="closeTab(false)">
      <span class="learn_more_parent inline_block">
        <span class="hover_circle inline_block"></span>
        <span class="learn_more_icon inline_block"><img class="lozad"
            [src]="cdn_url+'/assets/images/close_icon_dark.png'"></span>
        <span class="learn_more_text inline_block">Close</span>
      </span>
    </div>
  </div>
</div>
