import { FtSigninComponent } from './ft-gmat-prep/signin/ft-signin.component';
import { PrepareWithEGmatComponent } from './ft-gmat-prep/prepare-with-eGmat/prepare-with-eGmat.component';
import { DealPageHeaderComponent } from './deal-pages/common-pages/deal-page-header/deal-page-header.component';
import { LoginService } from './Services/login.service';
import { FaqScreenComponent } from './faq-screen/faq-screen.component';
import { DealCourseComparisonComponent } from './deal-pages/common-pages/deal-course-comparison/deal-course-comparison.component';
import { EverythingYouNeedWebinarComponent } from './webinar/everything-you-need/everything-you-need.component';
import { CommonService } from './Services/common.service';
import { UrlService } from './Services/UrlService';
import { ModalService } from './Services/modal.service';
import { APP_INITIALIZER, NgModule, Renderer2 } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home-page/home/home.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpClientService } from './Services/http-client.service';
import { HeaderComponent } from './header/header.component';
import { SigninComponent } from './signin/signin.component';
import { AchieverComponent } from './home-page/achiever/achiever.component';
import { EGmatLoadComponent } from './e-gmat-load/e-gmat-load.component';
import { DataService } from './Services/data.service';
import { ScoreComponent } from './home-page/score/score.component';
import { ScoreGraphComponent } from './home-page/score-graph/score-graph.component';
import { ScholarshipComponent } from './home-page/scholarship/scholarship.component';
import { SuccessDeliveryComponent } from './home-page/success-delivery/success-delivery.component';
import { PspComponent } from './home-page/psp/psp.component';
import { PersonalizedFeedbackComponent } from './home-page/personalized-feedback/personalized-feedback.component';
import { AiMaximizeComponent } from './home-page/ai-maximize/ai-maximize.component';
import { IframeComponent } from './iframe/iframe.component';
import { TestimonialComponent } from './home-page/testimonial/testimonial.component';
import { AnalyticsComponent } from './home-page/analytics/analytics.component';
import { AnalyticsCardComponent } from './home-page/analytics-card/analytics-card.component';
import { CaseStudyComponent } from './home-page/case-study/case-study.component';
import { CustomerSuccessChampionsComponent } from './home-page/customer-success-champions/customer-success-champions.component';
import { WebinarsComponent } from './home-page/webinars/webinars.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { EverythingYouNeedComponent } from './course-detail/everything-you-need/everything-you-need.component';
import { CourseDetailComponent } from './course-detail/course-detail/course-detail.component';
import { BuyNowComponent } from './course-detail/buy-now/buy-now.component';
import { ProvenMethodsComponent } from './course-detail/proven-methods/proven-methods.component';
import { PersonalGmatJourneyComponent } from './course-detail/personal-gmat-journey/personal-gmat-journey.component';
import { CourseComparisonComponent } from './course-detail/course-comparison/course-comparison.component';
import { CourseComparisonMenuComponent } from './course-detail/course-comparison-menu/course-comparison-menu.component';
import { MoneyBackGuaranteeComponent } from './course-detail/money-back-guarantee/money-back-guarantee.component';
import { WhatOurStudentsSayComponent } from './course-detail/what-our-students-say/what-our-students-say.component';
import { PlanAndPricingComponent } from './plan-and-pricing/plan-and-pricing/plan-and-pricing.component';
import { GmatSuccessStoryComponent } from './plan-and-pricing/gmat-success-story/gmat-success-story.component';
import { ProvenSolutionComponent } from './plan-and-pricing/proven-solution/proven-solution.component';
import { PricingComponent } from './plan-and-pricing/pricing/pricing.component';
import { PricingCardComponent } from './plan-and-pricing/pricing-card/pricing-card.component';
import { FaqComponent } from './faq/faq.component';
import { SolutionDetailComponent } from './plan-and-pricing/solution-detail/solution-detail.component';
import { WorldClassSupportComponent } from './plan-and-pricing/world-class-support/world-class-support.component';
import { WorldClassSupportCardComponent } from './plan-and-pricing/world-class-support-card/world-class-support-card.component';
import { RoiComponent } from './plan-and-pricing/roi/roi.component';
import { GmatJourneyComponent } from './plan-and-pricing/gmat-journey/gmat-journey.component';
import { EvaluationComponent } from './plan-and-pricing/evaluation/evaluation.component';
import { EvaluationTabComponent } from './plan-and-pricing/evaluation-tab/evaluation-tab.component';
import { VideoPopupComponent } from './video-popup/video-popup.component';
import {AboutUsComponent} from './about-us/about-us-home/about-us.component';
import { AboutUsJourneyComponent } from './about-us/about-us-journey/about-us-journey.component';
import { AboutUsTeamsComponent } from './about-us/about-us-teams/about-us-teams.component';
import { AboutUsFoundersComponent } from './about-us/about-us-founders/about-us-founders.component';
import { ConcernsComponent } from './plan-and-pricing/concerns/concerns.component';
import { ConcernCardComponent } from './plan-and-pricing/concern-card/concern-card.component';
import { AdmitsAndScholarshipComponent } from './admits-and-scholarships/admits-and-scholarship/admits-and-scholarship.component';
import { MbaSuccessStoryComponent } from './admits-and-scholarships/mba-success-story/mba-success-story.component';
import { ScoreSevenFortyPlusComponent } from './admits-and-scholarships/score-seven-forty-plus/score-seven-forty-plus.component';
import { ScoreSevenSixtyComponent } from './admits-and-scholarships/score-seven-sixty/score-seven-sixty.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { RegistrationComponent } from './registration/registration.component';
import { ScFreeRegistrationComponent } from './webinar/sc-free-registration/sc-free-registration.component';
import { TargetScoreComponent } from './webinar/target-score/target-score.component';
import { StudentsSayingComponent } from './webinar/students-saying/students-saying.component';
import { AboutTheSpeakerComponent } from './webinar/about-the-speaker/about-the-speaker.component';
import { NintyPercentAccuracyComponent } from './webinar/ninty-percent-accuracy/ninty-percent-accuracy.component';
import { WebinarDetailsComponent } from './webinar/webinar-details/webinar-details.component';
import { FlipClockComponent } from './flip-clock/flip-clock/flip-clock.component';
import { ClockComponent } from './flip-clock/clock/clock.component';
import { ClockLabelComponent } from './flip-clock/clock-label/clock-label.component';
import { WebinarLoginComponent } from './webinar/webinar-login/webinar-login.component';
import {CartComponent} from './cart/cart-home/cart.component';
import {SignInSignUpComponent} from './cart/signin-signup/signin-signup.component';
import {PaymentScreenComponent} from './cart/payment-screen/payment-screen.component';
import { RefreshCourseEnrollmentComponent } from './common/refresh-course-enrollment/refresh-course-enrollment.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { LoginComponent } from './common/login/login.component';
import { SignUpComponent } from './common/sign-up/sign-up.component';
import { SignInComponent } from './common/sign-in/sign-in.component';
import { ActivityRedirectComponent } from './common/activity-redirect/activity-redirect.component';
import { ChartsDataComparisonComponent } from './common/charts-data-comparison/charts-data-comparison.component';
import { CheckSegmentAndRedirectComponent } from './common/check-segment-and-redirect/check-segment-and-redirect.component';
import { CustomContentComponent } from './common/redirect/custom-content/custom-content.component';
import { GcSuccessStoriesComponent } from './common/gc-success-stories/gc-success-stories.component';
import { VideosRedirectComponent } from './common/videos-redirect/videos-redirect.component';
import { BarGraphComponent } from './deal-pages/common-pages/bar-graph/bar-graph.component';
import { SalesPagesComponent } from './deal-pages/sales-pages/sales-pages/sales-pages.component';
import { LimitedPeriodOfferComponent } from './deal-pages/sales-pages/limited-period-offer/limited-period-offer.component';
import { SuccessMetricsComponent } from './deal-pages/common-pages/success-metrics/success-metrics.component';
import { TopReasonsComponent } from './deal-pages/common-pages/top-reasons/top-reasons.component';
import { PersonalizedImprovementPlanComponent } from './deal-pages/common-pages/personalized-improvement-plan/personalized-improvement-plan.component';
import { CouponDetailsComponent } from './deal-pages/sales-pages/coupon-details/coupon-details.component';
import { DealPageFooterComponent } from './deal-pages/common-pages/deal-page-footer/deal-page-footer.component';
import { CourseOfferingPagesComponent } from './deal-pages/course-offering-pages/course-offering-pages/course-offering-pages.component';
import { CoursePricingComponent } from './deal-pages/course-offering-pages/course-pricing/course-pricing.component';
import { ProofOfSuccessComponent } from './deal-pages/common-pages/proof-of-success/proof-of-success.component';
import { DealGmatJourneyComponent } from './deal-pages/common-pages/deal-gmat-journey/deal-gmat-journey.component';
import { ProductCardComponent } from './deal-pages/common-pages/product-card/product-card.component';
import { JoinSessionComponent } from './common/free-session/join-session/join-session.component';
import { AchieveSevenfortyPlusComponent } from './ft-gmat-prep/achieve-sevenforty-plus/achieve-sevenforty-plus.component';
import { FtGmatPrepHomeComponent } from './ft-gmat-prep/ft-gmat-prep-home/ft-gmat-prep-home.component';
import { WhatStudentsSaysAboutComponent } from './ft-gmat-prep/what-students-says-about/what-students-says-about.component';
import { ThingsYouCanDoFreeTrialComponent } from './ft-gmat-prep/things-you-can-do-free-trial/things-you-can-do-free-trial.component';
import { MostCompregensiveFreeTrialComponent } from './ft-gmat-prep/most-compregensive-free-trial/most-compregensive-free-trial.component';
import { ContactUsChatComponent } from './common/contact-us-chat/contact-us-chat.component';
import { WebinarHeaderComponent } from './webinar/webinar-header/webinar-header.component';
import { FtGmatPrepFooterComponent } from './webinar/ft-gmat-prep-footer/ft-gmat-prep-footer.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule  } from "@angular/platform-browser/animations";
import { GoogleSigninBtnComponent } from './common/google-signin-btn/google-signin-btn.component';
import { APP_BASE_HREF, TitleCasePipe } from '@angular/common';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { ScoreGraphPieComponent } from './home-page/score-graph-pie/score-graph-pie.component';
import { NewSalesPagesComponent } from './deal-pages/sales-pages/new-sales-pages/new-sales-pages.component';
import { HeroSectionComponent } from './deal-pages/new-common-components/hero-section/hero-section.component';
import { NewLimitedPeriodOfferComponent } from './deal-pages/new-common-components/new-limited-period-offer/new-limited-period-offer.component';
import { NewProductCardComponent } from './deal-pages/new-common-components/new-product-card/new-product-card.component';
import { NewSuccessMetrixComponent } from './deal-pages/new-common-components/new-success-metrix/new-success-metrix.component';
import { AchieveSevenfortyPlusV2Component } from './ft-gmat-prep-v2/achieve-sevenforty-plus/achieve-sevenforty-plus-v2.component';
import { FtGmatPrepHomeV2Component } from './ft-gmat-prep-v2/ft-gmat-prep-home/ft-gmat-prep-home-v2.component';
import { MostCompregensiveFreeTrialV2Component } from './ft-gmat-prep-v2/most-compregensive-free-trial/most-compregensive-free-trial-v2.component';
import { FtSigninV2Component } from './ft-gmat-prep-v2/signin/ft-signin-v2.component';
import { ThingsYouCanDoFreeTrialV2Component } from './ft-gmat-prep-v2/things-you-can-do-free-trial/things-you-can-do-free-trial-v2.component';
import { FtGmatPrepFooterV2Component } from './ft-gmat-prep-v2/ft-gmat-prep-footer/ft-gmat-prep-footer-v2.component';
import { MostSuccessfullAndTrustedV2Component } from './ft-gmat-prep-v2/most-successfull-and-trusted-v2/most-successfull-and-trusted-v2.component';
import { NewGmatJourneyComponent } from './deal-pages/new-common-components/new-gmat-journey/new-gmat-journey.component';
import { NewDealPageHeaderComponent } from './deal-pages/new-common-components/new-deal-page-header/new-deal-page-header.component';
import { NewCouponDetailsComponent } from './deal-pages/new-common-components/new-coupon-details/new-coupon-details.component';
import { MostPopularAndTrustedPrepCourseComponent } from './deal-pages/new-common-components/most-popular-and-trusted-prep-course/most-popular-and-trusted-prep-course.component';
import { WhatStudentsSaysAboutV2Component } from './ft-gmat-prep-v2/what-students-says-about/what-students-says-about-v2.component';
import { CommonFooterGmatPrepComponent } from './ft-gmat-prep-v2/common-footer-gmat-prep/common-footer-gmat-prep.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NewPersonalizedImprovementPlanComponent } from './deal-pages/new-common-components/new-personalized-improvement-plan/new-personalized-improvement-plan.component';
import { ReachYourGoalComponent } from './deal-pages/new-common-components/reach-your-goal/reach-your-goal.component';
import { AuthorCardsComponent } from './deal-pages/new-common-components/author-cards/author-cards.component';
import { NewProofOfSuccessComponent } from './deal-pages/new-common-components/new-proof-of-success/new-proof-of-success.component';
import { TopThreeReasonsComponent } from './deal-pages/new-common-components/top-three-reasons/top-three-reasons.component';
import { FtGmatPrepFooterGfeV2Component } from './ft-gmat-prep-gfe-v2/ft-gmat-prep-footer/ft-gmat-prep-footer-gfe-v2.component';
import { AchieveSevenfortyPlusGfeV2Component } from './ft-gmat-prep-gfe-v2/achieve-sevenforty-plus/achieve-sevenforty-plus-gfe-v2.component';
import { FtGmatPrepHomeGfeV2Component } from './ft-gmat-prep-gfe-v2/ft-gmat-prep-home/ft-gmat-prep-home-gfe-v2.component';
import { MostCompregensiveFreeTrialGfeV2Component } from './ft-gmat-prep-gfe-v2/most-compregensive-free-trial/most-compregensive-free-trial-gfe-v2.component';
import { MostSuccessfullAndTrustedGfeV2Component } from './ft-gmat-prep-gfe-v2/most-successfull-and-trusted-v2/most-successfull-and-trusted-gfe-v2.component';
import { FtSigninGfeV2Component } from './ft-gmat-prep-gfe-v2/signin/ft-signin-gfe-v2.component';
import { ThingsYouCanDoFreeTrialGfeV2Component } from './ft-gmat-prep-gfe-v2/things-you-can-do-free-trial/things-you-can-do-free-trial-gfe-v2.component';
import { WhatStudentsSaysAboutGfeV2Component } from './ft-gmat-prep-gfe-v2/what-students-says-about/what-students-says-about-gfe-v2.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TopReasonTileComponent } from './deal-pages/new-common-components/top-reason-tile/top-reason-tile.component';
import { CommonFooterGmatPrepDealComponent } from './deal-pages/new-common-components/common-footer-gmat-prep-deal/common-footer-gmat-prep-deal.component';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { ChristmasDealPageComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-deal-page/christmas-deal-page.component';
import { ChristmasHeroSectionComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-hero-section/christmas-hero-section.component';
import { ChristmasLimitedPeriodOfferComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-limited-period-offer/christmas-limited-period-offer.component';
import { ChristmasGmatJourneyComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-gmat-journey/christmas-gmat-journey.component';
import { ChristmasCouponDetailsComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-coupon-details/christmas-coupon-details.component';
import { ChristmasSuccessMatrixComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-success-matrix/christmas-success-matrix.component';
import { ChristmasMostPopularAndTrustedPrepCourseComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-most-popular-and-trusted-prep-course/christmas-most-popular-and-trusted-prep-course.component';
import { ChristmasPersonalizedImprovementPlanComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-personalized-improvement-plan/christmas-personalized-improvement-plan.component';
import { ChristmasReachYourGoalComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-reach-your-goal/christmas-reach-your-goal.component';
import { ChristmasProofOfSuccessComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-proof-of-success/christmas-proof-of-success.component';
import { ChristmasAuthorCardsComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-author-cards/christmas-author-cards.component';
import { ChristmasDealPageHeaderComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-deal-page-header/christmas-deal-page-header.component';
import { GceOneMComponent } from './deal-pages/sales-pages/christmas-sales-pages/gce-one-m/gce-one-m.component';
import { PspInfoTabsComponent } from './deal-pages/new-common-components/psp-info-tabs/psp-info-tabs.component';

declare const google:any;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SigninComponent,
    AchieverComponent,
    EGmatLoadComponent,
    ScoreComponent,
    ScoreGraphComponent,
    ScholarshipComponent,
    SuccessDeliveryComponent,
    PspComponent,
    PersonalizedFeedbackComponent,
    AiMaximizeComponent,
    IframeComponent,
    TestimonialComponent,
    AnalyticsComponent,
    AnalyticsCardComponent,
    CaseStudyComponent,
    CustomerSuccessChampionsComponent,
    WebinarsComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    EverythingYouNeedComponent,
    CourseDetailComponent,
    BuyNowComponent,
    ProvenMethodsComponent,
    PersonalGmatJourneyComponent,
    CourseComparisonComponent,
    CourseComparisonMenuComponent,
    MoneyBackGuaranteeComponent,
    WhatOurStudentsSayComponent,
    PlanAndPricingComponent,
    GmatSuccessStoryComponent,
    ProvenSolutionComponent,
    PricingComponent,
    PricingCardComponent,
    FaqComponent,
    SolutionDetailComponent,
    WorldClassSupportComponent,
    WorldClassSupportCardComponent,
    RoiComponent,
    GmatJourneyComponent,
    EvaluationComponent,
    EvaluationTabComponent,
    VideoPopupComponent,
    AboutUsComponent,
    AboutUsJourneyComponent,
    AboutUsTeamsComponent,
    AboutUsFoundersComponent,
    ConcernsComponent,
    ConcernCardComponent,
    AdmitsAndScholarshipComponent,
    MbaSuccessStoryComponent,
    ScoreSevenFortyPlusComponent,
    ScoreSevenSixtyComponent,
    ForgotPasswordComponent,
    PasswordResetSuccessComponent,
    RegistrationComponent,
    ScFreeRegistrationComponent,
    TargetScoreComponent,
    StudentsSayingComponent,
    EverythingYouNeedWebinarComponent,
    AboutTheSpeakerComponent,
    NintyPercentAccuracyComponent,
    WebinarDetailsComponent,
    FlipClockComponent,
    ClockComponent,
    ClockLabelComponent,
    WebinarLoginComponent,
    CartComponent,
    SignInSignUpComponent,
    PaymentScreenComponent,
    FaqScreenComponent,
    RefreshCourseEnrollmentComponent,
    PageNotFoundComponent,
    LoginComponent,
    SignUpComponent,
    SignInComponent,
    ActivityRedirectComponent,
    ChartsDataComparisonComponent,
    CheckSegmentAndRedirectComponent,
    CustomContentComponent,
    GcSuccessStoriesComponent,
    VideosRedirectComponent,
    BarGraphComponent,
    SalesPagesComponent,
    LimitedPeriodOfferComponent,
    SuccessMetricsComponent,
    TopReasonsComponent,
    PersonalizedImprovementPlanComponent,
    CouponDetailsComponent,
    DealPageFooterComponent,
    CourseOfferingPagesComponent,
    CoursePricingComponent,
    ProofOfSuccessComponent,
    DealGmatJourneyComponent,
    DealCourseComparisonComponent,
    ProductCardComponent,
    DealPageHeaderComponent,
    JoinSessionComponent,
    AchieveSevenfortyPlusComponent,
    PrepareWithEGmatComponent,
    FtGmatPrepHomeComponent,
    WhatStudentsSaysAboutComponent,
    ThingsYouCanDoFreeTrialComponent,
    MostCompregensiveFreeTrialComponent,
    FtSigninComponent,
    ContactUsChatComponent,
    WebinarHeaderComponent,
    FtGmatPrepFooterComponent,
    GoogleSigninBtnComponent,
    ScoreGraphPieComponent,
    NewSalesPagesComponent,
    HeroSectionComponent,
    NewLimitedPeriodOfferComponent,
    NewProductCardComponent,
    NewSuccessMetrixComponent,
    AchieveSevenfortyPlusV2Component,
    FtGmatPrepHomeV2Component,
    MostCompregensiveFreeTrialV2Component,
    FtSigninV2Component,
    ThingsYouCanDoFreeTrialV2Component,
    FtGmatPrepFooterV2Component,
    MostSuccessfullAndTrustedV2Component,
    WhatStudentsSaysAboutV2Component,
    CommonFooterGmatPrepComponent,
    NewGmatJourneyComponent,
    ChristmasGmatJourneyComponent,
    NewDealPageHeaderComponent,
    NewCouponDetailsComponent,
    MostPopularAndTrustedPrepCourseComponent,
    WhatStudentsSaysAboutV2Component,
    NewPersonalizedImprovementPlanComponent,
    ReachYourGoalComponent,
    AuthorCardsComponent,
    NewProofOfSuccessComponent,
    TopThreeReasonsComponent,
    FtGmatPrepFooterGfeV2Component,
    AchieveSevenfortyPlusGfeV2Component,
    FtGmatPrepHomeGfeV2Component,
    MostCompregensiveFreeTrialGfeV2Component,
    MostSuccessfullAndTrustedGfeV2Component,
    FtSigninGfeV2Component,
    ThingsYouCanDoFreeTrialGfeV2Component,
    WhatStudentsSaysAboutGfeV2Component,
    TopReasonTileComponent,
    CommonFooterGmatPrepDealComponent,
    ChristmasDealPageComponent,
    ChristmasHeroSectionComponent,
    ChristmasLimitedPeriodOfferComponent,
    ChristmasCouponDetailsComponent,
    ChristmasSuccessMatrixComponent,
    ChristmasMostPopularAndTrustedPrepCourseComponent,
    ChristmasPersonalizedImprovementPlanComponent,
    ChristmasReachYourGoalComponent,
    ChristmasProofOfSuccessComponent,
    ChristmasAuthorCardsComponent,
    ChristmasDealPageHeaderComponent,
    GceOneMComponent,
    PspInfoTabsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    FontAwesomeModule,
    ScriptLoaderModule
  ],
  providers: [
    HttpClientService,
    DataService,
    ModalService,
    UrlService,
    CommonService,
    LoginService,
    TitleCasePipe,
    { provide: APP_BASE_HREF, useValue: environment.googleBtnURL }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function playerFactory() {
  return player;
}
