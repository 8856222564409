<div class="loading_screen">
  <svg id="logo" width="316" height="59" viewBox="0 0 316 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.7328 58.88C18.9328 58.88 14.7728 58.0533 11.2528 56.4C7.78616 54.6933 5.11949 52.2933 3.25282 49.2C1.38616 46.1067 0.45282 42.4533 0.45282 38.24C0.45282 34.24 1.33282 30.6933 3.09282 27.6C4.90615 24.5067 7.35949 22.1067 10.4528 20.4C13.5995 18.64 17.1728 17.76 21.1728 17.76C25.1195 17.76 28.5328 18.5867 31.4128 20.24C34.3462 21.8933 36.5862 24.2667 38.1328 27.36C39.7328 30.4 40.5328 33.9733 40.5328 38.08V41.2H14.0528C14.5328 43.76 15.5995 45.6 17.2528 46.72C18.9062 47.84 21.1995 48.4 24.1328 48.4C26.1595 48.4 28.2662 48.1067 30.4528 47.52C32.6928 46.88 34.6662 45.9733 36.3728 44.8L39.8928 54.16C37.8661 55.6 35.3862 56.7467 32.4528 57.6C29.5195 58.4533 26.6128 58.88 23.7328 58.88ZM21.8128 26.96C19.6795 26.96 17.9195 27.6 16.5328 28.88C15.1995 30.16 14.3462 32.0267 13.9728 34.48H28.8528C28.6928 31.9733 27.9995 30.1067 26.7728 28.88C25.5995 27.6 23.9461 26.96 21.8128 26.96Z"
      stroke="#7f7f7f" stroke-width="1" />
    <path d="M48.6416 42.08V30.96H74.2416V42.08H48.6416Z" stroke="#7f7f7f" stroke-width="1" />
    <path
      d="M113.147 58.88C106.693 58.88 101.2 57.68 96.6666 55.28C92.1866 52.88 88.7732 49.52 86.4266 45.2C84.0799 40.88 82.9066 35.84 82.9066 30.08C82.9066 24.1067 84.1332 18.9333 86.5866 14.56C89.0932 10.1333 92.6666 6.72 97.3066 4.32C101.947 1.92 107.547 0.720001 114.107 0.720001C118.053 0.720001 121.813 1.25333 125.387 2.32C128.96 3.33333 131.867 4.72 134.107 6.48L129.627 17.84C127.173 16.24 124.693 15.0933 122.187 14.4C119.68 13.6533 117.013 13.28 114.187 13.28C108.8 13.28 104.773 14.72 102.107 17.6C99.4932 20.48 98.1866 24.64 98.1866 30.08C98.1866 35.52 99.5199 39.6267 102.187 42.4C104.853 45.1733 108.8 46.56 114.027 46.56C116.48 46.56 119.227 46.1867 122.267 45.44V36.56H110.987V26.24H134.267V54.56C131.28 55.9467 127.947 57.0133 124.267 57.76C120.587 58.5067 116.88 58.88 113.147 58.88Z"
      stroke="#7f7f7f" stroke-width="1" />
    <path
      d="M144.272 58V1.6H156.512L174.512 33.92L192.512 1.6H204.432V58H191.152V27.36L178.272 49.68H170.272L157.552 27.68V58H144.272Z"
      stroke="#7f7f7f" stroke-width="1" />
    <path
      d="M208.015 58L234.415 1.6H246.015L272.415 58H257.695L252.815 46.56H227.615L222.735 58H208.015ZM240.095 16.96L232.415 35.28H247.935L240.255 16.96H240.095Z"
      stroke="#7f7f7f" stroke-width="1" />
    <path id="last_frame" d="M282.783 58V13.6H264.863V1.6H315.423V13.6H297.503V58H282.783Z" stroke="#7f7f7f"
      stroke-width="1" />
  </svg>
</div>
