import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;
declare const push_site_event: any;
@Component({
  selector: 'app-admits-and-scholarship',
  templateUrl: './admits-and-scholarship.component.html',
  styleUrls: ['./admits-and-scholarship.component.css'],
})
export class AdmitsAndScholarshipComponent implements OnInit, OnDestroy {
  mba_success_story_data: any;
  score_seven_forty_plus_data: any;
  score_seven_sixty_data: any;
  link_tags;
  meta_tags;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe(version => {
      if(version > 0){
        this.httpService
      .getJson(
        'admits-and-scholarships.json?version='+version
      )
      .subscribe((response) => {
        this.mba_success_story_data = response['mba_success_story_data'];
        this.score_seven_forty_plus_data =
          response['score_seven_forty_plus_data'];
        this.score_seven_sixty_data = response['score_seven_sixty_data'];
        if (response['title']) {
          this.titleService.setTitle(response['title']);
        }
        if (response['meta_tags']) {
          this.meta_tags = response['meta_tags'];
          this.metaService.addTags(response['meta_tags']);
        }
        if(response['link_tags']){
          this.link_tags= response['link_tags'];
          this.seoService.createMultiLinks(response['link_tags']);
        }
        if(response['json_ld']){
          this.seoService.createJsonLd(response['json_ld']);
        }
      });
      }
    });

    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event("accessed site");
        var eventName = 'Viewed Admits Scholarships Page';
        var VAS_properties = createproperties_nonpropagation(eventName);
        VAS_properties['VAS_Page_Name'] = 'main';
        push_event_properties(eventName, VAS_properties);
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
