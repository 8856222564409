import { SEOService } from './../../Services/seo.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from './../../Services/common.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { ModalService } from './../../Services/modal.service';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../Services/data.service';

declare const getUTMParametersFromCookies: any;
declare const getCookie: any;
declare const identication: any;
declare const push_km_event_login_propagation_redirect: any;
declare const push_event_properties: any;
declare const push_events_to_lambda_SEG: any;
declare const pushEventsToLamda: any;
declare const push_km_event_propagation_session_registration_redirect;

declare var auth2: any;
declare const push_eventto_segment: any;
declare const push_km_event_google_registration_propagation_redirect: any;
declare const multipleEmailTest: any;
declare const push_coreg_login_event_redirect: any;
declare const push_km_event_coreg_registration_propagation_redirect: any;
declare var eventsArray: any;
declare const push_site_event: any;
@Component({
  selector: 'app-sc-free-registration',
  templateUrl: './sc-free-registration.component.html',
  styleUrls: ['./sc-free-registration.component.css'],
})
export class ScFreeRegistrationComponent implements OnInit, OnDestroy {
  target_score_data: any;
  subject: any;
  student_saying_data: any;
  everything_you_need_data: any;
  about_speaker_data: any;
  ninty_percent_accuracy_data: any;
  webinarEventName = null;
  webinarDetail;
  canRegister = false;
  subjectName = window.location.pathname.split('/').pop();
  background_color;
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  publicIP = '';
  lmsURL = environment.bz_app_url;

  google_submitted = false;
  username_google: any;
  id_token_google: any;
  bzUser: any;
  state_user = 'email';
  submitted = false;
  registrationModeSite = environment.registrationModeSite;
  login_error_msg = '';
  link_tags;
  meta_tags;

  post_registration_page_url = 'post-registration-sc-session';

  constructor(
    private dataService: DataService,
    private httpService: HttpClientService,
    private modalService: ModalService,
    private commonService: CommonService,
    private titleService: Title,
    private metaService: Meta,
    private httpClientService: HttpClientService,
    private router: Router,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    this.getUsersIp();
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('webinars.json?version=' + version)
          .subscribe((response) => {
            this.getWebinarDetails(response['webinars_data']);
          });
        this.getDataOfPage(version);
      }
    });
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
      }
    });
  }
  getWebinarDetails(webinars_data) {
    let matches = webinars_data.webinars.filter((v, i) => {
      return v.subject === this.subjectName;
    });
    if (matches.length > 0) {
      this.webinarDetail = matches[0];
      this.canRegister = true;
    }
  }

  extractKeyValue(obj, value) {
    return Object.keys(obj)[Object.values(obj)?.indexOf(value)];
  }
  getDataOfPage(version) {
    this.httpService
      .getJson(this.subjectName + '.json?version=' + version)
      .subscribe((response) => {
        this.post_registration_page_url =
          response['post_registration_page_url'];
        this.target_score_data = response['target_score_data'];
        if (response['target_score_data']['background_color']) {
          this.background_color =
            response['target_score_data']['background_color'];
        }
        this.subject = response['subject'];
        this.student_saying_data = response['student_saying_data'];
        this.everything_you_need_data = response['everything_you_need_data'];
        this.about_speaker_data = response['about_speaker_data'];
        this.ninty_percent_accuracy_data =
          response['ninty_percent_accuracy_data'];
        this.webinarEventName = response['webinarEventName'];
        if (response['title']) {
          this.titleService.setTitle(response['title']);
        }
        if (response['meta_tags']) {
          this.meta_tags = response['meta_tags'];
          this.metaService.addTags(response['meta_tags']);
        }
        if (response['link_tags']) {
          this.link_tags = response['link_tags'];
          this.seoService.createMultiLinks(response['link_tags']);
        }
        if (response['json_ld']) {
          this.seoService.createJsonLd(response['json_ld']);
        }
      });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
  ModalOpen() {
    if (this.webinarEventName && !this.checkCookieBasedLogin(false)) {
      this.modalService.open_webinar_login();
      this.dataService.updateWebinarEventName = this.webinarEventName;
      this.dataService.updateWebinarPostRegPageUrl =
        this.post_registration_page_url;
    }
  }
  checkCookieBasedLogin(fire_login_event = true) {
    var userCookieFlag = this.checkUserCookie(this.userLoginCookie);
    if (userCookieFlag) {
      var userCookieVal = this.getUserCookie(this.userLoginCookie);
      var userInfo = userCookieVal.split('|');
      if (userInfo.length > 0) {
        if (userInfo[2]) {
          var userId = userInfo[2];
          this.saveUTMParemeters(userId);
          this.freeTrialUser = userInfo[4] == 'true' ? true : false;
          identication(userId);
          if (fire_login_event) {
            push_km_event_login_propagation_redirect('completed login', userId);
          }
          this.fireLoggedInFromBrowserEvent(userId);
          this.setUserCookie(this.userLoginCookie, userCookieVal, 30);
          this.logoutMoodle();
          this.fireRegisteredForSession(
            'registered for free session',
            this.webinarEventName,
            userId
          );
          this.pushEventsToLamdaAndRedirect(
            environment.learnDomainURL + this.post_registration_page_url
          );
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  fireRegisteredForSession(eventname, webinarEventName, userId) {
    push_km_event_propagation_session_registration_redirect(
      eventname,
      webinarEventName,
      userId
    );
  }
  pushEventsToLamdaAndRedirect(redirectURL) {
    var targetURL = this.getUrlParameter('redirectURL');

    if (targetURL != null && targetURL != undefined && targetURL != '') {
      redirectURL = targetURL;
    }
    pushEventsToLamda(redirectURL, eventsArray);
  }
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain=' +
      window.location.hostname +
      ';path=/';
  }
  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }
  getUsersIp() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        this.publicIP = ip['ip'];
      });
  }
  fireLoggedInFromBrowserEvent(username) {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
    push_events_to_lambda_SEG(eventName, properties, username);
  }
  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpService.saveUTMParemeters(utmParameters).subscribe();
  }
  getUserCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkUserCookie(cookieName) {
    var user = getCookie(cookieName);
    if (user != '' && user != null) {
      return true;
    } else {
      return false;
    }
  }
  googleLoginClickEvent(event) {
    if (this.webinarEventName && !this.checkCookieBasedLogin(false)) {
      this.googleSignInCallback(event);
    }
  }
  // googleLoginClick() {
  //   this.dataService.getEventScriptStatus.subscribe((status) => {
  //     if (status) {
  //       this.google_submitted = true;
  //       auth2.signIn().then(() => {
  //         this.googleSignInCallback();
  //       });
  //     }
  //   });
  // }

  googleSignInCallback(user) {
    if (user) {
      this.google_submitted = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      this.isBlitzkriegUser(user.email);
    } else {
      this.login_error_msg = 'There is a problem with google sign in';
    }
  }
  isBlitzkriegUser(username, flag = true) {
    var response;
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
          this.state_user = 'password';
        } else {
          this.bzUser = false;
          this.state_user = 'register';
        }
        if (flag) {
          this.googleSignIn(this.id_token_google, this.username_google);
        } else {
          this.submitted = false;
        }
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
    return response;
  }

  googleSignIn(id_token, username) {
    if (this.bzUser) {
      this.googleLoginToBlitzkrieg(id_token, username, false);
    } else {
      if (this.registrationModeSite == 'blitzkrieg') {
        this.googleLoginToBlitzkrieg(id_token, username, true);
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }

  googleLoginToBlitzkrieg(id_token, username, registerUserFlag) {
    multipleEmailTest(username);
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
        if (registerUserFlag) {
          push_km_event_google_registration_propagation_redirect(
            'completed registration',
            username
          );
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username);
      },
      (error) => {
        this.login_error_msg = 'Server error : Please contact support';
        this.google_submitted = false;
      }
    );
  }

  completeLoginAndRedirect(username) {
    this.logoutMoodle();
    var loginCount = 1;
    push_km_event_login_propagation_redirect('completed login', username);
    this.getBasicUserInfo(username);
  }
  getBasicUserInfo(username) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            push_coreg_login_event_redirect(
              'Completed Coreg Login',
              data['coregPartner'],
              username
            );
            push_km_event_coreg_registration_propagation_redirect(
              'completed registration',
              username,
              data['coregPartner']
            );
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
        this.redirectAfterInfo(username);
      },
      (error) => {}
    );
  }
  redirectAfterInfo(username) {
    var fname = '';
    var lname = '';
    var userName = username;
    var loginTime = new Date().getTime();
    var cookieValues =
      fname +
      '|' +
      lname +
      '|' +
      userName +
      '|' +
      loginTime +
      '|' +
      this.freeTrialUser;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);
    this.fireRegisteredForSession(
      'registered for free session',
      this.webinarEventName,
      username
    );
    this.pushEventsToLamdaAndRedirect(
      environment.learnDomainURL + this.post_registration_page_url
    );
  }
}
