import { DataService } from '../../Services/data.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { UntypedFormBuilder, Validators } from '@angular/forms';

declare var Stripe:any;


@Component({
  selector: 'app-payment-screen',
  templateUrl: './payment-screen.component.html',
  styleUrls: ['./payment-screen.component.css']
})
export class PaymentScreenComponent implements OnInit {

  @Input() paymentData;
  @Output() sendPaymentSuccessToCart = new EventEmitter<Boolean>();
  @Output() sendPaymentStartedToCart = new EventEmitter<String>();

  cdn_url = environment.cdn_url;

  showSignUpForm:boolean = false;

  stripePublicKey:string = environment.stripePublicKey;
  paymentFormValid:boolean = false;
  paymentForm:any;
  purchasedExtension:any;
  validStringPattern:string="^[A-Za-z0-9.\\s ]+$";
  validAddressPattern:string="^[A-Za-z0-9.,-/\\s ]+$";
  Card:any;
  disablePayBtn:boolean=false;
  refreshSubscriptionURL:string = "https://e-gmat.com/refresh-subscription/";
  processStatus:string = "";
  countries:any;
  paymentFailed:boolean = false;
  paymentSuccess:boolean = false;
  orderNumber:string="";
  timeLeft: number = 5;
  interval;
  paymentFailDetails = {};

  constructor(
    private httpClientService: HttpClientService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private dataService: DataService
   ) {}

  ngOnInit(): void {
    this.getCountryList();
    this.paymentForm = this.formBuilder.group({
      'nameOnCard': ['', [Validators.required,Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.validStringPattern)]],
      'address1': ['', [Validators.required,Validators.minLength(3), Validators.maxLength(100), Validators.pattern(this.validAddressPattern)]],
      'address2': ['', [Validators.maxLength(100), Validators.pattern(this.validAddressPattern)]],
      'country': ['IN', [Validators.required]]
    });
    this.dataService.getStripLoaded.subscribe((status) => {
      if(status){
        this.setupPaymentForm();
      }
    });
  }

  get nameOnCard() { return this.paymentForm.get('nameOnCard'); }
  get address1() { return this.paymentForm.get('address1'); }
  get address2() { return this.paymentForm.get('address2'); }
  get country() { return this.paymentForm.get('country'); }


  getCountryList()
  {
    this.httpClientService.get('fetch-countries').subscribe((data)=>{
      if(data)
      {
        console.log("country data:::")
        console.log(data);
          this.countries = data;
      }
    });
  }

  setupPaymentForm()
  {
    var then = this;
    var stripe = Stripe(this.stripePublicKey);
    var elements = stripe.elements();
    var style = {
      base: {
        fontSize:"14px",
        color: "#32325d",
        fontWeight: '400',
        "::placeholder": {
             color: "#E8E8E8"
           }
      }
    };

    this.Card = elements.create("card", {
      hidePostalCode: true,
      style: style
    });
    setTimeout(()=>{
        this.Card.mount("#card-element");
        this.Card.on('change', ({error}) => {
            let displayError = document.getElementById('card-errors');
            if(then.processStatus!="")
            {
              then.processStatus = "";
            }
            if (error) {
              displayError.textContent = error.message;
              this.paymentFormValid = false;
            } else {
              displayError.textContent = '';
              this.paymentFormValid = true;
            }
          });

          var form = document.getElementById('payment-form');

          form.addEventListener('submit', function(ev) {
            ev.preventDefault();
            console.log(then.nameOnCard.value);
            console.log(then.Card);
            then.sendPaymentStartedToCart.emit("started");
            then.processStatus = "Processing your payment...";
            then.disablePayBtn = true;
            stripe.confirmCardPayment(then.paymentData["paymentIntentCS"], {
              payment_method: {
                card: then.Card,
                billing_details: {
                  name: then.nameOnCard.value,
                  address:{
                    country:then.country.value,
                    line1:then.address1.value,
                    line2:then.address2.value
                  }
                }
              }
            }).then(function(result) {
              console.log("RESULT::::::::");
              console.log(result);
              let paymentData = {};
              if (result.error) {
                then.sendPaymentSuccessToCart.emit(false);
                then.paymentFailDetails["code"] = result.error.code;
                then.paymentFailDetails["decline_code"] = (result.error.decline_code != null && result.error.decline_code.trim() != "") ?result.error.decline_code : "Declined" ;
                then.paymentFailDetails["message"] = result.error.message;

                then.processStatus = "Payment was unsucessful";
                then.paymentFailed = true;
                paymentData['success'] = false;
                then.disablePayBtn = false;
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded')
                {
                  then.fetchOrderId(result.paymentIntent.description);
                  // Show a success message to your customer
                  // There's a risk of the customer closing the window before callback
                  // execution. Set up a webhook or plugin to listen for the
                  // payment_intent.succeeded event that handles any business critical
                  // post-payment actions.
                  then.processStatus = "Payment was successful";
                  paymentData['success'] = true;
                  paymentData['stripeStatus'] = result.paymentIntent.status;
                  // paymentData['secretId'] = result.paymentIntent.client_secret;
                  paymentData['paymentId'] = result.paymentIntent.id;

                  then.savePaymentData(paymentData,true);
                }
                else
                {
                  then.processStatus = "Payment was unsuccessful";
                  then.paymentFailed = true;
                  then.sendPaymentSuccessToCart.emit(false);
                  paymentData['success'] = false;
                  paymentData['paymentId'] = result.paymentIntent.id;
                  paymentData['stripeStatus'] = result.paymentIntent.status;
                  then.savePaymentData(paymentData,false);
                }
              }
            });

          });
      },500);
  }

  fetchOrderId(orderDescription){
    let descriptionArray = orderDescription.split("#");
    this.orderNumber = descriptionArray[1];
  }

  savePaymentData(paymentInfo,paymentSuccessFlag){
    let paymentStatus = paymentSuccessFlag?'successful':'unsuccessful';
    this.processStatus = "Saving Order Details...";
    paymentInfo['username'] = this.paymentData["username"];
    paymentInfo['cardName'] = this.nameOnCard.value;
    paymentInfo['discountCode'] =  this.paymentData["discountCode"] != undefined ? this.paymentData["discountCode"] : null;
    this.httpClientService.post('save-payment-data',paymentInfo).subscribe((data)=>{
      console.log("in save payment data::::");
      console.log(data);
      if(data["success"]){
        this.processStatus = "Saved Order Details Successfully";
        if(paymentInfo['success'])
        {
            setTimeout(()=>{
              this.paymentSuccess = true;
              this.disablePayBtn = false;
              this.sendPaymentSuccessToCart.emit(true);
              this.startTimer();
            },1000);
        }
        else
        {
            this.disablePayBtn = false;
            this.processStatus = "Payment was " + paymentStatus + ", Please try again...";
        }
      }
      else{
          this.disablePayBtn = false;
          this.processStatus = "Payment was " + paymentStatus + ", Failed to save order status...";
      }
    });
  }
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 1) {
        this.timeLeft--;
      } else {
        this.router.navigateByUrl('refresh-course-enrollment?target=page/your_study_plan');
      }
    },1000)
}
refreshPaymentFields(){
  this.paymentFailed = false;
  this.setupPaymentForm();
}

}
