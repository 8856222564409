<div class="section section_money_back text_center" *ngIf="money_back_guarantee_data">
  <div class="page_container">
    <div class="row custom_row">
      <div class="col-md-8 text_left mobile_text_center">
        <div class="section_money_back_sub_head" data-aos="slide-up">
          {{money_back_guarantee_data['header']['first_line']}}
        </div>
        <div class="section_money_back_head" data-aos="slide-up">
          {{money_back_guarantee_data['header']['second_line']}}
        </div>
        <div class="section_money_back_text" data-aos="slide-up">
          {{money_back_guarantee_data['header']['third_line']}}
        </div>
      </div>
      <div class="col-md-4">
        <div class="img_container" data-aos="slide-up">
          <img class="lozad money_back_img" data-aos="slide-up"
            [src]="money_back_guarantee_data['image_url']">
        </div>
      </div>
    </div>
  </div>
</div>
