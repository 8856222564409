<div class="page_container">
  <h2 class="learn_proven_heading">Terms &amp; Conditions of Use</h2>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    Welcome to
    YourPerfectScore, a self paced learning system for your GMAT Preparations.<br><br>e-gmat.com is a wholly owned
    subsidiary of YourPerfectScore LLC and this agreement governs the terms and conditions for e-gmat.com and
    e-gmat.net.<br><br>The E-GMAT service and forum (referred to as YPS from here on) are operated by YourPerfectScore
    LLC. By using our website at e-gmat.com or e-gmat.net (referred to as .e-gmat.com. ), you (the .User.) signify that
    you have read and understood these terms and conditions (.Terms of Use.) and that you agree to be bound by them,
    regardless of whether or not you are a registered member of e-gmat.com.<br><br>YPS reserves the right to change,
    modify, or delete portions of this Terms of Use at any time without any further notice. Your continued use of
    e-gmat.com or any other YPS properties constitutes agreement to these changes.<br><br>BY USING THE YOURPERFECTSCORE
    SERVICE, YOU AGREE TO THE TERMS AND CONDITIONS OF THIS USER AGREEMENT AND CREATE A BINDING CONTRACT BETWEEN YOU AND
    YOURPERFECTSCORE. If you do not accept the terms and conditions contained in this User Agreement, do not use the YPS
    Service.</div>
  <h4 class="wdrd1">1. Use of the YourPerfectScore Service</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    TTo use YPS
    service, you should be 13 years of age or older. Any registration, use or access to the site by anyone under 13
    years of age is a violation of the terms of use. Using this site constitutes a confirmation that you are 13 years of
    age or older.<br>To authorized users, YPS grants a terminable non-exclusive, non-transferable license to use the YPS
    Service solely for your personal, noncommercial, and noncompetitive use. The purpose of the license is to help users
    prepare for the GMAT. No site content may be modified, distributed, downloaded, posted or sold in any forms or
    means, either complete or in part, without the prior permission from the Company.</div>
  <h4 class="wdrd1">2. User Conduct</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    YPS YPS offers
    the service from e-gmat.com to help authorized users improve their score in the GMAT. You agree to use the site as
    per the terms and conditions listed below:<br>
    You agree, as a condition of your registration at YPS to provide accurate registration information including a valid
    email address, name etc. YPS has the right to terminate your registration without prior notice or issuance of a
    refund if it has reason to believe that you have misrepresented yourself.<br>
    You agree not to disassemble, copy, distribute, resell, loan, share, or reverse engineer any content on YPS.
    Furthermore, you also shall not translate or prepare derivative works of any of the content on YPS. You also
    understand that YPS holds the right to terminate your license without any notice if it has reasonable doubt that you
    intend to or have attempted any of the above.<br>
    Users take on full responsibility of the material that they post at e-gmat.com. YPS does not have any obligation to
    preview, screen, or monitor any content that may be added to e-gmat.com.<br>
    Users further agree not to a) harvest or collect email address or contact information of other users on YPS, b) use
    the service in an unlawful manner that could overburden, damage or disable the site or impair it to a degree, c)
    upload unlawful, defamatory content or make public any private information such as social security numbers,
    addresses, or bank account information of individual..</div>
  <h4 class="wdrd1">3. Email communication and Use of Cookies</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    From time to time
    YPS may place a cookie on your computer. A “cookie” is a small line of code that is stored in your browser when you
    visit a cookie-enabled website. A cookie allows us to track and keep records on user activity and personalize your
    learning. Most browsers are initially set to accept cookies. If you configure your browser to reject cookies, it is
    possible that some parts of the Website will not function. Moreover, to use the Services, your browser must accept
    cookies.</div>
  <h4 class="wdrd1">4. Return Policy</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">

    YPS will refund your complete course fee, if you inform us that you are not satisfied with the course, within 7 days
    of your purchase date. You will
    not be eligible for refund if you have done any one of the below.<p></p>
    <p>1. You have accessed the course for more than 7 hours (note that in 7 hours you can easily watch 16 files).<br>
      2. You have attempted more than one SIGma-X Mock Test. </p>
    <p>Please note that YPS is not obligated to issue any refund if you do not meet any of the criteria above.</p>
  </div>
  <h4 class="wdrd1">5. Limited Liability</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    Although YPS has
    a rigorous testing process to ensure that all the material posted by YPS and its employees is accurate, it does not
    guarantee the accuracy of the material. You agree that YPS or its employees shall not be liable for any damage or
    loss whether direct, indirect, consequential, or incidental that is caused by the services provided by YPS. YPS
    shall not be held liable for any damages caused by a) the use of or b) the inability to use YPS service.
    Furthermore, YPS is not liable for a) disclosure of your personal information obtained from e-gmat.com.</div>
  <h4 class="wdrd1">6. Indemnification</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    You agree to
    indemnify YPS, its officers, employees, contractors, agents, service providers, and partners from losses, expenses,
    damages resulting from the use of or your association with YPS. You further agree that YPS shall not bear any costs
    incurred by you, including reasonable attorney fees resulting from any violation of Terms and Conditions of use
    agreement or privacy policy.</div>
  <h4 class="wdrd1">7. Conflict Resolution and Governing Law</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    This agreement
    between YPS and you is governed by the laws of state of Arizona. You agree that any dispute, claim, or cause of
    action related to YPS Terms and Conditions regardless of any statue of law shall be brought to the courts of state
    of Arizona. You further agree that all claims and disputes shall be filed within 6 months of the claim or cause of
    action or be forever barred.</div>
</div>
