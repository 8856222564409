import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './data.service';

@Injectable()
export class HttpClientService {
  domain = window.location.protocol + '//' + window.location.host;
  url = environment.cdn_url + environment.dynamic_json_data_url;
  // url = environment.cdn_url_local + environment.dynamic_json_data_url; //for localhost
  bz_url = environment.bz_app_url + '/api/';

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) {}

  createAuthorizationHeader(headers: HttpHeaders) {
    if (localStorage.getItem('id_token')) {
      headers.append(
        'X-Authorization',
        'Bearer ' + localStorage.getItem('id_token')
      );
    }
    headers.append('Content-Type', 'application/json');
    headers.append('Cache-Control', 'no-cache');
  }

  getJson(url) {
    return this.httpClient.get(this.url + url);
  }

  getVersion(url) {
    return this.httpClient.get(this.domain + url);
  }

  getVersionJson(url) {
    return this.httpClient.get(this.url + url);
  }

  login(username, password) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify({ username: username, password: password });
    return this.httpClient.post(this.bz_url + 'auth/login', body, {
      headers: headers,
      withCredentials: true,
    });
  }
  sendMail(email, query) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('query', query);
    return this.httpClient.post(
      environment.googleBtnURL + '/contact_us_mail.php',
      formData
    );
  }
  googleLogin(id_token) {
    const headers = {
      'content-type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = 'idtoken=' + id_token;
    return this.httpClient.post(this.bz_url + 'auth/googleLogin', body, {
      headers: headers,
      withCredentials: true,
    });
  }
  saveUTMParemeters(utmParameters) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify(utmParameters);
    return this.httpClient.post(
      this.bz_url + 'public/save/utmProperties',
      body,
      {
        headers: headers,
      }
    );
  }
  forgotPassword(username) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify({ username: username });
    return this.httpClient.post(
      this.bz_url + 'public/user/forgotPassword',
      body,
      {
        headers: headers,
      }
    );
  }

  getBasicUserInfo() {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.get(this.bz_url + 'user/basic/info', {
      headers: headers,
      withCredentials: true,
    });
  }
  isBlitzkriegUser(username) {
    let headers = new HttpHeaders();
    return this.httpClient.get(
      this.bz_url + 'public/isBlitzkriegUser/' + username,
      {
        headers: headers,
        withCredentials: true,
      }
    );
  }
  registerUserInBlitzkrieg(registerationData) {
    const headers = {
      'content-type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    const body = JSON.stringify(registerationData);
    return this.httpClient.post(this.bz_url + 'public/user', body, {
      headers: headers,
      withCredentials: true,
    });
  }

  post(url: any, data: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.post(this.bz_url + url, data, {
      headers: headers,
      withCredentials: true,
    });
  }

  get(url: any) {
    let headers = new HttpHeaders();
    this.createAuthorizationHeader(headers);
    return this.httpClient.get(this.bz_url + url, {
      headers: headers,
      withCredentials: true,
    });
  }

  getAny(url: any) {
    let headers = new HttpHeaders();
    return this.httpClient.get(url, {
      headers: headers,
    });
  }
}
