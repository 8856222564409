<div class="faq" [ngClass]="tab_data['expand_faq']?'expand_faq':''" *ngIf="tab_data">
  <div class="question" [ngClass]="{'border_bottom_none': is_last_tab}" (click)="openCard(index)"> <span class="highlight_text">{{tab_data['tab_number']}}.</span>
    <span>{{tab_data['query']}}</span>
    <span class="expand_icon lozad" data-loaded="true"> <img class="lozad"
        [src]="cdn_url+'/assets/images/down_arrow_icon_xs.png'" data-loaded="true">
    </span>
  </div>
  <div class="answer">
    <ng-container *ngFor="let answer of tab_data['answer']">
      <ng-container *ngIf="answer['type']=='text'">{{answer['content']}}</ng-container>
      <ng-container *ngIf="answer['type']=='strong'">
        <strong>{{answer['content']}}</strong>
      </ng-container>
      <ng-container *ngIf="answer['type']=='text_bold'">
        <b>{{answer['content']}}</b>
      </ng-container>
      <ng-container *ngIf="answer['type']=='video'">
        <div class="wistia_embed   popover=true popoverContent=link" [ngClass]="'wistia_async_'+answer['video_url']"
          style="display:inline-block; white-space:nowrap;"><a href="#">{{answer['content']}}</a></div>
      </ng-container>
      <ng-container *ngIf="answer['type']=='pre'">
        <span class="pre">{{answer['content']}}</span>
      </ng-container>
      <ng-container *ngIf="answer['type']=='expand_link'">
        <span class="expand_link" (click)="openCard(answer['tab_number']-1)">{{answer['content']}}</span>
      </ng-container>
      <ng-container *ngIf="answer['type']=='text_under_line'">
        <u>{{answer['content']}}</u>
      </ng-container>
      <ng-container *ngIf="answer['type']=='text_mail'">
        <a [attr.href]="'mailto:'+answer['content']">{{answer['content']}}</a>
      </ng-container>
      <ng-container *ngIf="answer['type']=='list'">
        <ol>
          <ng-container *ngFor="let content of answer['content'];let j=index;">
            <li>{{content}}</li><br *ngIf="j < answer['content'].length-1">
          </ng-container>
        </ol>
      </ng-container>
    </ng-container>
  </div>
</div>
