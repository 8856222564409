<div class="section" *ngIf="most_compregensive_free_trial_data">
  <div class="page_container">
    <div class="row">
      <div class="col-12 text-center sub-header">
        {{width>=600 ? most_compregensive_free_trial_data['sub_heading'] : 'Kickstart your prep with the'}}
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center header">
        {{most_compregensive_free_trial_data['heading']}}
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let tab of most_compregensive_free_trial_data['tabs'];let i=index;">
        <div class="col-6" [ngClass]="{'even': i%2==0,'odd': i%2 !=0}">
          <div class="tab-container">
            <div class="image-container">
              <ng-container *ngIf="tab['type']=='image'">
                <img [src]="tab['image_url']">
              </ng-container>
              <ng-container *ngIf="tab['type']=='text'">
                <span class="icon-text" [ngClass]="{'has-second-line': tab['icon_text_2']}" [ngStyle]="{'fontSize': tab['text_font_size']}">{{tab['icon_text']}}</span>
                <span *ngIf="tab['icon_text_2']" class="icon-text">{{tab['icon_text_2']}}</span>
              </ng-container>
            </div>
            <div class="tab-heading">
              <span class="head">{{tab['heading']}}</span>
              <span class="sub-head">{{tab['sub_heading']}}</span>
            </div>
            <div class="description">
              <ng-container *ngFor="let description of tab['description']">
                <ng-container *ngIf="description['type']=='text'">{{description['text']}}</ng-container>
                <ng-container *ngIf="description['type']=='bold_text'">
                  <span class="bold-text">{{description['text']}}</span>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="button-container">
          <a class="btn-get-free-resources" (click)="openModal('Start Your Free Trial Now')"><span class="label">Start Your Free Trial Now</span></a>
        </div>
      </div>
    </div>

  </div>
</div>
