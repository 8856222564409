<div class="section section_pip !pt-0 md:!pt-[75px] bg-auto section_pad section_5_pad_btm text_center bg-no-repeat bg-[url('/assets/images/personalized_plan_bg_NY.png')] md:bg-[url('/assets/images/personalized_plan_bg_mobile_NY.png')] md:bg-contain " id="study_plan" *ngIf="personalizedImprovementPlanData">
  <div class="page_container">
   <p class="page_head section_page_head_gap extra_bold_font" data-aos="slide-up">
    <span class="text-transparent bg-clip-text bg-xmasHeadings">{{personalizedImprovementPlanData['heading']['highlightWords']}}
    </span>
    <br/>{{personalizedImprovementPlanData['heading']['restPart']}}
  </p>
  <div class="section_5_section_container">
  <p class="page_sub_head page_sub_head_light_color px-[75px] md:px-[9px]" data-aos="slide-up">
    {{personalizedImprovementPlanData['subHeading']['firstPart']}} <b class="text-[#000000]"><span class="text-[#ff9725]">{{personalizedImprovementPlanData['subHeading']['xColorLetter']}}</span>{{personalizedImprovementPlanData['subHeading']['boldWord']}}</b> {{personalizedImprovementPlanData['subHeading']['restWords']}}
  </p>
  <div class="tab_view_wrapper">
    <div class="psp_section egmat_tab_view" data-numberoftabs="3" data-contentconfig="bubbleChartsData" >
          <div class='tab_view_container'>
            <div class='tab_content_container'>
              <ng-container *ngFor="let video of personalizedImprovementPlanData['videos'];let i = index;">
                <div *ngIf="!isGCEpage" class="tab_content !pt-0 !shadow-none" [ngClass]="(i == active_index) ? 'active_tab' : ''">
                    <img [src]="video['url']">
                </div>
                <div  *ngIf="isGCEpage" class="tab_content" [ngClass]="(i == active_index) ? 'active_tab' : ''">
                  <app-iframe [url]="video['url']" [class]="'psp_wistia_video lozad wistia_embed'" [onload]="true" (loadEvent)="IframeLoad($event)"></app-iframe>
              </div>
              </ng-container>
            </div>
              <div class='tab_head_container md:!hidden'>
                <ng-container *ngFor="let video of personalizedImprovementPlanData['videos']; let i = index;">
                  <div (click)="change_tab(i)" class="tab_head" [ngClass]="(i == active_index) ? 'active_tab' : ''">
                    <span class="tab_text">
                      <span class="label">{{video['tab_label']}}</span>
                      <span class="duration_label">{{video['tab_duration_label']}}</span>
                      <span class="customer_name">{{video['customer_name']}}</span>
                    </span>
                  </div>
                </ng-container>
                <div class="tab_select"[ngStyle]="{'left': left_tab_select+'%'}"></div>
              </div>
              <div class="md:flex hidden mt-[30px]">
                <div class="w-[5%] relative">
                  <div (click)="slideChange('prev')" [ngClass]="{'border-[#d2d2d2]': active_index === 0,'border-[#000]': active_index != 0}" class="w-[35px] h-[35px] rounded-full border-[3px] border-solid absolute top-1/2 translate-y-[-50%] right-[10px]">
                    <fa-icon [ngClass]="{'text-[#d2d2d2]': active_index === 0,'text-[#000]': active_index != 0}" [icon]="['fas', 'angle-left']" class="text-[22px]" ></fa-icon>
                  </div>
                </div>
                <ng-container *ngFor="let video of personalizedImprovementPlanData['videos']; let i = index;">
                  <div *ngIf="i === active_index" class="text-[#000000] text-center w-[90%] border-solid border-[3px] border-[#000000] py-[30px]">
                    <div class="text-[18px] font-semibold">{{video['tab_label']}}</div>
                    <div class="text-[30px] font-bold">{{video['tab_duration_label']}}</div>
                    <div class="text-[18px]">{{video['customer_name']}}</div>
                  </div>
                </ng-container>
                <div class="w-[5%] relative">
                  <div (click)="slideChange('next')" [ngClass]="{'border-[#d2d2d2]': active_index === (personalizedImprovementPlanData['videos'].length-1),'border-[#000]': active_index != (personalizedImprovementPlanData['videos'].length-1)}" class="w-[35px] h-[35px] rounded-full border-[3px] border-solid absolute top-1/2 translate-y-[-50%] left-[10px]">
                    <fa-icon [ngClass]="{'text-[#d2d2d2]': active_index === (personalizedImprovementPlanData['videos'].length-1),'text-[#000]': active_index != (personalizedImprovementPlanData['videos'].length-1)}" [icon]="['fas', 'angle-right']" class="text-[22px]" ></fa-icon>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
  </div>
   <div class="curve_style_div">

    </div>
  </div>
