<div class="nav desktop" [ngClass]="{ 'scroll-add' : scrolled }">
    <div class="desktop_nav align_navbar" data-aos="slide-up"
      [ngClass]="{ 'desktop_nav_white': isHeaderWhite && !scrolled}">
      <div class="vertical_middle_align text_left">
        <a routerLink="/" class="active egmat_logo_header"><img
            [src]="cdn_url+'/assets/logo/e_gmat_logo_small.png'" /></a>
      </div>
      <div class="vertical_middle_align text_left">
        <a class="hover:bg-[#ffffff33] rounded-[10px] px-[20px]" routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
          (click)="scrollToSection('top_reasons')"><span class="underlined_example"
            [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Course Offerings</span></a>
        <a class="hover:bg-[#ffffff33] rounded-[10px] px-[20px]" routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
          (click)="scrollToSection('limited_offer')"><span class="underlined_example"
            [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Discounted Pricing</span></a>
        <a (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)" (click)="routeToCart('CTA_header')"
          class="btn btn_solid !bg-xmasBg btn_round wavy_btn btn_try_it_free">
          <div class="front">
            <span style="--i:1;" [ngClass]="class_transform">B</span>
            <span style="--i:2;" [ngClass]="class_transform">u</span>
            <span style="--i:3;" [ngClass]="class_transform">y</span>
            <span style="--i:4;" [ngClass]="class_transform">&nbsp;</span>
            <span style="--i:5;" [ngClass]="class_transform">N</span>
            <span style="--i:6;" [ngClass]="class_transform">o</span>
            <span style="--i:7;" [ngClass]="class_transform">w</span>
          </div>
          <div class="back">
            <span style="--i:1;" [ngClass]="class_transform">B</span>
            <span style="--i:2;" [ngClass]="class_transform">u</span>
            <span style="--i:3;" [ngClass]="class_transform">y</span>
            <span style="--i:4;" [ngClass]="class_transform">&nbsp;</span>
            <span style="--i:5;" [ngClass]="class_transform">N</span>
            <span style="--i:6;" [ngClass]="class_transform">o</span>
            <span style="--i:7;" [ngClass]="class_transform">w</span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="nav mobile" [ngClass]="{ 'scroll-add-mobile' : scrolled ,'white-color-mobile': !isHeaderWhite }">
    <div class="page_container inherit_height">
      <nav class="navbar navbar-default egmat_header inherit_height">
        <div class="container display_flex align_navbar inherit_height">
          <div class="navbar-header header_top_header text_left vertical_middle_align"> <a
              class="navbar-brand egmat_logo_header" routerLink="/"><img
                [src]="cdn_url+'/assets/logo/e_gmat_logo_small.png'"></a></div>
          <div class="nav_container display_flex inherit_height mr-7">
            <a (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)" (click)="routeToCart('CTA_header')"
              class="btn btn_solid !bg-xmasBg font-bold btn_round wavy_btn">BUY NOW</a>
          </div>
        </div>
      </nav>
    </div>
  </div>
