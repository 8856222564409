import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-christmas-success-matrix',
  templateUrl: './christmas-success-matrix.component.html',
  styleUrls: ['../../../deal-pages-common-css.css','./christmas-success-matrix.component.css']
})
export class ChristmasSuccessMatrixComponent implements OnInit {
  cdn_url = environment.cdn_url;
  @Input() successMetricsData;
  @Input() salesDealOfferConfig;
  tooltipScoresBtn = 'Know more';
  tooltipStoriesBtn = 'Know more';
  tooltipReviewsBtn = 'Know more';
  tooltipInstructorsBtn = 'Know more';
  toolTip = '';



  constructor() {}

  ngOnInit(): void {}

  changeBtnName(btn = '', btnText = '') {
    this.tooltipScoresBtn = 'Know more';
    this.tooltipStoriesBtn = 'Know more';
    this.tooltipReviewsBtn = 'Know more';
    this.tooltipInstructorsBtn = 'Know more';
    this.toolTip = btn;
    if(btnText.includes('more'))
      this[`tooltip${btn}Btn`] = '';
    else
      this.toolTip = '';

  }
}
