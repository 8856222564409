<div class="section section_pad lozad !pb-[130px] bg-no-repeat bg-cover bg-[url('/assets/images/Trusted_prep_bg_NY.png')] md:bg-[url('/assets/images/Trusted_prep_bg_NY_mobile.png')]" *ngIf="popularTrustedPrepCourseData" >
  <div class="page_container" data-aos="slide-up">
    <div class="page_head text-center extra_bold_font leading-tight">
      <span class="md:block">Most </span> <span class="text-transparent bg-clip-text bg-xmasHeadings">Popular and Trusted</span> <span class="md:block"> Prep Course out there</span>
    </div>
    <div data-aos="slide-up" class="page_sub_head page_sub_head_light_color aos-init aos-animate text-[26px] text-[#404040] text-center mt-[50px] px-[126px] md:px-[5px]">
      Extreme focus on student success with product innovation and personal connect with mentors made us the most popular prep option in with World. We have received <span class="font-semibold text-[#000000]">{{popularTrustedPrepCourseData['textContentDarkTexts']}}</span>
    </div>
    <div class="img_container pl-[35px] pt-[100px] md:hidden">
      <img [src]="popularTrustedPrepCourseData['barChartUrl']">
      <div class="pl-[60px]">
        <div>** Logos are properties of respective companies</div>
      </div>
    </div>
    <div class="img_container mt-[60px] hidden md:block">
      <img [src]="popularTrustedPrepCourseData['mobileBarChartUrl']">
      <div class="text-center">
        <div>** Logos are properties of respective companies</div>
      </div>
    </div>

    <div class="btn_container buy_now_cta_container text-center mt-[80px] mb-[-20px]" (click)="routeToCart('CTA_five_star_reviews')">

      <div class="hero_cta_btn secondary_section active !bg-ctaBtnBg !border-ctaBtnBg" data-cta_name="CTA_timer_ribbon">
        <div class="logo_container">
          <img class="lozad" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
        </div>
        <div class="price_container">
          <div class="discounted_price page_head extra_bold_font !text-ctaBtn">{{discountedPrice}}</div>
          <span class="original_price">{{originalPrice}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
