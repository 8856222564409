<div class="support_block" data-aos="slide-up">
  <div class="support_inner_wrap display_flex">
    <div class="support_text_section">
      <div class="section_text_wrap">
        <p class="page_sub_head semi_bold_font support_head">{{card['head']}}</p>
        <p class="page_sub_head support_desc">{{card['desc']}}</p>
      </div>
    </div>
    <div class="support_check_container">
      <div class="support_check"> <img class="lozad" [src]="cdn_url+'/assets/images/filled_check_icon.png'"></div>
    </div>
  </div>
</div>
