<div class="section section_2 text_center" *ngIf="personal_gmat_journey_data">
  <div class="page_container">
    <div class="page_container_child">
      <div class="col-md-12">
        <div class="section_2_head_small mt_90 aos-init aos-animate" data-aos="slide-up">
          {{personal_gmat_journey_data['header']['first_line']}}
        </div>
        <div class="section_2_head_large aos-init aos-animate" data-aos="slide-up">
          {{personal_gmat_journey_data['header']['second_line']}}
        </div>
        <div class="section_2_sub_head aos-init aos-animate" data-aos="slide-up">
          {{personal_gmat_journey_data['header']['third_line']}}
        </div>
        <div class="section_2_rectangle text_center aos-init aos-animate" data-aos="slide-up">
          <div class="section_2_rectangle_video aos-init aos-animate" data-aos="slide-up">
            <div class="video_iframe_container">
              <app-iframe [url]="personal_gmat_journey_data['video_url']" [class]="'lozad wistia_embed video_iframe'"></app-iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-buy-now></app-buy-now>
  </div>
</div>
