<div class="roi_section background_config lozad desktop_view" *ngIf="roi_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/'+roi_bg_name+'.png)'}">
  <div class="page_container">
    <div class="roi_section_heading" data-aos="slide-up">{{roi_data['header']['first_line']}}<span
        class="roi_section_highlight_heading">{{roi_data['header']['second_line']}}</span></div>
    <div class="bubble_container">
      <ng-container *ngFor="let bubble of roi_data['bubbles'];let i = index;">
        <div class="row_bubble"  [ngClass]="('row_bubble_'+(i+1))" data-aos="slide-up">
          <div class="ellipse_col" data-aos="slide-up">
            <div class="ellipse_white" data-aos="slide-up"></div>
          </div>
          <div class="line_col" data-aos="slide-up"></div>
          <div class="bubble_col" [ngClass]="('bubble_col_'+(i+1))" data-aos="slide-up">
            <div [ngClass]="('bubble_'+(i+1))" data-aos="slide-up">{{bubble['bubble_text']}}</div>
          </div>
        </div>
        <div class="row_bubble_content" [ngClass]="('row_bubble_content_'+(i+1))">
          <div class="bubble_content_head" [ngClass]="i==2?'pre':''" data-aos="slide-up">{{bubble['content_head']}}</div>
          <div class="bubble_content_text" data-aos="slide-up">{{bubble['content_sub_head']}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
