<div class="section">
  <div class="page_container">
    <div class="header">Why Prepare with e-GMAT?</div>
    <div class="row">
      <div class="col-4 text_center">
        <div class="img_container">
          <img [src]="cdnUrl + '/assets/images/effectiveLearning.png'">
        </div>
        <div class="heading">Effective Learning</div>
        <div class="content">e-GMAT’s online learning architecture improves engagement and retention, delivering learning in 40% less time.</div>
      </div>
      <div class="col-4 text_center">
        <div class="img_container">
          <img [src]="cdnUrl + '/assets/images/5xFastPrep.png'">
        </div>
        <div class="heading">5x Faster Prep</div>
        <div class="content">e-GMAT’s data driven platform delivers 5x greater feedback points than an expensive private tutor.</div>
      </div>
      <div class="col-4 text_center">
        <div class="img_container">
          <img [src]="cdnUrl + '/assets/images/testReadiness.png'">
        </div>
        <div class="heading">Test Readiness</div>
        <div class="content">AI based tools help precisely diagnose weaknesses, formulate an action plan and elevate a good GMAT score to a great GMAT score.</div>
      </div>
    </div>
    <a class="styled_blue_button main_btn" id="lp-pom-button-2088"  (click)="openModal('Begin GMAT Preparation')"><span class="label">Begin GMAT Preparation</span></a>
  </div>
</div>
