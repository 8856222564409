<ng-container *ngIf="state == 'close' ? false : true">
  <div class="modalDialog" (click)="$event.stopPropagation();close(true)">
    <div [@modalOneView]="state"  (click)="$event.stopPropagation();close(false)">
      <div class="modal-header login_modal_header">
        <button type="button" class="close" (click)="$event.stopPropagation();close(true)"><span aria-hidden="true"><img class="close_icon_img"
              [src]="cdn_url+'/assets/icons/close_icon.png'"></span></button>
      </div>
      <div class="modal-body">
        <div class="form_outer_container">
          <div class="form_container">
            <div class="login_heading form_head" [ngClass]="{'login_heading_error':login_error_msg!=''}">
            <ng-container *ngIf="!username">Welcome Back</ng-container>
            <div *ngIf="username" style="font-size:20px;max-width:425px;margin:auto;">Your password has been successfully reset. Please enter your new password to login.</div>
            </div>
            <div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
            <div class="login_form">

              <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
                <div class="form-group form_group">
                  <div class="input_field_label">Email*</div>
                  <div *ngIf="f.username.errors" class="invalid-feedback">
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted['username'] && f.username.errors.required">
                      Email is Required
                    </div>
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted['username'] && f.username.errors.pattern">
                      Enter a valid Email Address
                    </div>
                  </div>
                  <input (focus)="login_error_msg = '';" autocomplete="off" formControlName="username" type="text" class="form-control form_control reqField loginfield"
                    placeholder="Email" id="loginEmailval" name="username" value=""
                    [ngClass]="{ 'is-invalid': alert_submitted['username'] && f.username.errors }" (keyup)="onKeyUpEmail('username')">
                </div>
                <div class="form-group form_group">
                  <div class="input_field_label">Password*</div>
                  <div *ngIf="f.password.errors" class="invalid-feedback">
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                      *ngIf="alert_submitted['password'] && f.password.errors.required">
                      Password is Required
                    </div>
                  </div>
                  <input (focus)="login_error_msg = '';" autocomplete="off" formControlName="password" [attr.type]="show_password?'text':'password'"
                    class="form-control form_control reqField loginfield" id="loginPass" name="password"
                    placeholder="min. 8 characters" value=""
                    [ngClass]="{ 'is-invalid': alert_submitted['password'] && f.password.errors }" (keyup)="onKeyUpEmail('password')">
                  <img toggle="#loginPass"
                    [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;" [ngClass]="{'display_ele': !show_password}"/>
                  <img toggle="#loginPass"
                    [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;"  [ngClass]="{'display_ele': show_password}"/>
                  <div class="reg_tnc text-center loginfield"><span (click)="forgot_password()" class="fgt_pss">Forgot
                      Password?</span></div>
                </div>
                <div class="form-group form_sub_btn">
                  <button *ngIf="!submitted" type="submit" id="submitmyform"
                    class="btn btn_default lgn_btn_form loginfield signin_submit_button_login" [ngClass]="{'margin_top_zero':login_error_msg!=''}">Login</button>
                  <button *ngIf="submitted" type="button" id="submitmyformgif" class="btn btn_default lgn_btn_form" disabled><img
                      style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></button>
                </div>
              </form>
              <div class="form_or">
                <div class="form_or_text form_login_or_text">or Sign in with Google</div>
                <div class="form_or_line form_login_or_line"></div>
              </div>

              <div class="form_google_btn">
                <app-google-signin-btn [submit_button_class]="'signin_submit_button_login'" *ngIf="!google_submitted" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
                <!-- <a *ngIf="!google_submitted" id="submitmyform" (click)="googleLoginClick()"
                  class="btn btn_default google_lgn_btn_form"><span class="fa fa_google reg_google_plus"
                    aria-hidden="true"></span> &nbsp;&nbsp;Sign in with
                  Google</a> -->
                <a *ngIf="google_submitted" id="submitmyformgif" class="btn btn_default google_lgn_btn_form" disabled><img
                    style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></a>
              </div>
              <div class="sign_up_from_login_txt">
                <a href="/free-resources-gmat-registration/?utm_source=mainloginsplash">Don’t have an account? Click here to Sign Up.</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
