<div *ngIf="what_students_says_data" class="section"
  [ngStyle]="{'background-image':' url(&quot;' + cdn_url + '/assets/images/'+tab_background_name+'.png&quot;)'}">
  <div id="color-overlay">
    <div class="page_container">
      <div class="row">
        <div class="large-heading">
          <div class="col-12 text-center header">
           {{what_students_says_data['heading']}}
           <span class="relative whitespace-nowrap md:w-max md:m-auto"> e-GMAT
            <img class="absolute bottom-0 right-0 w-[250px] md:w-[120px]" [src]="cdn_url+'/assets/images/img-egmat underline.png'" alt="">
           </span>
          </div>
        </div>
      </div>
      <div class="row mobile-row">
        <div class="left-arrow">
          <div class="navigation_buttons_box cursor-pointer">
            <a class="navigation_buttons navigation_buttons-left" (click)="slideChange(false)">
              <img class="label" [src]="cdn_url+'/assets/icons/img-prev.png'" alt="">
              <img class="label-hover" [src]="cdn_url+'/assets/icons/img-prev-hover.png'" alt="">
            </a>
          </div>
        </div>
        <ng-container *ngFor="let slide of what_students_says_data['slides'];let i = index;">
          <div class="slider-middle" [ngClass]="{'active_slide': active_index == i}">
            <div class="card-body relative bg-[#FFFFFF] h-[700px] md:h-[850px] rounded-[20px] shadow-[0px_0px_40px_0px_#FFFFFF66]">
              <div class="absolute left-1/2 translate-x-[-50%] top-0 translate-y-[-30%]" *ngIf="width < 769">
                <img [src]="slide['profile_image_mobile_url']" alt="">
              </div>
              <div class="absolute right-0 top-0" *ngIf="width >= 769">
                <img class="card-image relative z-[9] rounded-[20px]" [src]="slide['profile_image_url']" alt="">
              </div>
              <div class="absolute top-0 left-0 z-[10] w-[52%] pt-[70px] pb-[60px] pl-[60px] md:w-[100%] md:pt-[180px] md:px-[36px] md:pb-[60px]">
                <div class="text-[36px] text-[#00AEEF] font-bold md:text-[26px] md:text-center" [innerHTML]="slide['heading']"></div>
                <div [ngStyle]="{'background-image': 'url(&quot;'+cdn_url+'/assets/images/img-quote.png&quot;)'}" class="text-[20px] md:text-[18px] text-[#404040] comments pt-[50px] mb-[60px] md:text-center bg-no-repeat md:!bg-none" [innerHTML]="slide['comments']"></div>
              </div>
              <div class="absolute right-[50%] z-[10] bottom-[20px] md:bottom-[50px] md:right-[unset] md:left-1/2 md:translate-x-[-50%]">
                <div class="font-bold whitespace-nowrap text-[26px] md:text-[22px] text-[#00AEEF] text-end md:text-center md:mb-[30px]">
                  <div>- {{width>=769?slide['name']:slide['name'].split(" ")[0]}}<span class="hidden md:inline border-[1px] border-solid border-[#707070] h-[33px] mx-[5px]"></span><span class="hidden md:inline font-bold text-[26px] text-[#404040]">{{slide['score']}}</span></div>
                </div>
                <div class="font-bold text-[26px] text-[#404040] text-end md:hidden">
                  <div class="">{{slide['score']}}</div>
                </div>
                <div class="hidden md:block relative h-[50px]" *ngIf="width < 769">
                  <img class="w-[190px] h-[50px] left-1/2 translate-x-[-50%] absolute" [src]="slide['logo_image_url']" alt="">
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="right-arrow">
          <div class="navigation_buttons_box cursor-pointer">
            <a class="navigation_buttons navigation_buttons-right" (click)="slideChange(true)">
              <img class="label" [src]="cdn_url+'/assets/icons/img-next.png'" alt="">
              <img class="label-hover" [src]="cdn_url+'/assets/icons/img-next-hover.png'" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="dots_container">
        <ng-container *ngFor="let slide of what_students_says_data['slides'];let i = index;">
          <span class="dots_wrapper">
            <span class="dot" [ngClass]="{'active': active_index == i}" (click)="active_index = i"></span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
