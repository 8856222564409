import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private display: BehaviorSubject<string> = new BehaviorSubject('close');
  private display_ft: BehaviorSubject<string> = new BehaviorSubject('close');

  private display_webinar_login: BehaviorSubject<string> = new BehaviorSubject('close_webinar_login');

  private display_video: BehaviorSubject<any> = new BehaviorSubject(null);

  watch_webinar_login(): Observable<string> {
    return this.display_webinar_login.asObservable();
  }

  open_webinar_login() {
    this.display_webinar_login.next('open_webinar_login');
  }

  close_webinar_login() {
    this.display_webinar_login.next('close_webinar_login');
  }

  watch_video(): Observable<'open_video' | 'close_video'> {
    return this.display_video.asObservable();
  }

  open_video(video_url) {
    this.display_video.next(video_url);
  }

  close_video() {
    this.display_video.next(null);
  }

  watch(): Observable<string> {
    return this.display.asObservable();
  }

  open() {
    this.display.next('open');
  }

  close() {
    this.display.next('close');
  }

  watch_ft(): Observable<string> {
    return this.display_ft.asObservable();
  }

  open_ft() {
    this.display_ft.next('open');
  }

  close_ft() {
    this.display_ft.next('close');
  }
}
