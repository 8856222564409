import { SEOService } from './../../Services/seo.service';
import { ModalService } from './../../Services/modal.service';
import { LoginService } from './../../Services/login.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { DataService } from './../../Services/data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

declare const push_event_properties: any;
declare const push_site_event: any;
declare var pageType: any;
@Component({
  selector: 'app-ft-gmat-prep-home',
  templateUrl: './ft-gmat-prep-home.component.html',
  styleUrls: ['./ft-gmat-prep-home.component.css'],
})
export class FtGmatPrepHomeComponent implements OnInit, OnDestroy {
  what_students_says_data;
  you_can_do_with_free_trial_data;
  most_compregensive_free_trial_data;
  link_tags;
  meta_tags;

  constructor(
    private dataService: DataService,
    private httpService: HttpClientService,
    private titleService: Title,
    private metaService: Meta,
    private loginService: LoginService,
    private modalService: ModalService,
    private seoService: SEOService
  ) {
    pageType = 'free_trial';
  }

  ngOnInit(): void {
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('ft-gmat-prep.json?version=' + version)
          .subscribe((response) => {
            this.what_students_says_data = response['what_students_says_data'];
            this.you_can_do_with_free_trial_data =
              response['you_can_do_with_free_trial_data'];
            this.most_compregensive_free_trial_data =
              response['most_compregensive_free_trial_data'];
            if (response['title']) {
              this.titleService.setTitle(response['title']);
            }
            if (response['meta_tags']) {
              this.meta_tags = response['meta_tags'];
              this.metaService.addTags(response['meta_tags']);
            }
            if(response['link_tags']){
              this.link_tags= response['link_tags'];
              this.seoService.createMultiLinks(response['link_tags']);
            }
            if(response['json_ld']){
              this.seoService.createJsonLd(response['json_ld']);
            }
          });
      }
    });
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
      }
    });
    // gtag('js', new Date());
    // gtag('config', 'AW-1010091293');
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
  ModalOpen(e) {
    if (!this.loginService.checkCookieBasedLogin(false, false, false)) {
      this.modalService.open_ft();
      document.getElementById('body')?.classList.add('modal-open');
    }
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        var eventProperties = {
          'Page Name': 'Free Trial Registration',
          'Button Name': e,
        };
        push_event_properties('Clicked CTA Button', eventProperties);
      }
    });
  }
}
