import { DataService } from '../../Services/data.service';
import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { CommonService } from './../../Services/common.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { googleSignInResponse } from '../../common/google-signin-btn/model/google-signin-reponse';

declare const getUTMParametersFromCookies: any;
declare const push_event_properties: any;
declare const push_events_to_lambda_SEG: any;
declare const push_km_event_login_propagation_redirect: any;
declare const push_coreg_login_event_redirect: any;
declare const push_km_event_coreg_registration_propagation_redirect: any;
declare const push_eventto_segment: any;
declare const multipleEmailTest: any;
declare const push_km_event_google_registration_propagation_redirect: any;
declare const push_km_event_registration_propagation_redirect: any;
declare const event_push: any;

@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.css']
})
export class SignInSignUpComponent implements OnInit {

  cdn_url = environment.cdn_url;
  show_password: boolean = false;
  registrationModeSite = environment.registrationModeSite;
  googleBtnURL = environment.googleBtnURL;
  loginURL = window.location.href;
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  publicIP = '';
  loginForm: UntypedFormGroup;
  submitted = false;
  alert_submitted = false;
  google_submitted = false;
  returnUrl: string;
  login_error_msg: string = '';
  lmsURL = environment.bz_app_url;
  id_token_google: any;
  username_google: any;
  bzUser: any;
  showSignUpForm:boolean = false;
  signUpForm: UntypedFormGroup;
  reg_error_message = '';
  tnc_error = true;
  mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  @Output() sendUserNameToCart = new EventEmitter<String>();

  constructor(
    private httpClientService: HttpClientService,
    private dataService: DataService,
     private router: Router,
     private route:ActivatedRoute,

     private formBuilder: UntypedFormBuilder,
     private commonService: CommonService
   ) {}

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required,Validators.pattern(this.mailPattern)])],
      password: ['', Validators.required],
    });

    this.signUpForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.compose([Validators.required,Validators.pattern(this.mailPattern)])],
      password: ['', Validators.required]
    //  tnc: ['', Validators.required],
    });

    this.getUsersIp();
  }

  forgot_password() {
    this.router.navigateByUrl('forgot-password');
  }
  // googleLoginClick() {
  //   this.dataService.getEventScriptStatus.subscribe((status) => {
  //     if (status) {
  //       this.google_submitted = true;
  //       auth2.signIn().then(() => {
  //         this.googleSignInCallback();
  //       });
  //     }
  //   });
  // }

  tncChecked(event) {
    console.log("in tnc checked :"+ event.target.checked);
    if (event.target.checked)
      this.tnc_error = false;
    else
      this.tnc_error = true;
  }

  googleSignInCallback(user:googleSignInResponse) {
    if (user) {
      this.google_submitted = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      if(!this.showSignUpForm)
        this.isBlitzkriegUser(user.email);
      else
        this.isBlitzkriegUserGoogle(user.email);
    } else {
      this.login_error_msg = 'There is a problem with google sign in';
    }
  }

  googleSignIn(id_token, username) {
    if (this.bzUser) {
      this.googleLoginToBlitzkrieg(id_token, username, false);
    } else {
      if (this.registrationModeSite == 'blitzkrieg') {
        this.googleLoginToBlitzkrieg(id_token, username, true);
      } else {
        this.router.navigateByUrl('home');
      }
    }
  }

  googleLoginToBlitzkrieg(id_token, username, registerUserFlag) {
    multipleEmailTest(username);
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
      //  this.setEssentialCookies(username);
        if (registerUserFlag) {
            let properties = {
              Email: username,
              id:username
            };
            push_events_to_lambda_SEG('completed registration', properties, username);
        //  push_km_event_google_registration_propagation_redirect('completed registration',username);
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username);
      },
      (error) => {
        this.login_error_msg = 'Server error : Please contact support';
        this.google_submitted = false;
      }
    );
  }

  isBlitzkriegUser(username) {
    var response;
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
        } else {
          this.bzUser = false;
        }
        if(!this.showSignUpForm)
          this.googleSignIn(this.id_token_google, this.username_google);
        else
          this.UserInMoodle();
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
    return response;
  }

  get f() {
    if(!this.showSignUpForm)
      return this.loginForm.controls;
    else
      return this.signUpForm.controls;
  }


  onFormSubmit() {
    this.alert_submitted = true;
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        if (!this.submitted) {
          if (this.loginForm.invalid) {
            return;
          }
          this.submitted = true;
          this.httpClientService
            .login(this.f.username.value, this.f.password.value)
            .subscribe(
              (data) => {
              //  this.setEssentialCookies(this.f.username.value);
                this.saveUTMParemeters(this.f.username.value);
                this.fireLoggedInFromBrowserEvent(this.f.username.value);
                this.completeLoginAndRedirect(this.f.username.value);
              },
              (error) => {
                this.login_error_msg = 'Email and Password not valid';
                this.submitted = false;
                event_push('Failed Login',this.f.username.value);
              }
            );
        }
      }
    });
  }

  getUsersIp() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        this.publicIP = ip['ip'];
      });
  }

  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }

  fireLoggedInFromBrowserEvent(username) {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
    push_events_to_lambda_SEG(eventName, properties, username);
  }

  getBasicUserInfo(username) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            push_coreg_login_event_redirect(
              'Completed Coreg Login',
              data['coregPartner'],
              username
            );
            push_km_event_coreg_registration_propagation_redirect('completed registration',username,data['coregPartner']);
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
      },
      (error) => {}
    );
  }


  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }

/*  setEssentialCookies(username){
    var d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie = "km_ni="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "km_ai="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "ajs_user_id="+username +';' + expires + ';' + domain + ';path=/';
    document.cookie = "_cioid="+username +';' + expires + ';' + domain + ';path=/';
  }*/

  completeLoginAndRedirect(username) {

    console.log("IN completeLoginAndRedirect FOR COMPLETED LOGIN>>>>>>>>>");
    this.logoutMoodle();
    var loginCount = 1;
    console.log("FIRING COMPLETED LOGIN");
    let properties = {
      id: username,
      Email: username
    };
    push_events_to_lambda_SEG('completed login', properties, username);
  //  push_km_event_login_propagation_redirect('completed login', username);
    this.getBasicUserInfo(username);
    var fname = '';
    var lname = '';
    var userName = username;
    var loginTime = new Date().getTime();
    var cookieValues =
      fname +
      '|' +
      lname +
      '|' +
      userName +
      '|' +
      loginTime +
      '|' +
      this.freeTrialUser;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);
    console.log("Login completed!!!!>>>"+username);
    this.sendUserNameToCart.emit(username);
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  showSignUp(){
    this.showSignUpForm = true;
  }
  showSignIn(){
      this.showSignUpForm = false;
  }

  onSignUpFormSubmit() {
    this.alert_submitted = true;
    if(!this.tnc_error){
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          if (!this.submitted) {
            if (this.signUpForm.invalid) {
              return;
            }
            this.submitted = true;
            this.validatemyform();
          }
        }
      });
    }
  }

  validatemyform() {
    if (this.registrationModeSite == 'blitzkrieg') {
      this.checkRegisteredInBlitzkrieg(this.f.username.value);
    } else {
      this.submitted = false;
    }
  }

  checkRegisteredInBlitzkrieg(emailid) {
    this.isBlitzkriegUser(emailid);
  }


  isBlitzkriegUserGoogle(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
        } else {
          this.bzUser = false;
        }
        if (!this.bzUser) {
          if (this.registrationModeSite == 'blitzkrieg') {
          this.googleLoginToBlitzkrieg(this.id_token_google, this.username_google, true);
          }else{
            this.router.navigateByUrl('home');
          }
        } else {
          this.reg_error_message = 'You are already registered';
          this.google_submitted = false;
        }

      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
  }

  UserInMoodle() {
    if (!this.bzUser) {
      this.registerUserInBlitzkrieg(
        this.f.username.value,
        this.f.password.value,
        this.f.name.value
      );
    } else {
      this.reg_error_message = 'You are already registered';
      this.submitted = false;
    }
  }

  registerUserInBlitzkrieg(username, password, name) {
    multipleEmailTest(username);
    var fullname = name.split(' ');
    var fname = '';
    var lname = '';
    if (fullname.length == 1) {
      fname = fullname[0];
    } else if (fullname.length > 1) {
      fname = fullname[0];
      lname = fullname[1];
    }
    var registerationData = {
      firstName: fname,
      lastName: lname,
      username: username,
      password: password,
    };
    this.httpClientService
      .registerUserInBlitzkrieg(registerationData)
      .subscribe(
        (data) => {
      //    this.setEssentialCookies(username);
          let properties = {
            Email: username,
            id:username
          };
          push_events_to_lambda_SEG('completed registration', properties, username);
        //  push_km_event_registration_propagation_redirect('completed registration',username);
          this.completeLoginAndRedirect(username);
        },
        (error) => {
          this.reg_error_message = error.error.message;
          this.submitted = false;
        }
      );
  }

  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain='+window.location.hostname+';path=/';
  }



}
