<div class="section section_pad section_limited_offer" id="limited_offer" *ngIf="limitedPeriodOfferData">
  <div class="page_container">
    <p class="page_head text_center extra_bold_font" data-aos="slide-up">
      {{limitedPeriodOfferData['heading']}}
    </p>
    <div class="offer_banner_container">
      <div class="offer_banner_wrapper">
        <div class="offer_banner">
          <div class="offer_countdown_section">
            <div class="offer_label extra_bold_font">
              <div class="offer_label_wrap">
                <img class="lozad" [src]="cdnUrl + '/assets/images/fast_delivery_icon.png'">
                <span>
                  {{limitedPeriodOfferData['limitedText']}}<br>{{limitedPeriodOfferData['offerText']}}
                </span>
              </div>
            </div>
            <div class="countdown_section sm:!px-[20px]">
              <div class="countdown" [style.height]="isDealLive ? '' : '158px'">
                <div class="countdown_display" [style.visibility]="isDealLive ? 'visible':'hidden'">
                  <app-clock
                  [HoursTensPlace]='HoursTensPlace' [HoursOnesPlace]='HoursOnesPlace'
                  [MinutesTensPlace]='MinutesTensPlace' [MinutesOnesPlace]='MinutesOnesPlace'
                  [SecondsTensPlace]='SecondsTensPlace' [SecondsOnesPlace]='SecondsOnesPlace'></app-clock>
                </div>
                <div class="row clock_desc md:!justify-center"  [style.visibility]="isDealLive ? 'visible':'hidden'">
                  <div class="clock_desc_text">Hours</div>
                  <div class="clock_desc_text">Minutes</div>
                  <div class="clock_desc_text">Seconds</div>
                </div>
              </div>

              <div class="coupon_left_section md:!shadow-none">
                <div class="coupon_wrapper md:!justify-center sm:before:!left-[-3px] sm:after:!right-[-3px] " >
                  <div class="coupon_label sm:!w-[60%]">
                    <div class="coupon_label_wrapper">
                      <div>{{limitedPeriodOfferData['discountedText']}}<br>{{limitedPeriodOfferData['couponsLeftText']}}
                      </div>
                      <div class="coupon_disc">{{limitedPeriodOfferData['updatedHourlyText']}}</div>
                    </div>
                  </div>
                  <div class="coupon_left sm:!w-[30%]">
                    {{couponsLeft}}
                  </div>
                </div>
                <div *ngIf=" isDealLive" class="course_validity_info md:text-center sm:!ml-0">
                  <span class="white_text">*Valid till </span> <span class="expiry_date_string">{{dealEndDateString}} (local time)</span>
                </div>
              </div>
              <div class="brand_guarantee_section">
                <div class="image_logo_container">
                  <img class="lozad" [src]="cdnUrl + '/assets/images/shield_icon.png'">
                </div>
                <div class="guarantee_text_container">
                  <div class="guarantee_head">
                    {{limitedPeriodOfferData['guaranteeTextContainer']['guaranteeHeadText']}}
                  </div>
                  <div class="guarantee_text">
                    {{limitedPeriodOfferData['guaranteeTextContainer']['guaranteeText']}} <a
                      class="external_link hover_effect faq_pop_trigger extra_bold_font"
                      (click)="openFAQPopUp()">guarantee.</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="offer_cta_section desktop">
            <app-new-product-card  [dealResetHappened]="dealResetHappened" [couponArr]="couponArr" [couponsLeft]="couponsLeft" [dealCounters]="dealCounters" [product]="product" [productName]="productName"></app-new-product-card>
          </div>
        </div>
        <div class="offer_cta_section mobile">
          <app-new-product-card  [dealResetHappened]="dealResetHappened" [couponArr]="couponArr" [couponsLeft]="couponsLeft" [dealCounters]="dealCounters" [product]="product" [productName]="productName"></app-new-product-card>
        </div>
      </div>
    </div>
    <div class="support_text_container">
      <div class="support_text_head">
        {{limitedPeriodOfferData['supportText']['heading']}}
      </div>
      <div class="support_text desktop">
        {{limitedPeriodOfferData['supportText']['content']['firstPart']}} <a href="mailto:support@e-gmat.com"
          class="hover_effect external_link extra_bold_font">{{limitedPeriodOfferData['supportText']['content']['supportMailId']}}</a>
        {{limitedPeriodOfferData['supportText']['content']['restPart']}}
      </div>
      <div class="support_text mobile">
        {{limitedPeriodOfferData['supportText']['content']['firstPart']}} <br><a href="mailto:support@e-gmat.com"
          class="hover_effect external_link extra_bold_font">{{limitedPeriodOfferData['supportText']['content']['supportMailId']}}</a>
        {{limitedPeriodOfferData['supportText']['content']['restPart']}}
      </div>
    </div>

    <div class="modal_faq">
      <div class="modal_wrapper lozad" [style.backgroundImage]="'url(' + cdnUrl + '/assets/images/pop_bg.png)'">
        <div class="pop_header bold_font">{{limitedPeriodOfferData['faqModal']['popUpHeader']}}</div>
        <div class="pop_body">
          <div class="pop_content">
            <div>
              {{limitedPeriodOfferData['faqModal']['popContentPart1']}} (<b><a href="mailto:support@e-gmat.com"
                  class="hover_effect external_link">{{limitedPeriodOfferData['supportText']['content']['supportMailId']}}</a></b>)
              {{limitedPeriodOfferData['faqModal']['popContentPart2']}}
            </div>
            <div>
              <span class="content_heading"><b>Note:</b></span>
              <br>
              {{limitedPeriodOfferData['faqModal']['contentNote']}}
            </div>
            <ol>
              <ng-container *ngFor="let note of limitedPeriodOfferData['faqModal']['noteList']">
                <li>
                  <span>
                    {{note}}
                  </span>
                </li>
              </ng-container>
            </ol>
          </div>
        </div>
        <div class="learn_more_btn_container close_btn semibold_font close_faq_pop" (click)="closeFAQPopUp()"
          data-aos="slide-up">
          <span class="learn_more_parent inline_block">
            <span class="hover_circle inline_block"></span>
            <span class="learn_more_icon inline_block"><img class="lozad"
                [src]="cdnUrl + '/assets/images/close_icon_dark.png'"></span>
            <span class="learn_more_text inline_block">{{limitedPeriodOfferData['closeBtnText']}}</span>
          </span>
        </div>
      </div>
    </div>

  </div>
</div>
