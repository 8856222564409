import { DataService } from '../../Services/data.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ninty-percent-accuracy',
  templateUrl: './ninty-percent-accuracy.component.html',
  styleUrls: ['./ninty-percent-accuracy.component.css']
})
export class NintyPercentAccuracyComponent implements OnInit {


  @Input() ninty_percent_accuracy_data;
  @Input() canRegister;
  @Output() openModalEvent = new EventEmitter<string>();
  username = '';
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
  }

  openModal() {
    this.dataService.updateUserName = this.username;
    this.openModalEvent.emit();
  }
}
