<div class="section7_div">
  <div class="page_container container_section_mob" *ngIf="what_our_students_say_data">
    <div class="section7_header_div">
      <div class="sec_7_small_header" data-aos="slide-up">{{what_our_students_say_data['header']['first_line']}}</div>
      <div class="sec_7_main_header" data-aos="slide-up">{{what_our_students_say_data['header']['second_line']}}</div>
    </div>
    <div class="section7_flex_container" data-aos="slide-up">
      <div class="sidebar_left sticky_sidebar">
        <div class="anchors_div">
          <ng-container *ngFor="let slide of what_our_students_say_data['slides'];let i = index;">
            <a (click)="scrollIntoView(i)" class="anchor_tag" [ngClass]="active_slide==i?'active_tag':''">
              <img class="lozad color_dot_active_img" [ngClass]="active_slide==i?'active_img':''"
                [src]="cdn_url+'/assets/images/active_yellow_dot.png'">
              <img class="lozad color_dot_passive_img" [ngClass]="active_slide!=i?'active_img':''"
                [src]="cdn_url+'/assets/images/passive_grey_dot.png'">
              <span class="anchor_text adjust_anchor_width"
                [ngClass]="active_slide!=i?'anchor_text_passive':''">{{slide['anchor_text']}}</span>
            </a>
          </ng-container>
        </div>
      </div>
      <div class="border_middle sticky_sidebar"><img class="lozad img_fixed" [src]="cdn_url+'/assets/images/border_image_new.png'">
      </div>
      <div class="content_right">

        <div class="part_flex_container">
          <div class="image_left">
            <img class="lozad part_header_image" [src]="cdn_url+'/assets/images/inverted-commas.png'">
          </div>
          <div class="header_right">
            <div class="part_description">{{what_our_students_say_data['header']['third_line']}}</div>
          </div>
        </div>
        <ng-container *ngFor="let slide of what_our_students_say_data['slides'];let i = index;">
          <div class="sec7_parts">
            <div class="mobile_view sec7_mobile_accordian sticky_accordian">
              <div class="accordian_contents">
                <img class="lozad color_dot_active_mobile_img" [src]="cdn_url+'/assets/images/active_yellow_dot.png'">
                <span class="sec7_accordian_text">{{slide['anchor_text']}}</span>
                <img class="lozad arrow_accordian_up" [ngClass]="{'display_none':slide['is_closed']}" (click)="changeTabState(i, true)" [src]="cdn_url+'/assets/images/arrow-accordian-up.png'">
                <img class="lozad arrow_accordian_down" [ngClass]="{'display_none':!slide['is_closed']}" (click)="changeTabState(i, false)"
                  [src]="cdn_url+'/assets/images/arrow-accordian-down.png'">
              </div>
            </div>
            <div [attr.id]="'part-'+i" class="sec7_video_container part_video_container"
              [ngClass]="{'display_none':slide['is_closed'], padding_80_class: i==0}">
              <app-iframe [url]="slide['slide_video_url']" [class]="'part_iframe part_iframe_mobile lozad wistia_embed'"></app-iframe>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
