<div class="section_3" *ngIf="student_saying_data">
  <div class="page_container">
    <div class="row">
      <div class="col-md-12 sectio_2_heading sectio_quotes_heading text-center">
        {{student_saying_data['header']['first_line']}}<p></p>
      </div>
    </div>
    <div id="carousel-example" class="carousel">
      <ol class="carousel-indicators">
        <ng-container *ngFor="let item of student_saying_data['carousel_items'];let i = index;">
          <li [ngClass]="{'active': (i == currentItem)}"></li>
        </ng-container>
      </ol>
      <div class="row">
        <div class="col-xs-offset-3 col-xs-6 carousel-custom-col">
          <div class="carousel-inner">
            <ng-container *ngFor="let item of student_saying_data['carousel_items'];let i = index;">
              <div class="carousel-item" [ngClass]="{'active': (i == currentItem)}">
                <div class="carousel-content text-center" style="height: 250px;">
                  <div>
                    <p>{{item['text']}}</p>
                    <p class="student_name text-center">{{item['student_name']}}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <a id="carouselPrev" class="carousel-control-prev" (click)="prev($event)" role="button">
        <i class="gg-chevron-left"></i>
        <span class="sr-only">Previous</span>
      </a>
      <a id="carouselNext" class="carousel-control-next" (click)="next($event)" role="button">
        <i class="gg-chevron-right"></i>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>
