<div class="section" *ngIf="you_can_do_with_free_trial_data">
  <div class="page_container">
    <div class="row margin-bottom-40">
      <div class="col-12 text-center header">
        {{you_can_do_with_free_trial_data['heading']}}
      </div>
    </div>
    <ng-container *ngFor="let tab of you_can_do_with_free_trial_data['tabs']">
      <div class="row both-tabs">
        <ng-container *ngFor="let lnr_tab of tab['left_and_right']">
          <ng-container *ngIf="lnr_tab['type'] == 'description'">
            <div class="desc_container">
              <div class="desc-tab">
                <div class="row mobile-row">
                  <div class="icon">
                    <img [src]="lnr_tab['image_url']">
                  </div>
                  <div class="middle-line">
                    <div id="color-overlay"></div>
                  </div>
                  <div class="heading-container">
                    <div class="tab_heading">{{lnr_tab['heading']}}</div>
                    <div class="tab_sub_heading">{{lnr_tab['sub_heading']}}</div>
                  </div>
                </div>
                <div class="description">{{lnr_tab['description']}}</div>
                <a class="btn-get-free-resources" (click)="openModal(lnr_tab['button']['text'])"><span class="label">{{lnr_tab['button']['text']}}</span></a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="lnr_tab['type'] == 'image'">
            <div class="image_container"
              [ngStyle]="{'text-align': (width >= 769)?((tab['left_and_right'][0]['type'] == 'image')?'start':'end'):'center'}">
              <img [src]="lnr_tab['image_url']" [ngStyle]="{'width': ((width >= 769)?lnr_tab['width']:lnr_tab['m_width']) + 'px', 'height': ((width >= 769)?lnr_tab['height']:lnr_tab['m_height']) + 'px'}">
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="path-images row mobile-hide" *ngIf="tab['background_image_1'] && tab['background_image_2']">
        <div class="image_1" [ngStyle]="{'left': tab['background_image_1']['left'],'top': tab['background_image_1']['top']}">
          <img [src]="tab['background_image_1']['image_url']" [ngStyle]="{'height': tab['background_image_1']['height']}">
        </div>
        <div class="image_2" [ngStyle]="{'left': tab['background_image_2']['left'],'top': tab['background_image_2']['top']}">
          <img [src]="tab['background_image_2']['image_url']" [ngStyle]="{'height': tab['background_image_2']['height']}">
        </div>
      </div>
    </ng-container>
  </div>
</div>
