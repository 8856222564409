import { DataService } from './../../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-top-reasons',
  templateUrl: './top-reasons.component.html',
  styleUrls: ['./top-reasons.component.css','../../deal-pages-common-css.css'],
})
export class TopReasonsComponent implements OnInit, AfterContentChecked {
  cdn_url = environment.cdn_url;
  @Input() topReasonsData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;
  isScrolled = false;


  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit(): void {}

  ngAfterContentChecked() {
    let courseDetailsSectionId = location.href.split('#')[1];
    if (!this.isScrolled && courseDetailsSectionId && document.getElementById(courseDetailsSectionId)) {
      document.getElementById(courseDetailsSectionId)?.scrollIntoView({ behavior: 'smooth' });
      this.isScrolled = true;
    }

  }

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }
}
