import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.css']
})
export class AnalyticsCardComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor() { }
  @Input() card;
  @Input() card_class;
  @Input() card_number;

  @Output() openTabEvent = new EventEmitter<any>();

  ngOnInit(): void {
  }

  openTab(){
    let data = {
      flag: true,
      index: this.card_number
    }
    this.openTabEvent.emit(data);
  }

}
