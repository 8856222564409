<div class="offer_info_container" *ngIf="product.productSlug">
  <div class="offer_info_header">
    <div class="month_label"><span class="num_months">{{product.productValidityMonths}}</span> Months</div>
    <div class="offer_course_name">
      GMAT Online<br><span class="product_slug">{{product.productSlug}}</span>
    </div>
  </div>
  <div class="offer_info_body text_center">
    <div class="offer_info_body_wrap">
      <div class="offer_original_course_price"> {{product.originalPrice}} </div>
      <div class="offer_discounted_price">
        <span class="discount_price">{{product.discountedPrice}}</span>
        <span class="percent_off">
          <span>
            <div class="percent_value">{{product.percentOff}}</div><div class="off_label">OFF</div>
          </span>
        </span>
        <div class="bubble_tip"></div>
      </div>
      <div class="btn_container buy_now_cta_container text_center" (click)="routeToCart('CTA_pricing')">
        <button data-cta_name="CTA_pricing" class="btn btn_solid btn_round extra_bold_font">
              <div class="btn_wrapper">
                  <img class="lozad inline_block" [src]="cdnUrl + '/assets/images/buy_now_icon.png'">
                  <span class="inline_block">BUY NOW</span>
              </div>
        </button>
      </div>
      <div *ngIf="!isCourseOfferingPage && isDealLive" class="course_validity_info">
        *Valid till <span class="expiry_date_string">{{dealResetDate}}, 11:59 PM PST</span>
      </div>
      <div *ngIf="!isCourseOfferingPage" class="course_purchased_info">
        <span class="people_purchased" id="people_purchased_so_far"><span>{{peopleTensPlace}}</span><span>{{peopleOnesPlace}}</span></span>people have bought so far
      </div>
    </div>
  </div>
  <div class="offer_info_footer">
    <div class="offer_info_footer_wrap">
      <div class="offer_info">
        <span>
          <img class="lozad" [src]="cdnUrl + '/assets/images/tick_icon_filled_sm.png'">
        </span>
        <span class="bold_font"><span class="num_months">{{product.productValidityMonths}}</span>-month</span> access to everything</div>

        <div class="offer_info">
          <span>
              <img class="lozad" [src]="cdnUrl + '/assets/images/tick_icon_filled_sm.png'">
          </span>
          <span><b>BONUS</b>: GFE included ($199 value)</span>
        </div>
        <div class="offer_info">
        <span>
            <img class="lozad" [src]="cdnUrl + '/assets/images/tick_icon_filled_sm.png'">
        </span>
        <span>24 hr world class support</span>
      </div>

    </div>
  </div>
</div>
