<div class="tab first_tab"  [ngClass]="tab['is_active']?'tab_checked':''" data-aos="slide-up">
  <label class="tab-label" [ngClass]="tab['is_active']?'level_checked':''" for="rd1" data-aos="slide-up" (click)="openTab()">
    <span class="accordion_number">{{tab['tab_number']}}.</span><span class="max_240">{{tab['query']}}</span></label>
  <div class="tab-content" [ngClass]="tab['is_active']?'checked':''" data-aos="slide-up">
    <ng-container *ngFor="let answer of tab['answer']">
      <span *ngIf="answer['type']=='text'" [innerHTML]="answer['content']"></span>
      <span *ngIf="answer['type']=='text_bold'">
        <b [innerHTML]="answer['content']"></b>
      </span>
      <ng-container *ngIf="answer['type']=='video'">
        <div class="wistia_embed   popover=true popoverContent=link" [ngClass]="'wistia_async_'+answer['video_url']"
          style="display:inline-block; white-space:nowrap;"><a href="#">{{answer['content']}}</a></div>
      </ng-container>
    </ng-container>
  </div>
</div>
