import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-target-score',
  templateUrl: './target-score.component.html',
  styleUrls: ['./target-score.component.css']
})
export class TargetScoreComponent implements OnInit {

  @Input() target_score_data;
  @Input() subject;
  @Input() canRegister;
  @Input() subjectName;
  @Output() openModalEvent = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    this.openModalEvent.emit();
  }

}
