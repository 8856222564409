import { CommonService } from './../../Services/common.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css'],
})
export class AnalyticsComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor(private commonService: CommonService) {}
  is_open = false;
  opened_index = 0;

  @Input() analytics_data;

  ngOnInit(): void {}
  closeTabCaseStudy(flag) {
    this.is_open = flag;
    if (!this.is_open) {
      this.commonService.doScrolling('#analytics_section', 500);
    }
  }
  openTabCaseStudy(data) {
    this.is_open = false;
    this.opened_index = data.index;
    setTimeout(()=>{
      this.is_open = data.flag;
    });
    console.log(this.opened_index);
  }
}
