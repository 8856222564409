import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-evaluation-tab',
  templateUrl: './evaluation-tab.component.html',
  styleUrls: ['./evaluation-tab.component.css'],
})
export class EvaluationTabComponent implements OnInit {
  @Output() open_tab = new EventEmitter();

  constructor() {}

  @Input() tab;
  @Input() tab_number;

  ngOnInit(): void {}

  openTab() {
    if (!this.tab['is_active']) {
      this.tab['is_active'] = true;
      this.open_tab.emit(this.tab_number);
    }
  }
}
