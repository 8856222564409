import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-journey',
  templateUrl: './about-us-journey.component.html',
  styleUrls: ['./about-us-journey.component.css']
})
export class AboutUsJourneyComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor() { }

  @Input() journey_data;
  ngOnInit(): void {
  }

}
