<div class="as_section1_div" *ngIf="mba_success_story_data">
  <div class="page_container">
    <div class="as_main_header" data-aos="slide-up">{{mba_success_story_data['header']['first_line']}}</div>
    <div class="as_main_header subheader_yellow" data-aos="slide-up">{{mba_success_story_data['header']['second_line']}}
    </div>
    <div class="as_section1_description" data-aos="slide-up">{{mba_success_story_data['header']['third_line']}}</div>
    <div class="tab_view_wrapper">
      <div class="egmat_tab_view">
        <div class="tab_view_container" data-aos="slide-up">
          <div class="tab_content_container">
            <div class="sec1_tab_content active_tab_display"
              [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/'+sec1_tab_background_name+'.png)'}">
              <div class="content_header_text mobile_show">{{mba_success_story_data['tabs'][active_index]['tab_name']}}
              </div>
              <div class="sec1_content_flex_div">
                <div class="content_header_block mobile_hide">
                  <div class="content_header_text">{{mba_success_story_data['tabs'][active_index]['tab_name']}}</div>
                  <div class="m7_bschool_click sec1_view_more_div" (click)="redirectToUnbounce()">
                    <div class="view_more_plus_div"><img class="view_more_plus lozad"
                        [src]="cdn_url+'/assets/images/plus.png'"></div>
                    <div class="view_more_text_div">View More</div>
                  </div>
                </div>
                <ng-container *ngFor="let tab of mba_success_story_data['tabs'];let tab_i = index;">
                  <ng-container *ngFor="let slide of tab['slides'];let i = index;">
                    <div class="content_body_block display_none_tab"
                      [ngClass]="{'display_tab': active_index == tab_i,'mobile_hide':i>1,'small_mobile_hide':i==1}">
                      <div class="sec1_card_div mobile_card_right"
                        [ngClass]="{'mobile_card_left':(width<769 && i%2 == 0)}">
                        <div class="card_div_top_bar"></div>
                        <div class="card_video_container"><img class="card_person_image lozad"
                            [src]="slide['student_image_url']">
                        </div>
                        <div class="play_button_div" (click)="openVideoPopup(slide['video_url'])"><img
                            class="play_button_img lozad" [src]="mba_success_story_data['header']['play_button_url']">
                        </div>
                        <div class="play_button_hover_div" (click)="openVideoPopup(slide['video_url'])"><img
                            class="play_button_img lozad"
                            [src]="mba_success_story_data['header']['play_button_hover_url']">
                        </div>
                        <div class="card_overlay_div"><img class="card_overlay_image lozad"
                            [src]="mba_success_story_data['header']['card_overlay_url']">
                        </div>
                        <div class="card_person_score">{{slide['score']}}</div>
                        <div class="card_school_div"><img class="card_columbia_image lozad"
                            [src]="slide['school_image_url']" [ngStyle]="{'height': tab['image_height']}"
                            *ngIf="width>769">
                          <img class="card_columbia_image lozad" [src]="slide['school_image_url']"
                            [ngStyle]="{'height': tab['image_height'],'width': slide['image_height_mobile']?slide['image_height_mobile']:'140px','padding':slide['image_height_mobile']?'0px':'10px 0px 5px 0px'}"
                            *ngIf="width<769">
                        </div>
                        <div class="card_person_name">{{slide['student_name']}}</div>
                        <div class="card_person_amount">{{slide['amount']}}</div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="mobile_show mobile_view_more_parent_div">
                <div class="m7_bschool_click sec1_view_more_div" (click)="redirectToUnbounce()">
                  <div class="view_more_plus_div"><img class="view_more_plus lozad"
                      [src]="cdn_url+'/assets/images/plus.png'"></div>
                  <div class="view_more_text_div">View More</div>
                </div>
              </div>
            </div>
          </div>
          <div class="sec1_tab_head_container mobile_hide">
            <ng-container *ngFor="let tab of mba_success_story_data['tabs'];let i = index;">
              <div class="tab_head" [ngClass]="i == active_index?'active_tab':''" (click)="active_index = i;">
                <div class="tab_header_div">
                  <div>{{tab['tab_name']}}</div>
                </div>
              </div>
            </ng-container>
            <div class="tab_select" [ngStyle]="{'left': (active_index * 20.5)+'%'}">
              <div class="tab_select_child"></div>
            </div>
          </div>
          <div class="sec1_slider_mobile_container" data-aos="slide-up">
            <div class="sec1_controls_prev" (click)="selectSlide(false)">
              <div class="sec1_controls_prev_button" [ngClass]="{'controls_passive':active_index==0}">
                <img class="sec1_left_slide_img lozad" [src]="cdn_url+'/assets/images/slider_left_arrow.png'">
              </div>
            </div>
            <div class="tns-outer">
              <ng-container *ngFor="let tab of mba_success_story_data['tabs'];let i = index;">
                <div class="tab_head" [ngClass]="i == active_index?'active_tab active_tab_mobile':''" (click)="active_index = i;"
                  [ngStyle]="{'left': (i == active_index)?0:(i<active_index?'-700px':'700px')}">
                  <div class="tab_header_div">
                    <div>{{tab['tab_name']}}</div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="sec1_controls_next" (click)="selectSlide(true)">
              <div class="sec1_controls_next_button"
                [ngClass]="{'controls_passive':active_index==mba_success_story_data['tabs'].length-1}"> <img
                  class="sec1_right_slide_img lozad" [src]="cdn_url+'/assets/images/slider_right_arrow.png'"></div>
            </div>
          </div>
          <div class="sec1_slider_mobile_nav_div">
            <ng-container *ngFor="let tab of mba_success_story_data['tabs'];let i = index;">
              <button type="button" class="sec1_navigation_button_passive" [ngClass]="{'tns-nav-active':active_index == i}" disabled=""></button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="section1_footer mid_mobile_hide"><img class="lozad"
        [src]="cdn_url+'/assets/images/line_circle_top.png'">
    </div>
  </div>
</div>
