import { environment } from 'projects/egmat-home/src/environments/environment';
import { UrlService } from './../../Services/UrlService';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ai-maximize',
  templateUrl: './ai-maximize.component.html',
  styleUrls: ['./ai-maximize.component.css']
})
export class AiMaximizeComponent implements OnInit {

  cdn_url = environment.cdn_url;
  constructor(private urlService: UrlService) { }

  @Input() ai_maximize_data;
  ngOnInit(): void {
  }

}
