<div class="section section_pad section_case_study_view" *ngIf="proofOfSuccessData">
  <div class="page_container">
    <p class="page_head highlight_text text_center extra_bold_font" data-aos="slide-up">
      {{proofOfSuccessData['heading']}}
    </p>
    <div class="case_study_view egmat_tab_view" data-numberoftabs="3" data-aos="slide-up">
      <p class="page_sub_head text_center" data-aos="slide-up">
        {{proofOfSuccessData['subHeading']}}
      </p>
      <div class='tab_view_container'>
        <div class='tab_content_container'>
          <ng-container *ngFor="let video of proofOfSuccessData['videos']; let i = index;">
            <div class="tab_content" [ngClass]="(i == active_index) ? 'active_tab' : ''">
              <div class="content_wrap">
                <div class="content_placeholder inline_block">
                  <div class="iframe_video_wrapper">
                    <ng-container *ngIf="i == active_index">
                      <app-iframe [url]="video['url']" [class]="'psp_wistia_video lozad wistia_embed'"></app-iframe>
                    </ng-container>
                  </div>
                </div>
                <div class="content_details background_config inline_block lozad" [style]="{'background-image' : 'url( ' + cdnUrl + '/assets/images/dark_quote.png)'}">
                  <div class="content_text">
                    <div class="problem_text">
                      <span class="highlight_text">{{video['problemText']['highlightText']}}</span>
                      <span class="content_highlight"> {{video['problemText']['highlightContent']}} </span>
                      <span>{{video['problemText']['textContent']}}</span>
                    </div>
                    <div class="solution_text">
                      <span class="highlight_text">{{video['solutionText']['highlightText']}}</span>
                      <span class="content_highlight"> {{video['solutionText']['highlightContent']}} </span>
                      <span>{{video['solutionText']['textContent']}}</span>
                      <ol class="styled_list_content">
                        <li>
                          <span>
                            <span class="content_highlight"><b>{{video['solutionText']['list'][0]['boldText']}}</b></span> {{video['solutionText']['list'][0]['normalText']['firstPart']}} <span class="content_highlight"><b>{{video['solutionText']['list'][0]['normalText']['boldPart']}}</b></span> {{video['solutionText']['list'][0]['normalText']['lastPart']}}
                          </span>
                        </li>
                        <li>
                          <span>
                            <span class="content_highlight"><b>{{video['solutionText']['list'][1]['boldText']}}</b></span> {{video['solutionText']['list'][1]['normalText']['firstPart']}} <span class="content_highlight"><b>{{video['solutionText']['list'][1]['normalText']['boldPart']}}</b></span> {{video['solutionText']['list'][1]['normalText']['lastPart']}}
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class='tab_head_container'>
          <ng-container *ngFor="let video of proofOfSuccessData['videos']; let i = index;">
            <div (click)="change_tab(i)" class="tab_head" [ngClass]="(i == active_index) ? 'active_tab' : ''">
              <span class="tab_text">
                <span class="label">{{video['tab_label']}}</span>
                <span class="duration_label">{{video['tab_duration_label']}}</span>
                <span class="duration_label">{{video['customer_name']}}</span>
              </span>
            </div>
          </ng-container>
          <div class="tab_select"[ngStyle]="{'left': left_tab_select+'%'}"></div>
        </div>
      </div>
    </div>
    <div class="btn_container buy_now_cta_container text_center"  (click)="routeToCart('CTA_success_stories')">
      <div class="hero_cta_btn secondary_section" data-cta_name="CTA_success_stories">
        <div class="logo_container">
          <img class="lozad" [src]="cdnUrl + '/assets/images/buy_cart_icon_white.png'">
        </div>
        <div class="price_container">
          <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
          <span class="original_price">{{originalPrice}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
