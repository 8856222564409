import { SEOService } from './../../Services/seo.service';
import { DataService } from './../../Services/data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

declare const push_site_event: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  slides: any = [];
  score_graph_data: any = [];
  scholarship_data: any;
  success_delivery_data: any;
  psp_data: any;
  personalized_feedback_data: any;
  ai_maximize_data: any;
  testimonial_data: any;
  analytics_data: any;
  customer_success_champions_data: any;
  webinars_data: any;

  deliver_more__than_anyone_else: string = '';
  deliver_more__than_anyone_else_second_line: string = '';
  display_svg = true;
  link_tags;
  meta_tags;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}
  ngOnInit(): void {
    setTimeout(() => {
      document.dispatchEvent(new Event('load_home_page'));
      this.display_svg = false;
    }, environment.svg_load_time);
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('home.json?version=' + version)
          .subscribe((response) => {
            this.slides = response['slides'];
            this.deliver_more__than_anyone_else =
              response['deliver_more__than_anyone_else'];
            this.deliver_more__than_anyone_else_second_line = response['deliver_more__than_anyone_else_second_line'];
            this.score_graph_data = response['score_graph_data'];
            this.scholarship_data = response['scholarship_data'];
            this.success_delivery_data = response['success_delivery_data'];
            this.psp_data = response['psp_data'];
            this.personalized_feedback_data =
              response['personalized_feedback_data'];
            this.ai_maximize_data = response['ai_maximize_data'];
            this.testimonial_data = response['testimonial_data'];
            this.analytics_data = response['analytics_data'];
            this.customer_success_champions_data =
              response['customer_success_champions_data'];
            if (response['title']) {
              this.titleService.setTitle(response['title']);
            }
            if (response['meta_tags']) {
              this.meta_tags = response['meta_tags'];
              this.metaService.addTags(response['meta_tags']);
            }
            if(response['link_tags']){
              this.link_tags= response['link_tags'];
              this.seoService.createMultiLinks(response['link_tags']);
            }
            if(response['json_ld']){
              this.seoService.createJsonLd(response['json_ld']);
            }
          });
        this.httpService
          .getJson('webinars.json?version=' + version)
          .subscribe((response) => {
            this.webinars_data = response['webinars_data'];
          });
      }
    });

    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
