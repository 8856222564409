<div class="section_2" *ngIf="everything_you_need_data">
  <div class="page_container">
    <div class="row">
      <div class="col-md-12 sectio_2_heading text-center">{{everything_you_need_data['header']['first_line']}}</div>
    </div>
    <div class="row section_2_row_box">
      <div (mouseover)="mouseoverevent(0)" (mouseleave)="mouseleaveevent(0)"
        class="col-md-4 section_2_sections section_2_sections_first text-center hover hover1" [@tabOnHover]="state[0]"
        style="padding-top: 56px;">
        <img class="section_2_images section_2_images_third"
          [src]="everything_you_need_data['sections']['first']['image_url']"
          style="margin-bottom: 35px !important;"><br><span
          class="section_2_number">{{everything_you_need_data['sections']['first']['header']}}</span><br><span
          class="section_2_image_text"></span><br>
        <br>
        <div class="section_paragraphs section_paragraphs1" [@tabOnHoverBottom]="state[0]">
          {{everything_you_need_data['sections']['first']['description']}}</div>
      </div>
      <div (mouseover)="mouseoverevent(1)" (mouseleave)="mouseleaveevent(1)"
        class="col-md-4 section_2_sections text-center hover hover2" [@tabOnHover]="state[1]">
        <img class="section_2_images section_2_images_webinar_practice"
        [src]="everything_you_need_data['sections']['second']['image_url']"><br><span class="section_2_number">{{everything_you_need_data['sections']['second']['header']}}</span><br><span class="section_2_image_text"></span><br>
        <br>
        <div class="section_paragraphs section_paragraphs2" [@tabOnHoverBottom]="state[1]">{{everything_you_need_data['sections']['second']['description']}}</div>
      </div>
      <div (mouseover)="mouseoverevent(2)" (mouseleave)="mouseleaveevent(2)"
        class="col-md-4 text-center section_2_sections third_section hover hover3" [@tabOnHover]="state[2]">
        <img class="section_2_images section_2_images_third"
        [src]="everything_you_need_data['sections']['third']['image_url']" style="height:108px"><br><span
          class="section_2_number">{{everything_you_need_data['sections']['third']['header']}}</span><span class="section_2_image_text"></span><br>
        <br><span class="section_2_image_text"></span><br>
        <div class="section_paragraphs section_paragraphs3" [@tabOnHoverBottom]="state[2]">{{everything_you_need_data['sections']['third']['description']}}</div>
      </div>
    </div>
    <div class="row" *ngIf="canRegister">
      <div class="col-md-12 section_1_cta section_2_cta text-center"> <button (click)="openModal()" type="button"
          class="btn btn-custom yellow_reserve cta_track" data-section="occurrence4" data-toggle="modal"
          data-target="#startnow">Register Now</button></div>
    </div>
    <div class="row" *ngIf="!canRegister">
      <div class="col-md-12 section_1_cta section_2_cta text-center"> <button type="button"
          class="btn btn-custom gray_reserve cta_track" data-section="occurrence4" data-toggle="modal"
          data-target="#startnow">No Webinar Scheduled</button></div>
    </div>
  </div>
</div>
