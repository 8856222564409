import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClientService } from '../../Services/http-client.service';
import { DataService } from '../../Services/data.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent implements OnInit, OnDestroy {
  founders_data: any;
  journey_data: any;
  teams_data: any;
  display_svg = true;
  link_tags;
  meta_tags;
  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}
  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('about-us.json?version=' + version)
          .subscribe((response) => {
            this.founders_data = response['founders'];
            this.journey_data = response['journey'];
            this.teams_data = response['teams'];
            if (response['title']) {
              this.titleService.setTitle(response['title']);
            }
            if (response['meta_tags']) {
              this.meta_tags = response['meta_tags'];
              this.metaService.addTags(response['meta_tags']);
            }
            if(response['link_tags']){
              this.link_tags= response['link_tags'];
              this.seoService.createMultiLinks(response['link_tags']);
            }
            if(response['json_ld']){
              this.seoService.createJsonLd(response['json_ld']);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
