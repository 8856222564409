<div class="section section_5_tab_container section_pad section_5_pad_btm text_center" *ngIf="proven_methods_data">
  <div class="page_container">
    <div class="container_section_new">
      <div class="row">
        <div class="col-md-12">
          <div class="section_5_tab_container_head" data-aos="slide-up">{{proven_methods_data['header']['first_line']}}
          </div>
          <div class="section_5_tab_container_text" data-aos="slide-up">
            {{proven_methods_data['header']['second_line']}}</div>
        </div>
      </div>
      <div class="tab_view_wrapper">
        <div class="psp_section egmat_tab_view" data-aos="slide-up">
          <div class="tab_view_container">
            <div class="tab_content_container">
              <ng-container *ngFor="let slide of proven_methods_data['slides'];let i=index;">
                <div class="tab_content" [ngClass]="pm_active_class_index == i?'active_tab':''">
                  <app-iframe [url]="slide['video_url']" [class]="'psp_wistia_video lozad wistia_embed border_radius_10'" [onload]="true" (loadEvent)="IframeLoad($event)"></app-iframe>
                </div>
              </ng-container>
            </div>
            <div class="tab_head_container">
              <ng-container *ngFor="let slide of proven_methods_data['slides'];let j=index;">
                <div class="tab_head" [ngClass]="pm_active_class_index == j?'active_tab':''"
                  (click)="change_tab(j)">
                  <span class="tab_text">
                    <span class="label">{{slide['tab_head']}}</span>
                    <span class="duration_label">{{slide['tab_sub_head']}}</span>
                    <span class="customer_name">{{slide['tab_name']}}</span>
                  </span>
                </div>
              </ng-container>
              <div class="tab_select_parent" [ngStyle]="{'left': (pm_active_class_index * 25)+'%'}">
                <div class="tab_select">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-buy-now [paddingBottom] = "'86px'"></app-buy-now>
</div>
