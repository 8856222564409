<div class="section section_pip section_pad section_5_pad_btm text_center" id="study_plan"
  *ngIf="personalizedImprovementPlanData">
  <div class="page_container">
    <p class="page_head section_page_head_gap extra_bold_font" data-aos="slide-up">
      <span class="highlight_text">{{personalizedImprovementPlanData['heading']['highlightWords']}}
      </span>
      <br />{{personalizedImprovementPlanData['heading']['restPart']}}
    </p>
    <div class="section_5_section_container">
      <p class="page_sub_head page_sub_head_light_color px-[75px] md:px-[9px]" data-aos="slide-up">
        {{personalizedImprovementPlanData['subHeading']['firstPart']}} <b class="text-[#000000]"><span
            class="text-[#ff9725]">{{personalizedImprovementPlanData['subHeading']['xColorLetter']}}</span>{{personalizedImprovementPlanData['subHeading']['boldWord']}}</b>
        {{personalizedImprovementPlanData['subHeading']['restWords']}}
      </p>
      <app-psp-info-tabs [videos]="personalizedImprovementPlanData['videos']"></app-psp-info-tabs>
    </div>
  </div>
  <div class="curve_style_div">

  </div>
</div>
