import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css'],
})
export class ScoreComponent implements OnInit, OnChanges {
  tab_type: string = 'monthly';

  @Input() score_graph_data;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if(this.score_graph_data){
      this.tab_type = this.score_graph_data['default'];
    }
  }
  getStyleLeft() {
    switch (this.tab_type) {
      case 'yearly':
        return 0;
      case 'monthly':
        return 35;
      case 'weekly':
        return 70;
    }
  }
}
