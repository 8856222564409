<div class="about_us_section2_div" *ngIf="journey_data">
  <div class="about_us_sec_2_header" data-aos="slide-up">e-GMAT's Journey</div>
  <div class="about_us_sec2_flex_container">
    <div class="sec2_left">
      <div class="timeline_div_parent2" data-aos="fade-right">
        <div class="timeline_div2">
          <div class="timeline_header">{{journey_data[1]["header"]}}<span
              class="timeline_sub_header student_text_t2">{{journey_data[1]["subheader"]}}</span></div>
          <div class="year_div"><span class="year_text">{{journey_data[1]["year"]}}</span></div>
        </div>
        <div class="timeline_description2">{{journey_data[1]["description"]}}</div>
      </div>

      <div class="timeline_div_parent4" data-aos="fade-right">
        <div class="timeline_div4">
          <div class="timeline_header">{{journey_data[3]["header"]}}<span
              class="timeline_sub_header student_text_t4">{{journey_data[3]["subheader"]}}</span></div>
          <div class="year_div"><span class="year_text">{{journey_data[3]["year"]}}</span></div>
        </div>
        <div class="timeline_description4">{{journey_data[3]["description"]}}</div>
      </div>

      <div class="timeline_div_parent6" data-aos="fade-right">
        <div class="timeline_div6">
          <div class="timeline_header">{{journey_data[5]["header"]}}<span
              class="timeline_sub_header student_text_t6">{{journey_data[5]["subheader"]}}</span></div>
          <div class="year_div"><span class="year_text">{{journey_data[5]["year"]}}</span></div>
        </div>
        <div class="timeline_description6">{{journey_data[5]["description"]}}</div>
      </div>
    </div>
    <div class="sec2_middle">
      <img class="only_desktop_view lozad" [src]="cdn_url+'/assets/images/dash-dot.png'">
      <img class="only_mobile_view lozad" [src]="cdn_url+'/assets/images/dash-dot-mobile.png'">
    </div>
    <div class="sec2_right">
      <div class="timeline_div_parent1" data-aos="fade-left">
        <div class="timeline_div1">
          <div class="year_div"><span class="year_text">{{journey_data[0]["year"]}}</span></div>
          <div class="timeline_header timeline_header_1">{{journey_data[0]["header"]}}</div>
        </div>
        <div class="timeline_description1">{{journey_data[0]["description"]}}</div>
      </div>

      <div class="timeline_div_parent3" data-aos="fade-left">
        <div class="timeline_div3">
          <div class="year_div"><span class="year_text">{{journey_data[2]["year"]}}</span></div>
          <div class="timeline_header">{{journey_data[2]["header"]}}<span
              class="timeline_sub_header student_text_t3">{{journey_data[2]["subheader"]}}</span></div>
        </div>
        <div class="timeline_description3">{{journey_data[2]["description"]}}</div>
      </div>

      <div class="timeline_div_parent5" data-aos="fade-left">
        <div class="timeline_div5">
          <div class="year_div"><span class="year_text">{{journey_data[4]["year"]}}</span></div>
          <div class="timeline_header">{{journey_data[4]["header"]}}<span
              class="timeline_sub_header student_text_t5">{{journey_data[4]["subheader"]}}</span></div>
        </div>
        <div class="timeline_description5">{{journey_data[4]["description"]}}</div>
      </div>

      <div class="timeline_div_parent7" data-aos="fade-left">
        <div class="timeline_div7">
          <div class="year_div"><span class="year_text">{{journey_data[6]["year"]}}</span></div>
          <div class="timeline_header">{{journey_data[6]["header"]}}<span
              class="timeline_sub_header student_text_t7">{{journey_data[6]["subheader"]}}</span></div>
        </div>
        <div class="timeline_description7">{{journey_data[4]["description"]}}</div>
      </div>
    </div>
  </div>
  <div class="sec2_footer_text" data-aos="slide-up">Inspiring and Helping thousands of students everyday to reach their
    dream GMAT score.</div>
</div>
