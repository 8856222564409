<app-bar-graph [barGraphData]="barGraphData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-bar-graph>
<app-limited-period-offer [couponsLeftBeforeDealStart]="couponsLeftBeforeDealStart" [limitedPeriodOfferData]="limitedPeriodOfferData" [dealCounters]="dealCounters" [product]="product"></app-limited-period-offer>
<app-success-metrics [successMetricsData]="successMetricsData"></app-success-metrics>
<app-top-reasons [topReasonsData]="topReasonsData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-top-reasons>
<app-deal-course-comparison [courseComparisonData]="courseComparisonData"  [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-deal-course-comparison>
<app-personalized-improvement-plan [personalizedImprovementPlanData]="personalizedImprovementPlanData"></app-personalized-improvement-plan>
<app-proof-of-success [proofOfSuccessData]="proofOfSuccessData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-proof-of-success>
<app-coupon-details [dealCounters]="dealCounters" [couponDetailsData]="couponDetailsData"  [product]="product"></app-coupon-details>
<app-deal-gmat-journey [gmatJourneyData]="gmatJourneyData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-deal-gmat-journey>

