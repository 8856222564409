<div class="main_page_bg background_config"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/bg_resgistration_page.png)'}">
  <div class="page_container">
    <div class="page_modal_container">
      <div class="reg_page">
        <div class="reg_page_header">
          <div class="row row_mobile">
            <div class="col-md-4 col-sm-2 col-xs-2">
              <div class="timeline_head"><img class="img_egmat"
                  [src]="cdn_url+'/assets/images/egmat_logo_cart_login_timeline.png'"></div>
            </div>
            <div class="col-md-8 col-sm-10 col-xs-10">
              <div class="row row_mobile">
                <div class="col-md-9 col-sm-9 col-xs-8">
                  <div class="back_to_home_container"> <a class="back_to_home" routerLink="/">back to home</a></div>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-4 login_button_col">
                  <div class="login_button_container">
                    <div class="reg_header_login" data-toggle="modal" data-target="#loginBox" (click)="ModalOpen()">Login</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 class="main_reg_heading text-center">GMAT Free Resources</h1>
        </div>
        <div class="reg_form_sec">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="form_outer_container">
                <div class="reg_form">
                  <div class="red_reg_form_error_msg" id="reg_error_message_id">{{reg_error_message}}</div>
                  <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                    <div class="form-group">
                      <div class="input_field_label">Name*</div>
                      <div *ngIf="f.name.errors" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg" id="name_error_message"
                          *ngIf="alert_submitted['name'] && f.name.errors.required">
                          Name is Required
                        </div>
                      </div>
                      <input formControlName="name" [ngClass]="{ 'is-invalid': alert_submitted['name'] && f.name.errors }"
                        type="text" class="form-control reqField" id="name" name="name" placeholder="Name" value="" (keyup)="onKeyUpEmail($event,'name')">
                    </div>
                    <div class="form-group">
                      <div class="input_field_label">Email*</div>
                      <div *ngIf="f.username.errors" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message"
                          *ngIf="alert_submitted['username'] && f.username.errors.required">
                          Email is Required
                        </div>
                        <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message"
                          *ngIf="alert_submitted['username'] && f.username.errors.pattern">
                          Enter a valid Email Address
                        </div>
                      </div>
                      <input formControlName="username"
                        [ngClass]="{ 'is-invalid': alert_submitted['username'] && f.username.errors }" type="text"
                        class="form-control reqField" id="emailval" name="username" placeholder="Email" value="" (keyup)="onKeyUpEmail($event,'username')">
                    </div>
                    <div class="form-group password-form">
                      <div class="input_field_label">Password*</div>
                      <div *ngIf="f.password.errors" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message"
                          *ngIf="alert_submitted['password'] && f.password.errors.required">
                          Password is Required
                        </div>
                      </div>
                      <input formControlName="password" [attr.type]="show_password?'text':'password'"
                        [ngClass]="{ 'is-invalid': alert_submitted['password'] && f.password.errors }" type="password"
                        class="form-control reqField" id="password" name="password" placeholder="min. 8 characters"
                        value="" (keyup)="onKeyUpEmail($event,'password')">
                      <img toggle="#pass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
                        class="field-icon toggle-password" (click)="show_password = !show_password;" [ngClass]="{'display_ele': !show_password}">
                      <img toggle="#pass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
                        class="field-icon toggle-password" (click)="show_password = !show_password;"  [ngClass]="{'display_ele': show_password}">
                      <div class="checkbox reg_tnc">
                        <label>

                          <input [ngClass]="{ 'is-invalid': alert_submitted['tnc'] && tnc_error  }"
                            type="checkbox" class="reg_check" id="input-check" (change)="tncChecked($event)"><label class="square_label"
                            for="input-check">
                          </label>&nbsp; I accept
                          <a class="tnc" target="_blank" routerLink="/terms-and-conditions">Terms &amp; Conditions</a>
                          <div *ngIf="alert_submitted['tnc'] && tnc_error" class="invalid-feedback">
                            <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message">
                              Please accept the terms and conditions
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div class="form-group form_sub_btn">
                      <button *ngIf="!submitted" type="submit" id="submitmyformRegister"
                        class="btn btn-default reg_btn_form register_submit reg_submit_button_login">Get Started</button>
                      <button *ngIf="submitted" type="submit" id="submitmyformgif"
                        class="btn btn-default reg_btn_form register_submit" disabled><img style="width:40px;"
                          [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></button>
                    </div>
                  </form>
                  <div class="form_or">
                    <div class="form_or_text form_register_or_text">or Sign up with Google</div>
                    <div class="form_or_line form_register_or_line"></div>
                  </div>
                  <div class="form_google_btn">
                    <div class="form_google_btn form_google_btn_register">
                      <app-google-signin-btn [submit_button_class]="'reg_submit_button_login'" *ngIf="!submitted_google" text="signup_with" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
                      <!-- <a *ngIf="!submitted_google" (click)="google_signup()" id="submitmyform"
                        class="btn btn-default google_lgn_btn_form">
                        <span class="fa fa-google reg_google_plus" aria-hidden="true"></span> &nbsp;&nbsp;Sign up with
                        Google</a> -->
                      <a *ngIf="submitted_google" id="submitmyformgif" class="btn btn-default google_lgn_btn_form" disabled><img
                          style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 flex_display">
              <div class="free_sources">
                <div class="resource_row mt_0">
                  <div class="resou_img col-md-6 col-sm-6 col-xs-6">01</div>
                  <div class="resource_text col-md-6 col-sm-6 col-xs-6">Mock Test with ESR</div>
                </div>
                <div class="resource_row">
                  <div class="resou_img col-md-6 col-sm-6 col-xs-6">25</div>
                  <div class="resource_text col-md-6 col-sm-6 col-xs-6">Free Video Lessons</div>
                </div>
                <div class="resource_row">
                  <div class="resou_img col-md-6 col-sm-6 col-xs-6">200<sup class="sup_elem">+</sup></div>
                  <div class="resource_text col-md-6 col-sm-6 col-xs-6">Free Practice Questions</div>
                </div>
                <div class="resource_row mb_75">
                  <div class="resou_img col-md-6 col-sm-6 col-xs-6"><img class="infinity_img"
                      [src]="cdn_url+'/assets/images/resgistration_page_infinity_img.png'"></div>
                  <div class="resource_text col-md-6 col-sm-6 col-xs-6">Free Weekend Live Webinars</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-signin></app-signin>
