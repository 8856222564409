import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-author-cards',
  templateUrl: './author-cards.component.html',
  styleUrls: ['./author-cards.component.css']
})
export class AuthorCardsComponent {
  cdn_url = environment.cdn_url;
  @Input() card;
  @Input() card_number;
  @Output() openTabEvent = new EventEmitter<any>();

  openTab(){
    let data = {
      flag: true,
      index: this.card_number
    }
    this.openTabEvent.emit(data);
  }
}
