<div *ngIf="gmatJourneyData" id="visualize_journey" class="section section_pad section_gmat_journey">
  <div class="page_container">
    <p class="page_head highlight_text mob_col_black text_center extra_bold_font" data-aos="slide-up">
      {{gmatJourneyData['heading']['firstPart']}} <span class="highlight_text mob_display_block">{{gmatJourneyData['heading']['restPart']}}</span>
    </p>
    <div class="img_container">
      <img *ngIf="screenSize >= 769" class="lozad desktop_graphic" [src]="cdn_url + '/assets/images/gmat_journey_graphic.png'">
      <img *ngIf="screenSize < 769" class="lozad mobile_graphic" [src]="cdn_url + '/assets/images/deals_page_gmat_journey_mobile_graphic.png'">
    </div>
    <div class="btn_container buy_now_cta_container text_center"  (click)="routeToCart('CTA_visualize_journey')">
      <div class="hero_cta_btn secondary_section" data-cta_name="CTA_visualize_journey">
        <div class="logo_container">
          <img class="lozad" [src]="cdn_url + '/assets/images/buy_cart_icon_white.png'">
        </div>
        <div class="price_container">
          <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
          <span class="original_price">{{originalPrice}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
