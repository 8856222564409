import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-concerns',
  templateUrl: './concerns.component.html',
  styleUrls: ['./concerns.component.css']
})
export class ConcernsComponent implements OnInit {
  cdn_url = environment.cdn_url;

  tab_type = 'support';
  constructor() { }

  @Input() concerns_data;
  ngOnInit(): void {
  }

  open_card(i,key){
    this.concerns_data[key].forEach((element,index) => {
      if(index == i){
        element['expand_faq'] = true;
      }else{
        element['expand_faq'] = false;
      }
    });
  }

}
