import { FtGmatPrepHomeComponent } from './ft-gmat-prep/ft-gmat-prep-home/ft-gmat-prep-home.component';
import { JoinSessionComponent } from './common/free-session/join-session/join-session.component';
import { VideosRedirectComponent } from './common/videos-redirect/videos-redirect.component';
import { GcSuccessStoriesComponent } from './common/gc-success-stories/gc-success-stories.component';
import { CustomContentComponent } from './common/redirect/custom-content/custom-content.component';
import { CheckSegmentAndRedirectComponent } from './common/check-segment-and-redirect/check-segment-and-redirect.component';
import { ChartsDataComparisonComponent } from './common/charts-data-comparison/charts-data-comparison.component';
import { ActivityRedirectComponent } from './common/activity-redirect/activity-redirect.component';
import { LoginComponent } from './common/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { RefreshCourseEnrollmentComponent } from './common/refresh-course-enrollment/refresh-course-enrollment.component';
import { FaqScreenComponent } from './faq-screen/faq-screen.component';
import { CourseOfferingPagesComponent } from './deal-pages/course-offering-pages/course-offering-pages/course-offering-pages.component';
import { SalesPagesComponent } from './deal-pages/sales-pages/sales-pages/sales-pages.component';
import { ScFreeRegistrationComponent } from './webinar/sc-free-registration/sc-free-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordResetSuccessComponent } from './password-reset-success/password-reset-success.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AdmitsAndScholarshipComponent } from './admits-and-scholarships/admits-and-scholarship/admits-and-scholarship.component';
import { FaqComponent } from './faq/faq.component';
import { PlanAndPricingComponent } from './plan-and-pricing/plan-and-pricing/plan-and-pricing.component';
import { CourseDetailComponent } from './course-detail/course-detail/course-detail.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home-page/home/home.component';
import { AboutUsComponent } from './about-us/about-us-home/about-us.component';
import { CartComponent } from './cart/cart-home/cart.component';
import { RouterModule, Routes } from '@angular/router';
import { NewSalesPagesComponent } from './deal-pages/sales-pages/new-sales-pages/new-sales-pages.component';
import { FtGmatPrepHomeV2Component } from './ft-gmat-prep-v2/ft-gmat-prep-home/ft-gmat-prep-home-v2.component';
import { FtGmatPrepHomeGfeV2Component } from './ft-gmat-prep-gfe-v2/ft-gmat-prep-home/ft-gmat-prep-home-gfe-v2.component';
import { ChristmasDealPageComponent } from './deal-pages/sales-pages/christmas-sales-pages/christmas-deal-page/christmas-deal-page.component';
import { GceOneMComponent } from './deal-pages/sales-pages/christmas-sales-pages/gce-one-m/gce-one-m.component';
const routes: Routes = [
  { path: '404', component: PageNotFoundComponent },
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'learning-login', component: LoginComponent },
  { path: 'gmat-course-online', component: CourseDetailComponent },
  { path: 'pricing', component: PlanAndPricingComponent },
  {
    path: 'pricing/complete-gmat-preparation-courses',
    pathMatch: 'full',
    redirectTo: 'pricing',
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'faq/:applicationName', component: FaqScreenComponent },
  { path: 'faq/:applicationName/:questionId', component: FaqScreenComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'admits-scholarships', component: AdmitsAndScholarshipComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'password-reset-success', component: PasswordResetSuccessComponent },
  {
    path: 'free-resources-gmat-registration',
    component: RegistrationComponent,
  },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'master-pre-thinking', component: ScFreeRegistrationComponent },
  { path: 'geometry_webinar', component: ScFreeRegistrationComponent },
  {
    path: 'strategy-session-registration',
    component: ScFreeRegistrationComponent,
  },
  { path: 'number-properties', component: ScFreeRegistrationComponent },
  { path: 'sc-free-registration', component: ScFreeRegistrationComponent },
  { path: 'rc1-webinar', component: ScFreeRegistrationComponent },
  { path: 'algebra-webinar', component: ScFreeRegistrationComponent },
  { path: 'cart/:productId', component: CartComponent },
  {
    path: 'refresh-course-enrollment',
    component: RefreshCourseEnrollmentComponent,
  },
  { path: 'activity-redirect', component: ActivityRedirectComponent },
  { path: 'charts-data-comparison', component: ChartsDataComparisonComponent },
  { path: 'charts-data-gc-page', component: ChartsDataComparisonComponent },
  {
    path: 'check-segment-and-redirect',
    component: CheckSegmentAndRedirectComponent,
  },
  { path: 'custom-content', component: CustomContentComponent },
  { path: 'gc-articles', component: CustomContentComponent },
  { path: 'success-stories', component: CustomContentComponent },
  { path: 'upcoming-session', component: CustomContentComponent },
  { path: 'direct-calendly-redirect', component: CustomContentComponent },
  { path: 'gc-success-stories', component: GcSuccessStoriesComponent },
  { path: 'videos', component: VideosRedirectComponent },
  { path: 'free-session/join.php', component: JoinSessionComponent },
  {
    path: 'free-session/session-recording.php',
    component: JoinSessionComponent,
  },
  { path: 'ft-gmat-prep', component: FtGmatPrepHomeComponent },
  { path: 'ft-gmat-preparation', component: FtGmatPrepHomeComponent },
  { path: 'ft-gmat-prep-gce', component: FtGmatPrepHomeV2Component},
  { path: 'ft-gmat-focus-edition-prep', component: FtGmatPrepHomeGfeV2Component},
  { path: 'courses', pathMatch: 'full', redirectTo: 'gmat-course-online' },
  {
    path: 'registration',
    pathMatch: 'full',
    redirectTo: 'free-resources-gmat-registration',
  },
  { path: 'forgot_password', pathMatch: 'full', redirectTo: 'forgot-password' },
  { path: 'buy', pathMatch: 'full', redirectTo: 'pricing' },
  { path: 'about_us', pathMatch: 'full', redirectTo: 'about-us' },
  { path: 'limited-period-offer', component: NewSalesPagesComponent },
  { path: 'limited-period-offer/go-1m', component: ChristmasDealPageComponent },
  { path: 'limited-period-offer/go-2m', component: NewSalesPagesComponent },
  { path: 'limited-period-offer/ap-1m', component: GceOneMComponent },
  { path: 'limited-period-offer/go-4m', component: NewSalesPagesComponent },
  { path: 'limited-period-offer/go-6m', component: NewSalesPagesComponent },
  { path: 'course-offerings-go-2m', component: CourseOfferingPagesComponent },
  { path: 'course-offerings-go-4m', component: CourseOfferingPagesComponent },
  { path: 'course-offerings-go-6m', component: CourseOfferingPagesComponent },
  { path: '**', pathMatch: 'full', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
