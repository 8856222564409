<div class="container_section container_section_mob" *ngIf="everything_you_need_data">
  <div class="section1_div">
    <div class="main_small_header" data-aos="slide-up">{{everything_you_need_data['header']['first_line']}}</div>
    <div class="main_header" data-aos="slide-up">{{everything_you_need_data['header']['second_line']}}</div>
    <div class="section1_sub_header" data-aos="slide-up">{{everything_you_need_data['header']['third_line']}}</div>
    <div class="flex_container">
      <div class="section1_left" data-aos="slide-up">
        <div class="buttons_div_left" data-aos="slide-up">
          <ng-container *ngFor="let video_left of everything_you_need_data['videos'];let i=index;">
            <ng-container *ngIf="i < half_count">
              <button class="section1_buttons" [ngClass]="{'active_btn':active_class_index == i,'bottom_gap':i != half_count - 1}"
                type="button" data-aos="slide-up" (click)="active_class_index = i;">
                <img class="lozad color_play_img" [src]="cdn_url+'/assets/images/ellipse-triangle.png'"
                  [ngClass]="(active_class_index == i)? 'active_img':''">
                <img class="lozad faded_play_img" [src]="cdn_url+'/assets/images/ellipse-triangle-passive.png'"
                  [ngClass]="(active_class_index != i)? 'active_img':''">
                <span class="button_text" data-aos="slide-up"
                  [ngClass]="(active_class_index != i)? 'button_text_passive':''">{{video_left['tab_text']}}</span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="section1_middle" data-aos="slide-up">
        <div class="iframe_video_wrapper">
          <ng-container *ngFor="let video of everything_you_need_data['videos'];let i=index;">
            <app-iframe [url]="video['video_url']" [class]="'lozad wistia_embed section_1_iframe'"
              [ngClass]="{'display_none':active_class_index != i}">
            </app-iframe>
          </ng-container>
        </div>
      </div>
      <div class="section1_right" data-aos="slide-up">
        <div class="buttons_div_right" data-aos="slide-up">
          <ng-container *ngFor="let video_right of everything_you_need_data['videos'];let i=index;">
            <ng-container *ngIf="i >= half_count">
              <button class="section1_buttons" type="button" data-aos="slide-up"
                (click)="active_class_index = i;" [ngClass]="{'active_btn':active_class_index == i,'bottom_gap':i != total_count - 1}">
                <img class="lozad color_play_img" [src]="cdn_url+'/assets/images/ellipse-triangle.png'"
                  [ngClass]="(active_class_index == i)? 'active_img':''">
                <img class="lozad faded_play_img" [src]="cdn_url+'/assets/images/ellipse-triangle-passive.png'"
                  [ngClass]="(active_class_index != i)? 'active_img':''">
                <span class="button_text" data-aos="slide-up"
                  [ngClass]="(active_class_index != i)? 'button_text_passive':''">{{video_right['tab_text']}}</span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="sec1_slider_mobile_container" data-aos="slide-up">
      <div class="sec1_controls_prev" (click)="selectSlide(false)" data-aos="slide-up">
        <img class="lozad sec1_left_slide_img" [ngStyle]="{'display':active_class_index != 0?'':'none'}" [src]="cdn_url+'/assets/images/mobile_left.png'" data-loaded="true">
      </div>
      <div class="tns-outer">
        <ng-container *ngFor="let video_right of everything_you_need_data['videos'];let i=index;">
          <button class="application_button section1_buttons slide_2_button" type="button" data-aos="slide-up"
            [ngStyle]="{'left': (i == active_class_index)?'50%':((i < active_class_index)?'-200%':'200%')}">
            <div class="mobile_slider_buttons_div">
              <img class="lozad color_play_img loaded tns-complete"
                [ngClass]="(active_class_index == i)? 'active_img':''"
                [src]="cdn_url+'/assets/images/ellipse-triangle.png'">
              <img class="lozad faded_play_img loaded tns-complete"
                [ngClass]="(active_class_index != i)? 'active_img':''"
                [src]="cdn_url+'/assets/images/ellipse-triangle-passive.png'"><span
                class="button_text" data-aos="slide-up">{{video_right['tab_text']}}</span>
            </div>
          </button>
        </ng-container>
      </div>
      <div class="sec1_controls_next" (click)="selectSlide(true)" data-aos="slide-up">
        <img class="lozad sec1_right_slide_img" [ngStyle]="{'display':active_class_index != (everything_you_need_data['videos'].length-1)?'':'none'}" [src]="cdn_url+'/assets/images/mobile_right.png'" data-loaded="true">
      </div>
    </div>
    <app-buy-now></app-buy-now>
  </div>
</div>
