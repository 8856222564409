<div class="solution_details_section" *ngIf="solution_detail_data">
  <div class="page_container">
    <h1 class="page_head extra_bold_font text_center" data-aos="slide-up">
      {{solution_detail_data['header']['first_line']}}
    </h1>
    <p class="page_sub_head text_center" data-aos="slide-up">
      {{solution_detail_data['header']['second_line']}}
    </p>
    <div class="mx-auto w-max rounded-[5px] flex mt-[40px] mb-[25px] p-[5px] bg-[#F2F2F2]">
      <div class="w-max">
        <button (click)="displayLiveSessionSolutionView(false)" [ngClass]="{'!bg-[#FFC766] !text-[#000000]': !solution_detail_data['liveSessionSolutionView']}" class="text-[#7F7F7F] w-max text-[16px] font-semibold px-[45px] py-[6px] border-none rounded-[5px] cursor-pointer">Self Paced</button>
      </div>
      <div class="w-max">
        <button (click)="displayLiveSessionSolutionView(true)" [ngClass]="{'!bg-[#000000] !text-[#ffffff]': solution_detail_data['liveSessionSolutionView']}" class="text-[#7F7F7F] w-max text-[16px] font-semibold px-[30px] py-[6px] border-none rounded-[5px] cursor-pointer relative">
          Live Sessions
          <div class="absolute top-[4px] right-[12px]">
            <img [src]="cdn_url+'/assets/icons/ico-stars.svg'" alt="">
          </div>
        </button>
      </div>
    </div>
    <div class="solution_details_container">
      <div class="solution_details_wrap">
        <div class="solution_details">
          <ng-container *ngIf="solution_detail_data['liveSessionSolutionView']">
            <ng-container *ngFor="let solution_detail of solution_detail_data['solution_details']['live_sessions'];let i = index;">
              <div class="solution_row">
                <div class="live_session_sol_detail">
                  <div class="header_label"> <span>{{solution_detail['label_head']}}</span></div>
                </div>
                <div [ngClass]="i != 0?'solution_info_column column_lines_ls':''"></div>
                <div [ngClass]="i != 0?'solution_info_check_column column_lines_ls':''"></div>
              </div>
              <ng-container *ngFor="let row of solution_detail['rows'];let k = index;">
                <div class="solution_row">
                  <div class="live_session_sol_detail icon_container">
                    <div class="solution_header"> <span>
                        <img class="lozad" [src]="cdn_url+row['header']['image_url']">
                      </span> <span>&nbsp;{{row['header']['text']}}&nbsp;</span> <span class="mobile"> <img
                          class="lozad icon_default" [src]="cdn_url+row['header']['image_url']"> <img
                          class="lozad icon_hover" [src]="cdn_url+row['header']['image_url']">
                      </span>
                    </div>
                    <div class="solution_detail">{{row['detail']}}</div>
                  </div>
                  <div class="solution_info_column column_lines_ls">
                    <div class="check_column_wrap">
                      <ng-container *ngFor="let info of row['info']">
                        <div [ngClass]="{'check_column_wrap_div':info['image_url']}"> <span *ngIf="info['image_url']"> <img class="lozad" [src]="info['image_url']">
                          </span>
                          <span> {{info['text']}}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="solution_info_check_column column_lines_ls"> <span> <img class="lozad" [src]="cdn_url+'/assets/icons/ls_blue_tick.png'">
                    </span></div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let solution_detail of solution_detail_data['solution_details']['self_paced'];let i = index;">
            <div class="solution_row">
              <div [ngClass]="solution_detail_data['liveSessionSolutionView']?'live_session_sol_detail':'solution_detail_column'">
                <div class="header_label"> <span>{{solution_detail['label_head']}}</span></div>
              </div>
              <ng-container *ngIf="!solution_detail_data['liveSessionSolutionView']">
                <div [ngClass]="{'solution_info_column' : i != 0}"></div>
                <div [ngClass]="{'solution_info_check_column' : i != 0}"></div>
              </ng-container>
              <ng-container *ngIf="solution_detail_data['liveSessionSolutionView']">
                <div class="solution_info_column column_lines_ls"></div>
                <div class="solution_info_check_column column_lines_ls"></div>
              </ng-container>
            </div>
            <ng-container *ngFor="let row of solution_detail['rows']">
              <div class="solution_row">
                <div class="solution_detail_column icon_container">
                  <div class="solution_header"> <span>
                      <img class="lozad" [src]="solution_detail_data['liveSessionSolutionView'] ? cdn_url+row['header']['image_url_blue']  : row['header']['image_url']">
                    </span> <span>&nbsp;{{row['header']['text']}}&nbsp;</span> <span class="mobile"> <img
                        class="lozad icon_default" [src]="cdn_url+'/assets/images/expand_arrow_default_icon.png'"> <img
                        class="lozad icon_hover" [src]="cdn_url+'/assets/images/expand_arrow_selected_icon.png'">
                    </span>
                  </div>
                  <div class="solution_detail">{{row['detail']}}</div>
                </div>
                <div class="solution_info_column" [class.column_lines_ls]="solution_detail_data['liveSessionSolutionView']">
                  <div class="check_column_wrap">
                    <ng-container *ngFor="let info of row['info']">
                      <div [ngClass]="{'check_column_wrap_div':info['image_url']}"> <span *ngIf="info['image_url']"> <img class="lozad" [src]="solution_detail_data['liveSessionSolutionView'] ? cdn_url+info['image_url_blue'] : info['image_url']">
                        </span>
                        <span> {{info['text']}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="solution_info_check_column" [class.column_lines_ls]="solution_detail_data['liveSessionSolutionView']"> <span> <img class="lozad" [src]="solution_detail_data['liveSessionSolutionView']? cdn_url+'/assets/icons/ls_blue_tick.png' :  row['info_check_image_url']">
                  </span></div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
