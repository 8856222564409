<footer class="w-full h-[537px] sm:md:h-[819px] flex flex-row sm:md:flex-col-reverse md:flex-col-reverse   font-['Nunito Sans']" [ngStyle]=" {'background-image': ' url('+ cdn_url+'/assets/images/img-footer-bg.png)'} ">
   <div class="flex  basis-2/5 sm:md:basis-[38%]  items-center justify-center ">
    <div class="flex flex-col" >
        <div class="flex items-center my-6 justify-start sm:md:justify-center sm:md:my-[5px]">
            <img [src]="cdn_url+'/assets/logo/e-gmat-logo-ft-gmat-prep.png'" alt="e-gmat" class="h-[52px] sm:md:h-[19px] w-[60px] sm:md:w-[21px]">
            <h2 class="text-[26px] sm:md:text-[18px] text-white font-bold mx-4">e-GMAT.com</h2>
        </div>
        <div class="flex flex-col  text-[26px] items-start sm:md:items-center sm:md:text-[18px]  text-[#A6A6A6] mb-[72px] sm:md:mb-3">
            <p>Empowering dreams </p> <p> Changing lives</p>
        </div>
        <div class="flex flex-col items-start justify-normal sm:md:justify-center sm:md:items-center mt-0 sm:md:mt-[10px] ">
            <h3 class="text-white text-[26px] sm:md:text-[18px] font-bold sm:md:font-semibold">Follow us</h3>
            <div class="flex my-6 sm:md:my-3 ml-0 sm:md:ml-4">
                <a target="_blank" href="https://www.facebook.com/E-Gmat-499275643430980" class="h-[38px]   w-[38px] sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#3B5998] m-1 ml-0 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'facebook-f']" class="text-white text-[24px] sm:md:text-lg"></fa-icon>
                </a>
                <a target="_blank" href="https://www.linkedin.com/in/rajat-sadana-ba459a" class="h-[38px] w-[38px] sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#0E76A8] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'linkedin-in']" class="text-white text-[24px] sm:md:text-lg"></fa-icon>
                </a>
                <a  href="https://www.youtube.com/user/eGMATconcepts" target="_blank" class="h-[38px] w-[38px] sm:md:h-[30px] sm:md:w-[30px] py-[3.5px] px-[11.5px] rounded-full bg-[#727272] hover:bg-[#D8362A] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fas', 'play']" class="text-white text-[22px] sm:md:text-lg"></fa-icon>
                </a>
                <a href="https://twitter.com/e_GMAT" target="_blank" class="h-[38px] w-[38px]  sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#fff] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'x-twitter']" class="text-white text-lg text-[24px] sm:md:text-lg  hover:text-[#1A1A1A]"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</div>
    <div class="flex items-center justify-center font-['Nunito Sans']">
        <div class="relative flex flex-col items-start justify-normal sm:md:justify-end sm:md:items-center h-[350px] sm:md:h-[372px] sm:md:w-[410px] w-[717px] bg-[#262626] rounded-[21px] sm:md:p-0 pt-[62px] pl-[45px]">
        <h1 class="text-white text-[36px] font-semibold sm:md:text-[30px] my-0 sm:md:my-[20px]">Looking for <span class="text-gfe"> help?</span></h1>
        <p class="text-[20px] text-white w-[44%] sm:md:w-[78%] sm:md:text-[18px] text-left sm:md:text-center pt-[35px] sm:md:pt-[8px] pb-[20px]">If you have any queries or need help with your preparation, you can write back to me at</p>


        <div  class="px-3 bg-[#464646] rounded-lg flex items-end mb-0 sm:md:mb-[30px]">
            <fa-icon [icon]="['fas', 'envelope']" class="text-white text-sm" ></fa-icon> <span class="text-gfe ml-2">‍rajat@e-gmat.com</span>
        </div>

        <img [src]="cdn_url+'/Pubsite_Uploads/deal_pages_uploads/Rajat_footer_image.png'" class="absolute bottom-[-2px] sm:md:bottom-auto top-auto  sm:md:top-[-20%] sm:md:right-auto right-[-10%] w-[359px]  sm:md:w-[164px]"  alt="rajat">
    </div>
    </div>
</footer>


