<div *ngIf="barGraphData" class="section section_pad hero_section lozad" [ngStyle]="{'background-image': 'url('+ cdn_url + '/assets/images/deal_hero_bg.png' }">
  <div class="page_container">
    <p class="page_head extra_bold_font" data-aos="slide-up">
      {{barGraphData['heading']['firstLine']}}<br>{{barGraphData['heading']['secondLineFirstWord']}} <span class="highlight_text">{{barGraphData['heading']['secondLineColorfulWord']}}</span> {{barGraphData['heading']['secondLineLastWord']}}
    </p>
    <p class="page_sub_head semibold_font">
      {{barGraphData['subHeading']['firstWord']}} <span
        class="extra_bold_font page_head">{{barGraphData['subHeading']['boldWord']}}</span>
      {{barGraphData['subHeading']['restWords']}}
    </p>

    <p class="page_sub_head_gfe page_sub_head semibold_font">
      <img class="lozad"
          [src]="cdn_url+'/assets/icons/GMAT_logo_2.png'" data-loaded="true">
          <span>FOCUS EDITION</span> &nbsp;Course included.
    </p>

    <div class="hero_section_content_container">
      <div class="hero_cta_container">
        <div class="hero_cta_wrap">
          <p>
            {{barGraphData['content']['line1']}}<br />{{barGraphData['content']['line2']}}<br />{{barGraphData['content']['line3']}}
          </p>
          <div class="hero_cta_btn" data-cta_name="CTA_barchart" (click)="routeToCart('CTA_barchart')">
            <div class="logo_container">
              <img class="lozad img_cart_hero_cta" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
            </div>
            <div class="price_container">
              <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
              <span class="original_price">{{originalPrice}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="hero_graphic_container">
        <div class="graphic">
          <img class="lozad" [src]="barGraphData['barGraphUrl']">
        </div>
        <div class="disclaimer_text">
          <span>{{barGraphData['notes']['note1']['1star']}} {{barGraphData['notes']['note1']['line']}}</span>
              <span class="float_right mob_display_disclaimer_section">{{barGraphData['notes']['note2']['2star']}} {{barGraphData['notes']['note2']['line']}}</span>
        </div>
        <div class="disclaimer_text">
          <span class="mob_display_disclaimer_section">{{barGraphData['notes']['note3']['3star']}} {{barGraphData['notes']['note3']['line']}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
