<div class="ft_contact_us">
  <div class="chat_btn_container">
    <div class="chat_btn" (click)="chat_open()" [ngClass]="{'egmat_chat_active': is_chat_open}">
      <div class="chat_open_lnik"> <img class="lozad"
          [src]="cdn_url+'/assets/icons/chat_icon.png'" data-loaded="true"></div>
      <div class="chat_close_link"> <img class="lozad" data-class="chat_close_icon_img"
          [src]="cdn_url+'/assets/icons/chat_close_icon.png'"
          data-loaded="true"></div>
    </div>
    <div class="chat_window" [ngClass]="{'egmat_chat_window_in': is_chat_open, 'text_area_first_chat_focused': chat_focused, 'form_submitted_success': form_submitted_success}">
      <div>
        <div class="payal_img_box"><img class="chat_img_payal"
            [src]="cdn_url+'/assets/icons/chat_payal.png'"><span
            class="payal_co_founder">Payal Tandon<br>Co-founder, e-GMAT</span>
          <div class="chat_des">Welcome to e-GMAT Support!<br>I am Payal, Co-Founder of e-GMAT.<div
              class="feel_free_chat">Feel free to ask any Query.</div>
          </div>
        </div>
        <div class="error_container">
          <div class="error_submit_query" *ngIf="error_submit_query_visible">Query must be more than 15 characters long.</div>
        </div>
      </div>
      <div class="grey_typing_area" id="chatForm" [ngClass]="{'greyform_left_out': step == 'greyform_left_out'}">
        <form id="greytextArea" [ngClass]="{'textareaEnambled': textareaEnambled}">
          <textarea [(ngModel)]="query" [ngModelOptions]="{standalone: true}" placeholder="Type your query here..." class="text_box_first_chat" (focus)="chat_focused = true" (focusout)="focusOut()" (keyup)="keyUpTextArea()"></textarea>
          <div class="chat_form_btn_row">
            <div class="sbmt_btn icon_chat_btn"> <label for="sbmt_btn_chat" class=""> <span class="icon">
              <img class="hover lozad" [src]="cdn_url+'/assets/icons/send_hover.png'" (click)="greytextAreaSubmit($event)">
                  <img class="default lozad"
                    [src]="cdn_url+'/assets/icons/send_default.png'" data-loaded="true"> </span>
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="first_submitted_from" id="chatForm2" [ngClass]="{'secong_form_right_in': step == 'greyform_left_out', 'edit_textbox_active': editText, 'form_submitted_success': form_submitted_success}">
        <form id="bluetextArea">
          <textarea [(ngModel)]="query_2" [ngModelOptions]="{standalone: true}" placeholder="Type your query here..." class="textbox_second_chat" [attr.disabled]="textbox_second_chat_disabled"></textarea>
          <div class="chat_form_btn_row2">
            <div class="sbmt_btn icon_chat_btn enabled_btns_chat" id="form_uper_sbmt_final" (click)="form_uper_sbmt_final_click()"> <label for="sbmt_btn_chat2"
                class=""> <span class="icon"> <img class="hover lozad"
                    [src]="cdn_url+'/assets/icons/send_hover.png'"> <img
                    class="default lozad" [src]="cdn_url+'/assets/icons/send_default.png'">
                </span> </label> </div>
          </div>
          <div class="chat_edit_btn"><span (click)="edit()">Edit</span></div>
          <div class="support_contact_msg">Please enter your email id below.<br>Our Support team will contact you soon.
          </div>
          <div class="email_field_div">
            <input class="chat_email_field" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" tabindex="-1" placeholder="email@domain.com" [attr.disabled]="email_field_input_disabled">
            <div class="sbmt_btn final_chat_sbmt_btn"(click)="bluetextAreaSubmit($event)"> <label for="email_sbmt_btn" class=""> <img class="lozad"
                  [src]="cdn_url+'/assets/icons/arrow_right.png'"> </label> </div>
          </div>
          <div class="invalid_query_warning">{{invalid_query_warning}}</div>
        </form>
      </div>
      <div class="form_sumitted_msg_true_sec" [ngClass]="{'form_submitted_success': form_submitted_success}">
        <div class="query_succsess_img text-center"><img class="lozad"
            [src]="cdn_url+'/assets/icons/resret_pwd_img.png'"></div>
        <div class="query_cont_soon">Thank you for your query.<br>We will be contacting you soon on</div>
        <div class="query_sender_email">{{query_sender_email}}</div>
        <div class="query_submitted_free_trial">In the meantime, feel free to watch video lessons in your <a
            tabindex="-1"
            href="https://e-gmat.com/free-resources-gmat-registration/?utm_source=website&amp;utm_medium=contactus&amp;utm_campaign=registration&amp;utm_content=free_trial_page">free
            trial account</a>.</div>
        <div class="query_submitted_register"><a tabindex="-1"
            href="https://e-gmat.com/free-resources-gmat-registration/?utm_source=website&amp;utm_medium=contactus&amp;utm_campaign=registration&amp;utm_content=free_trial_page">Register
            for free</a></div>
      </div>
    </div>
  </div>
</div>
