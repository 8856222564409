<div class="about_us_section1_div"  *ngIf="founders_data">
      <div class="about_us_main_header" data-aos="slide-up">A Passionate <span class="yellow_text">Team</span></div>
      <div class="about_us_main_description" data-aos="slide-up">We are the only GMAT prep company that hires its instructors on a full-time basis to ensure that they are completely dedicated to creating, enhancing and supporting our online offerings. The passionate Support, Marketing, Technology and e-Learning teams ensure a smooth journey to success for our students</div>
      <div class="founder_heading_div" data-aos="slide-up"><img  [src]="cdn_url+'/assets/images/Founders_parallelogram.png'"></div>
      <div class="about_us_flex_container_parent" data-aos="slide-up">
        <div class="about_us_flex_container">
          <div class="about_us_person"  *ngFor="let founder of founders_data;">
             <img class="founder_image lozad" data-src="{{founder.image_url}}">
             <div class="person_name_text">{{founder.name}}</div>
             <div class="person_title_text">{{founder.position}}</div>
           </div>
        </div>
      </div>
</div>
