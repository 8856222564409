import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClockComponent implements OnInit {
  @Input() DaysTensPlace: number = undefined;
  @Input() DaysOnesPlace: number = undefined;
  @Input() HoursTensPlace: number = 0;
  @Input() HoursOnesPlace: number = 0;
  @Input() MinutesTensPlace: number = 0;
  @Input() MinutesOnesPlace: number = 0;
  @Input() SecondsTensPlace: number = 0;
  @Input() SecondsOnesPlace: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
