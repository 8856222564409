<div
  class="section pt-[58px] !pb-[1px]  bg-no-repeat bg-[url('/assets/images/reach_your_goal_bg_NY.png')] lozad sm:md:pb-40px sm:md:!pb-0 sm:md:mb-[-5px]"
  id="reach_your_goal_section"
  *ngIf="reachYourGoalData"
  [ngClass]="{'pb-12': !is_open}"
>
  <div class="page_container" data-aos="slide-up">
    <div class="text-[#ffffff] text-[26px] text-center">
      {{ reachYourGoalData["header"]["firstLine"] }}
    </div>
    <div
      class="page_head extra_bold_font text-center text-transparent bg-clip-text bg-xmasHeadings leading-none"
    >
      {{ reachYourGoalData["header"]["colorHeading"] }}
    </div>
    <div
      class="text-[40px] sm:text-[30px] md:text-[30px] p-0 sm:md:px-[75px] sm:md:py-[8px] text-[#ffffff] text-center font-bold leading-none"
    >
      {{ reachYourGoalData["header"]["thirdLine"] }}
    </div>
    <div
      class="text-[26px] text-center text-[#d2d2d2] my-[50px] sm:md:my-[40px] px-[120px] sm:md:px-[36px] sm:md:text-[18px]"
    >
      {{ reachYourGoalData["header"]["text"] }}
    </div>
    <div
      class="card_container flex md:flex-col justify-around mt-[150px] sm:md:hidden"
    >
      <ng-container
        *ngFor="let card of reachYourGoalData['cards']; let i = index"
      >
        <app-christmas-author-cards
          [card]="card"
          [card_number]="i"
          (openTabEvent)="openTabCaseStudy($event)"
        ></app-christmas-author-cards>
      </ng-container>
    </div>
    <div
      class="sm:md:hidden learn_more_parent text-[20px] text-[#ffffff] font-bold my-[100px] text-center"
      data-aos="slide-up"
    >
      <div
        class="learn_more w-[200px] mx-auto cursor-pointer"
        (click)="is_open = true"
        [ngClass]="is_open ? 'disabled' : ''"
      >
        <span class="plus_icon mr-[22px] relative top-[2px] text-[30px]"
          >+</span
        >
        <span class="learn_more_text">LEARN MORE</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="!!cards"
    class="hidden sm:md:flex items-center justify-center flex-col sm:md:py-[50px] bg-white rounded-lg"
  >
    <div class="w-[120px] h-[120px] rounded-full clip-circle bg-[#FCE8D2]">
      <img
        class="w-[125px] h-[125px] ml-[8px] mt-[4px]"
        [src]="cards[0]?.image_url"
        alt="img"
      />
    </div>
    <div class="text-[20px] capitalize py-2">{{ cards[0].author_name }}</div>
    <div class="flex flex-col items-center">
      <img [src]="cards[0]?.universityLogo" alt="university" />
      <p class="text-[18px]">{{ cards[0]?.universityName }}</p>
      <div class="w-[90%] my-5 mx-2" style="border-bottom: 1px solid #d2d2d2;"></div>
      <h1 class="text-[22px]">Improved from GMAT</h1>
      <h1 class="text-[36px] text-transparent bg-clip-text bg-xmasHeadings">{{ cards[0].card_score }}</h1>
      <div class="relative mt-[38px] !w-[350px] h-[197px] ">
      <app-iframe class="absolute !w-[350px] h-[197px]"
        [url]="cards[0]['video_url']"
      ></app-iframe>
      </div>
      <div class="text-[18px] font-semibold text-[#c99412] py-8 text-center">
        Top 3 reasons <br />
        for {{ cards[0].author_name }}'s Success
      </div>
      <div class="flex flex-col text-[18px] " style="font-family: 'Poppins'">
        <div class="flex px-8 py-3">
          <p class="font-bold text-[#c99412] w-[35px]">01.</p>
          <p class="text-[#7f7f7f]">
            Built <strong class="text-black"> Strong Foundation </strong>using
            the basics modules.
          </p>
        </div>

        <div class="flex px-7 py-3">
          <p class="font-bold text-[#c99412] w-[35px]">02.</p>
          <p class="text-[#7f7f7f]">
            Diligently implemented his
            <strong class="text-black"> Mentor’s feedback</strong>.
          </p>
        </div>
        <div class="flex px-7 py-3">
          <p class="font-bold text-[#c99412] w-[35px]">03.</p>
          <p class="text-[#7f7f7f]">
            Worked on his
            <strong class="text-black"> test readiness</strong> using
            Scholaranium.
          </p>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="reachYourGoalData && reachYourGoalData['videos']">
    <app-christmas-proof-of-success
      [cards]="reachYourGoalData['videos']"
      (closeTabEvent)="closeTabCaseStudy($event)"
      [is_open]="is_open"
      [input_opened_index]="opened_index"
    ></app-christmas-proof-of-success>
  </ng-container>
</div>

