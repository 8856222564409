<div class="takeaway_section" *ngIf="proven_solution_data" (click)="close_competitor_menu()">
  <div class="page_container text_center">
    <div class="comparison_section_head_small" data-aos="slide-up">{{proven_solution_data['header']['first_line']}}
    </div>
    <div class="comparison_section_head_big" data-aos="slide-up">{{proven_solution_data['header']['second_line']}}</div>
    <div class="comparison_section_sub_head" data-aos="slide-up">{{proven_solution_data['header']['third_line']}}</div>
    <div class="table_container_row">
      <div class="table_container">
        <div class="compare_toggle_tab_parent_div"> 
          <div class="compare_text_div"><span class="compare_text">Compare</span></div>
          <div class="compare_tab_div">
            <ng-container *ngFor="let company of proven_solution_data['companies'];">
              <div *ngIf="company['name'] != 'e-GMAT'" class="compare_tab_block" [class.tab_block_selected] ="company['is_visible']" [class.tab_block_not_selected] ="!company['is_visible']" (click)="changeCompareTabs(company['name'])">
                <span class="compare_tab_block_text">{{company['name']}}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <table class="comparison_table center" data-aos="slide-up">
          <colgroup>
            <col>
            <col class="outlined">
            <col>
            <col class="outline_dotted">
          </colgroup>
          <thead>
            <tr>
              <th class="w_40"></th>
              <ng-container *ngFor="let company of proven_solution_data['companies'];let i = index;">
                <th [ngClass]="(i == 0) ? 'main' : 'competitor z_25'" *ngIf="company['is_visible']">
                 <!-- <app-course-comparison-menu *ngIf="showCourseComparisonMenu && i!=0" [multi_select]="false"
                    [companies]="proven_solution_data['companies']"></app-course-comparison-menu>-->
                  <div *ngIf="i != 0 && showCourseComparisonMenu" class="edit_img"
                    (click)="$event.stopPropagation();showCourseComparisonMenu = false;">
                    <img class="close_competitor_menu_img lozad" [src]="cdn_url+'/assets/images/Close_pop_up.png'">
                  </div>
                  <img class="competitor_img lozad mt_53" [ngClass]="{'width_35':company['name'] == 'Manhattan', 'width_45':company['name'] == 'TTP', 'main_img': i==0}" [src]="company['name'] == 'Manhattan' ? cdn_url+'/assets/icons/manhattan.png' : company['logo_url']">
                 <!-- <div *ngIf="i != 0 && !showCourseComparisonMenu" class="edit_img">
                    <img class="img_pencil lozad" [src]="cdn_url+'/assets/images/new_pencil_ellipse.png'"
                      (click)="$event.stopPropagation();showCourseComparisonMenu = true;">
                  </div>-->
                  <div class="mt_5">{{company['name']}}</div>
                </th>
              </ng-container>
              <th class="takeaways">Takeaways</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of proven_solution_data['rows']">
              <tr [ngClass]="row['row_type']" data-aos="slide-up">
                <td class="text_left pdl_56 p_first_td z_index_20">
                  <div class="display_content_inline">
                    <div class="td_pointer"></div>
                    <div *ngIf="!row['has_info_notation']" class="pdr_15">{{row['row_text']}}</div>
                    <div *ngIf="row['has_info_notation']" class="pdr_15">
                      <span>{{row['row_text1']}}</span>
                      <sup class="comparison_info_notation">{{row['info_notation']}}</sup>
                      <span>{{row['row_text2']}}</span>
                    </div>
                  </div>
                </td>
                <ng-container *ngFor="let row_data of row['row_data'];let ii = index">
                  <td [ngClass]="ii==0?'highlight':''"
                    *ngIf="proven_solution_data['companies'][ii]['is_visible']">{{row_data['value']}}</td>
                </ng-container>
                <td class="takeaways takeaways_1 aos-init aos-animate">
                  <ng-container *ngIf="row['row_data'][getActiveIndex()]['takeaway']">
                    <ng-container *ngFor="let takeaway of row['row_data'][getActiveIndex()]['takeaway']">
                      <span [ngClass]="takeaway['class']">{{takeaway['text']}}</span>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div *ngIf="proven_solution_data['score_info']" class="score_info_head" data-aos="slide-up">
          <sup class="score_info_head_sup" >{{proven_solution_data['score_info_notation']}}</sup>
          <span>{{proven_solution_data['score_info']}}</span>
       </div>
      </div>
    </div>
  </div>
</div>
