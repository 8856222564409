<div class="section section_pad section_course_comparison" id="course_comparison" *ngIf="courseComparisonData">
  <div class="page_container">
    <p class="page_head text_center extra_bold_font">
     <span class="highlight_text">{{courseComparisonData['heading']['highlightWords']}}</span><br class="desktop_view_text_hero"> {{courseComparisonData['heading']['restPart']}}
    </p>
    <p class="page_sub_head page_sub_head_light_color aos-init" data-aos="slide-up">{{courseComparisonData['subHeading']}}</p>
    <div class="course_comparison_container">
      <div class="course_comparison_wrap">
        <div class="course_comparison_table">
          <div class="info_table">
            <div class="info_head">
              <div class="info_head_label_container"></div>
              <div class="info_head_label_container">
                <div class="brand_logo">
                  <img class="lozad width_26" [src]="courseComparisonData['brands'][0]['brandLogo']">
                </div>
                <div class="brand_name">
                  {{courseComparisonData['brands'][0]['brandName']}}
                </div>
                <div class="course_price egmat"></div>
              </div>
              <div class="info_head_label_container">
                <div class="brand_logo">
                  <img class="lozad" [src]="courseComparisonData['brands'][1]['brandLogo']">
                </div>
                <div class="brand_name">
                  {{courseComparisonData['brands'][1]['brandName']}}
                </div>
                <div class="course_price crack_verbal"></div>
              </div>
              <div class="info_head_label_container">
                <div class="brand_logo">
                  <img class="lozad width_37" [src]="courseComparisonData['brands'][2]['brandLogo']">
                </div>
                <div class="brand_name">
                  {{courseComparisonData['brands'][2]['brandName']}}
                </div>
                <div class="course_price ttp"></div>
              </div>
              <div class="info_head_label_container">
                <div class="brand_logo">
                  <img class="lozad" [src]="courseComparisonData['brands'][3]['brandLogo']">
                </div>
                <div class="brand_name">
                  {{courseComparisonData['brands'][3]['brandName']}}
                </div>
                <div class="course_price gmat_whiz"></div>
              </div>
            </div>
            <div class="info_label info_head">
              <div class="info_head_label_container">{{courseComparisonData['successRecord']}}</div>
              <div class="info_head_label_container"></div>
              <div class="info_head_label_container"></div>
              <div class="info_head_label_container"></div>
            </div>
            <div class="info_container_row info_head">
              <div class="info_text_label info_head_label_container">
                <span>{{courseComparisonData['scores']['scoreTimePeriod']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container bold_font egmat_scores">
                <span>{{courseComparisonData['scores']['egmatScore']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container crack_verbal_scores">
                <span>{{courseComparisonData['scores']['magooshScore']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container ttp_scores">
                <span>{{courseComparisonData['scores']['ttpScore']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container gmat_whiz_scores">
                {{courseComparisonData['scores']['gmatWhizScore']}}
              </div>
            </div>
            <div class="info_container_row info_head">
              <div class="info_text_label info_head_label_container">
                <span>{{courseComparisonData['verifiedReviews']['reviewsTimePeriod']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container bold_font egmat_reviews">
                <span>{{courseComparisonData['verifiedReviews']['egmatReviews']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container crack_verbal_reviews">
                <span>{{courseComparisonData['verifiedReviews']['magooshReviews']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container ttp_reviews">
                <span>{{courseComparisonData['verifiedReviews']['ttpReviews']}}</span>
              </div>
              <div class="info_text_detail info_head_label_container gmat_whiz_reviews">
                <span>{{courseComparisonData['verifiedReviews']['gmatWhizReviews']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="expand_link_section" (click)="toggleExpandClass()" [class.expand]="addExpandClass">
        <hr>
        <span><span class="link_text">Compare Courses</span><span class="arrow_down"><img class="lozad arrow_img_mobile" [src]="cdnUrl + '/assets/images/down_arrow_more_icon.png'"></span></span>
        <hr>
      </div>
      <div class="course_comparison_wrap" id="compare_course_table">
      <div class="course_comparison_table">
          <div class="info_table">
            <div class="info_head">
              <div class="info_head_label_container"></div>
              <ng-container *ngFor="let brand of courseComparisonData['brands']">
                <div class="info_head_label_container">
                  <div class="brand_logo">
                    <img class="lozad" [class.width_26]="brand['brandName']==='e-GMAT'" [class.width_37]="brand['brandName']==='Target Test Prep'" [src]="brand['brandLogo']" >
                  </div>
                  <div class="brand_name">
                    {{brand['brandName']}}
                  </div>
                  <div class="course_price">{{brand['brandCoursePrice']}}</div>
                </div>
              </ng-container>
            </div>
            <ng-container *ngFor="let row of courseComparisonData['courseComparison']['rows']">
              <div class="info_head" [ngClass]="row['row_type']==='head_row' ? 'info_label' : 'info_container_row'">
                <div class="info_head_label_container" [class.info_text_label]="row['row_type']==='head_row'"><span>{{row['row_text']}}</span></div>
                <ng-container *ngFor="let data of row['row_data']">
                  <div class="info_head_label_container" [class.info_text_detail]="row['row_type']==='content_row'">
                    <span>{{data['normalPart']}}<br><span class="course_comparison_highlight">{{data['highlightedPart']}}</span></span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="btn_container buy_now_cta_container text_center" (click)="routeToCart('CTA_comparision')">
      <div class="hero_cta_btn secondary_section" data-cta_name="CTA_comparision">
                <div class="logo_container">
                  <img class="lozad" [src]="cdnUrl + '/assets/images/buy_cart_icon_white.png'">
                </div>
                <div class="price_container">
                  <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
                  <span class="original_price">{{originalPrice}}</span>
                </div>
              </div>
    </div>
  </div>

