<app-hero-section [barGraphData]="barGraphData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-hero-section>
<app-new-limited-period-offer [couponsLeftBeforeDealStart]="couponsLeftBeforeDealStart" [limitedPeriodOfferData]="limitedPeriodOfferData" [dealCounters]="dealCounters" [product]="product" [productName]="productName"></app-new-limited-period-offer>
<app-new-success-metrix [successMetricsData]="successMetricsData"></app-new-success-metrix>
<app-top-three-reasons [topThreeReasonsData]="topThreeReasonsData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-top-three-reasons>
<app-most-popular-and-trusted-prep-course [popularTrustedPrepCourseData]="popularTrustedPrepCourseData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-most-popular-and-trusted-prep-course>
<app-new-personalized-improvement-plan [personalizedImprovementPlanData]="personalizedImprovementPlanData"></app-new-personalized-improvement-plan>
<app-reach-your-goal [reachYourGoalData]="reachYourGoalData"></app-reach-your-goal>

<app-new-coupon-details [dealCounters]="dealCounters" [couponDetailsData]="couponDetailsData"  [product]="product"></app-new-coupon-details>
<app-new-gmat-journey [gmatJourneyData]="gmatJourneyData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-new-gmat-journey>
<app-common-footer-gmat-prep-deal *ngIf="jsonReceived"></app-common-footer-gmat-prep-deal>
