<div class="section_6 footer_section_srcset" *ngIf="ninty_percent_accuracy_data"
  style="background-color:#3d393f; background-position: 1% 92%; background-size: cover; background-repeat: no-repeat; overflow: hidden;">
  <div class="page_container">
    <div class="row">
      <div class="col-md-12 sectio_2_heading section_last_heading text-center">
        {{ninty_percent_accuracy_data['header']['first_line']}}</div>
    </div>
    <div class="row">
      <div class="col-md-12 eligibility text-center">
        {{ninty_percent_accuracy_data['header']['second_line']}}
      </div>
    </div>
    <div class="row form_row" *ngIf="canRegister">
      <div class="col-md-7 col-md-offset-3 col-md-offset-25 text-center med-screen">
        <p></p>
        <div class="form_last">Email*</div>
        <div class="yellow_form"><input type="text" class="email_value"  [(ngModel)]="username"></div>
        <div (click)="openModal()" class="email_cta cta_track" data-section="occurrence7" style="width:205px;">Reserve your Spot</div>
      </div>
    </div>
  </div>
</div>
