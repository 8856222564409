import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { DataService } from '../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLogin: boolean = true;
  cdn_url = environment.cdn_url;
  loginURL = window.location.href;
  isLearningLogin: boolean = false;

  meta_tags;
  link_tags;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {
    if (this.loginURL.indexOf('learning-login') !== -1) {
      this.isLearningLogin = true;
    }
  }
  ngOnInit() {
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('seo.json?version=' + version)
          .subscribe((response) => {
            let page_name = this.isLearningLogin ? 'learning-login' : 'login';
            if (response[page_name]['title']) {
              this.titleService.setTitle(response[page_name]['title']);
            }
            if (response[page_name]['meta_tags']) {
              this.meta_tags = response[page_name]['meta_tags'];
              this.metaService.addTags(response[page_name]['meta_tags']);
            }
            if (response[page_name]['link_tags']) {
              this.link_tags = response[page_name]['link_tags'];
              this.seoService.createMultiLinks(
                response[page_name]['link_tags']
              );
            }
            if (response[page_name]['json_ld']) {
              this.seoService.createJsonLd(response[page_name]['json_ld']);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
