import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gmat-journey',
  templateUrl: './gmat-journey.component.html',
  styleUrls: ['./gmat-journey.component.css']
})
export class GmatJourneyComponent implements OnInit {
  cdn_url = environment.cdn_url;
  gmat_journey_image_name = 'image_url';
  width = window.screen.width;
  constructor() {
    if(this.width < 769){
      this.gmat_journey_image_name = 'mobile_image_url';
    }
   }

  @Input() gmat_journey_data;

  ngOnInit(): void {
  }

}
