import { DataService } from '../../Services/data.service';
import { Title } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit } from '@angular/core';

declare const push_site_event: any;

@Component({
  selector: 'app-activity-redirect',
  templateUrl: './activity-redirect.component.html',
  styleUrls: ['./activity-redirect.component.css'],
})
export class ActivityRedirectComponent implements OnInit {
  cdn_url = environment.cdn_url;
  title = 'Redirecting to Activity';
  loginPageURL = environment.googleBtnURL + '?showLoginPopup=true';
  redirectURL;

  constructor(
    private httpService: HttpClientService,
    private titleService: Title,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      console.log(status+'============');
      if (status) {
        push_site_event('accessed site');
      }
    });
    document.dispatchEvent(new Event('load_home_page'));
    this.titleService.setTitle(this.title);
    this.redirectURL = this.getUrlParameter('redirectURL');
    if (
      this.redirectURL != null &&
      this.redirectURL != undefined &&
      this.redirectURL != ''
    ) {
      this.checkLoggedInAndRedirect(this.redirectURL);
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  checkLoggedInAndRedirect(targetURL) {
    this.httpService.get('user/isFreeTrial').subscribe(
      (response) => {
        setTimeout(function(){
          window.location.href = targetURL;
        },6000);
      },
      (error) => {
        setTimeout(() => {
          this.loginPageURL += `&redirectURL=${targetURL}`;
          window.location.href = this.loginPageURL;
        }, 6000);
      }
    );
  }
}
