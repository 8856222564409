import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-course-comparison-menu',
  templateUrl: './course-comparison-menu.component.html',
  styleUrls: ['./course-comparison-menu.component.css']
})
export class CourseComparisonMenuComponent implements OnInit, OnChanges {

  width = window.screen.width;
  constructor() { }

  @Input() companies;

  @Input() multi_select;

  @Input() left = 23;
  @Input() top = 87;
  @Input() companyNameSelected;

  @Output() close_competitor_menu = new EventEmitter();

  ngOnInit(): void {
  }

  ngOnChanges():void{
  }

  changeState(i, event){
    if(!this.multi_select || this.width < 769){
      this.companies.forEach((element,index) => {
        if(index == 0 || index == i){
          element['is_visible'] = true;
        }else{
          element['is_visible'] = false;
        }
      });
    }else{
      event.stopPropagation();
      this.companies[i]['is_visible'] = !this.companies[i]['is_visible'];
    }
    if((this.isAllSame() && this.multi_select) || (this.companyNameSelected == this.companies[i]['name'])){
      this.close_competitor_menu.emit();
    }
  }
  isAllSame(){
    let flag = true;
    let temp;
    this.companies.forEach((element,index) => {
      if(index <= 1){
        temp = element['is_visible'];
      }else{
        if(temp != element['is_visible']){
          flag = false;
        }
      }
    });
    return flag;
  }
}
