import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-concern-card',
  templateUrl: './concern-card.component.html',
  styleUrls: ['./concern-card.component.css'],
})
export class ConcernCardComponent implements OnInit {
  cdn_url = environment.cdn_url;

  @Output() open_card = new EventEmitter();

  @Input() index;
  @Input() tab_data;
  @Input() is_last_tab;

  constructor() {}

  ngOnInit(): void {}

  openCard(i) {
    this.open_card.emit(i);
  }
}
