import { UrlService } from './../Services/UrlService';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  faq_url;
  title = 'FAQs - e-GMAT';
  constructor(private titleService: Title, private urlService: UrlService) { }

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.titleService.setTitle(this.title);
    this.faq_url = this.urlService.updateVideoUrl(environment.faqUrl);
  }

}
