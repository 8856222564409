import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from '../../../Services/data.service';
import { CommonService } from '../../../Services/common.service';

@Component({
  selector: 'app-new-limited-period-offer',
  templateUrl: './new-limited-period-offer.component.html',
  styleUrls: ['../../deal-pages-common-css.css','./new-limited-period-offer.component.css']
})
export class NewLimitedPeriodOfferComponent implements OnInit, OnChanges {
  cdnUrl = environment.cdn_url;
  @Input() limitedPeriodOfferData;
  @Input() product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    purchaseLink: '',
    percentOff: '',
    willDealGoLiveOnThisPage: 'NO',
  };

  @Input() dealCounters;
  @Input() couponsLeftBeforeDealStart;
  @Input() productName!: string;

  DaysTensPlace;
  DaysOnesPlace;
  HoursTensPlace;
  HoursOnesPlace;
  MinutesTensPlace;
  MinutesOnesPlace;
  SecondsTensPlace;
  SecondsOnesPlace;
  dealResetDate;
  dealEndDate;
  couponsLeft;
  dealEndTimeLeftInHours;
  couponArr;
  dealResetHappened = false;
  timeLeftInDealEnd;
  dealEndDateString = '';
  isDealLive = false;
  timer = {
    HoursTensPlace: 0,
    HoursOnesPlace: 0,
    MinutesTensPlace: 0,
    MinutesOnesPlace: 0,
    SecondsTensPlace: 0,
    SecondsOnesPlace: 0
  }

  isScrolled =false;

  constructor(private commonService: CommonService, private dataService: DataService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.dealCounters?.dealResetDate) {
      this.dealResetDate = this.dealCounters.dealResetDate;
      this.dealEndDate = this.dealCounters.dealEndDate;
      this.couponArr = this.dealCounters.couponArr;
      this.timeLeftInDealEnd = (new Date(this.dealResetDate).getTime() + this.dealCounters.resetHappenBeforeHours * 60 * 60 * 1000 - 1 * 60 * 60 * 1000);
      this.countdownStart();
      let dateObj = new Date(new Date(this.dealResetDate).getTime() + this.dealCounters.resetHappenBeforeHours * 60 * 60 * 1000 - 1 * 60 * 60 * 1000 - 60 * 1000);
      this.dealEndDateString = dateObj.getDate() + this.commonService.nth(dateObj) + ' ' + this.commonService.getMonthName(dateObj) + ' ' + dateObj.getFullYear() + ', ' + dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
       });

    }
  }

  ngAfterContentChecked() {
    let limitedOfferSectionId = location.href.split('#')[1];
    if (!this.isScrolled && limitedOfferSectionId && document.getElementById(limitedOfferSectionId)) {
      document.getElementById(limitedOfferSectionId)?.scrollIntoView({ behavior: 'smooth' });
      this.isScrolled = true;
    }

  }

  countdownStart() {
    setInterval((_) => {
      this.checkDealReset();
      // this.dealResetDate = new Date(this.dealResetDate).setDate(new Date(this.dealResetDate).getDate() - 1);
        let dateObj = new Date(this.dealResetDate);
        this.dealResetDate = dateObj.getDate() + this.commonService.nth(dateObj) + ' ' + this.commonService.getMonthName(dateObj) + ' ' + dateObj.getFullYear();
      if(this.dealResetHappened) {
        this.dealResetDate = new Date(new Date(this.dealEndDate).getTime() - 60 * 1000);
        // this.dealResetDate = new Date(this.dealResetDate).setDate(new Date(this.dealResetDate).getDate());
        let dateObj = new Date(this.dealResetDate);

        this.dealEndDateString = dateObj.getDate() + this.commonService.nth(dateObj) + ' ' + this.commonService.getMonthName(dateObj) + ' ' + dateObj.getFullYear() + ', ' + dateObj.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
         });
      }
      let t = this.timeLeftInDealEnd - new Date().getTime();
      this.dealEndTimeLeftInHours = Math.ceil(t/(60*60*1000));
      this.couponsLeft = this.couponsLeftBeforeDealStart ? this.couponsLeftBeforeDealStart : this.couponArr[this.dealEndTimeLeftInHours] ? this.couponArr[this.dealEndTimeLeftInHours] : this.couponArr[1];
      this.couponsLeft = this.product.willDealGoLiveOnThisPage.toUpperCase() === 'NO' ? this.dealCounters.resetCouponArr[1] : this.couponsLeft;
      // console.log(this.couponsLeft)
      this.dataService.updateCouponsLeftInDeal(this.couponsLeft);

      if (t >= 0) {
        let hours = Math.floor(t / (1000 * 60 * 60));
        let mins = Math.floor((t / (1000 * 60)) % 60);
        let secs = Math.floor((t / 1000) % 60);

        this.HoursTensPlace = Math.floor(hours / 10);
        this.HoursOnesPlace = hours % 10;
        this.MinutesTensPlace = Math.floor(mins / 10);
        this.MinutesOnesPlace = mins % 10;
        this.SecondsTensPlace = Math.floor(secs / 10);
        this.SecondsOnesPlace = secs % 10;

        this.timer.HoursTensPlace = this.HoursTensPlace
        this.timer.HoursOnesPlace = this.HoursOnesPlace;
        this.timer.MinutesTensPlace = this.MinutesTensPlace;
        this.timer.MinutesOnesPlace = this.MinutesOnesPlace;
        this.timer.SecondsTensPlace = this.SecondsTensPlace;
        this.timer.SecondsOnesPlace = this.SecondsOnesPlace;

        this.dataService.updateTimer(this.timer);

      }
    }, 1000);
  }

  checkDealReset() {
    this.isDealLive = new Date(this.dealEndDate).getTime() - new Date().getTime() > 0 && this.product.willDealGoLiveOnThisPage.toUpperCase() === 'YES' ? true : false;
    this.dataService.updateIsDealLive(this.isDealLive);
    if(new Date(this.dealResetDate).getTime() - new Date().getTime() <= 0) {
      this.timeLeftInDealEnd = new Date(this.dealEndDate).getTime();
      this.couponArr = this.dealCounters.resetCouponArr;
      this.dealResetHappened = true;
    }
  }

  openFAQPopUp() {
    document.body.classList.add('show_pop');
  }

  closeFAQPopUp() {
    document.body.classList.remove('show_pop');
  }
}
