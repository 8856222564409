import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './../../Services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

declare const push_utm_event_nonpropagation_and_redirect: any;

@Component({
  selector: 'app-gc-success-stories',
  templateUrl: './gc-success-stories.component.html',
  styleUrls: ['./gc-success-stories.component.css'],
})
export class GcSuccessStoriesComponent implements OnInit {

  cdn_url = environment.cdn_url;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        this.route.queryParams.subscribe((params) => {
          if (params['name'] && params['page']) {
            push_utm_event_nonpropagation_and_redirect(
              params['name'],
              params['page']
            );
          }
        });
      }
    });
  }
}
