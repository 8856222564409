<div class="section section_pad section_coupon_details" *ngIf="couponDetailsData && product">
  <div class="page_container">
    <div class="coupon_container coupan_deals_conatiner">
      <div class="coupon">
        <div class="coupon_detail_section text_center">
          <div class="coupon_detail_wrapper">
            <p class="coupon_label_text px-[75px] md:!px-0 md:!text-[#000000]">{{couponDetailsData['limitedPeriodText']}} {{couponDetailsData['offerText']}}</p>
            <p class="coupon_value sm:!mx-[10px] sm:!w-[50%] percent_value md:!text-[#000000]">{{product['percentOff']}} OFF</p>
            <div class="btn_container buy_now_cta_container text_center buy_now_button_desktop" (click)="routeToCart('CTA_timer_ribbon')">
              <div class="hero_cta_btn secondary_section active" data-cta_name="CTA_timer_ribbon">
                <div class="logo_container">
                  <img class="lozad" [src]="cdnUrl + '/assets/images/buy_cart_icon.png'">
                </div>
                <div class="price_container">
                  <div class="discounted_price page_head extra_bold_font">{{product['discountedPrice']}}</div>
                  <span class="original_price">{{product['originalPrice']}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="deal_end_timer_section text_center">
          <div class="deal_end_timer_wrapper" >
            <div class="validity_string" *ngIf="isDealLive" ><span class="image_icon">
              <img class="lozad desktop_graphic" [src]="cdnUrl + '/assets/images/time_left_clock_icon.png'"><img class="lozad mobile_graphic" [src]="cdnUrl + '/assets/images/time-left.png'"></span>{{couponDetailsData['validTillText']}} <span class="expiry_date_string md:!text-[#000000] md:!font-semibold">{{dealEndDate}} (local time)</span></div>
            <div class="countdown_display" *ngIf="isDealLive">
              <app-clock
                  [HoursTensPlace]='timer.HoursTensPlace' [HoursOnesPlace]='timer.HoursOnesPlace'
                  [MinutesTensPlace]='timer.MinutesTensPlace' [MinutesOnesPlace]='timer.MinutesOnesPlace'
                  [SecondsTensPlace]='timer.SecondsTensPlace' [SecondsOnesPlace]='timer.SecondsOnesPlace'></app-clock>
            </div>
            <div class="clock_desc" *ngIf="isDealLive">
              <div class="clock_desc_text">Hours</div>
              <div class="clock_desc_text">Minutes</div>
              <div class="clock_desc_text">Seconds</div>
            </div>
              <div class="coupon_left_section">
                <div class="coupon_wrapper">
                  <div class="coupon_label">
                    <div class="coupon_label_wrapper">
                      <div>{{couponDetailsData['discountedText']}}<br>{{couponDetailsData['couponsLeftText']}}</div>
                      <div class="coupon_disc">{{couponDetailsData['updatedHourlyText']}}</div>
                    </div>
                  </div>
                  <div class="coupon_left">
                    {{couponsLeft}}
                  </div>
                </div>
              </div>
          </div>
          <div class="btn_container buy_now_cta_container text_center buy_now_button_mobile" (click)="routeToCart('CTA_timer_ribbon')">

            <div class="hero_cta_btn secondary_section active" data-cta_name="CTA_timer_ribbon">
              <div class="logo_container">
                <img class="lozad" [src]="cdnUrl + '/assets/images/buy_cart_icon.png'">
              </div>
              <div class="price_container">
                <div class="discounted_price page_head extra_bold_font">{{product['discountedPrice']}}</div>
                <span class="original_price">{{product['originalPrice']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
