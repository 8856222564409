<div class="card" [ngClass]="card_class">
  <div class="card_inner">
    <div class="front_view">
      <div class="card_text">
        <p class="page_sub_head bold_font">{{card['card_sub_head']}}</p>
        <p class="card_head semibold_font">{{card['card_head']['first_line']}} {{card['card_head']['second_line']}}</p>
      </div>
      <div class="author_container">
        <div class="author_image">
          <img class="lozad" [src]="card['image_url']">
        </div>
        <div class="author_detail">
          <div class="author_name">{{card['author_name']}}</div>
        </div>
      </div>
    </div>
    <div class="rear_view">
      <div class="author_container">
        <div class="author_image">
          <img class="lozad" [src]="card['image_url']">
        </div>
        <div class="author_detail">
          <div class="author_name">{{card['author_name']}}</div>
        </div>
      </div>
      <div class="card_desc">
        <p class="card_text_desc">{{card['card_text']}}</p>
        <p class="case_study_btn">
          <span class="case_study_parent" (click)="openTab()">
            <span class="hover_circle"></span>
            <span class="play_icon inline_block">
              <img class="lozad"
                [src]="cdn_url+'/assets/images/play_btn.png'" data-loaded="true">
            </span>
            <span class="inline_block case_study_text semibold_font ">Watch Case Study</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
