import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-flip-clock',
  templateUrl: './flip-clock.component.html',
  styleUrls: ['./flip-clock.component.css']
})

export class FlipClockComponent implements OnChanges{

  @Input() value = 0;
  numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  currentValue: number;
  previousValue: number;

  ngOnChanges(changes: SimpleChanges): void {
      this.currentValue = changes.value.currentValue;
      this.previousValue = changes.value.previousValue;
  }

  IsActive(number) {
    return this.currentValue === number;
  }

  IsBefore(number) {
    return this.previousValue !== this.currentValue && number === this.previousValue;
  }

  IsAnimated() {
    return this.previousValue !== this.currentValue;
  }

}


