<div class="faq_contanier text_nunito" [class.new_theme]="showNewTheme==true">
  <div class="container">
    <div class="faq_header">
      <div class="row">
        <div class="col-md-7">
          <div class="faq_heading">
            {{faqData?.heading}}
          </div>
          <div class="faq_sub_head">
            {{faqData?.subhead}}
          </div>
          <div class="search_box" *ngIf="showNewTheme==false">
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback" *ngIf="searchWord.length<=0 || isValueSearched"></span>
              <span class="arrow left_arrow form-control-feedback_arrow" (click)="clearSearch()"
                *ngIf="searchWord.length>0 && !isValueSearched"></span>
              <span class="form-control-feedback_clear_button" (click)="clearSearch()"
                *ngIf="searchWord.length>0 && !isValueSearched">X</span>
              <span class="fa fa-search form-control-feedback_search" (click)="searchValue()"
                *ngIf="searchWord.length>0 && !isValueSearched"></span>
              <input #search type="text" class="form-control" [class.border_blue]="isInputFeildInFocus"
                placeholder="Ask us a question, or enter a keyword" [(ngModel)]="searchWord"
                (ngModelChange)="showSearchButtons()" (keyup.enter)="$event.target.blur();searchValue()"
                (focus)="showSearchButtons()" (focusout)="isInputFeildInFocus=false;">
            </div>
          </div>
          <div class="search_box desktop_search_box" *ngIf="showNewTheme==true">
            <div class="form-group has-search">
              <div class="input-group">
                <span class="form-control-feedback_clear_button" (click)="clearSearch()"
                  *ngIf="searchWord.length>0 && !isValueSearched">X</span>
                <input #search type="text" class="form-control" [class.pdr_73]="searchWord.length>0 && !isValueSearched"
                  [class.border_blue]="isInputFeildInFocus"
                  placeholder="Search for any answer(Score Improvement, Free Trial, Pricing..." [(ngModel)]="searchWord"
                  (ngModelChange)="showSearchButtons()" (keyup.enter)="$event.target.blur();searchValue()"
                  (focus)="showSearchButtons()" (focusout)="isInputFeildInFocus=false;">
                <div class="input-group-append">
                  <button class="btn btn-secondary" type="button" (click)="searchValue()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="search_box mobile_search_box" *ngIf="showNewTheme==true">
            <div class="form-group has-search">
              <div class="input-group">
                <span class="form-control-feedback_clear_button" (click)="clearSearch()"
                  *ngIf="searchWord.length>0 && !isValueSearched">X</span>
                <input #search type="text" class="form-control" [class.pdr_73]="searchWord.length>0 && !isValueSearched"
                  [class.border_blue]="isInputFeildInFocus" placeholder="Search for any answer" [(ngModel)]="searchWord"
                  (keyup.enter)="$event.target.blur();searchValue()" (focus)="showSearchButtons()"
                  (focusout)="isInputFeildInFocus=false;">
                <div class="input-group-append">
                  <button class="btn btn-secondary" type="button" (click)="searchValue()">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 img_head">
          <img class="float_right img_align_center" [src]="headImg">
        </div>
      </div>
    </div>
    <div class="faq_section" *ngIf="showSearchResults==false">
      <div class="faq_section_head" *ngIf="showNewTheme==false">
        Popular topics
      </div>
      <div class="row">
        <div class="col-md-6">
          <div *ngFor="let item of faqData?.questionArr;let i = index;">
            <div class="faq_section_container mt_33 mb_36 mt_55" *ngIf="(i+1)%2!=0">
              <div class="row row_new">
                <img [src]="cdnUrl+item.categoryImg">
                <div class="faq_section_container_head">
                  {{item.categoryQuestion}}
                </div>
              </div>
              <div class="list_elements_faq_container">
                <div class="row mt_10" [class.hide_item]="showNewTheme && item.visible==false && j>=4"
                  *ngFor="let element of item.subQuestions;let j = index;">
                  <div class="ellipse_faq"></div>
                  <div class="faq_question col-md-10 pdr_0">
                    <div class="main_question" (click)="showAnswerModal(element,i)">{{element.question}}</div>
                    <div class="row info_points mt_5" *ngFor="let info of element.infoPoints">
                      <div class="info_point_number">{{info.pointNumber}}</div>
                      <div class="info_point_text col-md-10 pdl_0 pdr_0"
                        (click)="showAnswerModal(element,i);scroll(info.id)">{{info.point}}</div>
                    </div>
                  </div>
                </div>
                <div class="see_all_question_tag"
                  *ngIf="showNewTheme && item.visible==false && item?.subQuestions.length>4"
                  (click)="showHiddenElems(i)">
                  <div class="row">
                    See all {{item?.subQuestions.length}} Questions <span class="right_arrow_bg"><i
                        class="arrow right_arrow see_all_question_arrow"></i></span>
                  </div>
                </div>
                <div class="see_all_question_tag"
                  *ngIf="showNewTheme && item.visible==true && item?.subQuestions.length>4" (click)="hideElems(i)">
                  <div class="row">
                    See less <span class="right_arrow_bg"><i
                        class="arrow right_arrow see_less_question_arrow"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 pdr_0 pdl_21">
          <div *ngFor="let item of faqData?.questionArr;let i = index;">
            <div class="faq_section_container mt_33 mb_36 mt_55" *ngIf="(i+1)%2==0">
              <div class="row row_new">
                <img [src]="cdnUrl+item.categoryImg">
                <div class="faq_section_container_head">
                  {{item.categoryQuestion}}
                </div>
              </div>
              <div class="list_elements_faq_container">
                <div class="row mt_10" [class.hide_item]="showNewTheme && item.visible==false && j>=4"
                  *ngFor="let element of item.subQuestions;let j = index;">
                  <div class="ellipse_faq"></div>
                  <div class="faq_question col-md-10 pdr_0">
                    <div class="main_question" (click)="showAnswerModal(element,i)">{{element.question}}</div>
                    <div class="row info_points mt_5" *ngFor="let info of element.infoPoints">
                      <div class="info_point_number">{{info.pointNumber}}</div>
                      <div class="info_point_text col-md-10 pdl_0 pdr_0">{{info.point}}</div>
                    </div>
                  </div>
                </div>
                <div class="see_all_question_tag"
                  *ngIf="showNewTheme && item.visible==false && item?.subQuestions.length>4"
                  (click)="showHiddenElems(i)">
                  <div class="row">
                    See all {{item?.subQuestions.length}} Questions <span class="right_arrow_bg"><i
                        class="arrow right_arrow see_all_question_arrow"></i></span>
                  </div>
                </div>
                <div class="see_all_question_tag"
                  *ngIf="showNewTheme && item.visible==true && item?.subQuestions.length>4" (click)="hideElems(i)">
                  <div class="row">
                    See less <span class="right_arrow_bg"><i
                        class="arrow right_arrow see_less_question_arrow"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search_section" *ngIf="showSearchResults==true">
      <div class="row mr_0">
        <div class="faq_back_button" [class.desktop_search_results]="showNewTheme" (click)="showSearchResults=false"><i
            class="arrow left_arrow"></i>Back to all categories</div>
        <div class="faq_back_button mobile_search_results" *ngIf="showNewTheme" (click)="showSearchResults=false"><i
            class="arrow left_arrow"></i>Back</div>
        <div class="search_head" *ngIf="searchData.length>0 || showNewTheme">Search Results</div>
      </div>
      <div class="search_results mt_33 desktop_search_results" *ngIf="searchData.length>0">
        <div class="row mt_0 mt_33 mb_33">
          <div class="col-md-4 mt_33">
            <div *ngFor="let item of searchData;let i = index;">
              <span *ngIf="selectedIndex==i" class="selected_tag"></span>
              <div (click)="selectedIndex=i" class="category_search" [class.selected_category]="selectedIndex==i">
                {{item.categoryQuestion}}</div>
            </div>
          </div>
          <div class="col-md-8 mt_33">
            <div class="search_list" *ngFor="let item of searchData[selectedIndex].subQuestions;let i = index;"
              [class.border_bottom_search_list]="searchData[selectedIndex].subQuestions.length-1!=i">
              <div class="selected_question" [class.mt_20]="i!=0">{{item.question}}</div>
              <div class="selected_answer" [innerHTML]="item.answer"></div>
              <div class="read_more_text" (click)="showAnswerModal(item,selectedIndex)">Read More<i
                  class="arrow right_arrow arrow_small"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div class="search_results mt_33 mobile_search_results" *ngIf="searchData.length>0 && showNewTheme">
        <div class="row mt_0 mt_33 mb_33">
          <div class="col-md-4 mt_33">
            <div *ngFor="let item of searchData;let i = index;">
              <button class="accordion category_search" [class.bb_1]="i<searchData.length-1"
                (click)="toggleAccordian($event, i)"><span *ngIf="item.isActive"
                  class="selected_tag"></span>{{item.categoryQuestion}}</button>
              <div class="panel" [class.bb_1]="i<searchData.length" hide="!item.isActive">
                <div class="search_list" *ngFor="let elem of searchData[i].subQuestions;let j = index;"
                  [class.border_bottom_search_list]="searchData[i].subQuestions.length-1!=j">
                  <div class="selected_question" [class.mt_20]="j!=0">{{elem.question}}</div>
                  <div class="selected_answer" [innerHTML]="elem.answer"></div>
                  <div class="read_more_text" (click)="showAnswerModal(elem,i)">Read More<i
                      class="arrow right_arrow arrow_small"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no_result_found mt_33" *ngIf="showNewTheme && searchData.length<=0">
        <div class="row">
          <div class="col-md-6 img_no_result_found"><img class="float_right" [src]="noResultFoundImageUrl"></div>
          <div class="col-md-6 col_no_result_content">
            <div class="row no_result_found_head">Sorry we couldn't find any matches for <span
                class="col_new_theme">“{{searchVal}}”</span></div>
            <div class="row row_no_content mt_20">
              <div class="ellipse_no_result"></div>
              <div class="no_result_content">Try searching for another term or <span class="col_new_theme">talk to
                  us</span> about your query.</div>
            </div>
            <div class="row row_no_content mt_10">
              <div class="ellipse_no_result"></div>
              <div class="no_result_content">Alternatively, you can <span class="col_new_theme">go back</span> to search
                available categories</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" row post_query_section mr_0">
      <div class="faq_section_head">
        Can’t find what you are looking for ?
      </div>
      <div class="faq_help_table_container">
        <div class="row mt_26">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-1">
                <img class="mt_10" [src]="mailImageUrl">
              </div>
              <div class="col-md-10">
                <div class="msg_head">Email us your concerns</div>
                <div class="msg_sub_head">We will get back to you within 24 hrs</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <button class="btn_chat" (click)="mail()">Send Mail</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="modal_container_bg_aq align_center text_nunito" *ngIf="showAnswer">
        <div class="quiz_popup_aq modal_quiz_popup_aq">
          <div class="row">
            <div class="col-md-7">
              <div class="question_head">
                {{modalData?.question}}
              </div>
              <div class="answer" [innerHTML]="answer">
              </div>
            </div>
            <div class="col-md-5 rel_topics_col">
              <div class="related_topic_head">
                Related topics
              </div>
              <div class="mt_18">
                <div class="related_topics_questions" [hidden]="!item" *ngFor="let item of relatedTopicsData"
                  (click)="showAnswerModal(item,faqDataQuestionIndex)">
                  {{item.question}}
                </div>
              </div>
            </div>
          </div>
          <div class="timing_modal_close_section text_center" *ngIf="!showNewTheme">
            <button class="close_button" (click)="closeAnswerModal()">CLOSE <div class="timing_close_button">x</div>
              </button>
          </div>
          <div class="timing_modal_close_section text_center" *ngIf="showNewTheme">
            <button class="close_button" (click)="closeAnswerModal()">
              <div class="timing_close_button">x</div> CLOSE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
