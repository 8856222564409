import { Component, Output,EventEmitter, Input } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-most-successfull-and-trusted-gfe-v2',
  templateUrl: './most-successfull-and-trusted-gfe-v2.component.html',
  styleUrls: ['./most-successfull-and-trusted-gfe-v2.component.css']
})
export class MostSuccessfullAndTrustedGfeV2Component {
  @Output() openMdalFt = new EventEmitter<Boolean>();
  cdn_url = environment.cdn_url;
  @Input() most_successfull_and_trusted_data;
  backgroundImage = 'img-S2 bg';
  width = window.screen.width;
  constructor() {
    if(this.width < 1025){
      this.backgroundImage = 'img-S2 bg-mobile';
    }
  }

  ngOnInit() {
  }

  openModal(btn_name){
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName',btn_name);
  }
}
