<div class="section section_pad section_limited_offer" id="limited_offer" *ngIf="coursePricingData">
  <div class="page_container">
    <p class="page_head text_center extra_bold_font">
      {{coursePricingData['heading']}}
    </p>
    <div class="offer_banner_container">
      <div class="offer_banner_wrapper">
        <div class="offer_banner">
          <div class="offer_countdown_section">
            <div class="countdown_section">
              <div class="exclusive_offer_text_container">
              <div class="guarantee_head">
                {{coursePricingData['exclusiveOfferTextContainer']['guaranteeHeadText1']}} <br>{{coursePricingData['exclusiveOfferTextContainer']['guaranteeHeadText2']}}
              </div>
              <div class="guarantee_text">
                {{coursePricingData['exclusiveOfferTextContainer']['guaranteeText']}}
              </div>
              </div>
              <div class="brand_guarantee_section">
                <div class="image_logo_container">
                  <img class="lozad" [src]="cdnUrl + '/assets/images/shield_icon.png'" >
                </div>
                <div class="guarantee_text_container">
                  <div class="guarantee_head">
                    {{coursePricingData['guaranteeTextContainer']['guaranteeHeadText']}}
                  </div>
                  <div class="guarantee_text">
                    {{coursePricingData['guaranteeTextContainer']['guaranteeText']}}<br> Please refer <a class="external_link hover_effect faq_pop_trigger extra_bold_font"  (click)="openFAQPopUp()">here</a> for terms & conditions.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="offer_cta_section desktop">
            <app-product-card [product]="product" [isCourseOfferingPage]="true"></app-product-card>
          </div>
        </div>
        <div class="offer_cta_section mobile">
            <app-product-card [product]="product" [isCourseOfferingPage]="true"></app-product-card>
          </div>
      </div>
    </div>
    <div class="support_text_container">
      <div class="support_text_head">
        {{coursePricingData['supportText']['heading']}}
      </div>
      <div class="support_text desktop">
        {{coursePricingData['supportText']['content']['firstPart']}} <a href="mailto:support@e-gmat.com" class="hover_effect external_link extra_bold_font">{{coursePricingData['supportText']['content']['supportMailId']}}</a> {{coursePricingData['supportText']['content']['restPart']}}
      </div>
      <div class="support_text mobile">
        {{coursePricingData['supportText']['content']['firstPart']}} <br><a href="mailto:support@e-gmat.com" class="hover_effect external_link extra_bold_font">{{coursePricingData['supportText']['content']['supportMailId']}}</a> {{coursePricingData['supportText']['content']['restPart']}}
      </div>
    </div>

    <div class="modal_faq">
        <div class="modal_wrapper lozad" [style.backgroundImage]="'url(' + cdnUrl + '/assets/images/pop_bg.png)'">
            <div class="pop_header bold_font">{{coursePricingData['faqModal']['popUpHeader']}}</div>
            <div class="pop_body">
              <div class="pop_content">
                <div>
                  {{coursePricingData['faqModal']['popContentPart1']}} (<b><a href="mailto:support@e-gmat.com" class="hover_effect external_link">{{coursePricingData['supportText']['content']['supportMailId']}}</a></b>) {{coursePricingData['faqModal']['popContentPart2']}}
                </div>
                <div>
                  <span class="content_heading"><b>Note:</b></span>
                  <br>
                  {{coursePricingData['faqModal']['contentNote']}}
                </div>
                <ol>
                  <ng-container *ngFor="let note of coursePricingData['faqModal']['noteList']">
                    <li>
                      <span>
                      {{note}}
                      </span>
                    </li>
                  </ng-container>
                </ol>
              </div>
            </div>
            <div class="learn_more_btn_container close_btn semibold_font close_faq_pop" (click)="closeFAQPopUp()" data-aos="slide-up">
          <span class="learn_more_parent inline_block">
            <span class="hover_circle inline_block"></span>
            <span class="learn_more_icon inline_block"><img class="lozad" [src]="cdnUrl + '/assets/images/close_icon_dark.png'"></span>
            <span class="learn_more_text inline_block">{{coursePricingData['closeBtnText']}}</span>
          </span>
        </div>
        </div>
    </div>

  </div>
</div>
