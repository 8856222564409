import { DataService } from './../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css']
})


export class PricingCardComponent implements OnInit {
  showChangePlanBtn = true;

  constructor( private router: Router, private route:ActivatedRoute, private dataService:DataService) {
  }
  cdn_url = environment.cdn_url;
  cartPage:boolean = false;
  @Input() plan;
  @Input() paymentDone;
  @Input() disableChangePlan;
  @Input() productType;
  @Input() forCart = false;
  @Input() cartLoaded = true;

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      this.showChangePlanBtn = +param['cp']===1 ? true : false;

    });
    if(this.plan["cartPage"] != undefined && this.plan["cartPage"] == true){
        this.cartPage = true;
    }else{
      this.paymentDone = false;
      this.plan["discountCouponApplied"] = true;
    //  this.plan["discountCouponPresent"] = false;
    }
  }
  buyNow(url,discountCode){
    if(discountCode){
      this.router.navigateByUrl('/cart/'+url+'?discountCode='+discountCode+'&cp='+1, { state: { 'productType': this.productType } });
    }else{
      this.router.navigateByUrl('/cart/'+url+'?cp='+1, { state: { 'productType': this.productType } });
    }
  }

  changePlan(){
      this.router.navigateByUrl('/pricing?utm_source=change_plan', { state: { scroll: 'scroll-to-buy-now','productType': this.productType } });
  }
}
