import { Component, Input } from '@angular/core';
import { zoomInOnEnterAnimation } from 'angular-animations';
@Component({
  selector: 'app-score-graph-pie',
  templateUrl: './score-graph-pie.component.html',
  styleUrls: ['./score-graph-pie.component.css'],
  animations: [zoomInOnEnterAnimation()] 
})
export class ScoreGraphPieComponent{

  @Input() graph_data = null;
  @Input() tab_type = null;
}
