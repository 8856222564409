<div class="section success_delivery_section section_pad lozad background_config" *ngIf="success_delivery_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/success_delivery_section_bg.png)'}">
  <div class="page_container">
    <div class="section_with_side_image">
      <div class="section_text_container">
        <h1 class="page_head extra_bold_font highlight_text section_page_head_gap" data-aos="slide-up"
        >{{success_delivery_data['highlighted_text']}}</h1>
        <p class="page_sub_head page_sub_head_light_color" data-aos="slide-up">{{success_delivery_data['without_highlight_text']}}</p>
      </div>
      <div class="section_image_container desktop" data-aos="slide-up">
        <div class="section_image_wrapper"> <img class="lozad"
            [src]="success_delivery_data['trophy_image_url']" data-loaded="true"></div>
      </div>
    </div>
    <div class="cards_layout">
      <div class="success_card_row">
        <div class="success_card" data-aos="slide-up">
          <div class="success_card_wrap">
            <div class="channel_logo"> <img class="lozad"
                [src]="success_delivery_data['gmat_club']['logo_url']" data-loaded="true"></div>
            <div class="rating_data_container">
              <div class="rating_column" *ngFor="let rating_column of success_delivery_data['gmat_club']['rating_columns']">
                <div class="rating">{{rating_column['rating']}}</div>
                <div class="rating_text">{{rating_column['rating_text']}}<sup *ngIf="rating_column['rating_text_sup']">{{rating_column['rating_text_sup']}}</sup></div>
              </div>
            </div>
          </div>
        </div>
        <div class="success_card" data-aos="slide-up">
          <div class="success_card_wrap">
            <div class="channel_logo"> <img class="lozad"
                [src]="success_delivery_data['beat_the_gmat']['logo_url']" data-loaded="true"></div>
            <div class="rating_data_container">
              <div class="rating_column" *ngFor="let rating_column of success_delivery_data['beat_the_gmat']['rating_columns']">
                <div class="rating">{{rating_column['rating']}}</div>
                <div class="rating_text">{{rating_column['rating_text']}}<sup *ngIf="rating_column['rating_text_sup']">{{rating_column['rating_text_sup']}}</sup></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="success_card_row" data-aos="slide-up">
        <div class="success_card">
          <div class="success_card_wrap">
            <div class="channel_logo"> <img class="lozad"
                [src]="success_delivery_data['youtube']['logo_url']" data-loaded="true"></div>
            <div class="rating_data_container">
              <div class="rating_column" *ngFor="let rating_column of success_delivery_data['youtube']['rating_columns']">
                <div class="rating">{{rating_column['rating']}}</div>
                <div class="rating_text">{{rating_column['rating_text']}}<sup *ngIf="rating_column['rating_text_sup']">{{rating_column['rating_text_sup']}}</sup></div>
              </div>
            </div>
          </div>
          <div class="tm_info"> <sup>1</sup> interviews available on our YouTube channel</div>
        </div>
      </div>
    </div>
    <div class="tm_info" data-aos="slide-up"> *Logos, names, and trademarks are properties of
      respective companies.</div>
  </div>
</div>
