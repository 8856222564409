<div>
    <div class="nav_section pos_rel">
      <div class="container">
        <img src="https://cdn.e-gmat.com/wp-content/themes/twentythirteen/images/egmat3_images/logoold.png">
      </div>
    </div>
    <div class="loader_section">
      <div class="execution_pop_up">
        <div class="exec_pop_body">
          <img [src]="cdn_url+'/assets/images/psp-execution-slider.png'" class="center_x" alt="psp-execution-slider">
          <div class="sliderContainer">
            <ul class='pos_rel'>
              <li class="slideContent slide_1">Just a few seconds as we setup your activity access ...</li>
              <li class="slideContent slide_2">Finishing your activity setup ...</li>
              <li class="slideContent slide_3">You are all set!<br>Redirecting to Activity ...</li>
            </ul>
            <div class="executionloader">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
