<ng-container *ngIf="state == 'close' ? false : true">
  <div class="modalDialog registration_modal" (click)="$event.stopPropagation();close(false)">
    <div class="modal-content" [@modalOneView]="state" (click)="$event.stopPropagation();close(false)">
      <div class="modal-header login_modal_header">
        <button type="button" class="close" (click)="$event.stopPropagation();close(true)"><span aria-hidden="true">
            Close
          </span></button>
      </div>
      <div class="modal-body">
        <div class="form_outer_container">
          <div class="form_container">
            <div class="login_heading form_head">
              Complete Your Registration
            </div>
            <div class="form_google_btn">
              <app-google-signin-btn [submit_button_class]="'ft_signin_submit_button_login'" *ngIf="!google_submitted" text="continue_with" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
              <a *ngIf="google_submitted" id="submitmyformgif" class="btn btn_default google_lgn_btn_form"><img
                  style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></a>
            </div>
            <div class="email_link_container">
              <p>or</p>
            </div>
            <div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
            <div class="user_message" id="message">{{user_message}}</div>
            <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
              <div class="form-group form_group">
                <!-- <div class="input_field_label">Email*</div> -->
                <div *ngIf="f.username.errors" class="invalid-feedback">
                  <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                    *ngIf="alert_submitted['username'] && f.username.errors.required">
                    Email is Required
                  </div>
                  <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message"
                    *ngIf="alert_submitted['username'] && f.username.errors.pattern">
                    Enter a valid Email Address
                  </div>
                </div>
                <input (focus)="login_error_msg = '';" autocomplete="off" formControlName="username" type="text"
                  class="form-control form_control reqField loginfield" placeholder="Email" id="loginEmailval"
                  name="username" value=""
                  [ngClass]="{ 'is-invalid': alert_submitted['username'] && f.username.errors }"
                  (keyup)="onKeyUpEmail('username')" (blur)="checkMailAgain()">
              </div>
              <ng-container *ngIf="step_login != 'check_user'">
                <div class="form-group form_group">
                  <!-- <div class="input_field_label">Password*</div> -->
                  <div *ngIf="f.password.errors" class="invalid-feedback">
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                      *ngIf="alert_submitted['password'] && f.password.errors.required">
                      Password is Required
                    </div>
                  </div>
                  <input (focus)="login_error_msg = '';" autocomplete="off" formControlName="password"
                    [attr.type]="show_password?'text':'password'" class="form-control form_control reqField loginfield"
                    id="loginPass" name="password" placeholder="min. 8 characters" value=""
                    [ngClass]="{ 'is-invalid': alert_submitted['password'] && f.password.errors }"
                    (keyup)="onKeyUpEmail('password')">
                  <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;"
                    [ngClass]="{'display_ele': !show_password}" />
                  <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;"
                    [ngClass]="{'display_ele': show_password}" />
                  <div *ngIf="step_login != 'register' && login_failed" class="reg_tnc text-center loginfield"><span (click)="forgot_password()" class="fgt_pss">Forgot
                      Password?</span></div>
                  <div class="checkbox reg_tnc" *ngIf="step_login == 'register'">
                    <label>
                      <input [ngClass]="{ 'is-invalid': alert_submitted['tnc'] && tnc_error }"
                        type="checkbox" class="reg_check" id="input-check" (change)="tncChecked($event)"><label class="square_label" for="input-check">
                      </label>
                      <span>
                        &nbsp; I accept
                        <a class="tnc" target="_blank" routerLink="/terms-and-conditions">Terms &amp; Conditions</a>
                      </span>
                      <div *ngIf="alert_submitted['tnc'] && tnc_error" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message">
                          Please accept the terms and conditions
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="step_login != 'check_user'">

              </ng-container>
              <div class="form-group form_sub_btn">
                <button *ngIf="!submitted" type="submit" id="submitmyform"
                  class="btn btn_default lgn_btn_form loginfield ft_signin_submit_button_login"
                  [ngClass]="{'margin_top_zero':login_error_msg!=''}">{{button_text}}</button>
                <button *ngIf="submitted" type="button" id="submitmyformgif" class="btn btn_default lgn_btn_form"><img
                    style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></button>
              </div>
              <br>
            </form>
            <!-- <div class="form_or">
              <div class="form_or_text form_login_or_text">or Sign in with Google</div>
              <div class="form_or_line form_login_or_line"></div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
