<div class="page_container">
  <h2 class="learn_proven_heading">Privacy Policy</h2>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    “GMAT” and other
    GMAC™ trademarks are registered trademarks of the Graduate Management Admission Council™. The Graduate Management
    Admission Council™ does not endorse, nor is affiliated in any way with YourPerfectScore or www.e-gmat.com. The
    content and the opinions expressed on www.e-gmat.com belong solely to the employees and users of
    www.e-gmat.com.<br>E-GMAT and YourPerfectScore (together referred as YPS going forward in this agreement) are
    committed to protecting their privacy. Please continue to use the privacy policy below for more details. Please be
    sure to read our <a routerLink="/terms-and-conditions" class="url_in_faqs">terms and conditions</a>
    section in conjunction with the privacy policy as well. This privacy policy together with the terms and conditions
    constitutes the entire agreement between you and YPS. By registering on the website, you agree to abide by the
    entire agreement.</div>
  <h4 class="wdrd1">1. PERSONALLY IDENTIFIABLE INFORMATION</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    To provide
    services we collect certain personal information to be able to provide services to users. This information includes
    but not limited to name, date of birth, email address and credit card number. In additional we may collect certain
    information that cannot be used to identify a user but is collected to statistical purposes such as demographic
    information, the average time that a user spends on the website etc. Such information that does not uniquely
    identity a particular user may be shared with our partners, affiliates, sponsors, and other third parties.<br>
    Registered User may be provided a unique username and password that enable access to the Service. We may track the
    use of those usernames and passwords in order to collect and maintain records of individual transactions conducted
    through the Services by Registered Users, and those records may be associated with each Registered User’s individual
    account profile within the Company system.<br>
    Please note that you may not divulge your account information (username/password) to anyone. Under no circumstances
    would we ask for your password via e mail. If you have any further question, please contact support@e-gmat.com</div>
  <h4 class="wdrd1">2. Use of Personal Information</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    YPS uses personal
    information for the following purposes:<br>
    1. To identify you to provide access to the account.<br>
    2. To maintain your account and to improve the quality of services provided to you by customizing our services as
    per your preferences.<br>
    3. To promote and market product and services offered by YPS, its partners and its associates.<br>
    4. To bill you for the products and services.<br>
    YPS will not sell or lease your personal information to third-parties. If YPS is sold, your personal information is
    one of the assets that would be transferred by YPS.</div>
  <h4 class="wdrd1">3. COOKIES</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    From time to time
    YPS may place a cookie on your computer. A “cookie” is a small line of code that is stored in your browser when you
    visit a cookie-enabled website. A cookie allows us to track and keep records on user activity. Most browsers are
    initially set to accept cookies. If you set your browser to reject cookies, it is possible that some parts of the
    Website will not function. Moreover, in order to use the Services, your browser must accept cookies.</div>
  <h4 class="wdrd1">4. DISCLOSURE FOR LAW ENFORCEMENT PURPOSES</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    The information
    provided by you is not shared with any third parties and resides only with officers of YPS. However, under certain
    circumstances, we may be subject to legal requirements by a government agency to disclose personal information. YPS
    intends to fully comply with all such requirements. If you do not want us to make these necessary disclosures please
    do not use these service</div>
  <h4 class="wdrd1">5. NOTIFICATION OF CHANGES</h4>
  <div class="describe_sub learn_desc_sub subheading_size p2">
    YPS reserves the
    right to modify this Privacy Policy at any time. Please review this Policy from time to time to ensure that you are
    up to date with the latest terms. Please contact legal@e-gmat.com, if you have any questions about this Privacy
    Policy..<br>
    © 2010 YourPerfectScore, LLC All Rights Reserved.</div>
</div>
