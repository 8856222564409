<div class="section analytics_section section_8_pad section_8 lozad" id="analytics_section" *ngIf="analytics_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/circle_pattern.png)'}">
  <div class="page_container" data-aos="slide-up">
    <p class="page_sub_head bold_font aos-init aos-animate" data-aos="slide-up">
      {{analytics_data['header']['first_line']}}
    </p>
    <p class="page_head extra_bold_font aos-init aos-animate" data-aos="slide-up">
      {{analytics_data['header']['second_line']}}
    </p>
    <p class="page_sub_head bold_font aos-init aos-animate" data-aos="slide-up">
      {{analytics_data['header']['third_line']}}
    </p>
    <div class="two_column_section">
      <div class="sub_text_container">
        <p class="page_sub_head page_content_gap_top gap_bottom_learn_more page_sub_head_light_color aos-init aos-animate"
          data-aos="slide-up">
          {{analytics_data['header']['text']}}
        </p>
        <div class="learn_more_btn_container desktop dark_styled semibold_font aos-init aos-animate"
          data-aos="slide-up">
          <span class="learn_more_parent inline_block" (click)="is_open = true;" [ngClass]="is_open?'disabled':''">
            <span class="hover_circle inline_block"></span>
            <span class="learn_more_icon inline_block">+</span>
            <span class="learn_more_text inline_block">LEARN MORE</span>
          </span>
        </div>
      </div>
      <div class="cards_container aos-init aos-animate" data-aos="slide-up">
        <div class="card_row">
          <ng-container *ngFor="let card of analytics_data['cards']; let i = index;">
            <app-analytics-card [card]="card" [card_class]="(i == (analytics_data['cards'].length-1))?'full_width':''" [card_number]="i" (openTabEvent)="openTabCaseStudy($event)"></app-analytics-card>
          </ng-container>
        </div>
        <div class="learn_more_btn_container mobile dark_styled semibold_font aos-init aos-animate" data-aos="slide-up">
          <span class="learn_more_parent inline_block" (click)="is_open = true;" [ngClass]="is_open?'disabled':''">
            <span class="hover_circle inline_block"></span>
            <span class="learn_more_icon inline_block">+</span>
            <span class="learn_more_text inline_block">LEARN MORE</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="analytics_data">
  <app-case-study [cards]="analytics_data['cards']" (closeTabEvent)="closeTabCaseStudy($event)" [is_open]="is_open" [input_opened_index]="opened_index"></app-case-study>
</ng-container>
