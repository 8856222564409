import { SEOService } from './../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClientService } from './../Services/http-client.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../Services/data.service';

declare const identication: any;
declare const event_push: any;
declare const push_eventto_segment: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  cdn_url = environment.cdn_url;
  loginForm!: UntypedFormGroup;
  submitted = false;
  alert_submitted = false;
  login_error_msg = '';
  is_BZUserFP: any;
  meta_tags;
  link_tags;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClientService: HttpClientService,
    private router: Router,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    let RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required,Validators.pattern(RegExp)])],
    });
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpClientService
          .getJson('seo.json?version=' + version)
          .subscribe((response) => {
            let page_name = 'forgot-password';
            if (response[page_name]['title']) {
              this.titleService.setTitle(response[page_name]['title']);
            }
            if (response[page_name]['meta_tags']) {
              this.meta_tags = response[page_name]['meta_tags'];
              this.metaService.addTags(response[page_name]['meta_tags']);
            }
            if (response[page_name]['link_tags']) {
              this.link_tags = response[page_name]['link_tags'];
              this.seoService.createMultiLinks(
                response[page_name]['link_tags']
              );
            }
            if (response[page_name]['json_ld']) {
              this.seoService.createJsonLd(response[page_name]['json_ld']);
            }
          });
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
  onFormSubmit() {
    this.alert_submitted = true;
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        if (!this.submitted) {
          if (this.loginForm.invalid) {
            return;
          }
          this.submitted = true;
          this.isBlitzkriegUser(this.f['username'].value);
        }
      }
    });
  }
  forgotPassword() {
    if (this.is_BZUserFP) {
      identication(this.f['username'].value);
      event_push('forgot password');
      this.httpClientService.forgotPassword(this.f['username'].value).subscribe(
        (data) => {
          this.submitted = false;
          this.router.navigateByUrl('password-reset-success');
        },
        (error) => {}
      );
    } else {
      this.submitted = false;
      this.login_error_msg = 'This id is not registered';
    }
  }
  isBlitzkriegUser(username) {
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.is_BZUserFP = true;
        } else {
          this.is_BZUserFP = false;
        }
        this.forgotPassword();
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
        this.submitted = false;
      }
    );
  }

  get f() {
    return this.loginForm.controls;
  }
}
