<div class="section section_10 section_10_background_config lozad" *ngIf="webinars_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/upcoming_sessions_bg.png)'}">
  <div class="page_container">
    <p class="page_head extra_bold_font text_center" data-aos="slide-up">{{webinars_data['header']['first_line']}}
    </p>
    <div class="upcoming_sessions_wrapper">
      <div class="upcoming_sessions_container collapsed" data-aos="slide-up">
        <ng-container *ngFor="let webinar of webinars_data['webinars'];let i=index;">
          <div class="upcoming_session" [ngClass]="(isCollapsed && i >= webinars_data['expanded_webinars']) ? 'collapsed_webinar' : ''">
            <div class="session_instructor_info mobile">
              <div class="instruction_img">
                <img class="lozad" [src]="webinar['instructor_image']">
              </div>
              <div>
                <span class="instructor_name semibold_font">
                  {{webinar['instructor_name']}}
                </span>
                <br>
                <span class="instructor_meta">
                  {{webinar['instructor_about']}} </span>
              </div>
            </div>
            <div class="session_head bold_font">
              {{webinar['heading']}}
            </div>
            <div class="session_description">
              {{webinar['description']}} </div>
            <div class="session_details semibold_font">
              <span class="session_date">
                {{getDate(webinar['date_time']) | date: 'd'}}<sup>{{nth(getDate(webinar['date_time']))}}</sup> {{getDate(webinar['date_time']) | date: 'MMM'}} </span>
              <br>
              <span class="session_time">{{getDate(webinar['date_time']) | date: 'h:mm a'}} IST</span>
            </div>
            <div class="session_info_container">
              <div class="session_instructor_info desktop">
                <div class="instruction_img">
                  <img class="lozad" [src]="webinar['instructor_image']">
                </div>
                <div>
                  <span class="instructor_name semibold_font">
                    {{webinar['instructor_name']}}
                  </span>
                  <br>
                  <span class="instructor_meta">
                    {{webinar['instructor_about']}} </span>
                </div>
              </div>
              <div class="session_link_container">
                <a (click)="redirectTo(webinar['subject'] + '?' + webinar['utm_parameters'])"
                  target="_blank" class="btn btn_round btn_solid register">Register Now</a>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="expand_collapse_sessions bold_font page_sub_head" *ngIf="webinars_data['webinars'].length > webinars_data['expanded_webinars']">
          <span class="expand_collapse_text" [ngClass]="isCollapsed ? 'collapsed' : ''"
            (click)="isCollapsed = !isCollapsed">
            <span class="collapsed_text">SHOW LESS</span>
            <span class="expand_meta_text">
              <span class="expand_text">SHOW ALL EVENTS</span>
              <span class="more_events_text">(+<span class="more_events">{{webinars_data['webinars'].length - webinars_data['expanded_webinars']}}</span> more)</span>
            </span>
          </span>
        </div>
        <div class="expand_collapse_sessions bold_font page_sub_head" *ngIf="webinars_data['webinars'].length <= webinars_data['expanded_webinars']">
          <span class="expand_collapse_text expand_collapse_text_inactive collapsed">
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
