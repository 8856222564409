import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-money-back-guarantee',
  templateUrl: './money-back-guarantee.component.html',
  styleUrls: ['./money-back-guarantee.component.css']
})
export class MoneyBackGuaranteeComponent implements OnInit {

  constructor() { }

  @Input() money_back_guarantee_data;
  ngOnInit(): void {
  }

}
