import { SEOService } from './../../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from './../../../Services/data.service';
import { HttpClientService } from './../../../Services/http-client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

declare const push_event_properties: any;
declare const createproperties_nonpropagation: any;
declare const push_site_event: any;

@Component({
  selector: 'app-course-offering-pages',
  templateUrl: './course-offering-pages.component.html',
  styleUrls: ['./course-offering-pages.component.css']
})
export class CourseOfferingPagesComponent implements OnInit, OnDestroy {
  barGraphData: any;
  successMetricsData;
  topReasonsData;
  gmatJourneyData;
  personalizedImprovementPlanData;
  proofOfSuccessData;
  courseComparisonData;
  coursePricingData;
  exclusiveOfferConfig;
  product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    percentOff: ''
  };
  monthLabel;
  eventSubsribe;
  eventPropertySubscribe;
  link_tags;
  meta_tags;
  pageType;

  constructor(private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService) { }

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));

    this.monthLabel = location.pathname.split('/')[1].includes('course-offerings') ? location.pathname.split('/')[1].split('-')[location.pathname.split('/')[1].split('-').length - 1] : "";
    this.pageType = location.pathname.split('/')[1]?location.pathname.split('/')[1]:'default';
    console.log(this.pageType+'=============');
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('limited-period-offer.json?version=' + version)
          .subscribe((response) => {
            this.barGraphData = response['barGraphData'];
            this.successMetricsData = response['successMetricsData'];
            this.topReasonsData = response['topReasonsData'];
            this.gmatJourneyData = response['gmatJourneyData'];
            this.personalizedImprovementPlanData = response['personalizedImprovementPlanData'];
            this.proofOfSuccessData = response['proofOfSuccessData'];
            this.courseComparisonData = response['courseComparisonData'];
            this.coursePricingData = response['coursePricingData'];
            this.exclusiveOfferConfig = response['exclusiveOfferConfig'];
            this.product.productSlug = this.exclusiveOfferConfig[this.monthLabel]["productSlug"];
            this.product.productValidityMonths = this.exclusiveOfferConfig[this.monthLabel]["productValidityMonths"];
            this.product.originalPrice = this.exclusiveOfferConfig[this.monthLabel]["originalPrice"];
            this.product.discountedPrice = this.exclusiveOfferConfig[this.monthLabel]["discountedPrice"];
            this.product.productId = this.exclusiveOfferConfig[this.monthLabel]["productId"];
            this.product.discountCode = this.exclusiveOfferConfig[this.monthLabel]["discountCode"];
            this.product.percentOff = this.exclusiveOfferConfig[this.monthLabel]["percentOff"];
            if (response[this.pageType]['title']) {
              this.titleService.setTitle(response[this.pageType]['title']);
            }
            if (response[this.pageType]['meta_tags']) {
              this.meta_tags = response[this.pageType]['meta_tags'];
              this.metaService.addTags(response[this.pageType]['meta_tags']);
            }
            if(response[this.pageType]['link_tags']){
              this.link_tags= response[this.pageType]['link_tags'];
              this.seoService.createMultiLinks(response[this.pageType]['link_tags']);
            }
            if(response[this.pageType]['json_ld']){
              this.seoService.createJsonLd(response[this.pageType]['json_ld']);
            }
            this.dataService.updateProductId(this.product.productId);
            this.dataService.updateDiscountCode(this.product.discountCode);
          });
      }
    });

    this.eventSubsribe = this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event("accessed site");
        const eventName = 'Viewed Deal Page';
        const VDP_properties = createproperties_nonpropagation(eventName);
          push_event_properties(eventName,VDP_properties);

      }
    });
  }

  ngOnDestroy(): void {
    this.eventSubsribe.unsubscribe();
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }

}
