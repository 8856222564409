<div class="card" data-aos="slide-up" *ngIf="card">
  <div class="card-inner">
    <div class="front_view bg-[#ffffff] p-[26px] rounded-[10px]">
      <div class="flex">
        <img class="relative top-[-56px]" [src]="card['image_url']">
        <div class="ml-[15px]">
          <div class="bg-[#f2f2f2] rounded-[5px] text-[20px] text-center px-[14px] py-[8px] mb-[16px]">{{card['author_name']}}</div>
          <img class="mb-[16px] text-[26px]" [src]="card['universityLogo']">
          <div>{{card['universityName']}}</div>
        </div>
      </div>
      <div class="text-[26px] font-bold">{{card['card_sub_head']}}</div>
      <div class="font-bold text-[40px] text-[#f2b70a]">{{card['card_score']}}</div>
      <div class="text-right mt-[-40px]">
        <img class="inline relative lozad top-[32px] left-[26px]" [src]="'/assets/images/card_corner_icon_NY.png'">
      </div>
    </div>
    <div class="rear_view bg-xmasMidBg flex flex-col justify-between rounded-[10px] px-[24px] py-[58px]">
      <div class="text-[20px]">
        <span [innerHTML]="card['card_normal_text1']"></span><span class="font-bold"> {{card['card_bold_text1']}}</span><span> {{card['card_normal_text2']}}</span><span class="font-bold"> {{card['card_bold_text2']}}</span>
      </div>
      <div class="case_study_btn">
        <span class="case_study_parent" (click)="openTab()">
          <span class="play_icon inline_block">
            <img class="lozad"
              [src]="cdn_url+'/assets/images/play_btn.png'" data-loaded="true">
          </span>
          <span class="inline_block case_study_text semibold_font ">Watch Case Study</span>
        </span>
      </div>
    </div>
  </div>
</div>
