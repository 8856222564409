import { fromEvent, Observable, Subscription } from 'rxjs';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-score-graph',
  templateUrl: './score-graph.component.html',
  styleUrls: ['./score-graph.component.css'],
})
export class ScoreGraphComponent implements OnInit, OnChanges {
  totalLengthYaxis: number = 7;
  maxReviews: number = 0;
  totalReviews: number = 100;
  companies: any = [];
  constructor() {}

  @Input() graph_data = null;
  @Input() tab_type = '';
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.resizeWindow();
    });
  }
  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
  resizeWindow() {
    if (this.graph_data) {
      let width = window.screen.width;
      this.companies = [];
      this.graph_data['companies'].forEach((element) => {
        if (width < 769) {
          if (element['is_visible_in_small_devices']) {
            this.companies.push(element);
          }
        } else {
          this.companies.push(element);
        }
      });
      this.totalLengthYaxis = this.graph_data['y_axis_data'].length;
      this.maxReviews = this.max_value(this.companies, 'reviews');
      this.totalReviews = this.graph_data['y_axis_data'][0];
      let left = 0;
      this.companies.forEach((element) => {
        element['width_height'] = (185 / this.maxReviews) * element['reviews'];
        element['translate_y'] = (387 - (element['width_height']/2) - (375/this.totalReviews*element['reviews'])) + 'px';
        // element['translate_y'] = -(
        //   115 +
        //   (element['reviews'] / this.totalReviews) * 100
        // );
        if (width < 530) {
          left += 70 / this.companies.length;
        } else if (width < 769) {
          left += 80 / this.companies.length;
        } else {
          left += 90 / this.companies.length;
        }
        element['left'] = left;
        if (element['bubble_class'] == 'smallcircle') {
          switch (this.tab_type) {
            case 'yearly':
              element['small_circle_top'] = '-26px';
              break;
            case 'monthly':
              element['small_circle_top'] = '-24px';
              break;
            case 'weekly':
              element['small_circle_top'] = '-24px';
              break;
            default :
              element['small_circle_top'] = '-24px';
              break;
          }
        } else {
          element['small_circle_top'] = '0px';
        }
      });
    }
  }
  ngOnChanges(): void {
    this.resizeWindow();
  }

  max_value(array, key, flag_value = true) {
    var maxIndex = -1;
    var maxValue = Number.MIN_VALUE;
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] > maxValue) {
        maxValue = array[i][key];
        maxIndex = i;
      }
    }
    return flag_value ? maxValue : maxIndex;
  }
}
