<div class="offer_info_container" *ngIf="product.productSlug">

  <div class="offer_info_header bg-gfeBg">
    <div class="month_label md:!text-[18px] md:!max-w-[85%]"><span class="num_months">{{product.productValidityMonths}}</span> {{product.productValidityMonths > 1 ? 'Months': 'Month'}} access to
      everything</div>
    <div class="offer_course_name">
      {{productName}}<br><span class="product_slug">{{product.productSlug}}</span>
    </div>
  </div>
  <div class="offer_info_body text_center">
    <div class="offer_info_body_wrap">
      <div class="offer_original_course_price"> {{product.originalPrice}} </div>
      <div class="offer_discounted_price">
        <span class="discount_price text-transparent bg-gfeBg bg-clip-text">{{product.discountedPrice}}</span>
        <span class="percent_off md:!w-[95px] md:!h-[95px] md:!text-[26px] sm:!right-[33px] bg-percentOff">
          <span>
            <div class="percent_value">{{product.percentOff}}</div>
            <div class="off_label md:!text-[14px]">OFF</div>
          </span>
        </span>
        <div class="bubble_tip md:!h-[103px] md:!top-[-106px] sm:!right-[23px] md:!width-[90px] md:!rotate-0 md:!rounded-tl-[115px] md:!rounded-tr-[150px] md:!rounded-br-[175px] md:!rounded-bl-[20px]"></div>
      </div>
      <div class="btn_container buy_now_cta_container text_center" (click)="routeToCart('CTA_pricing')">
        <button data-cta_name="CTA_pricing" class="btn btn_solid btn_round extra_bold_font">
          <div class="btn_wrapper">
            <img class="lozad inline_block" [src]="cdnUrl + '/assets/images/buy_now_icon.png'">
            <span class="inline_block font-extrabold">BUY NOW</span>
          </div>
        </button>
      </div>
      <div *ngIf="!isCourseOfferingPage" class="course_purchased_info">
        <span class="people_purchased"
          id="people_purchased_so_far"><span>{{peopleTensPlace}}</span><span>{{peopleOnesPlace}}</span></span>people
        have bought so far
      </div>
    </div>
  </div>
  <div class="offer_info_footer" *ngIf="!isGCEpage">
    <div class="offer_info_footer_wrap">

      <div class="offer_info">
        <span class="rounded-full flex justify-center items-center w-[31px] h-[31px] bg-gfe text-[#ffffff]">&#10003;</span>
        <div class="inline-flex flex-col">
          <div class="gfe_course">GMAT Focus Edition Course</div>
          <div class="gfe_course_sections">(Verbal, Quant, Data Insights)</div>
        </div>
      </div>
      <div class="offer_info">
        <span class="rounded-full flex justify-center items-center w-[31px] h-[31px] bg-gfe text-[#ffffff]">&#10003;</span>
        <div class="mentor_support">Dedicated Mentor Support</div>
      </div>

    </div>
  </div>
  <div class="offer_info_footer"  *ngIf="isGCEpage">
    <div class="offer_info_footer_wrap">
      <div class="offer_info">
        <span class="rounded-full flex justify-center items-center w-[31px] h-[31px] bg-gfe text-[#ffffff]">&#10003;</span>
        <div class="inline-flex flex-col">
          <div class="current_course"><strong>1-month</strong> access to everything</div>
        </div>
      </div>

      <div class="offer_info">
        <span class="rounded-full flex justify-center items-center w-[31px] h-[31px] bg-gfe text-[#ffffff]">&#10003;</span>
        <div class="inline-flex flex-col">
          <div class="gfe_course"><strong>BONUS:</strong> GFE included ($199 value)</div>
        </div>
      </div>
      <div class="offer_info">
        <span class="rounded-full flex justify-center items-center w-[31px] h-[31px] bg-gfe text-[#ffffff]">&#10003;</span>
        <div class="mentor_support">24 hr world class support</div>
      </div>

    </div>
  </div>
</div>
