import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-delivery',
  templateUrl: './success-delivery.component.html',
  styleUrls: ['./success-delivery.component.css']
})
export class SuccessDeliveryComponent implements OnInit {
  cdn_url = environment.cdn_url;

  constructor() { }

  ngOnInit(): void {
  }

  @Input() success_delivery_data;
}
