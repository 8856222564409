<div *ngIf="what_students_says_data" class="section"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/'+tab_background_name+'.jpg)'}">
  <div id="color-overlay">
    <div class="page_container">
      <div class="row">
        <div class="col-12 text-center header">
          {{what_students_says_data['heading']}}
        </div>
      </div>
      <div class="row mobile-row">
        <div class="left-arrow">
          <div class="navigation_buttons_box">
            <a class="navigation_buttons" (click)="slideChange(false)">
              <span class="label">&lt;</span>
            </a>
          </div>
        </div>
        <ng-container *ngFor="let slide of what_students_says_data['slides'];let i = index;">
          <div class="slider-middle" [ngClass]="{'active_slide': active_index == i}">
            <div class="slider-container">
              <div class="slider-width" [ngStyle]="{'width': slide['width']}">
                <div class="student_profile">
                  <img [src]="slide['profile_image_url']">
                </div>
                <div class="img-comma">
                  <img [src]="cdn_url+'/assets/icons/comma-left.png'">
                </div>
                <div class="slider-heading">
                  {{slide['heading']}}
                </div>
                <div class="slider-sub-heading">
                  {{slide['comment']}}
                </div>
                <div class="underline"></div>
                <div class="slider-footer">
                  {{slide['name']}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="right-arrow">
          <div class="navigation_buttons_box">
            <a class="navigation_buttons" (click)="slideChange(true)">
              <span class="label">&gt;</span>
            </a>
          </div>
        </div>
      </div>
      <div class="dots_container">
        <ng-container *ngFor="let slide of what_students_says_data['slides'];let i = index;">
          <span class="dots_wrapper">
            <span class="dot" [ngClass]="{'active': active_index == i}" (click)="active_index = i"></span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
