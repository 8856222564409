import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-most-compregensive-free-trial-v2',
  templateUrl: './most-compregensive-free-trial-v2.component.html',
  styleUrls: ['./most-compregensive-free-trial-v2.component.css']
})
export class MostCompregensiveFreeTrialV2Component implements OnInit {
  @Output() openMdalFt = new EventEmitter<Boolean>();
  @Input() most_compregensive_free_trial_data;
  width = window.screen.width;

  constructor() { }

  ngOnInit(): void {
  }

  openModal(btn_name){
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName',btn_name);
  }

}
