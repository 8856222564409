import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-course-pricing',
  templateUrl: './course-pricing.component.html',
  styleUrls: ['./course-pricing.component.css','../../deal-pages-common-css.css']
})
export class CoursePricingComponent implements OnInit {
  cdnUrl = environment.cdn_url;
  @Input() coursePricingData;
  @Input() product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    purchaseLink: '',
    percentOff: ''
  };

  constructor() { }

  ngOnInit(): void {
  }

openFAQPopUp() {
  document.body.classList.add('show_pop');
}

closeFAQPopUp() {
  document.body.classList.remove('show_pop')
}

}
