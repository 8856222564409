import { Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent implements OnInit {

  cdn_url = environment.cdn_url;
  constructor(private router: Router) { }
  isCollapsed = true;

  @Input() webinars_data;
  ngOnInit(): void {
  }

  redirectTo(url){
    window.open(url);
  }

  getDate(date){
    return new Date(date);
  }
  nth(date){
    const d = date.getDate();
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
