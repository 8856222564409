<div class="section" *ngIf="you_can_do_with_free_trial_data">
    <div class="row">
      <div class="col-12 text-center header">
        {{you_can_do_with_free_trial_data['heading']}}
      </div>
    </div>
    <div class="row margin-bottom-40">
      <div class="col-12 text-center sub-header" [innerHTML]="you_can_do_with_free_trial_data['sub_heading']">
      </div>
    </div>
    <ng-container *ngFor="let tab of you_can_do_with_free_trial_data['tabs']">
      <div class="row both-tabs pb-[90px]">
        <ng-container *ngFor="let lnr_tab of tab['left_and_right'];let j = index;">
          <ng-container *ngIf="lnr_tab['type'] == 'description'">
            <div class="desc_container" [ngClass]="{'is_first':j===0}">
              <div class="desc-tab">
                <div class="icon-lottie w-[84px] h-[84px] rounded-full bg-[#F2F2F2BF] relative mb-[20px]">
                  <ng-lottie [styles]="{'width':'47px','mix-blend-mode':'luminosity','opacity':'0.5','transform':'translate(-50%, -50%)','top':'50%','left':'50%','position':'absolute'}" [options]="{path:lnr_tab['image_url']}"></ng-lottie>
                </div>
                <span class="tab_heading">{{lnr_tab['heading']}} </span>
                <span class="tab_sub_heading" [innerHTML]="lnr_tab['sub_heading']"></span>
                <div class="description">{{lnr_tab['description']}}</div>
                <a class="btn-get-free-resources uppercase" (click)="openModal(lnr_tab['button']['text'])"><span class="label">{{lnr_tab['button']['text']}}</span></a>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="lnr_tab['type'] == 'image'">
            <div class="image_container"
              [ngStyle]="{'margin-left':(width >= 769)?((tab['left_and_right'][0]['type'] == 'image')?'0px':'45px'):'0px','text-align': (width >= 769)?((tab['left_and_right'][0]['type'] == 'image')?'start':'end'):'center'}">
              <img [ngClass]="{'is_first_img':j===0,'is_second_img':j!=0}" class="image-big" [src]="lnr_tab['image_url']" [ngStyle]="{'width': ((width >= 769)?lnr_tab['width']:lnr_tab['m_width']), 'height': ((width >= 769)?lnr_tab['height']:lnr_tab['m_height'])}">
            </div>
          </ng-container>
        </ng-container>
      </div>
      <!-- <div class="path-images row mobile-hide" *ngIf="tab['background_image_1'] && tab['background_image_2']">
        <div class="image_1" [ngStyle]="{'left': tab['background_image_1']['left'],'top': tab['background_image_1']['top']}">
          <img [src]="tab['background_image_1']['image_url']" [ngStyle]="{'height': tab['background_image_1']['height']}">
        </div>
        <div class="image_2" [ngStyle]="{'left': tab['background_image_2']['left'],'top': tab['background_image_2']['top']}">
          <img [src]="tab['background_image_2']['image_url']" [ngStyle]="{'height': tab['background_image_2']['height']}">
        </div>
      </div> -->
    </ng-container>
</div>
