<div class="page-container fixed" [ngClass]="{'is-scrolled': scrolled, 'background_color_white': background_color == 'white'}">
  <div class="row fixed-row">
    <div class="col-md-12"> <span class="egmatlogo">
        <a href="/">e-GMAT.com</a>
      </span>
      <span class="sign_in_with_g">
          <!-- <button class="reserve_spot google_button cta_track" (click)="googleLoginClick()">
            <i class="fa fa-fw"></i>Sign in with google
          </button> -->
          <div class="flex_view">
            <div class="google_button_div">
              <app-google-signin-btn [width]="176" [shape]="'rectangular'" [size]="'medium'" (onLoggedIn)="googleLoginClick($event)"></app-google-signin-btn>
            </div>
            <div>
              <button class="reserve_spot register_button cta_track" (click)="openModal()">Reserve your spot</button><br>
            </div>
          </div>
        </span>
    </div>
  </div>
</div>
