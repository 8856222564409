import { CommonService } from './../../../Services/common.service';
import { Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-reach-your-goal',
  templateUrl: './reach-your-goal.component.html',
  styleUrls: ['../../deal-pages-common-css.css','./reach-your-goal.component.css']
})
export class ReachYourGoalComponent implements OnChanges{

  @Input() reachYourGoalData;
  cdn_url = environment.cdn_url;
  is_open = false;
  opened_index = 0;
  cards:object[];

  constructor(private commonService: CommonService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['reachYourGoalData'] && changes['reachYourGoalData'].currentValue){
      this.reachYourGoalData = changes['reachYourGoalData'].currentValue;
      this.cards = this.reachYourGoalData?.cards || [];
      console.log(this.reachYourGoalData);
    }
  }


  closeTabCaseStudy(flag) {
    this.is_open = flag;
    if (!this.is_open) {
      this.commonService.doScrolling('#reach_your_goal_section', 500);
    }
  }
  openTabCaseStudy(data) {
    this.is_open = false;
    this.opened_index = data.index;
    setTimeout(()=>{
      this.is_open = data.flag;
    });
    console.log(this.opened_index);
  }

}
