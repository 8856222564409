import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roi',
  templateUrl: './roi.component.html',
  styleUrls: ['./roi.component.css']
})
export class RoiComponent implements OnInit {
  cdn_url = environment.cdn_url;
  roi_bg_name = 'roi_bg_new';
  width = window.screen.width;
  constructor() {
    if(this.width < 769){
      this.roi_bg_name = 'roi_mobile_bg';
    }
  }

  @Input() roi_data;
  ngOnInit(): void {
  }

}
