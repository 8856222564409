import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-everything-you-need',
  templateUrl: './everything-you-need.component.html',
  styleUrls: ['./everything-you-need.component.css']
})
export class EverythingYouNeedComponent implements OnInit, OnChanges {
  cdn_url = environment.cdn_url;
  constructor() { }
  half_count = 0;
  total_count = 0;
  active_class_index = 0;

  @Input() everything_you_need_data = [];

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    if(this.everything_you_need_data){
      this.total_count = this.everything_you_need_data['videos'].length;
      this.half_count = Math.floor(this.everything_you_need_data['videos'].length / 2);
    }
  }

  selectSlide(flag){
    if(flag){
      if(this.active_class_index == this.everything_you_need_data['videos'].length - 1){
        return;
      }
      this.active_class_index += 1;
    }else{
      if(this.active_class_index == 0){
        return;
      }
      this.active_class_index -= 1;
    }
    if(this.active_class_index >= this.everything_you_need_data['videos'].length){
      this.active_class_index = 0;
    }
    if(this.active_class_index < 0){
      this.active_class_index = this.everything_you_need_data['videos'].length - 1;
    }
  }
}
