<div class="comparison_menu" [ngStyle]="{'left':left + 'px','top':top + 'px'}" id="comparison_menu_1" *ngIf="companies">
  <div class="padding_15 border_radius_10">
    <ng-container *ngFor="let company of companies;let i=index;">
      <div (mouseover)="(width>=769)?(company['mouseover'] = true):company['mouseover'] = false" (mouseout)="company['mouseover'] = false"
        class="row pdb_30" [ngClass]="multi_select?'row_mangoosh':'row_mangoosh_single'" (click)="changeState(i, $event);" *ngIf="i!=0">
        <div class="col-md-3">
          <img class="lozad img_row_1" [ngStyle]="{'width': company['logo_width_mobile']}"
            [ngClass]="(!(company['is_visible'] || company['mouseover']))?'selected_company':'not_selected_company'"
            [src]="company['logo_url_not_selected']">
          <img class="lozad img_row_1" [ngStyle]="{'width': company['logo_width_mobile']}"
            [ngClass]="(company['is_visible'] || company['mouseover'])?'selected_company':'not_selected_company'"
            [src]="company['logo_url_selected']">
        </div>
        <div class="col-md-7 text_left text_dropdown selected_text_row_1"
          [ngClass]="company['is_visible']?'selected_text':''">{{company['short_name']}}</div>
        <div class="col-md-2 comparison_menu_row_1" *ngIf="!(!multi_select || width < 769)">
          <div class="add_comm_button_multi" [ngClass]="!company['is_visible']?'selected_button':''">+</div>
          <div class="remove_comm_button_multi" [ngClass]="company['is_visible']?'selected_button':''">-</div>
        </div>
        <div class="col-md-2 comparison_menu_row_1" *ngIf="(!multi_select || width < 769)">
          <div class="add_comm_button" [ngClass]="!company['is_visible']?'selected_button':''">
            <div class="inner_small_circle"></div>
          </div>
          <div class="remove_comm_button" [ngClass]="company['is_visible']?'selected_button':''">
            <div class="inner_small_circle"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
