import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor() { }

  ngOnInit(): void {
  }

  faq_redirect(){
    window.open(environment.faqUrl+'/faq/faqs');
  }
}
