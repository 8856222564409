import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-achiever',
  templateUrl: './achiever.component.html',
  styleUrls: ['./achiever.component.css']
})
export class AchieverComponent implements OnInit, OnChanges {
  cdn_url = environment.cdn_url;
  link_container_styles: any = {};
  current_slide = 0;
  start_current_slide = 0;
  display = true;
  rotate: boolean = false;

  private totalSlides: number = 0;

  constructor() {

  }
  @Input() slides;
  @Input() deliver_more__than_anyone_else;
  @Input() deliver_more__than_anyone_else_second_line;

  ngOnInit(): void {
    this.link_container_styles.display = 'none';
    this.link_container_styles.right = 'auto';
    this.link_container_styles.left = '0px';
    setTimeout(() => {
      this.rotate = true;
    }, environment.svg_load_time);
  }

  ngOnChanges(){
    this.totalSlides = this.slides.length;
    this.current_slide = Math.floor(this.totalSlides / 2) + 1;
    this.start_current_slide = this.current_slide;
  }
  getStyleLeft(index) {
    if (index <= this.start_current_slide) {
      return 50 - ((this.start_current_slide - index) * 30 / this.totalSlides);
    } else {
      return 50 + ((index - this.start_current_slide) * 30 / this.totalSlides);
    }
  }
  getStyleZindex(index) {
    if (index < this.current_slide) {
      return index;
    } else if (index == this.current_slide) {
      return 1000;
    } else {
      return this.totalSlides + (this.totalSlides % 2) - index
    }
  }
  getStyleBrightness(index) {
    let btness = 1;
    if (index <= this.current_slide) {
      btness = 1 - (this.current_slide - index) / this.totalSlides;
    } else {
      btness = 1 - (index - this.current_slide) / this.totalSlides;
    }
    return 'brightness(' + btness + ')';
  }
  getStyleTransform(index) {
    return 'scale(1, ' + this.getScale(index) + ') translateX(-50%)';
  }

  getScale(index) {
    let scale = 1;
    if (index <= this.current_slide) {
      scale = 1 - (this.current_slide - index) / this.totalSlides;
    } else {
      scale = 1 - (index - this.current_slide) / this.totalSlides;
    }
    if (scale < 0) {
      scale = 0;
    }
    return scale;
  }

  nextSlide(activeSlide, next = false) {
    let whichSlide;
    if (activeSlide > 0) {
      whichSlide = activeSlide;
    } else {
      whichSlide = this.current_slide;
      if (next) {
        whichSlide = (whichSlide + 1);
        if (whichSlide > this.totalSlides) {
          whichSlide = 1;
        }
      } else {
        whichSlide = (whichSlide - 1);
        if (whichSlide <= 0) {
          whichSlide = this.totalSlides;
        }
      }

    }
    this.current_slide = whichSlide;

    //code for find more link
    // if ((whichSlide == 1 || (whichSlide == this.totalSlides))) {
    //   if (whichSlide == 1) {
    //     this.link_container_styles.right = 'auto';
    //     this.link_container_styles.left = '0px';
    //   } else {
    //     this.link_container_styles.right = '0px';
    //     this.link_container_styles.left = 'auto';
    //   }
    //   this.link_container_styles.display = 'block';
    // } else {
    //   this.link_container_styles.display = 'none';
    // }

  }

}
