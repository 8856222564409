import { environment } from 'projects/egmat-home/src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  title = 'Page not found - e-GMAT';
  cdn_url = environment.cdn_url;
  constructor(private titleService: Title) { }

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.titleService.setTitle(this.title);
  }

  openChat(){
    document.querySelector('.chat_btn').classList.add('egmat_chat_active');
    document.querySelector('.chat_window').classList.add('egmat_chat_window_in');
  }

}
