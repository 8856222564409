<div *ngIf="successMetricsData" class="section section_pad section_success_metrics background_config lozad" [ngStyle]="{'background-image': 'url(' + cdn_url + '/assets/images/success_metrics_bg.png'}">
  <div class="page_container">
    <p class="page_head let_spacing_sub_1 text_center extra_bold_font">
      {{successMetricsData['heading']['firstLine']}} <br /><span class="text-[#ff9725] md:text-[40px]">{{successMetricsData['heading']['secondLine']}}</span>
    </p>
    <div class="metrics_blocks_container">
      <div class="metrics_blocks_wrapper md:flex-col">
        <div class="metric odd_position_metric"  (mouseleave)="changeBtnName()" (mouseenter)="changeBtnName('Scores',tooltipScoresBtn) ">
          <div class="metrics_head md:flex-col md:!items-center">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/improve_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="hidden md:block md:self-end text-[18px] text-[#fbb362] font-bold  pr-[10px]">{{tooltipScoresBtn}} <span  *ngIf="tooltipScoresBtn == 'Know more'">&rarr;</span></div>
            <div *ngIf="toolTip=='Scores'" class="metric_tooltip sm:!left-[5%]">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric even_position_metric mt_mob_68"  (mouseleave)="changeBtnName()" (mouseenter)="changeBtnName('Stories', tooltipStoriesBtn)">
          <div class="metrics_head md:flex-col md:!items-center">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/youtube.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="hidden md:block md:self-end text-[18px] text-[#fbb362] font-bold  pr-[10px]">{{tooltipStoriesBtn}} <span  *ngIf="tooltipStoriesBtn == 'Know more'">&rarr;</span></div>
            <div *ngIf="toolTip == 'Stories'" class="metric_tooltip sm:!left-[5%]">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric odd_position_metric mt_mob_68" (mouseleave)="changeBtnName()"  (mouseenter)="changeBtnName('Reviews', tooltipReviewsBtn)">
          <div class="metrics_head md:flex-col md:!items-center">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/favorite_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="hidden md:block md:self-end text-[18px] text-[#fbb362] font-bold  pr-[10px]">{{tooltipReviewsBtn}} <span  *ngIf="tooltipReviewsBtn == 'Know more'">&rarr;</span></div>
            <div *ngIf="toolTip=='Reviews'" class="metric_tooltip sm:!left-[5%]">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric even_position_metric mt_mob_68" (mouseleave)="changeBtnName()"  (mouseenter)="changeBtnName('Instructors', tooltipInstructorsBtn)">
          <div class="metrics_head md:flex-col md:!items-center">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/group_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="hidden md:block md:self-end text-[18px] text-[#fbb362] font-bold  pr-[10px]">{{tooltipInstructorsBtn}} <span  *ngIf="tooltipInstructorsBtn == 'Know more'">&rarr;</span></div>
            <div *ngIf="toolTip=='Instructors'" class="metric_tooltip right sm:!left-[5%]">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

