<div class="section">
  <div class="page_container">
    <div class="row">
      <div class="col-12 egmat_logo">
        <a routerLink="/" class="active egmat_logo_header">
          <img [src]="cdn_url+'/assets/logo/e-gmat-logo-ft-gmat-prep.png'" />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-5 mobile">
        <div class="header">Achieve 740+ on the GMAT</div>
        <div class="sub-header">Ace your GMAT preparation with 10+ hours of <span *ngIf="width >=600">online</span> video lessons, 400+ GMAT like questions, and AI Driven tools.</div>
        <a class="btn-get-free-resources" (click)="openModal('Get Free Resources M')"><span class="label">Get Free Resources</span></a>
      </div>
      <div class="col-7" *ngIf="width >= 769">
        <div id="svgContainer"></div>
      </div>
    </div>
  </div>
</div>
