import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;
@Component({
  selector: 'app-christmas-hero-section',
  templateUrl: './christmas-hero-section.component.html',
  styleUrls: ['./christmas-hero-section.component.css']
})
export class ChristmasHeroSectionComponent {
  @Input() barGraphData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;
  cdn_url = environment.cdn_url;
  bg_img = this.cdn_url + '/Pubsite_Uploads/deal_pages_uploads/hero_section_ny_bg.png';
  constructor(private router: Router) {}

  ngOnInit(): void {}

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }

    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }

}
