import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-proven-solution',
  templateUrl: './proven-solution.component.html',
  styleUrls: ['./proven-solution.component.css']
})
export class ProvenSolutionComponent implements OnInit {
  cdn_url = environment.cdn_url;
  showCourseComparisonMenu = false;

  constructor() { }

  @Input() proven_solution_data;
  ngOnInit(): void {
  }

  close_competitor_menu() {
    if (this.showCourseComparisonMenu) {
      this.showCourseComparisonMenu = false;
    }
  }

  getActiveIndex(){
    let active = 0;
    this.proven_solution_data['companies'].forEach((element, index) => {
      if(element['is_visible'] && index != 0){
        active = index;
      }
    });
    return active;
  }

  changeCompareTabs(name){
    this.proven_solution_data['companies'].forEach((element, index) => {
      if( index != 0){
        if(element['name'] == name){
          element['is_visible'] = true;
        }
        else{
          element['is_visible'] = false;
        }
      }
     
    });
  }

}
