import { environment } from './../../../environments/environment';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prepare-with-eGmat',
  templateUrl: './prepare-with-eGmat.component.html',
  styleUrls: ['./prepare-with-eGmat.component.css']
})
export class PrepareWithEGmatComponent implements OnInit {
  @Output() openMdalFt = new EventEmitter<Boolean>();
  cdnUrl = environment.cdn_url;

  constructor() { }

  ngOnInit() {
  }

  openModal(btn_name){
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName',btn_name);
  }

}
