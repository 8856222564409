<div class="deal_footer_section text_center  background_config lozad" [ngStyle]="{'background-image':' url(' + cdn_url +  '/assets/images/deals_footer_bg.png)'}">
  <!-- <div class="filler_slant_div"></div> -->
  <div class="page_container">
    <div class="footer_wrapper" data-aos="slide-up">
      <p class="page_sub_content light_text">
        Have any questions? Or need help with your GMAT Strategy?
        <br>
        Write back to us at <a class="hover_effect font_weight_800"
          href="mailto:support@e-gmat.com">support@e-gmat.com</a> for a one on one discussion
      </p>
    </div>
  </div>
</div>
