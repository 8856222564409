import { SEOService } from './../Services/seo.service';
import { HttpClientService } from './../Services/http-client.service';
import { Title, Meta } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  meta_tags;
  link_tags;
  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('seo.json?version=' + version)
          .subscribe((response) => {
            let page_name = 'terms-and-conditions';
            if (response[page_name]['title']) {
              this.titleService.setTitle(response[page_name]['title']);
            }
            if (response[page_name]['meta_tags']) {
              this.meta_tags = response[page_name]['meta_tags'];
              this.metaService.addTags(response[page_name]['meta_tags']);
            }
            if (response[page_name]['link_tags']) {
              this.link_tags = response[page_name]['link_tags'];
              this.seoService.createMultiLinks(
                response[page_name]['link_tags']
              );
            }
            if (response[page_name]['json_ld']) {
              this.seoService.createJsonLd(response[page_name]['json_ld']);
            }
          });
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }

}
