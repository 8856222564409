<div class="evaluation_framework_section lozad" *ngIf="evaluation_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/evaluation_section_bg.png)'}">
  <div class="page_container">
    <div class="evaluation_section_head" data-aos="slide-up">{{evaluation_data['header']['first_line']}}<span
        class="highlight_evaluation_head">{{evaluation_data['header']['second_line']}}</span></div>
    <div class="evaluation_section_sub_head" data-aos="slide-up">{{evaluation_data['header']['third_line']}}</div>
    <div class="evaluation_oval_container">
      <div [style.height]="tabContainerHeight + 'px'" class="evaluation_section_oval" data-aos="slide-up" [ngClass]="class_name_evaluation_section_oval"></div>
      <div class="evaluation_accordion_section" data-aos="slide-up">
        <div #tabContent class="tabs">
          <ng-container *ngFor="let tab of evaluation_data['tabs'];let i = index;">
            <app-evaluation-tab (open_tab)="open_tab($event)" [tab]="tab" [tab_number]="i"></app-evaluation-tab>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
