<div class="pricing_table_section"  id="buy_now_table" *ngIf="pricing_data && all_data_loaded">
  <div class="page_container text_center">
    <div class="pricing_table_head aos-init aos-animate" data-aos="slide-up">{{pricing_data['header']['first_line']}}
    </div>
    <div class="mx-auto w-max rounded-[5px] flex mt-[40px] p-[5px] bg-[#F2F2F2]">
      <div class="w-max">
        <button (click)="productTypeChange('SELF_PACED')" [ngClass]="{'!bg-[#FFC766] !text-[#000000]': productType == 'SELF_PACED'}" class="text-[#7F7F7F] w-max text-[16px] font-semibold px-[45px] py-[6px] border-none rounded-[5px] cursor-pointer">Self Paced</button>
      </div>
      <div class="w-max">
        <button (click)="productTypeChange('LIVE_SESSIONS')" [ngClass]="{'!bg-[#000000] !text-[#ffffff]': productType == 'LIVE_SESSIONS'}" class="text-[#7F7F7F] w-max text-[16px] font-semibold px-[30px] py-[6px] border-none rounded-[5px] cursor-pointer relative">
          Live Sessions
          <div class="absolute top-[4px] right-[12px]">
            <img [src]="cdn_url+'/assets/icons/ico-stars.svg'" alt="">
          </div>
        </button>
      </div>
    </div>
    <div class="row_pricing_table">
      <ng-container *ngFor="let plan of pricing_data['plans']">
        <app-pricing-card [productType]="productType" *ngIf="plan['type'] == productType" [plan]="plan"></app-pricing-card>
      </ng-container>
    </div>
  </div>
</div>
<div class="page_container gmat-focused !py-0" *ngIf="pricing_data && all_data_loaded">
  <div class="w-[60%] ml-[60px]">
    <p class="card_head_data_bold !pb-[5px]">
      Architected for the
    </p>
    <img class="ml-[-12px]" src="../../../assets/images/img-gfe.png" alt="g">
    <p class="text-[20px]">
      We have invested more than 20,000+ hours of R&D to build the world’s first and only course <span class="relative inline-block">adapted <span class="absolute inline-block w-[73px] border-[#000000] border-solid border-[1px] left-0 top-[52%]"></span></span> <span class="font-bold bg-[#ffffff] rounded-[5px] px-[10px]">designed</span> for the GMAT
      Focus Edition.
    </p>
  </div>
  <div>
    <div class="">
    <img src="../../../assets/images/img-time.png" alt="20000+ hours">
  </div>
  </div>
</div>