<div class="section section_3 section_3_pad background_config text_center lozad" *ngIf="scholarship_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/section_3_bg-jpg.jpg)'}">
  <div class="page_container">
    <div class="section_container" data-aos="slide-up">
      <p class="page_head extra_bold_font"> <span
          class="highlight_text">{{scholarship_data['scholarship_head']['highlighted_text']}}</span>
        <sup>{{scholarship_data['scholarship_head']['highlighted_text_sup']}}</sup>
        {{scholarship_data['scholarship_head']['without_highlight_text']}}
      </p>
      <p class="page_sub_head section_3_page_head_gap">
        {{scholarship_data['scholarship_sub_head']}}
      </p>
    </div>
    <div class="slide_show_outer_container">
      <div class="slide_show_inner_container">
        <div class="slide_show_container_wrapper">
          <div class="slideshow-container" *ngIf="scholarship_data['slides']">
            <ng-container *ngFor="let slide of scholarship_data['slides']; let i = index;">
              <div class="mySlides mySlides1 padding_50_5 fade display_flex" [style.--i]="i+1" data-aos="slide-up">
                <div class="student_info student_info_small">
                  <div class="student_pic"> <img class="lozad" [src]="slide['student_img_url']"
                      (click)="student_pic_click(i)">
                  </div>
                  <div class="clg_logo"> <img class="lozad" [src]="slide['clg_logo_url']"></div>
                  <div class="student_name">
                    {{slide['student_name']['first_name']}}<br>{{slide['student_name']['last_name']}}</div>
                  <div class="student_score bold_font"> {{slide['student_score']}}</div>
                </div>
                <div class="scholarship_info text_left">
                  <div class="align_section_vertical">
                    <p class="scholarship_label bold_font">SCHOLARSHIP</p>
                    <p class="scholarship_amount semibold_font">{{slide['scholarship_amount']}}</p>
                    <hr class="divider">
                    <p class="student_quote">{{slide['student_quote']}}</p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="slide_show_container_overlay" data-aos="slide-up">
        <div class="slide_show_container_wrapper">
          <div class="slideshow-container">
            <ng-container *ngIf="scholarship_data['slides']">
              <div id="target_slide" class="mySlides mySlides1 fade_slide display_flex" [ngClass]="active_slide_class"
                [ngStyle]="{'opacity': opacity_target_slide}">
                <div class="student_info desktop">
                  <div class="student_pic"> <img class="lozad"
                      [src]="scholarship_data['slides'][active_index]['student_img_url']">
                  </div>
                  <div class="clg_logo"> <img class="lozad"
                      [src]="scholarship_data['slides'][active_index]['clg_logo_url']"></div>
                  <div class="student_name">
                    {{scholarship_data['slides'][active_index]['student_name']['first_name']}}<br>{{scholarship_data['slides'][active_index]['student_name']['last_name']}}
                  </div>
                  <div class="student_score bold_font"> {{scholarship_data['slides'][active_index]['student_score']}}
                  </div>
                </div>
                <div class="scholarship_info text_left desktop">
                  <div class="align_section_vertical">
                    <p class="scholarship_label bold_font">SCHOLARSHIP</p>
                    <p class="scholarship_amount semibold_font">
                      {{scholarship_data['slides'][active_index]['scholarship_amount']}}
                    </p>
                    <hr class="divider">
                    <p class="student_quote"> {{scholarship_data['slides'][active_index]['student_quote']}}</p>
                  </div>
                </div>
                <div class="student_pic mobile">
                  <img class="lozad" [src]="scholarship_data['slides'][active_index]['student_img_url']">
                  <div class="student_name">
                    {{scholarship_data['slides'][active_index]['student_name']['first_name']}}<br>{{scholarship_data['slides'][active_index]['student_name']['last_name']}}
                  </div>
                </div>
                <div class="scholarship_info mobile">
                  <p class="scholarship_label bold_font">SCHOLARSHIP</p>
                  <p class="scholarship_amount semibold_font">
                    {{scholarship_data['slides'][active_index]['scholarship_amount']}}
                  </p>
                </div>
                <div class="student_info mobile">
                  <p class="student_quote"> {{scholarship_data['slides'][active_index]['student_quote']}} </p>
                  <hr class="divider">
                  <div class="clg_logo"> <img class="lozad"
                      [src]="scholarship_data['slides'][active_index]['clg_logo_url']"></div>
                  <div class="student_score bold_font"> {{scholarship_data['slides'][active_index]['student_score']}}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="slide_show_container_forescreen_overlay" data-aos="slide-up">
        <div class="slide_show_container_wrapper">
          <div class="slideshow-container">
            <a class="prev_btn icon" (click)="arrowClick(false)">
              <img class="default" [src]="cdn_url+'/assets/images/left_arrow_default.png'">
              <img class="hover" [src]="cdn_url+'/assets/images/left_arrow_hover.png'">
            </a>
            <a class="next_btn icon" (click)="arrowClick()">
              <img class="default" [src]="cdn_url+'/assets/images/right_arrow_default.png'">
              <img class="hover" [src]="cdn_url+'/assets/images/right_arrow_hover.png'">
            </a>
          </div>
          <div style="text-align:center">
            <ng-container *ngFor="let slide of scholarship_data['slides']; let i = index;">
              <span class="dot" [ngClass]="intervalCount == i ? 'active' : ''" (click)="student_pic_click(i)"></span>
            </ng-container>
          </div>
        </div>
      </div>
    </div><br>
    <div class="disclaimer_text"><span class="disclaimer_wrap"><span><sup>1</sup> Estimated based on the number of
          successful students.</span></span></div>
  </div>
</div>

