<div class="section section_6 section_6_background_config section_6_pad lozad" *ngIf="personalized_feedback_data"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/section_6_bg.png)'}">
  <div class="page_container" data-aos="slide-up">
    <p class="page_sub_head bold_font"> {{personalized_feedback_data['header']['first_line']}} </p>
    <p class="page_head section_page_head_gap extra_bold_font"> <span
        class="highlight_text">{{personalized_feedback_data['header']['highlighted_text']}}</span>
      {{personalized_feedback_data['header']['second_line']}} <br>{{personalized_feedback_data['header']['third_line']}}
    </p>
    <p class="page_sub_head section_page_head_gap">{{personalized_feedback_data['header']['text']}} </p>
  </div>
  <div class="page_feedback_section_container" data-aos="slide-up">
    <div class="feedback_carousel_container two_column_section gap_bottom_feedback_section">
      <div class="feedback_slides_container">
        <div class="slide_points inline_block">
          <ng-container *ngFor="let slide of personalized_feedback_data['slides'];let i = index;">
            <div class="dot"
              [ngClass]="{ 'active_feedback' : (i == active_slide), 'next_active': (i > active_slide), 'complete': (i < active_slide) }"
              (click)="active_slide = i;"></div>
          </ng-container>
        </div>
        <div class="slide_content inline_block">
          <ng-container *ngFor="let slide of personalized_feedback_data['slides'];let i = index;">
            <div class="slide" [ngClass]="{ 'active_feedback' : (i == active_slide) }">
              <div style="--index:1;" class="slide_number extra_bold_font">{{FormatSlideNumber(i+1)}}<span
                  class="total_slides">
                  /{{FormatSlideNumber(personalized_feedback_data['slides'].length)}}</span></div>
              <div style="--index:2;" class="slide_head bold_font">{{slide['slide_head']}}</div>
              <div style="--index:3;" class="slide_desc">{{slide['slide_text']}}</div>
            </div>
          </ng-container>
        </div>
        <div class="feedback_content_container">
          <ng-container *ngFor="let slide of personalized_feedback_data['slides'];let i = index;">
            <div class="slide" [ngClass]="{ 'active_feedback' : (i == active_slide) }">
              <div class="iframe_video_wrapper">
                <app-iframe [url]="slide['slide_video_url']" [class]="'psp_wistia_video lozad wistia_embed'">
                </app-iframe>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
