<ng-container *ngIf="state == 'close_webinar_login' ? false : true">
  <div class="modalDialog fade">
    <div [@modalOneView]="state">
      <div class="modal-header login_modal_header">
        <button type="button" class="close" (click)="close()"><span aria-hidden="true"><img class="close_icon_img"
              [src]="cdn_url+'/assets/icons/close_icon.png'"></span></button>
      </div>
      <div class="modal-body">
        <div class="form_outer_container">
          <div class="form_container">
            <div class="login_heading form_head">Start Now</div>
            <div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
            <div class="user_message">{{user_message}}</div>
            <div class="login_form">
              <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                <div class="form-group form_group">
                  <div class="input_field_label">Email*</div>
                  <div *ngIf="f.username.errors" class="invalid-feedback">
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                      *ngIf="alert_submitted['username'] && f.username.errors.required">
                      Email is Required
                    </div>
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                      *ngIf="alert_submitted['username'] && f.username.errors.pattern">
                      Enter a valid Email Address
                    </div>
                  </div>
                  <input (keyup)="onKeyUpEmail('username')" formControlName="username" type="text"
                    class="form-control form_control reqField loginfield" placeholder="Email" id="loginEmailval"
                    name="username" value=""
                    [ngClass]="{ 'is-invalid': alert_submitted['username'] && f.username.errors }">
                </div>
                <div class="form-group form_group" *ngIf="state_user != 'email'">
                  <div class="input_field_label">Password*</div>
                  <div *ngIf="f.password.errors" class="invalid-feedback">
                    <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error"
                      *ngIf="alert_submitted['password'] && f.password.errors.required">
                      Password is Required
                    </div>
                  </div>
                  <input (keyup)="onKeyUpEmail('password')" formControlName="password" [attr.type]="show_password?'text':'password'"
                    class="form-control form_control reqField loginfield" id="loginPass" name="password"
                    placeholder="min. 8 characters" value=""
                    [ngClass]="{ 'is-invalid': alert_submitted['password'] && f.password.errors }">
                  <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;"
                    [ngClass]="{'display_ele': !show_password}" />
                  <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
                    class="field_icon toggle-password" (click)="show_password = !show_password;"
                    [ngClass]="{'display_ele': show_password}" />
                  <div *ngIf="state_user === 'password'" class="reg_tnc text-center"><span (click)="forgot_password()"
                      class="fgt_pss">Forgot Password?</span></div>
                  <div class="checkbox reg_tnc reg_tnc_div" *ngIf="state_user === 'register'">
                    <label>
                      <input [ngClass]="{ 'is-invalid': alert_submitted['tnc'] && tnc_error }"
                        type="checkbox" class="reg_check" id="input-check"  (change)="tncChecked($event)"><label class="square_label" for="input-check">
                      </label>&nbsp; I accept
                      <a class="tnc" target="_blank" routerLink="/terms-and-conditions">Terms &amp; Conditions</a>
                      <div *ngIf="alert_submitted['tnc'] && tnc_error" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg tnc_error_msg" id="pass_error_message">
                          Please accept the terms and conditions
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="form-group form_sub_btn">
                  <button *ngIf="!submitted" type="submit" id="submitmyform" class="btn btn_default lgn_btn_form webinar_submit_button_login">
                    <ng-container *ngIf="state_user === 'email'">Start Now</ng-container>
                    <ng-container *ngIf="state_user === 'password'">login</ng-container>
                    <ng-container *ngIf="state_user === 'register'">Register</ng-container>
                  </button>
                  <button *ngIf="submitted" type="button" id="submitmyformgif" class="btn btn_default lgn_btn_form"><img
                      style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></button>
                </div>
              </form>
              <div class="form_or">
                <div class="form_or_text form_login_or_text">or Sign in with Google</div>
                <div class="form_or_line form_login_or_line"></div>
              </div>
              <div class="form_google_btn">
                <app-google-signin-btn [submit_button_class]="'webinar_submit_button_login'" *ngIf="!google_submitted"  (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
                <!-- <a *ngIf="!google_submitted" id="submitmyform" (click)="googleLoginClick()"
                  class="btn btn_default google_lgn_btn_form"><span class="fa fa_google reg_google_plus"
                    aria-hidden="true"></span> &nbsp;&nbsp;Sign in with
                  Google</a> -->
                <a *ngIf="google_submitted" id="submitmyformgif" class="btn btn_default google_lgn_btn_form"><img
                    style="width:40px;" [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
