<div class="section section_pad section_case_study_view" id="section_case_study_view" [ngClass]="{'hide':!is_open}" *ngIf="cards">
  <div class="page_container">
    <div class="case_study_view egmat_tab_view" data-numberoftabs="3" data-aos="slide-up">
      <div class="tab_view_container">
        <div class="tab_content_container">
          <ng-container *ngFor="let card of cards; let i = index;">
            <div class="tab_content" [ngClass]="(i == opened_index)?'active_tab':''">
              <div class="content_wrap">
                <div class="content_placeholder inline_block">
                  <div class="iframe_video_wrapper">
                    <ng-container *ngIf="i == opened_index">
                      <app-iframe [url]="card['url']" [class]="'psp_wistia_video lozad wistia_embed'"></app-iframe>
                    </ng-container>
                  </div>
                </div>
                <div class="content_details background_config inline_block lozad">
                  <div class="text-[#ff9725] text-[36px] font-bold">Top 3 reasons</div>
                  <div class="text-[#000000] text-[36px] font-bold leading-none mb-[50px]">for {{card['customer_name']}}'s Success</div>
                  <ng-container *ngFor="let item of card['list'], let i = index;">
                    <div class="text-[#7f7f7f] text-[20px] mb-[30px]"><span class="text-[#ff9725]">{{'0' + (i+1)}}. </span><span>{{item['normalText1']}} </span><span class="text-[#000000] font-[600]">{{item['boldText']}} </span><span>{{item['normalText2']}}</span></div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="tab_head_container">
          <ng-container *ngFor="let card of cards; let i = index;">
            <div class="tab_head" [ngClass]="(i == opened_index)?'active_tab':''" (click)="opened_index = i;markActive()">
              <span class="tab_text">
                <span class="label">{{card['tab_label']}}</span>
                <span class="duration_label">{{card['tab_duration_label']}}</span>
              </span>
            </div>
          </ng-container>
          <div class="tab_select" [ngStyle]="{'left': left_tab_select+'%'}">

          </div>
        </div>
      </div>
    </div>
    <div class="learn_more_btn_container close_btn semibold_font" data-aos="slide-up" (click)="closeTab(false)">
      <span class="learn_more_parent inline_block">
        <span class="hover_circle inline_block"></span>
        <span class="learn_more_icon inline_block"><img class="lozad"
            [src]="cdn_url+'/assets/images/close_icon_dark.png'"></span>
        <span class="learn_more_text inline_block">Close</span>
      </span>
    </div>
  </div>
</div>
