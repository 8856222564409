<div class="speaker_section" style="background-color:#f4f4f4;" *ngIf="about_speaker_data">
  <div class="page_container">
    <div class="row">
      <div class="col-md-5">
        <div class="speaker_image"><img [src]="about_speaker_data['about']['image_url']"></div>
        <div class="speaker_name">{{about_speaker_data['about']['name']}}</div>
        <div class="speaker_title">{{about_speaker_data['about']['title']}}</div>
      </div>
      <div class="col-md-7">
        <div class="speaker_quote">
          <div class="about_speaker_text">{{about_speaker_data['header']['first_line']}}</div>
          <div class="speaker_text">{{about_speaker_data['description']['first_line']}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
