import { environment } from 'projects/egmat-home/src/environments/environment';
import { Observable } from 'rxjs';
import { ModalService } from './../Services/modal.service';
import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.css']
})
export class VideoPopupComponent {
  cdn_url = environment.cdn_url;
  display_video$: Observable<any>;

  video_url = null;

  constructor(
      private modalService: ModalService
  ) {}

  ngOnInit() {
    this.display_video$ = this.modalService.watch_video();

    this.display_video$.subscribe((item)=>{
      this.video_url = item;
    });
  }

  close_video() {
    this.modalService.close_video();
  }

}
