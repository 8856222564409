import { Component,Input, OnInit,ElementRef } from '@angular/core';

@Component({
  selector: 'app-about-us-teams',
  templateUrl: './about-us-teams.component.html',
  styleUrls: ['./about-us-teams.component.css']
})
export class AboutUsTeamsComponent implements OnInit {

  constructor(private elRef: ElementRef) { }
  @Input() teams_data;
  ngOnInit(): void {
  }

  showHoverViewLeft(event){
    var target = event.target;
    var parentElement = this.elRef.nativeElement.closest('.team_flex_container_parent_expand');
  //  target.closest(".team_person_expand_left").find(".team_member_name_text").fadeOut(500);
  //  target.closest(".team_person_expand_left").find(".team_member_title_text").fadeOut(500);
  }
 showNormalViewLeft(event){
 }

  showHoverViewRight(event){
  }
  showNormalViewRight(event){
  }

}
