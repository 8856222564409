import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-seven-sixty',
  templateUrl: './score-seven-sixty.component.html',
  styleUrls: ['./score-seven-sixty.component.css']
})
export class ScoreSevenSixtyComponent implements OnInit {

  cdn_url = environment.cdn_url;
  sec3_background_name = 'sec3_background';
  width = window.screen.width;
  constructor() {
    if(this.width < 549){
      this.sec3_background_name = 'sec3_mobile_background';
    }
  }

  @Input() score_seven_sixty_data;
  ngOnInit(): void {
  }

}
