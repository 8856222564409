<div class="section section_pad md:!pb-0 lozad bg-no-repeat bg-cover bg-[url('/assets/images/three_reasons_bg.png')] md:bg-[url('/assets/images/three_reasons_bg.png')]" id="top_reasons" *ngIf="topThreeReasonsData">
  <div class="page_container" data-aos="slide-up">
    <div class="font-bold text-center text-[65px] md:text-[30px] md:font-extrabold">
      {{topThreeReasonsData['header']['firstLine']}}</div>
    <div class="font-bold text-center text-[65px] md:text-[30px] md:font-extrabold">
      {{topThreeReasonsData['header']['secondLine']}}</div>
    <div class="font-bold text-center text-[65px] md:text-[30px] md:font-extrabold text-transparent !bg-clip-text !bg-gfeBg mb-[70px]">
      {{topThreeReasonsData['header']['lastLine']}}</div>
    <div class="tab_tiles_container flex md:flex-col md:!hidden">
      <div class="tab_container w-[40%]">
        <ng-container *ngFor="let tab of topThreeReasonsData['tabs']">
          <div class="flex tab_parent cursor-pointer group border-0 border-[#d2d2d2] hover:border-gfe border-solid border-b-2"
            [ngClass]="selectedTab == tab['tabName'] ? 'border-gfe': ''" (click)="selectedTab = tab['tabName']">
            <div class="text-[130px] mr-[30px] tab_name tracking-[-10px] font-extrabold"
              [ngClass]="selectedTab == tab['tabName'] ? 'tab_name_selected !bg-gfeNumberGradient': ''">{{tab['tabName']}}</div>
            <div class="text-[40px] text-[#d2d2d2] group-hover:text-[#000000] self-center font-bold" [ngClass]="selectedTab == tab['tabName'] ? '!text-[#000000]': ''">{{tab['tabText']}}</div>
          </div>
        </ng-container>
      </div>
      <div class="tiles_container w-[60%] ml-[100px]">
        <div class="flex flex-wrap flex-grow">
          <ng-container *ngFor="let tile of topThreeReasonsData['tiles'][selectedTab]; let i = index;">
            <app-top-reason-tile [originalPrice]="originalPrice" [discountedPrice]="discountedPrice"
              [productId]="productId" [discountCode]="discountCode" [tileData]="tile"
              [tileClass]="(i == (topThreeReasonsData['tiles'][selectedTab].length-1))?'lastTile':''"></app-top-reason-tile>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="hidden md:block">
      <ng-container *ngFor="let tab of topThreeReasonsData['tabs']">
        <div class="flex font-bold w-[90%] mx-auto items-center">
          <div class="text-[116px] tab_name_selected !bg-gfeNumberGradient tracking-[-7px]">{{tab['tabName']}}</div>
          <div class="text-[26px] pl-[50px] sm:pl-[20px]">{{tab['tabText']}}</div>
        </div>
        <div class="border-[0] border-b-[2px] border-solid border-[#d2d2d2] mb-[30px] pb-[30px] last:border-b-0">
          <ng-container *ngFor="let tile of topThreeReasonsData['tiles'][tab['tabName']]; let i = index;">
            <div class="shadow-[0px_0px_18px_1.6px_rgba(0,0,0,.1)] p-[34px] mb-[30px] rounded-[10px]">
              <div class="flex mb-[30px] items-center">
                <img class="" [src]="tile['icon']">
                <div class="text-[26px] ml-[20px]">
                  <div class="font-extrabold">{{tile['boldText1']}}</div>
                  <div class="">{{tile['normalText1']}}</div>
                  <div class="font-extrabold">{{tile['boldText2']}}</div>
                  <div class="">{{tile['normalText2']}}</div>
                </div>
              </div>
              <div class="text-[20px] text-[#7f7f7f] mb-[30px]">{{tile['backViewText']}}</div>
              <div class="text-[20px] text-gfe font-bold italic" (click)="routeToCart('CTA_course_offerings_tile')">{{tile['ctaButton']}} <span >&#8594;</span></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </div>
  <div class="bg-[#f7f9f8] mt-[30px] py-[70px]">
    <div class="page_container flex justify-between items-center mt-[180px] md:flex-col ">
      <div class="md:mb-[55px]">
        <div class="text-[26px]">{{topThreeReasonsData['footerText']['firstLine']}}</div>
        <div class="text-[36px] font-extrabold"><span>{{topThreeReasonsData['footerText']['secondLine']['blackWord']}}
          </span><span class="blue_text">{{topThreeReasonsData['footerText']['secondLine']['colorWord']}}</span></div>
        <div class="text-[36px] font-extrabold"><span>{{topThreeReasonsData['footerText']['lastLine']['blackWord']}}
          </span><span class="orange_text">{{topThreeReasonsData['footerText']['lastLine']['colorWord']}}</span></div>
      </div>
      <div class="btn_container buy_now_cta_container text-center" (click)="routeToCart('CTA_top_reasons')">

        <div class="hero_cta_btn secondary_section active" data-cta_name="CTA_timer_ribbon">
          <div class="logo_container">
            <img class="lozad" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
          </div>
          <div class="price_container">
            <div class="discounted_price page_head extra_bold_font !text-ctaBtn">{{discountedPrice}}</div>
            <span class="original_price">{{originalPrice}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
