<div *ngIf="barGraphData" class="section section_pad hero_section md:hidden"
  [ngStyle]="{'background-color': '#000000' }">
  <div class="page_container">
    <div class="flex md:flex-col">
      <div class="left_container">
        <div class="page_head extra_bold_font" data-aos="slide-up">
          <div>{{barGraphData['heading']['firstLine']}}</div>
          <div class="most_successful_text text-center"><span class="fa fa-star star"></span><span
              class="highlight_text">{{barGraphData['heading']['secondLineColorfulWord']}}</span><span
              class="fa fa-star star"></span></div>
          <div>{{barGraphData['heading']['thirdLine']}}</div>
        </div>
        <div class="white_bg_text mt-[20px]" data-aos="slide-up">
          {{barGraphData['subHeading']['lightWords']}} <span class="extra_bold_font"><span class="blue_word">
              {{barGraphData['subHeading']['blueWord']}}</span> {{barGraphData['subHeading']['andWord']}}<span
              class="orange_word"> {{barGraphData['subHeading']['orangeWord']}}</span>
            {{barGraphData['subHeading']['lastWord']}}</span>

        </div>
        <div class="other_text" data-aos="slide-up">
          <div [innerHTML]="barGraphData['content']['line1']"></div>
          <div>{{barGraphData['content']['line2']}}</div>
          <div>{{barGraphData['content']['line3']}}</div>
          <div class="extra_bold_font">{{barGraphData['content']['lastBoldLine']}}</div>
        </div>
        <div class="hero_cta_btn" data-cta_name="CTA_barchart" (click)="routeToCart('CTA_piechart')">
          <div class="logo_container">
            <img class="lozad img_cart_hero_cta" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
          </div>
          <div class="price_container">
            <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
            <span class="original_price">{{originalPrice}}</span>
          </div>
        </div>

      </div>
      <div class="right_container" data-aos="slide-up">
        <div class="pie_chart_container">
          <div class="relative left-[40px] md:left-0 top-[40px]">
            <img class="lozad !max-w-none" [src]="barGraphData['barGraphUrl']">
            <div class="disclaimer_text absolute left-[25px] bottom-[7px]">
              <div>{{barGraphData['notes']['note1']['1star']}} {{barGraphData['notes']['note1']['line']}}</div>
              <div class=" mob_display_disclaimer_section">{{barGraphData['notes']['note2']['2star']}}
                {{barGraphData['notes']['note2']['line']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="barGraphData" class="section section_pad hero_section hidden md:block bg-no-repeat bg-[url('/assets/images/hero_section_tablet_bg.png')] bg-bottom sm:bg-center sm:bg-[url('/assets/images/hero_section_mobile_bg.png')]">
  <div class="page_container text-center">
    <div class="text-center text-[30px] font-bold">{{barGraphData['heading']['firstLine']}}</div>
    <div
      class="highlight_text text-center text-[30px] font-extrabold rounded-[7px] py-[4px] bg-[#ffffff33] shadow-[2px_0px_2px_0px_rgba(0,0,0,0.3)] inline-flex px-[87px] sm:px-[20px] border-[0.5px] border-solid border-gray-100">
      {{barGraphData['heading']['secondLineColorfulWord']}}</div>
    <div class="text-center text-[30px] font-bold mb-[50px]">{{barGraphData['heading']['thirdLine']}}</div>
    <div class="bg-[#ff972540] rounded-[5px] inline-flex px-[50px] sm:px-[5px] py-[5px] mb-[40px]">
      {{barGraphData['subHeading']['lightWords']}} <span
        class="font-extrabold sm:ml-[2px]">{{barGraphData['subHeading']['blueWord']}} {{barGraphData['subHeading']['andWord']}}
        {{barGraphData['subHeading']['orangeWord']}} {{barGraphData['subHeading']['lastWord']}}</span>
    </div>
    <div class="text-[#7f7f7f]"  [innerHTML]="barGraphData['content']['line1']"></div>
    <div class="text-[#7f7f7f]">{{barGraphData['content']['line2']}}</div>
    <div class="text-[#7f7f7f]">{{barGraphData['content']['line3']}} <span class="font-bold">{{barGraphData['content']['lastBoldLine']}}</span>
    </div>
    <div class="hero_cta_btn md:!border-[0] mx-auto" data-cta_name="CTA_barchart" (click)="routeToCart('CTA_piechart')">
      <div class="logo_container">
        <img class="lozad img_cart_hero_cta" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
      </div>
      <div class="price_container">
        <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
        <span class="original_price">{{originalPrice}}</span>
      </div>
    </div>
    <div>
      <img class="lozad" [src]="barGraphData['barGraphUrlMobile']">
    </div>
    <div class="text-[#7f7f7f] mt-[66px] whitespace-no-wrap sm:mt-[-5px]">
      <div>{{barGraphData['notes']['note1']['1star']}} {{barGraphData['notes']['note1']['line']}}</div>
      <div>{{barGraphData['notes']['note2']['2star']}} {{barGraphData['notes']['note2']['line']}}</div>
    </div>


  </div>
</div>
