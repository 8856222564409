<div class="section_2">
  <div class="page_container text_center" data-aos="slide-up">
    <p class="page_head sec_2_page_head_gap extra_bold_font text-[#fda40a]">
      Most Successful Company since 2016
    </p>
    <div class="section_container">
      <p class="page_sub_head section_2_page_sub_head sec_2_page_sub_head_gap">
        Since 2016, e-GMATers have delivered more verified 645+ scores than have students from any other test prep company.
      </p>
      <div class="tab_section_container">
        <div class="700_score egmat_tab_view" data-numberoftabs="3" data-contentconfig="bubbleChartsData">
          <div class="tab_view_container">
            <div class="tab_content" [ngClass]="{ 'active_tab' : tab_type == 'yearly'}" *ngIf="tab_type == 'yearly'">
              <app-score-graph *ngIf="score_graph_data['yearly']['type'] === 'graph'" [graph_data]="score_graph_data['yearly']" [tab_type]="tab_type"></app-score-graph>
              <app-score-graph-pie *ngIf="score_graph_data['yearly']['type'] === 'image'" [graph_data]="score_graph_data['yearly']" [tab_type]="tab_type"></app-score-graph-pie>
            </div>
            <div class="tab_content" [ngClass]="{ 'active_tab' : tab_type == 'monthly'}" *ngIf="tab_type == 'monthly'">
              <app-score-graph *ngIf="score_graph_data['monthly']['type'] === 'graph'" [graph_data]="score_graph_data['monthly']" [tab_type]="tab_type"></app-score-graph>
              <app-score-graph-pie *ngIf="score_graph_data['monthly']['type'] === 'image'" [graph_data]="score_graph_data['monthly']" [tab_type]="tab_type"></app-score-graph-pie>
            </div>
            <div class="tab_content" [ngClass]="{ 'active_tab' : tab_type == 'weekly'}" *ngIf="tab_type == 'weekly'">
              <app-score-graph *ngIf="score_graph_data['weekly']['type'] === 'graph'" [graph_data]="score_graph_data['weekly']" [tab_type]="tab_type"></app-score-graph>
              <app-score-graph-pie *ngIf="score_graph_data['weekly']['type'] === 'image'" [graph_data]="score_graph_data['weekly']" [tab_type]="tab_type"></app-score-graph-pie>
            </div>
          </div>
          <div class="disclaimer_text"><span class="disclaimer_wrap"><span>*Logos are properties of respective
                companies.</span><span>** Source: Verified reviews on GMAT Club</span></span></div>
          <div class="tab_head_container">
            <ng-container *ngIf="score_graph_data['yearly']">
              <div class="tab_head" [ngClass]="{ 'active_tab' : tab_type == 'yearly'}" (click)="tab_type = 'yearly'">
                <span class="tab_text">
                  <span class="duration_label">{{score_graph_data['yearly']['tab_data']['duration']}}</span>
                  <span class="specific_time_label">{{score_graph_data['yearly']['tab_data']['specific_time']}}</span>
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="score_graph_data['monthly']">
              <div class="tab_head" [ngClass]="{ 'active_tab' : tab_type == 'monthly'}" (click)="tab_type = 'monthly'">
                <span class="tab_text">
                  <span class="duration_label">{{score_graph_data['monthly']['tab_data']['duration']}}</span>
                  <span class="specific_time_label">{{score_graph_data['monthly']['tab_data']['specific_time']}}</span>
                </span>
              </div>
            </ng-container>
            <ng-container *ngIf="score_graph_data['weekly']">
              <div class="tab_head" [ngClass]="{ 'active_tab' : tab_type == 'weekly'}" (click)="tab_type = 'weekly'">
                <span class="tab_text">
                  <span class="duration_label">{{score_graph_data['weekly']['tab_data']['duration']}}</span>
                  <span class="specific_time_label">{{score_graph_data['weekly']['tab_data']['specific_time']}}</span>
                </span>
              </div>
            </ng-container>
            <div class="tab_select" [ngStyle]="{'left': getStyleLeft()+'%'}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
