

      <!--sign in section-->
      <div *ngIf="!showSignUpForm" class="modal-body">
          <div class="form_outer_container">
              <div class="form_container">
                  <div class="login_heading form_head">Login</div>
                  <div class="dont_have_account">Don't have an account? <span class="show_sign_up" (click)="showSignUp()">Sign Up</span></div>
                  <div id="loginErrorMsg" class="login_error_msg">{{login_error_msg}}</div>
                  <div class="login_form">

                      <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
                          <div class="form-group form_group">
                              <div class="input_field_label">Email*</div>
                              <div *ngIf="f.username.errors" class="invalid-feedback">
                                <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted && f.username.errors.required">
                                  Email is Required
                                </div>
                                <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted && f.username.errors.pattern">
                                  Enter a valid Email Address
                                </div>
                            </div>
                              <input formControlName="username" type="text" class="form-control form_control reqField loginfield" placeholder="Email"
                                  id="loginEmailval" name="username" value=""  [ngClass]="{ 'is-invalid-signin': alert_submitted && f.username.errors }">
                          </div>

                          <div class="form-group form_group">
                              <div class="input_field_label">Password*</div>
                              <div *ngIf="f.password.errors" class="invalid-feedback">
                                <div class="reg_form_error_msg red_reg_form_error_msg login_empty_error" *ngIf="alert_submitted && f.password.errors.required">
                                  Password is Required
                                </div>
                            </div>
                              <input formControlName="password"  [attr.type]="show_password?'text':'password'" class="form-control form_control reqField loginfield" id="loginPass" name="password"
                                  placeholder="min. 8 characters" value="" [ngClass]="{ 'is-invalid-signin': alert_submitted && f.password.errors }">

                              <img *ngIf="!show_password" toggle="#loginPass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'" class="field_icon_login toggle-password" (click)="show_password = !show_password;" />
                              <img *ngIf="show_password" toggle="#loginPass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'" class="field_icon_login toggle-password" (click)="show_password = !show_password;" />

                              <div class="reg_tnc text-center"><span (click)="forgot_password()" class="fgt_pss">Forgot Password?</span></div>
                          </div>
                          <div class="form-group form_sub_btn">
                              <button *ngIf="!submitted" type="submit" id="submitmyform" class="btn btn_default lgn_btn_form signin_signup_submit_button_login">Login</button>
                              <button *ngIf="submitted" type="button" id="submitmyformgif" disabled class="btn btn_default lgn_btn_form"><img
                                      style="width:40px;"
                                      [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></button>
                          </div>
                      </form>
                      <div class="form_or">
                          <div class="form_or_text form_login_or_text">or Sign in with Google</div>
                          <div class="form_or_line form_login_or_line"></div>
                      </div>
                      <div class="form_google_btn">
                        <app-google-signin-btn [submit_button_class]="'signin_signup_submit_button_login'" *ngIf="!google_submitted" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
                          <!-- <a *ngIf="!google_submitted" id="submitmyform" (click)="googleLoginClick()" class="btn btn_default google_lgn_btn_form"><span
                                  class="fa fa_google reg_google_plus" aria-hidden="true"></span> &nbsp;&nbsp;Sign in with
                              Google</a> -->
                          <a *ngIf="google_submitted" id="submitmyformgif" disabled class="btn btn_default google_lgn_btn_form"><img style="width:40px;"
                                  [src]="cdn_url+'/assets/icons/Loading_animation.gif'" /></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--sign up section-->
      <div *ngIf="showSignUpForm" class="modal-body">
        <div class="form_outer_container">
          <div class="reg_form">
            <div class="login_heading form_head">Sign Up</div>
            <div class="dont_have_account">Already have an account? <span class="show_sign_up" (click)="showSignIn()">Sign In</span></div>
            <div class="login_error_msg" id="reg_error_message">{{reg_error_message}}</div>
            <form [formGroup]="signUpForm" (ngSubmit)="onSignUpFormSubmit()">
              <div class="form-group">
                <div class="input_field_label">Name*</div>
                <div *ngIf="f.name.errors" class="invalid-feedback">
                  <div class="reg_form_error_msg red_reg_form_error_msg" id="name_error_message"
                    *ngIf="alert_submitted && f.name.errors.required">
                    Name is Required
                  </div>
                </div>
                <input formControlName="name" [ngClass]="{ 'is-invalid-signup': alert_submitted && f.name.errors }" type="text" class="form-control reqField" id="name" name="name" placeholder="Name"
                  value="">
              </div>
              <div class="form-group">
                <div class="input_field_label">Email*</div>
                <div *ngIf="f.username.errors" class="invalid-feedback">
                  <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message" *ngIf="alert_submitted && f.username.errors.required">
                    Email is Required
                  </div>
                  <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message" *ngIf="alert_submitted && f.username.errors.pattern">
                    Enter a valid Email Address
                  </div>
                </div>
                <input formControlName="username" [ngClass]="{ 'is-invalid-signup': alert_submitted && f.username.errors }" type="text" class="form-control reqField" id="emailval" name="username" placeholder="Email"
                  value="">
              </div>
              <div class="form-group">
                <div class="input_field_label">Password*</div>
                <div *ngIf="f.password.errors" class="invalid-feedback">
                  <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message"
                    *ngIf="alert_submitted && f.password.errors.required">
                    Password is Required
                  </div>
                </div>
                <input formControlName="password" [ngClass]="{ 'is-invalid-signup': alert_submitted && f.password.errors }" [attr.type]="show_password?'text':'password'" class="form-control reqField" id="pass" name="password"
                  placeholder="min. 8 characters" value="">
                <img *ngIf="!show_password" toggle="#pass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'" class="field_icon toggle-password signup_show_pass" (click)="show_password = !show_password;" />
                <img *ngIf="show_password" toggle="#pass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'" class="field_icon toggle-password signup_show_pass" (click)="show_password = !show_password;" />

                <div class="checkbox reg_tnc terms_padding">
                  <label>
                    <input [ngClass]="{ 'is-invalid-signup-terms': alert_submitted && tnc_error }" type="checkbox" class="reg_check" id="input-check" (change)="tncChecked($event)"><label class="square_label"
                      for="input-check">
                    </label>&nbsp; I accept
                    <a class="tnc" target="_blank"
                      routerLink="/terms-and-conditions">Terms &amp; Conditions</a>
                    <div *ngIf="alert_submitted && tnc_error" class="invalid-feedback">
                        <div class="reg_form_error_msg red_reg_form_error_msg terms_and_conditions_err" id="pass_error_message">
                          Please accept the terms and conditions
                        </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-group form_sub_btn">
                <button *ngIf="!submitted" type="submit" id="submitmyformRegister"
                  class="btn btn-default reg_btn_form register_submit signin_signup_submit_button_login">Get Started</button>
                <button *ngIf="submitted" type="submit" id="submitmyformgif" disabled
                  class="btn btn-default reg_btn_form register_submit"><img style="width:40px;"
                    [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></button>
              </div>
            </form>
            <div class="form_or">
              <div class="form_or_text form_register_or_text">or Sign up with Google</div>
              <div class="form_or_line form_register_or_line"></div>
            </div>
            <div class="form_google_btn">
              <div class="form_google_btn form_google_btn_register">
                <app-google-signin-btn [submit_button_class]="'signin_signup_submit_button_login'" *ngIf="!google_submitted" text="signup_with" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
                <!-- <a *ngIf="!google_submitted" (click)="googleLoginClick()" id="submitmyform" class="btn btn-default google_lgn_btn_form">
                  <span class="fa fa-google fa_google reg_google_plus" aria-hidden="true"></span> &nbsp;&nbsp;Sign up with
                  Google</a> -->
                <a *ngIf="google_submitted" id="submitmyformgif" disabled class="btn btn-default google_lgn_btn_form"><img style="width:40px;"
                    [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
