import { environment } from './../../../../environments/environment';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-success-metrics',
  templateUrl: './success-metrics.component.html',
  styleUrls: ['./success-metrics.component.css','../../deal-pages-common-css.css'],
})
export class SuccessMetricsComponent implements OnInit {
  cdn_url = environment.cdn_url;
  @Input() successMetricsData;
  @Input() salesDealOfferConfig;

  constructor() {}

  ngOnInit(): void {}
}
