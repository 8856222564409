import { DataService } from './../../../Services/data.service';
import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;


@Component({
  selector: 'app-proof-of-success',
  templateUrl: './proof-of-success.component.html',
  styleUrls: ['../../deal-pages-common-css.css', './proof-of-success.component.css']
})
export class ProofOfSuccessComponent implements OnInit {
  @Input() proofOfSuccessData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;
  cdnUrl = environment.cdn_url;
  left_tab_select:number = 0;
  active_index = 0;

  constructor(private router: Router,private dataService: DataService) {}

  ngOnInit(): void {
  }

  change_tab(index){
    this.left_tab_select = index * 35;
    this.active_index = index;
  }

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }

}
