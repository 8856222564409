<div class="section section_pad section_9" *ngIf="customer_success_champions_data">
  <div class="page_container">
    <div class="csc_container">
      <div class="csc_content">
        <p class="page_sub_head bold_font dash_line" data-aos="slide-up">
          <span class="dash_style_line"></span>{{customer_success_champions_data['header']['first_line']}}
        </p>
        <p class="page_head extra_bold_font highlight_text" data-aos="slide-up">
          {{customer_success_champions_data['header']['second_line']}}</p>
        <p class="page_sub_head section_page_head_gap bold_font" data-aos="slide-up">
          {{customer_success_champions_data['header']['third_line']}}</p>

        <div class="csc_carousel_container mobile" data-aos="slide-up">
          <div class="csc_carousel_wrapper">
            <div class="carousel_images_container">
              <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
                <div class="carousel_slide" [ngClass]="(active_class_index == i) ? 'active_cs' : ''">
                  <div class="carousel_slide_inner_wrap">
                    <div class="cs_image">
                      <img class="lozad" [src]="slide['image_url']">
                    </div>
                    <div class="cs_rating_container">
                      <div class="csc_score_container">
                        <span class="csat_label">{{slide['score_text']}}</span>
                        <span class="csat_score">{{slide['score']}}</span>
                      </div>
                      <div class="star_rating" [style.--rating]="slide['score']">

                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="dots_container">
            <span class="dots_wrapper">
              <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
                <span class="dot" [ngClass]="(active_class_index == i) ? 'active_cs' : ''" (click)="active_class_index = i;"></span>
              </ng-container>
            </span>
          </div>
          <div class="names_container">
            <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
              <div class="cs_name" [ngClass]="(active_class_index == i) ? 'active_cs' : ''">
                <div class="name">{{slide['name']}}</div>
                <div class="profile">{{slide['profile']}}</div>
              </div>
            </ng-container>
          </div>
        </div>

        <p class="page_sub_head section_page_head_gap" data-aos="slide-up">
          {{customer_success_champions_data['header']['text']}}
        </p>
      </div>
      <div class="csc_carousel_container desktop" data-aos="slide-up">
        <div class="csc_carousel_wrapper">
          <div class="carousel_images_container">
            <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
              <div class="carousel_slide" [ngClass]="(active_class_index == i) ? 'active_cs' : ''">
                <div class="carousel_slide_inner_wrap">
                  <div class="cs_image">
                    <img class="lozad" [src]="slide['image_url']">
                  </div>
                  <div class="cs_rating_container">
                    <div class="csc_score_container">
                      <span class="csat_label">{{slide['score_text']}}</span>
                      <span class="csat_score">{{slide['score']}}</span>
                    </div>
                    <div class="star_rating" [style.--rating]="slide['score']">

                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="dots_container">
          <span class="dots_wrapper">
            <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
              <span class="dot" [ngClass]="(active_class_index == i) ? 'active_cs' : ''" (click)="active_class_index = i;"></span>
            </ng-container>
          </span>
        </div>
        <div class="names_container">
          <ng-container *ngFor="let slide of customer_success_champions_data['slides']; let i = index;">
            <div class="cs_name" [ngClass]="(active_class_index == i) ? 'active_cs' : ''">
              <div class="name">{{slide['name']}}</div>
              <div class="profile">{{slide['profile']}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="video_container" data-aos="slide-up">
    <div class="page_container">
      <div class="video_div">
        <div class="video_frame iframe_video_wrapper">
          <app-iframe [url]="customer_success_champions_data['video_frame_url']" [class]="'lozad wistia_embed'">
          </app-iframe>
        </div>

      </div>
    </div>
    <div class="filler_slat_bg_div background_config lozad" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/video_section_bg.jpg)'}">
      <div class="foreground_color">

      </div>
    </div>
  </div>
</div>
