<div class="gmat_journey_section background_config lozad" *ngIf="gmat_journey_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/egmat_journey_bg.png)'}">
  <div class="page_container">
    <div class="gmat_journey_heading mt_65" data-aos="slide-up">{{gmat_journey_data['header']['first_line']}}</div>
    <div class="gmat_journey_heading" data-aos="slide-up">{{gmat_journey_data['header']['second_line']}}<span
        class="gmat_journey_sub_heading">{{gmat_journey_data['header']['third_line']}}</span></div>
    <div class="gmat_journey_img_container" data-aos="slide-up">
      <img class="lozad egmat_journey_img" data-aos="slide-up" [src]="gmat_journey_data[gmat_journey_image_name]">
    </div>
  </div>
</div>
