import { Meta } from '@angular/platform-browser';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  constructor(@Inject(DOCUMENT) private doc: any, private metaService: Meta) {}
  createLink(attributes) {
    let link: HTMLLinkElement = this.doc.createElement('link');
    for (let k in attributes) {
      link.setAttribute(k, attributes[k]);
    }
    this.doc.head.appendChild(link);
  }
  createMultiLinks(links) {
    links.forEach((link) => {
      this.createLink(link);
    });
  }
  createJsonLd(json) {
    let el = document.createElement('script');
    el.type = 'application/ld+json';
    el.text = JSON.stringify(json);
    document.querySelector('head').appendChild(el);
  }
  removeMetaTags(meta_tags) {
    if (meta_tags) {
      meta_tags.forEach((meta_tag) => {
        let firstKey = Object.keys(meta_tag)[0];
        this.metaService.removeTag(
          firstKey + '=' + "'" + meta_tag[firstKey] + "'"
        );
      });
    }
  }
  removeMultiLinks(links) {
    links.forEach((link) => {
      this.removeLink(link);
    });
  }
  removeLink(link) {
    let firstKey = Object.keys(link)[0];
    let link_tag = document.querySelector(
      'link[' + firstKey + "='" + link[firstKey] + "']"
    );
    if (link_tag) {
      link_tag.parentNode.removeChild(link_tag);
    }
  }
  removeJsonLd() {
    let tag = document.querySelector("script[type='application/ld+json']");
    if (tag) {
      tag.parentNode.removeChild(tag);
    }
  }
}
