import { DataService } from './../../Services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit } from '@angular/core';

declare const push_site_event: any;
declare const push_utm_event_nonpropagation_and_redirect: any;

@Component({
  selector: 'app-videos-redirect',
  templateUrl: './videos-redirect.component.html',
  styleUrls: ['./videos-redirect.component.css'],
})
export class VideosRedirectComponent implements OnInit {
  cdn_url = environment.cdn_url;
  title = 'eGMAT:Videos';

  names = {
    'gcprocesschange': 'https://e-gmat.wistia.com/medias/ouqea1pe55',
    'searching-doubts-1': 'https://e-gmat.wistia.com/medias/sghzmd3626',
    'searching-doubts-2': 'https://e-gmat.wistia.com/medias/qtuk248dkq',
    'books-vs-online': 'https://e-gmat.wistia.com/medias/3ujqqsuznr',
    'best-practices-1': 'https://e-gmat.wistia.com/medias/ttpdl7yz4w',
    'best-practices-3': 'https://e-gmat.wistia.com/medias/p65ryf2p5m',
    'access_quant': 'https://e-gmat.wistia.com/medias/48mqfpv2ql',
    'create_custom_quiz': 'https://e-gmat.wistia.com/medias/050wi8ef5p',
    'create_persl_assess': 'https://e-gmat.wistia.com/medias/dpgr6d7eli',
    'best-practices-2': 'https://e-gmat.wistia.com/medias/36j9f6zubs',
  };
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
      }
    });
    this.titleService.setTitle(this.title);
    console.log(this.names.gcprocesschange);
    this.route.queryParams.subscribe((params) => {
      if (
        params['name'] &&
        this.names[params['name']] &&
        this.names[params['name']] != ''
      ) {
        this.dataService.getEventScriptStatus.subscribe((status) => {
          if (status) {
            push_utm_event_nonpropagation_and_redirect(
              'viewed video promotion',
              this.names[params['name']]
            );
          }
        });
      } else {
        this.router.navigateByUrl('404');
      }
    });
  }
}
