import { DataService } from './../../../Services/data.service';
import { CommonService } from './../../../Services/common.service';
import { Router } from '@angular/router';
import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css','../../deal-pages-common-css.css']
})
export class ProductCardComponent implements OnInit, OnChanges {
  cdnUrl = environment.cdn_url;
  @Input() product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    purchaseLink: '',
    percentOff: '',
    willDealGoLiveOnThisPage: 'YES',
  };

  @Input() dealCounters;
  @Input() couponsLeft;
  @Input() couponArr;
  @Input() dealResetHappened;
  @Input() isCourseOfferingPage= false;
  totalCouponsAfterDealReset;
  dealEndDate;
  dealResetDate;
  peoplePurchased;
  peopleTensPlace;
  peopleOnesPlace;
  isDealLive;

  constructor(private router: Router, private commonService: CommonService, private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.isDealLive.subscribe(flag => {
      this.isDealLive = flag;
    })
  }
  ngOnChanges(): void {
    if(this.dealCounters?.dealResetDate){
      if(this.dealResetHappened) {
        this.dealResetDate = this.dealCounters.dealEndDate;
        this.dealResetDate = new Date(this.dealResetDate).setDate(new Date(this.dealResetDate).getDate() - 1);
        this.totalCouponsAfterDealReset = this.dealCounters.totalCoupons + 10;
      } else {
        this.dealResetDate = this.dealCounters.dealResetDate;
        this.totalCouponsAfterDealReset = this.dealCounters.totalCoupons;
      }
      let dateObj = new Date(this.dealResetDate);
      this.peoplePurchased = this.totalCouponsAfterDealReset - this.couponsLeft;
      this.peopleTensPlace = Math.floor(this.peoplePurchased / 10);
      this.peopleOnesPlace = this.peoplePurchased % 10;
      console.log('this.peoplePurchased', this.peoplePurchased)
      this.dealResetDate = dateObj.getDate() + this.commonService.nth(dateObj) + ' ' + this.commonService.getMonthName(dateObj) + ' ' + dateObj.getFullYear();
      this.dataService.updateDealEndDate(this.dealResetDate);
    }
  }

  routeToCart(prop) {
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.product.productId],
    { queryParams: { discountCode: this.product.discountCode, cp: 0 } });
    }, 500);
  }

}
