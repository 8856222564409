<div class="section section_1 background_config text_center"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/hero_section_bg-jpg.jpg)'}">
  <div class="page_container">
    <div class="page_head gap_bottom extra_bold_font hero_head_top_gap" [attr.data-aos]="display?'slide-up':''">We<div
        id="filler_text">
        <div [ngClass]="{'rotate_text':rotate}">
          coach<br />
          train<br />
          forge<br />
          guide<br />
          build<br />
        </div>
      </div>achievers
    </div>
    <p class="page_sub_head" [attr.data-aos]="display?'slide-up':''">
      We have delivered more <span
      class="highlight_text bold_font">{{deliver_more__than_anyone_else}}</span> scores,
      <br>whether on the traditional GMAT or on the <span
        class="highlight_text bold_font">{{deliver_more__than_anyone_else_second_line}}</span></p>
    <div class="glide__main" id="wiz-glide" [attr.data-aos]="display?'slide-up':''">
      <div class="glide__arrows desktop" data-glide-el="controls">
        <a (click)="nextSlide(0)" class="glide__arrow glide__arrow--prev icon" data-glide-dir="<">
          <img class="default" [src]="cdn_url+'/assets/images/previous_icon_default.png'">
          <img class="hover" [src]="cdn_url+'/assets/images/previous_icon_hover.png'">
        </a>
      </div>
      <div class="glide__container" id="wiz-glide">
        <ul class="glide__slides">
          <ng-container *ngFor="let slide of slides; let i = index">
            <li (click)="nextSlide(i+1)" class="glide__slide" [ngClass]="{ 'active-slide' : i == (current_slide - 1) }"
              id="slide-{{(i+1)}}"
              [ngStyle]="{'left': getStyleLeft(i+1)+'%', 'z-index': getStyleZindex(i+1),'filter': getStyleBrightness(i+1), 'transform' : getStyleTransform(i+1)}">
              <div class="card_header display_flex align_navbar">
                <span class="card_logo">
                  <img class="lozad" data-src="{{slide.card_logo_url}}" src="{{slide.card_logo_url}}"
                    data-loaded="true">
                </span>
                <span class="card_score">{{slide.score}}</span>
              </div>
              <div class="card_content">
                <span class="card_student_img">
                  <img class="lozad" data-src="{{slide.student_img_url}}" src="{{slide.student_img_url}}"
                    data-loaded="true">
                </span>
                <span class="card_student_name">
                  {{slide.name}}
                </span>
              </div>
            </li>
          </ng-container>
        </ul>
        <div style="text-align:center" class="cards_arrow_container mobile">
          <div>
            <a (click)="nextSlide(0)" class="prev glide__arrow glide__arrow--prev icon_container">
              <span class="">
                <img class="icon_default" [src]="cdn_url+'/assets/images/left_arrow_default_sm.png'">
                <img class="icon_hover" [src]="cdn_url+'/assets/images/left_arrow_yellow_hover.png'">
              </span>
              <span class="arrow_label">Prev</span>
            </a>
          </div>
          <div>
            <a (click)="nextSlide(0, true)" class="next glide__arrow glide__arrow--next icon_container">
              <span class="arrow_label">Next</span>
              <span class="">
                <img class="icon_default" [src]="cdn_url+'/assets/images/right_arrow_default_sm.png'">
                <img class="icon_hover" [src]="cdn_url+'/assets/images/right_arrow_yellow_hover.png'">
              </span>
            </a>
          </div>
        </div>

        <div class="link_container" id="link_container" style="display: none; right: auto; left: 0px;"
          [ngStyle]="{'left': link_container_styles?.left, 'right': link_container_styles?.right, 'display': link_container_styles?.display}">
          <span>
            <a href="" class="external_link hover_effect">Find more <span class="focus_text">500+</span>
              achievers</a>
          </span>
          <span>
            <img class="lozad" [src]="cdn_url+'/assets/images/right_arrow_with_stick_default.png'">
          </span>
        </div>

      </div>
      <div class="glide__arrows desktop" data-glide-el="controls">
        <a (click)="nextSlide(0, true)" class="glide__arrow glide__arrow--next icon" data-glide-dir=">">
          <img class="default" [src]="cdn_url+'/assets/images/next_icon_default.png'">
          <img class="hover" [src]="cdn_url+'/assets/images/next_icon_hover.png'">
        </a>
      </div>
    </div>
  </div>
</div>
