<div class="nav desktop" [ngClass]="{ 'scroll-add' : scrolled }">
  <div class="desktop_nav align_navbar" data-aos="slide-up"
    [ngClass]="{ 'desktop_nav_white': isHeaderWhite && !scrolled}">
    <div class="vertical_middle_align text_left">
      <a routerLink="/" class="active egmat_logo_header"><img
          [src]="cdn_url+'/assets/logo/e_gmat_logo_small.png'" /></a>
      <a routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
        routerLink="/gmat-course-online"><span class="underlined_example"
          [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Course
          Details</span></a>
      <a routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
        routerLink="/pricing"><span class="underlined_example"
          [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Plans and
          Pricing</span></a>
      <a routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
          [href]="liveSessionUrl" target="_blank"><span class="underlined_example"
            [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Live Sessions</span></a>
      <a routerLinkActive="active_url_desktop" [ngClass]="{ 'active_url_desktop_white': isHeaderWhite && !scrolled}"
        routerLink="/admits-scholarships"><span class="underlined_example"
          [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Admits &
          Scholarships</span></a>
    </div>
    <div class="vertical_middle_align text_left">
      <a class="login_btn" (click)="ModalOpen()"><span class="underlined_example float_right"
          [ngClass]="{ 'underlined_example_white': isHeaderWhite && !scrolled}">Sign In</span></a>
      <a (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)"
        routerLink="/free-resources-gmat-registration" class="btn btn_solid btn_round wavy_btn btn_try_it_free">
        <div class="front">
          <span style="--i:1;" [ngClass]="class_transform">T</span>
          <span style="--i:2;" [ngClass]="class_transform">r</span>
          <span style="--i:3;" [ngClass]="class_transform">y</span>
          <span style="--i:4;" [ngClass]="class_transform">&nbsp;</span>
          <span style="--i:5;" [ngClass]="class_transform">i</span>
          <span style="--i:6;" [ngClass]="class_transform">t</span>
          <span style="--i:7;" [ngClass]="class_transform">&nbsp;</span>
          <span style="--i:8;" [ngClass]="class_transform">F</span>
          <span style="--i:9;" [ngClass]="class_transform">r</span>
          <span style="--i:10;" [ngClass]="class_transform">e</span>
          <span style="--i:11;" [ngClass]="class_transform">e</span>
        </div>
        <div class="back">
          <span style="--i:1;" [ngClass]="class_transform">T</span>
          <span style="--i:2;" [ngClass]="class_transform">r</span>
          <span style="--i:3;" [ngClass]="class_transform">y</span>
          <span style="--i:4;" [ngClass]="class_transform">&nbsp;</span>
          <span style="--i:5;" [ngClass]="class_transform">i</span>
          <span style="--i:6;" [ngClass]="class_transform">t</span>
          <span style="--i:7;" [ngClass]="class_transform">&nbsp;</span>
          <span style="--i:8;" [ngClass]="class_transform">F</span>
          <span style="--i:9;" [ngClass]="class_transform">r</span>
          <span style="--i:10;" [ngClass]="class_transform">e</span>
          <span style="--i:11;" [ngClass]="class_transform">e</span>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="nav mobile" [ngClass]="{ 'scroll-add-mobile' : scrolled ,'white-color-mobile': !isHeaderWhite }">
  <div class="page_container inherit_height">
    <nav class="navbar navbar-default egmat_header inherit_height">
      <div class="container display_flex align_navbar inherit_height">
        <div class="navbar-header header_top_header text_left vertical_middle_align"> <a
            class="navbar-brand egmat_logo_header" routerLink="/"><img
              [src]="cdn_url+'/assets/logo/e_gmat_logo_small.png'"></a></div>
        <div class="nav_container semibold_font display_flex inherit_height">

          <a class="nav_link hamburger_item hover_underline"
            [class.active_toggle_hamburger_item]="mobileNavTabClosed === 'opened' ? false : true">
            <div class="header_menu" (click)="openMobileTab()"
              [class.active_toggle]="mobileNavTabClosed === 'opened' ? true : false">
              <i></i><i></i><i></i>
            </div>
          </a>
        </div>
      </div>
    </nav>
  </div>
  <div class="mobile_nav_tab" [class.mobile_nav_tab_closed]="mobileNavTabClosed === 'closed' ? true : false"
    [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/header_menu_bg.png)'}"
    [class.mobile_nav_tab_opened]="mobileNavTabClosed === 'opened' ? true : false"
    [class.mobile_nav_tab_hide]="mobileNavTabClosed === '' ? true : false">
    <div class="header_wrapper" (click)="openMobileTab()">
      <div routerLinkActive="active_url" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/"><span>Home</span></a>
      </div>
      <div routerLinkActive="active_url">
        <a routerLink="/gmat-course-online"><span>Course Details</span></a>
      </div>
      <div routerLinkActive="active_url">
        <a routerLink="/pricing"><span>Plans and Pricing</span></a>
      </div>
      <div routerLinkActive="active_url">
        <a href="https://learn.e-gmat.com/products/coach-connect" target="_blank"><span>Live Sessions</span></a>
      </div>
      <div routerLinkActive="active_url">
        <a routerLink="/admits-scholarships"><span>Admits & Scholarships</span></a>
      </div>
      <div>
        <a (click)="$event.stopPropagation();ModalOpen()"><span>Sign In</span></a>
      </div>
      <div>
        <a routerLink="/free-resources-gmat-registration"><span>Try it Free</span></a>
      </div>
    </div>
  </div>
</div>



<!--
<div class="nav height_60 mobile_nav_main"
  [ngClass]="{ 'scroll-add-mobile' : scrolled ,'white-color-mobile': !isHeaderWhite }">
  <div class="mobile_nav" data-aos="slide-up">
    <div class="container display_flex align_navbar inherit_height">
      <div class="navbar-header header_top_header text_left vertical_middle_align">
        <a routerLink="/" class="active egmat_logo_header"><img
            [src]="cdn_url+'/assets/logo/e_gmat_logo_small.png'" /></a>
      </div>
      <div class="nav_container semibold_font display_flex inherit_height">
        <div class="nav_link hamburger_item hover_underline"
          [class.active_toggle_hamburger_item]="mobileNavTabClosed === 'opened' ? false : true">
          <div class="header_menu" (click)="openMobileTab()"
            [class.active_toggle]="mobileNavTabClosed === 'opened' ? true : false">
            <i></i><i></i><i></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</div> -->
<app-signin (closeHeader)='closeHeader($event)'></app-signin>
