<footer *ngIf="json_data" class="w-full bg-[length:120vw] h-[586px] sm:h-[646px] flex flex-row sm:flex-col-reverse md:flex-col-reverse   font-['Nunito Sans']" [ngStyle]=" {'background-image': ' url('+ cdn_url+'/assets/images/img-footer-bg.png)'} ">
   <div class="flex  basis-2/5 sm:basis-[32%]  items-center justify-center ">
    <div class="flex flex-col" >
        <div class="flex items-center my-6 justify-start sm:md:justify-center sm:md:my-[5px]">
            <img [src]="cdn_url+'/assets/logo/e-gmat-logo-ft-gmat-prep.png'" alt="e-gmat" class="h-[52px] sm:md:h-[19px] w-[60px] sm:md:w-[21px]">
            <h2 class="text-[26px] sm:md:text-[18px] text-white font-bold mx-4">e-GMAT.com</h2>
        </div>
        <div class="flex flex-col sm:md:flex-row text-[26px] sm:md:text-[18px]  text-[#A6A6A6] mb-[72px] sm:md:mb-3">
            <p>Empowering dreams </p> <p class="border-0 ml-0 pl-0 sm:md:pl-[10px] sm:md:ml-[10px] sm:md:border-l sm:md:border-solid sm:md:border-[#A6A6A6]"> Changing lives</p>
        </div>
        <div class="flex flex-col sm:md:flex-row items-start justify-normal sm:md:justify-center sm:md:items-center">
            <h3 class="text-white text-[26px] sm:md:text-[18px] font-bold sm:md:font-semibold">Follow us</h3>
            <div class="flex my-6 sm:md:my-3 ml-0 sm:md:ml-4">
                <a target="_blank" href="https://www.facebook.com/E-Gmat-499275643430980" class="h-[38px]   w-[38px] sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#3B5998] m-1 ml-0 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'facebook-f']" class="text-white text-[24px] sm:text-lg"></fa-icon>
                </a>
                <a target="_blank" href="https://www.linkedin.com/in/rajat-sadana-ba459a" class="h-[38px] w-[38px] sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#0E76A8] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'linkedin-in']" class="text-white text-[24px] sm:md:text-lg"></fa-icon>
                </a>
                <a  href="https://www.youtube.com/user/eGMATconcepts" target="_blank" class="h-[38px] w-[38px] sm:md:h-[30px] sm:md:w-[30px] py-[3.5px] px-[11.5px] rounded-full bg-[#727272] hover:bg-[#D8362A] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fas', 'play']" class="text-white text-[22px] sm:md:text-lg"></fa-icon>
                </a>
                <a href="https://twitter.com/e_GMAT" target="_blank" class="h-[38px] w-[38px]  sm:md:h-[30px] sm:md:w-[30px] flex items-center justify-center rounded-full bg-[#727272] hover:bg-[#fff] m-1 sm:md:m-2">
                    <fa-icon [icon]="['fab', 'x-twitter']" class="text-white text-lg text-[24px] sm:md:text-lg  hover:text-[#1A1A1A]"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</div>
    <div class="flex items-center justify-center font-['Nunito Sans']">
        <div class="relative flex flex-col items-start justify-normal sm:md:justify-end sm:md:items-center h-[443px] sm:md:h-[305px] sm:md:w-[400px] w-[717px] bg-[#262626] rounded-[21px] sm:md:p-0 pt-[48px] pl-[45px]">
        <h1 class="text-white text-[40px] font-semibold sm:md:text-[26px]">Achieve 735+</h1>
        <h5 class="text-[26px] text-white font-semibold sm:md:text-[26px] sm:md:font-normal">with our resources</h5>
        <div class="block sm:md:hidden text-[#D2D2D2] mt-[30px] text-[18px]"> <a class="cursor-pointer text-link font-semibold text-[#fda40a] no-underline leading-[1]" (click)="openModal('Sign up - footer')">Sign up</a> for our free trial and get access to</div>
        <div class=" sm:md:hidden flex items-center my-2"> 
            <div class="flex items-center justify-center h-[15px] w-[15px] rounded-full mr-3" [ngClass]="'bg-'+type" >
                <fa-icon [icon]="['fas', 'check']" class="text-white text-[10px]" ></fa-icon>
            </div>
            <p class="text-[#D2D2D2] text-lg">15+ hrs of online video lessons</p>
        </div>
        <div class=" sm:md:hidden flex items-center my-2"> 
            <div class="flex items-center justify-center h-[15px] w-[15px] rounded-full mr-3" [ngClass]="'bg-'+type">
                <fa-icon [icon]="['fas', 'check']" class="text-white text-[10px]" ></fa-icon>
            </div>
            <p class="text-[#D2D2D2] text-lg">400+ GMAT-like questions</p>
        </div>
        <div class=" sm:md:hidden flex items-center my-2"> 
            <div class="flex items-center justify-center h-[15px] w-[15px] rounded-full mr-3" [ngClass]="'bg-'+type">
                <fa-icon [icon]="['fas', 'check']" class="text-white text-[10px]" ></fa-icon>
            </div>
            <p class="text-[#D2D2D2] text-lg">7+ interactive live webinars</p>
        </div>
      
        <button (click)="openModal('Begin your Prep today M')" class="uppercase cursor-pointer w-[300px] sm:md:[270px] h-[60px] sm:md:h-[50px] font-bold   text-[16px] sm:md:text-[18px]  rounded-[40px] my-9 sm:md:my-6 hover:bg-[#ffc766]" [ngClass]="{'bg-gce':type=='gce','bg-gfe':type == 'gfe'}">
            Begin your Prep today!
        </button>

        <img [src]=" type=='gce' ? cdn_url+'/assets/images/img-rajat.png': cdn_url+'/assets/images/img-rajat-gfe.png'" class="absolute bottom-[-2px] sm:md:bottom-auto top-auto  sm:md:top-[-34%] sm:md:right-auto right-[-10%] w-[359px] h-[420px] sm:md:h-[192px] sm:md:w-[164px]"  alt="rajat">
    </div>
    </div>
</footer>
