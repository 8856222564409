import { SEOService } from './../../../Services/seo.service';
import { CommonService } from './../../../Services/common.service';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClientService } from './../../../Services/http-client.service';
import { Meta, Title } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../../Services/data.service';

declare const push_event_properties: any;
declare const createproperties_nonpropagation: any;
declare const push_site_event: any;

@Component({
  selector: 'app-sales-pages',
  templateUrl: './sales-pages.component.html',
  styleUrls: ['./sales-pages.component.css'],
})
export class SalesPagesComponent implements OnInit, OnDestroy {
  barGraphData: any;
  successMetricsData;
  topReasonsData;
  gmatJourneyData;
  personalizedImprovementPlanData;
  proofOfSuccessData;
  courseComparisonData;
  couponDetailsData;
  salesDealOfferConfig;
  limitedPeriodOfferData;
  link_tags;
  meta_tags;
  product = {
    productSlug: '',
    productValidityMonths: '',
    originalPrice: '',
    discountedPrice: '',
    productId: '',
    discountCode: '',
    percentOff: '',
    willDealGoLiveOnThisPage: 'NO',
  };
  monthLabel;
  dealCounters:any = {
    dealStartDate: null,
    dealResetDate: null,
    dealEndDate: null,
    couponArr: null,
    resetCouponArr: null,
    totalCoupons: null,
    resetHappenBeforeHours: null
  };
  couponsLeftBeforeDealStart;
  expiryDate;
  eventSubsribe;
  pageType;
  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private commonService: CommonService,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));

    this.monthLabel =
      location.pathname.split('/')[1] === 'limited-period-offer' &&
      location.pathname.split('/')[2]
        ? location.pathname.split('/')[2].split('-')[1]
        : '4m';
    this.pageType = location.pathname.split('/')[2]?location.pathname.split('/')[2]:'default';
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('limited-period-offer.json?version=' + version)
          .subscribe((response) => {
            this.barGraphData = response['barGraphData'];
            this.successMetricsData = response['successMetricsData'];
            this.topReasonsData = response['topReasonsData'];
            this.gmatJourneyData = response['gmatJourneyData'];
            this.personalizedImprovementPlanData =
              response['personalizedImprovementPlanData'];
            this.proofOfSuccessData = response['proofOfSuccessData'];
            this.courseComparisonData = response['courseComparisonData'];
            this.couponDetailsData = response['couponDetailsData'];
            this.limitedPeriodOfferData = response['limitedPeriodOfferData'];
            this.salesDealOfferConfig = response['salesDealOfferConfig'];
            this.product.productSlug =
              this.salesDealOfferConfig[this.monthLabel]['productSlug'];
            this.product.productValidityMonths =
              this.salesDealOfferConfig[this.monthLabel][
                'productValidityMonths'
              ];
            this.product.originalPrice =
              this.salesDealOfferConfig[this.monthLabel]['originalPrice'];
            this.product.discountedPrice =
              this.salesDealOfferConfig[this.monthLabel]['discountedPrice'];
            this.product.productId =
              this.salesDealOfferConfig[this.monthLabel]['productId'];
            this.product.discountCode =
              this.salesDealOfferConfig[this.monthLabel]['discountCode'];
            this.product.percentOff =
              this.salesDealOfferConfig[this.monthLabel]['percentOff'];
            this.product.willDealGoLiveOnThisPage = this.salesDealOfferConfig[this.monthLabel]['willDealGoLiveOnThisPage'];
            this.dealCounters.dealStartDate = this.commonService.convertToPst(this.salesDealOfferConfig['dealStartDate'] + ' GMT+05:30');
            this.dealCounters.dealResetDate = this.commonService.convertToPst(this.salesDealOfferConfig['dealResetDate'] + ' GMT+05:30');
            this.dealCounters.dealEndDate = this.commonService.convertToPst(this.salesDealOfferConfig['dealEndDate'] + ' GMT+05:30');
            this.couponsLeftBeforeDealStart = new Date(this.dealCounters.dealStartDate).getTime() - new Date(this.commonService.convertToPst(new Date())).getTime() > 0 ? this.salesDealOfferConfig['couponArr'].split(',')[1].split(':')[1] : null;
            this.dealCounters.couponArr = this.convertToObj(this.salesDealOfferConfig['couponArr'].split(','));
            this.dealCounters.resetCouponArr = this.convertToObj(this.salesDealOfferConfig['resetCouponArr'].split(','));
            this.dealCounters.totalCoupons =  +this.salesDealOfferConfig['totalCoupons'];
            this.dealCounters.resetHappenBeforeHours = +this.salesDealOfferConfig['resetHappenBeforeHours'];
            this.expiryDate = new Date(this.commonService.convertToPst(new Date())).getTime() > new Date(this.dealCounters.dealResetDate).getTime() ? this.dealCounters.dealEndDate : this.dealCounters.dealResetDate;
            this.expiryDate = this.getExpiryDate(new Date(this.expiryDate));
            if (response[this.pageType]['title']) {
              this.titleService.setTitle(response[this.pageType]['title']);
            }
            if (response[this.pageType]['meta_tags']) {
              this.meta_tags = response[this.pageType]['meta_tags'];
              this.metaService.addTags(response[this.pageType]['meta_tags']);
            }
            if(response[this.pageType]['link_tags']){
              this.link_tags= response[this.pageType]['link_tags'];
              this.seoService.createMultiLinks(response[this.pageType]['link_tags']);
            }
            if(response[this.pageType]['json_ld']){
              this.seoService.createJsonLd(response[this.pageType]['json_ld']);
            }
            this.dataService.updateProductId(this.product.productId);
            this.dataService.updateDiscountCode(this.product.discountCode);
          });
      }
    });

  }

  getExpiryDate(dt) {
    let monthStr = ((dt.getMonth()+1>=10)?dt.getMonth()+1:'0'+(dt.getMonth()+1));
		let dateStr = ((dt.getDate()- 1>=10)?dt.getDate():'0'+dt.getDate());
    const expDate = dt.getFullYear()+"-"+monthStr+"-"+dateStr+"T"+ "11" +":"+"59"+":"+"00";
    this.eventSubsribe = this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event("accessed site");
        const eventName = 'Viewed Deal Page';
        const VDP_properties = createproperties_nonpropagation(eventName);
        if(expDate) {
          VDP_properties["VDP_Date"] = expDate;
            push_event_properties(eventName,VDP_properties);
        }
      }
    });
    return expDate;
  }

  convertToObj(arr) {
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
      var split = arr[i].split(':');
      obj[split[0].trim()] = +split[1].trim();
    }
    return obj;
  }

  ngOnDestroy(): void {
    this.eventSubsribe.unsubscribe();
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
