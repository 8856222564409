import { SEOService } from './../../../Services/seo.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from './../../../Services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClientService } from './../../../Services/http-client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

declare const push_site_event;
declare const push_utm_event_nonpropagation_and_redirect;

@Component({
  selector: 'app-custom-content',
  templateUrl: './custom-content.component.html',
  styleUrls: ['./custom-content.component.css'],
})
export class CustomContentComponent implements OnInit, OnDestroy {
  urlname;
  redirectUrl;
  pushEvent = false;
  cdn_url = environment.cdn_url;
  eventName;
  link_tags;
  meta_tags;

  fileName = window.location.pathname.split('/').pop();

  constructor(
    private httpService: HttpClientService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private metaService: Meta,
    private titleService: Title,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['name']) {
        this.urlname = params['name'].toLowerCase();
      }
    });
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        this.dataService.getJsonVersion.subscribe((version) => {
          if (version > 0) {
            this.httpService
              .getJson(this.fileName + '.json?version=' + version)
              .subscribe((response: any) => {
                if (response['title']) {
                  this.titleService.setTitle(response['title']);
                }
                if (response['meta_tags']) {
                  this.meta_tags = response['meta_tags'];
                  this.metaService.addTags(response['meta_tags']);
                }
                if(response['link_tags']){
                  this.link_tags= response['link_tags'];
                  this.seoService.createMultiLinks(response['link_tags']);
                }
                if(response['json_ld']){
                  this.seoService.createJsonLd(response['json_ld']);
                }
                if (!this.urlname){
                  let keys = Object.keys(response);
                  this.urlname = keys[keys.length-1];
                }
                if(response['eventName']){
                  this.eventName = response['eventName'];
                }
                if (response[this.urlname]) {
                  this.redirectUrl = response[this.urlname];
                  this.pushEvent = true;
                } else {
                  this.redirectUrl = environment.googleBtnURL + '/404';
                }

                this.pushEvents();
              });
          }
        });
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
  readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  pushEvents() {
    if (this.pushEvent) {
      let event_properties = [];
      event_properties['redirect_variable'] = this.urlname;
      push_site_event('accessed site');
      if (this.eventName) {
        push_utm_event_nonpropagation_and_redirect(this.eventName, this.redirectUrl);
      }
    }else{
      window.location.href = this.redirectUrl;
    }
  }
}
