<app-christmas-hero-section [barGraphData]="barGraphData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-christmas-hero-section>
<app-christmas-limited-period-offer [couponsLeftBeforeDealStart]="couponsLeftBeforeDealStart" [limitedPeriodOfferData]="limitedPeriodOfferData" [dealCounters]="dealCounters" [product]="product"></app-christmas-limited-period-offer>
<app-christmas-success-matrix [successMetricsData]="successMetricsData"></app-christmas-success-matrix>
<app-top-three-reasons [topThreeReasonsData]="topThreeReasonsData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-top-three-reasons>
<app-christmas-most-popular-and-trusted-prep-course [popularTrustedPrepCourseData]="popularTrustedPrepCourseData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-christmas-most-popular-and-trusted-prep-course>
<app-christmas-personalized-improvement-plan [personalizedImprovementPlanData]="personalizedImprovementPlanData"></app-christmas-personalized-improvement-plan>
<app-christmas-reach-your-goal [reachYourGoalData]="reachYourGoalData"></app-christmas-reach-your-goal>

<app-christmas-coupon-details [dealCounters]="dealCounters" [couponDetailsData]="couponDetailsData"  [product]="product"></app-christmas-coupon-details>
<app-christmas-gmat-journey [gmatJourneyData]="gmatJourneyData" [originalPrice]="product['originalPrice']" [discountedPrice]="product['discountedPrice']" [productId]="product['productId']" [discountCode]="product['discountCode']"></app-christmas-gmat-journey>
<app-common-footer-gmat-prep-deal *ngIf="jsonReceived"></app-common-footer-gmat-prep-deal>
