<div id="loginErrorMsg" class="login_error_msg">{{reg_error_message}}</div>
<div class="form_outer_container">
  <div class="reg_form">
    <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <div class="form-group">
        <div class="input_field_label">Name*</div>
        <div *ngIf="f.name.errors" class="invalid-feedback">
          <div class="reg_form_error_msg red_reg_form_error_msg" id="name_error_message"
            *ngIf="alert_submitted && f.name.errors.required">
            Name is Required
          </div>
        </div>
        <input formControlName="name" [ngClass]="{ 'is-invalid': alert_submitted && f.name.errors }"
          type="text" class="form-control reqField" id="name" name="name" placeholder="Name" value="">
      </div>
      <div class="form-group">
        <div class="input_field_label">Email*</div>
        <div *ngIf="f.username.errors" class="invalid-feedback">
          <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message" *ngIf="alert_submitted && f.username.errors.required">
            Email is Required
          </div>
          <div class="reg_form_error_msg red_reg_form_error_msg" id="email_error_message" *ngIf="alert_submitted && f.username.errors.pattern">
            Enter a valid Email Address
          </div>
        </div>
        <input formControlName="username"
          [ngClass]="{ 'is-invalid': alert_submitted && f.username.errors }" type="text"
          class="form-control reqField" id="emailval" name="username" placeholder="Email" value="">
      </div>
      <div class="form-group">
        <div class="input_field_label">Password*</div>
        <div *ngIf="f.password.errors" class="invalid-feedback">
          <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message"
            *ngIf="alert_submitted && f.password.errors.required">
            Password is Required
          </div>
        </div>
        <input formControlName="password" [attr.type]="show_password?'text':'password'"
          [ngClass]="{ 'is-invalid': alert_submitted && f.password.errors }" type="password"
          class="form-control reqField" id="pass" name="password" placeholder="min. 8 characters"
          value="">
          <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/show_password_cart_login.png'"
        class="field-icon toggle-password" (click)="show_password = !show_password;"
        [ngClass]="{'display_ele': !show_password}" />
      <img toggle="#loginPass" [src]="cdn_url+'/assets/icons/hide_password_cart_login.png'"
        class="field-icon toggle-password" (click)="show_password = !show_password;"
        [ngClass]="{'display_ele': show_password}" />
        <div class="checkbox reg_tnc">
          <label>
            <input [ngClass]="{ 'is-invalid': alert_submitted && tnc_error }"
              type="checkbox" class="reg_check" id="input-check" (change)="tncChecked($event)"><label class="square_label"
              for="input-check">
            </label>&nbsp; I accept
            <a class="tnc" target="_blank" routerLink="/terms-and-conditions">Terms &amp;
              Conditions</a>
              <div *ngIf="alert_submitted && tnc_error" class="invalid-feedback">
                <div class="reg_form_error_msg red_reg_form_error_msg" id="pass_error_message">
                  Please accept the terms and conditions
                </div>
              </div>
          </label>
        </div>
      </div>
      <div class="form-group form_sub_btn">
        <button *ngIf="!submitted" type="submit" id="submitmyformRegister"
          class="btn btn-default reg_btn_form register_submit sign_up_submit_button_login">Buy Now</button>
        <button *ngIf="submitted" type="submit" id="submitmyformgif"
          class="btn btn-default reg_btn_form register_submit"><img style="width:40px;"
            [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></button>
      </div>
    </form>
    <div class="form_or">
      <div class="form_or_text form_register_or_text">or Sign up with Google</div>
      <div class="form_or_line form_register_or_line"></div>
    </div>
    <div class="form_google_btn">
      <div class="form_google_btn form_google_btn_register">
        <app-google-signin-btn [submit_button_class]="'sign_up_submit_button_login'" *ngIf="!submitted_google" text="signup_with" (onLoggedIn)="googleSignInCallback($event)"></app-google-signin-btn>
        <!-- <a *ngIf="!submitted_google" (click)="google_signup()" id="submitmyform"
          class="btn btn-default google_lgn_btn_form">
          <span class="fa fa-google reg_google_plus" aria-hidden="true"></span> &nbsp;&nbsp;Sign up
          with
          Google</a> -->
        <a *ngIf="submitted_google" id="submitmyformgif"
          class="btn btn-default google_lgn_btn_form"><img style="width:40px;"
            [src]="cdn_url+'/assets/icons/Loading_animation.gif'"></a>
      </div>
    </div>
  </div>
</div>
