import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-buy-now',
  templateUrl: './buy-now.component.html',
  styleUrls: ['./buy-now.component.css']
})
export class BuyNowComponent implements OnInit {

  cdn_url = environment.cdn_url;

  @Input() paddingBottom = '80px';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToPricing(){
    this.router.navigateByUrl('pricing');
  }
}
