<div class="world_class_support_section" *ngIf="world_class_support_data">
  <div class="page_container">
    <div class="heading_container" data-aos="slide-up">
      <div class="logo_container">
        <img class="lozad" [src]="world_class_support_data['header']['image_url']">
      </div>
      <div class="heading">{{world_class_support_data['header']['first_line']}}</div>
    </div>
    <div class="support_block_container">
      <ng-container *ngFor="let card of world_class_support_data['cards']">
        <app-world-class-support-card [card]="card"></app-world-class-support-card>
      </ng-container>
    </div>
  </div>
</div>
