import { DataService } from './../Services/data.service';
import { CommonService } from './../Services/common.service';
import { HttpClientService } from './../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit, Output, EventEmitter, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalService } from '../Services/modal.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { googleSignInResponse } from '../common/google-signin-btn/model/google-signin-reponse';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from '../Services/login.service';

declare const getUTMParametersFromCookies: any;
declare const push_event_properties: any;
declare const push_events_to_lambda_SEG: any;
declare const push_km_event_login_propagation_redirect: any;
declare const push_coreg_login_event_redirect: any;
declare const push_km_event_coreg_registration_propagation_redirect: any;
declare const pushEventsToLamda: any;
declare var eventsArray: any;
declare const push_eventto_segment: any;
declare const multipleEmailTest: any;
declare const push_km_event_google_registration_propagation_redirect: any;
declare const getCookie: any;
declare const event_push: any;
declare const identication: any;
declare const google:any;
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  animations: [
    // Define animations for the ROUTABLE VIEW itself, which has a HOST BINDING for
    // this animation trigger.
    trigger('modalOneView', [
      state(
        'open',
        style({
          top: '20px',
        })
      ),
      state(
        'close',
        style({
          top: '-200px',
        })
      ),
      transition('* <=> *', [animate('300ms ease-in')]),
    ]),
  ],
})
export class SigninComponent implements OnInit, OnDestroy {
  show_password: boolean = false;
  registrationModeSite = environment.registrationModeSite;
  googleBtnURL = environment.googleBtnURL;
  loginURL = window.location.href;
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  publicIP = '';
  loginForm!: UntypedFormGroup;
  submitted = false;
  alert_submitted:any[] = [];
  google_submitted = false;
  returnUrl!: string;
  login_error_msg: string = '';

  cdn_url = environment.cdn_url;
  lmsURL = environment.bz_app_url;
  id_token_google: any;
  username_google: any;

  username;

  bzUser: any;

  display$!: Observable<string>;

  state = 'close';
  redirectURLInput = '';
  mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  redirect!:string;
  destroy = new Subject();
  @Output() closeHeader = new EventEmitter<String>();

  constructor(
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpClientService: HttpClientService,
    private commonService: CommonService,
    private dataService: DataService,
    private loginService: LoginService
  ) {}
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe(params=> {
      this.redirect = params['redirect'];
      this.loginService.redirect = params['redirect'];
    });
    this.display$ = this.modalService.watch();
    this.display$.pipe(takeUntil(this.destroy)).subscribe((item) => {
      this.state = item;
    });
    this.dataService.getResetPasswordMail.pipe(takeUntil(this.destroy)).subscribe((mail) => {
      this.username = mail;
    });
    this.loginForm = this.formBuilder.group({
      username: [this.username, Validators.compose([Validators.required,Validators.pattern(this.mailPattern)])],
      password: ['', Validators.required],
    });
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params['redirectURL']) {
        this.redirectURLInput = params['redirectURL'];
      }
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.getUsersIp();
  }


  forgot_password() {
    this.closeHeader.emit('closed');
    this.close(true);
    this.router.navigateByUrl('forgot-password');
  }
  googleLoginClick() {
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // this.dataService.getEventScriptStatus.subscribe((status) => {
    //   if (status) {
    //     auth2.signIn().then(() => {
    //       this.googleSignInCallback();
    //     });
    //   }
    // });
  }

  googleSignInCallback = (user:googleSignInResponse) => {
    if (user) {
      this.google_submitted = true;
      this.username_google = user.email;
      this.id_token_google = user.idToken;
      this.isBlitzkriegUser(user.email);
    } else {
      this.login_error_msg = 'There is a problem with google sign in';
    }
  }
  googleSignIn(id_token, username) {
    if (this.bzUser) {
      this.googleLoginToBlitzkrieg(id_token, username, false);
    } else {
      if (this.registrationModeSite == 'blitzkrieg') {
        this.googleLoginToBlitzkrieg(id_token, username, true);
      } else if(!!this.redirect){
        this.pushEventsToLamdaAndRedirect(this.redirect);
      }else{
        this.router.navigateByUrl('home');
      }
    }
  }

  googleLoginToBlitzkrieg(id_token, username, registerUserFlag) {
    multipleEmailTest(username);
    this.httpClientService.googleLogin(id_token).subscribe(
      (data) => {
        // this.setEssentialCookies(username);
        if (registerUserFlag) {
          push_km_event_google_registration_propagation_redirect(
            'completed registration',
            username
          );
        }
        this.saveUTMParemeters(username);
        this.fireLoggedInFromBrowserEvent(username);
        this.completeLoginAndRedirect(username);
      },
      (error) => {
        this.login_error_msg = 'Server error : Please contact support';
        this.google_submitted = false;
      }
    );
  }
  isBlitzkriegUser(username) {
    var response;
    this.httpClientService.isBlitzkriegUser(username).subscribe(
      (data) => {
        if (data) {
          this.bzUser = true;
        } else {
          this.bzUser = false;
        }
        this.googleSignIn(this.id_token_google, this.username_google);
      },
      (error) => {
        var prop = { status: error.error.status, message: error.error.message };
        push_eventto_segment('error accessing BZ', prop);
      }
    );
    return response;
  }
  get f() {
    return this.loginForm.controls;
  }

  close(flag) {
    if (flag) {
      this.modalService.close();
      document.getElementById('body')?.classList.remove('modal-open');
    }
  }

  onKeyUpEmail(key) {
    this.alert_submitted[key] = true;
  }
  onFormSubmit() {
    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        if (!this.submitted) {
          this.alert_submitted['username'] = true;
          this.alert_submitted['password'] = true;
          if (this.loginForm.invalid) {
            return;
          }
          this.submitted = true;
          this.httpClientService
            .login(this.f['username'].value, this.f['password'].value)
            .subscribe(
              (data) => {
                // this.setEssentialCookies(this.f.username.value);
                this.saveUTMParemeters(this.f['username'].value);
                this.fireLoggedInFromBrowserEvent(this.f['username'].value);
                this.completeLoginAndRedirect(this.f['username'].value);
              },
              (error) => {
                this.login_error_msg = 'Email and Password not valid';
                this.submitted = false;
                event_push('Failed Login',this.f['username'].value);
              }
            );
        }
      }
    });
  }

  getUsersIp() {
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        this.publicIP = ip['ip'];
      });
  }

  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }

  fireLoggedInFromBrowserEvent(username) {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.publicIP,
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
  //  properties['username'] = username;
  //  properties['id'] = username;
  //  properties['Email'] = username;
  //  push_event_properties(eventName, properties);
    push_events_to_lambda_SEG(eventName, properties,username);
  }

  getBasicUserInfo(username) {
    this.httpClientService.getBasicUserInfo().subscribe(
      (data) => {
        if (data['firstLogin']) {
          if (
            data['coregPartner'] == 'GMATC_COREG' ||
            data['coregPartner'] == 'GMAT_PREPNOW'
          ) {
            push_coreg_login_event_redirect(
              'Completed Coreg Login',
              data['coregPartner'],
              username
            );
            push_km_event_coreg_registration_propagation_redirect(
              'completed registration',
              username,
              data['coregPartner']
            );
          }
        }
        if (data['freeTrial']) {
          this.freeTrialUser = true;
        }
        this.redirectAfterInfo(username);
      },
      (error) => {
        this.redirectAfterInfo(username);
      }
    );
  }

  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }
  // setEssentialCookies(username){
  //   var d = new Date();
  //   d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
  //   var domain = 'domain=' + window.location.hostname;
  //   var expires = 'expires=' + d.toUTCString();
  //   document.cookie = "km_ni="+username +';' + expires + ';' + domain + ';path=/';
  //   document.cookie = "km_ai="+username +';' + expires + ';' + domain + ';path=/';
  //   document.cookie = "ajs_user_id="+username +';' + expires + ';' + domain + ';path=/';
  //   document.cookie = "_cioid="+username +';' + expires + ';' + domain + ';path=/';
  // }

  completeLoginAndRedirect(username) {
    this.logoutMoodle();
    var loginCount = 1;
    push_km_event_login_propagation_redirect('completed login', username);
    this.getBasicUserInfo(username);
  }

  redirectAfterInfo(username) {
    console.log("IN SIGNIN:::::::REDIRECT AFTER INFOOOOOOOOOOOOOOOOOOOOOOO");
    var fname = '';
    var lname = '';
    var loginTime = new Date().getTime();
    var cookieValues =
      fname +
      '|' +
      lname +
      '|' +
      username +
      '|' +
      loginTime +
      '|' +
      this.freeTrialUser;
    this.setUserCookie(this.userLoginCookie, cookieValues, 30);
    var requiredURL = window.location.hostname + '/login';
    if (this.loginURL.indexOf(requiredURL) !== -1) {
      if (
        this.getParameterByName('q', this.loginURL) != null &&
        this.getParameterByName('q', this.loginURL)?.indexOf('custom/cart') !==
          -1
      ) {
        this.redirectToCartPage();
      } else {
        this.defaultNavigate(this.freeTrialUser);
      }
    } else {
      this.defaultNavigate(this.freeTrialUser);
    }
  }

  redirectToCartPage() {
    var cartParams = this.getParameterByName('q', this.loginURL);
    var coupon = this.getParameterByName('coupon', this.loginURL);
    if (coupon == '') {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon'
      );
    } else {
      this.pushEventsToLamdaAndRedirect(
        '/secure/cart/?q=' + cartParams + '&coupon=' + coupon
      );
    }
  }
  defaultNavigate(userAccess) {
    if (userAccess) {
      this.dataService.getFromRegisterPage.subscribe((status) => {
        if (status) {
          this.pushEventsToLamdaAndRedirect(
            environment.googleBtnURL + '/refresh-course-enrollment?target=page/free_trial'
          );
        }else if(!!this.redirect){
          this.pushEventsToLamdaAndRedirect(this.redirect);
        } else {
          this.pushEventsToLamdaAndRedirect(
            this.lmsURL + '/secure-lms/page/free_trial'
          );
        }
      });

    } else if(!!this.redirect){
      this.pushEventsToLamdaAndRedirect(this.redirect);
    } else {
      this.pushEventsToLamdaAndRedirect(this.lmsURL + '/secure-lms/dashboard');
    }
  }

  pushEventsToLamdaAndRedirect(redirectURL) {
    var targetURL = this.getUrlParameter('redirectURL');

    if (targetURL != null && targetURL != undefined && targetURL != '') {
      redirectURL = targetURL;
    }
    if (
      this.redirectURLInput != null &&
      this.redirectURLInput != undefined &&
      this.redirectURLInput != ''
    ) {
      redirectURL = this.redirectURLInput;
    }
    pushEventsToLamda(redirectURL, eventsArray);
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain=' +
      window.location.hostname +
      ';path=/';
  }
}
