<div class="section section_7 background_config text_center section_pad lozad" *ngIf="ai_maximize_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/section_8_bg.png)'}">
  <div class="page_container">
    <p class="page_sub_head bold_font" data-aos="slide-up">
      {{ai_maximize_data['header']['first_line']}}
    </p>
    <p class="page_head section_page_head_gap extra_bold_font" data-aos="slide-up">
      {{ai_maximize_data['header']['second_line']}}
    </p>
    <p class="page_sub_head page_sub_head_light_color" data-aos="slide-up">
      {{ai_maximize_data['header']['third_line']}}
    </p>
    <div class="ai_info_section_container">
      <ng-container *ngFor="let slide of ai_maximize_data['slides']">
        <div class="ai_info_section" data-aos="slide-up">
          <div class="ai_info_detail">
            <div class="ai_info_head bold_font">
              <span>{{slide['slide_head']}}</span>
            </div>
            <div class="ai_info_content text_left">
              {{slide['slide_text']}}
            </div>
          </div>
          <div class="ai_info_video">
            <div class="iframe_video_wrapper">
              <app-iframe [url]="slide['slide_video_url']" [class]="'psp_wistia_video lozad wistia_embed'"></app-iframe>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
