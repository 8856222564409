<div class="webinar_details" style="background-color:white;" *ngIf="webinarDetail">
  <div class="page_container text-center">
    <div class="row">
      <div class="col-sm-12 col-md-5">
        <div class="webinar_detail_text"><i class="gg-calendar-dates"></i>Webinar Details</div>
        <div class="webinar_date">{{date | date: 'd'}}<sup>{{nth()}}</sup> {{date | date: 'MMM'}}, {{date | date:
          'yyyy'}}</div>
        <div class="webinar_day"><span class="display_day_name">{{date | date: 'EEEE'}}</span> | <span
            id="display_local_time">{{date | date: 'h:mm a'}} (local time)</span></div>
        <div class="webinar_reserve_div"><button (click)="openModal()" type="button"
            class="btn btn-custom webinar_reserve_button cta_track yellow_reserve" data-section="occurrence3"
            data-toggle="modal" data-target="#startnow">Register Now</button></div>
        <p><span class="button_below_text">Even if you can't make it, Sign Up!<br>
            We'll send you the recording.</span></p>
      </div>
      <div class="col-sm-12 col-md-7 countdown_row timer">
        <div class="countdown">
          <span class="webinar_starts">Webinar Starts in </span>
          <app-clock [DaysTensPlace]='DaysTensPlace' [DaysOnesPlace]='DaysOnesPlace' [HoursTensPlace]='HoursTensPlace'
            [HoursOnesPlace]='HoursOnesPlace' [MinutesTensPlace]='MinutesTensPlace'
            [MinutesOnesPlace]='MinutesOnesPlace' [SecondsTensPlace]='SecondsTensPlace'
            [SecondsOnesPlace]='SecondsOnesPlace'></app-clock>
          <div class="row clock-desc" style="display: flex !important;">
            <div class="col-md-3 clock-desc-text"> DAYS</div>
            <div class="col-md-3 clock-desc-text">HOURS</div>
            <div class="col-md-3 clock-desc-text">MINUTES</div>
            <div class="col-md-3 clock-desc-text">SECONDS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
