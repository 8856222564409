import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF,faLinkedinIn,faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faPlay, faCheck} from '@fortawesome/free-solid-svg-icons';
import { environment } from 'projects/egmat-home/src/environments/environment';

@Component({
  selector: 'app-common-footer-gmat-prep',
  templateUrl: './common-footer-gmat-prep.component.html',
  styleUrls: ['./common-footer-gmat-prep.component.css']
})
export class CommonFooterGmatPrepComponent {
  @Input() type:'gce'|'gfe' = 'gce';
  @Output() openMdalFt = new EventEmitter();
  @Input() json_data;
  cdn_url = environment.cdn_url;
  constructor(library: FaIconLibrary){
    library.addIcons(faFacebookF, faLinkedinIn,faXTwitter, faPlay, faCheck);
  }

  openModal(btn_name){
    this.openMdalFt.emit(btn_name);
    sessionStorage.setItem('ctaName',btn_name);
  }
}
