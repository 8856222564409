import { ModalService } from './../../Services/modal.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mba-success-story',
  templateUrl: './mba-success-story.component.html',
  styleUrls: ['./mba-success-story.component.css']
})
export class MbaSuccessStoryComponent implements OnInit {

  cdn_url = environment.cdn_url;
  active_index = 0;
  width = window.screen.width;
  sec1_tab_background_name = 'sec1_tab_background';

  openVideoPopup(video_url) {
    this.modalService.open_video(video_url);
  }

  constructor(private modalService: ModalService) {
    if(this.width < 900){
      this.sec1_tab_background_name = 'sec1_mobile_background';
    }
  }

  @Input() mba_success_story_data;

  ngOnInit(): void {
  }
  redirectToUnbounce(){
    window.open(this.mba_success_story_data['tabs'][this.active_index]['view_more_url'])
  }
  selectSlide(flag){
    if(flag && this.active_index < this.mba_success_story_data['tabs'].length-1){
      this.active_index += 1;
    }
    if(!flag && this.active_index > 0){
      this.active_index -= 1;
    }
  }
}
