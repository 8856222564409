import { CommonService } from './common.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { HttpClientService } from './http-client.service';
import { Injectable } from '@angular/core';
declare const identication: any;
declare const getUTMParametersFromCookies: any;
declare const getCookie: any;
declare const push_km_event_login_propagation_redirect: any;
declare const push_event_properties: any;
declare const pushEventsToLamda: any;
declare var eventsArray: any;

@Injectable()
export class LoginService {
  userLoginCookie = 'userLoginCookie';
  freeTrialUser = false;
  lmsURL = environment.bz_app_url;
  redirect!:string;
  constructor(
    private httpClientService: HttpClientService,
    private commonService: CommonService
  ) {}

  checkCookieBasedLogin(
    sendUserId = false,
    fromRegisterPage = false,
    fire_login_event = true
  ) {
    const userCookieFlag = this.checkUserCookie(this.userLoginCookie);
    if (userCookieFlag) {
      var userCookieVal = this.getUserCookie(this.userLoginCookie);
      var userInfo = userCookieVal.split('|');
      if (userInfo.length > 0) {
        if (userInfo[2]) {
          var userId = userInfo[2];
          this.saveUTMParemeters(userId);
          this.freeTrialUser = userInfo[4] == 'true' ? true : false;
          identication(userId);
          if (fire_login_event) {
            push_km_event_login_propagation_redirect('completed login', userId);
          }
          this.fireLoggedInFromBrowserEvent();
          this.setUserCookie(this.userLoginCookie, userCookieVal, 30);
          this.logoutMoodle();
          if (sendUserId) {
            return userId;
          }
          if (this.freeTrialUser) {
            if (fromRegisterPage) {
              this.pushEventsToLamdaAndRedirect(
                environment.googleBtnURL +
                  '/refresh-course-enrollment?target=page/free_trial'
              );
            } else if(!!this.redirect){
              this.pushEventsToLamdaAndRedirect(this.redirect);
            }  else {
              this.pushEventsToLamdaAndRedirect(
                this.lmsURL + '/secure-lms/page/free_trial'
              );
            }
          } else if(!!this.redirect){
            this.pushEventsToLamdaAndRedirect(this.redirect);
          } else {
            this.pushEventsToLamdaAndRedirect(
              this.lmsURL + '/secure-lms/dashboard'
            );
          }
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  pushEventsToLamdaAndRedirect(redirectURL) {
    var targetURL = this.getUrlParameter('redirectURL');

    if (targetURL != null && targetURL != undefined && targetURL != '') {
      redirectURL = targetURL;
    }
    pushEventsToLamda(redirectURL, eventsArray);
  }
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  logoutMoodle() {
    document.cookie =
      'MoodleSession=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC; path=/secure/';
    document.cookie =
      'escholarauth=delete; expires=Thu, 18 Dec 1971 12:00:00 UTC;domain=' +
      window.location.hostname +
      ';path=/';
  }
  setUserCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var domain = 'domain=' + window.location.hostname;
    var expires = 'expires=' + d.toUTCString();
    document.cookie =
      cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/';
  }
  fireLoggedInFromBrowserEvent() {
    var browser = this.commonService.get_browser();

    let eventName = 'Logged_in_from_browser';
    let browserVersion = browser.version.split(' ')[0];
    let properties = {
      Browser_type: browser.name,
      os_name: browser.os,
      Browser_string: browser.browser_string,
      Browser_version: browserVersion.split(' ')[0],
      Public_Ip: this.getUsersIp(),
    };

    if (browserVersion.indexOf('Unknown') > -1) {
      properties['Browser_major_version'] = browserVersion;
      properties['Browser_minor_version'] = browserVersion;
    } else {
      properties['Browser_major_version'] = browserVersion.split('.')[0];
      properties['Browser_minor_version'] = browserVersion.split('.')[1];
    }
    push_event_properties(eventName, properties);
  }
  getUsersIp() {
    let ip = '';
    fetch('https://jsonip.com', { mode: 'cors' })
      .then((resp) => resp.json())
      .then((ip) => {
        ip = ip['ip'];
      });
    return ip;
  }
  saveUTMParemeters(username) {
    var utmParameters = getUTMParametersFromCookies();
    utmParameters['username'] = username;
    this.httpClientService.saveUTMParemeters(utmParameters).subscribe();
  }
  getUserCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  checkUserCookie(cookieName) {
    let user = getCookie(cookieName);
    if (user != '' && user != null) {
      return true;
    } else {
      return false;
    }
  }
}
