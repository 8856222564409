import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from '../../Services/data.service';
import { HttpClientService } from '../../Services/http-client.service';
import { LoginService } from './../../Services/login.service';

declare const geoplugin_countryCode: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  cdn_url = environment.cdn_url;
  productId = '';
  productData: any;
  paymentScreenData: Object = {};
  title = 'Shopping cart | e-GMAT';
  isSignedIn: boolean = false;
  signedInUser: string = '';

  isDataLoading: boolean = true;
  discountCode: string = '';

  paymentIntentCreationError: boolean = false;
  paymentDone: boolean = false;
  countryCode: string = 'us';
  currency:string = 'USD';
  disableChangePlan:boolean = false;
  zeroAmountPurchase:boolean = false;
  interval;
  timeLeft: number = 5;
  paymentSuccess:boolean = false;
  paymentFailed:boolean = false;
  grantAccessClicked:boolean = false;
  productType: string = '';
  cartLoaded: boolean = false;
  constructor(
    private httpClientService: HttpClientService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private loginService: LoginService
  ) {
    if((this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.productType)){
      this.productType = this.router.getCurrentNavigation().extras.state.productType;
    }
  }

  ngOnInit(): void {
    this.gotoTop();
    this.includeStripeFile();
    document.dispatchEvent(new Event('load_home_page'));
    this.route.params.subscribe((param) => {
      this.productId = param['productId'];
    });
    this.route.queryParams.subscribe((param) => {
      this.discountCode = param['discountCode'] != undefined ? param['discountCode'] : '';
    });
    this.titleService.setTitle(this.title);

    this.getCountryCode();
  }


  getCountryCode(){
        this.fetchProductInfoFromJSON();

        //working country fetch code from maxmind
/*    var g = document.createElement('script');
    g.src = 'https://geoip-js.com/js/apis/geoip2/v2.1/geoip2.js';
    g.setAttribute('async', '');
    document.getElementsByTagName('head')[0].appendChild(g);
    g.onload = () => {
          var then = this;
          var geoipcheck = (function () {
            var onSuccess = function (geoipResponse) {
              console.log("GEO IP RESPONSE:::::");
              console.log(geoipResponse.country.iso_code);
              if(geoipResponse.country.iso_code == "IN"){
                  then.countryCode = 'in';
              }
              then.fetchProductInfoFromJSON();
            };
            var onError = function (error) {
              console.log("GEO IP ERROR!!!!!");
              console.log(error);
              then.fetchProductInfoFromJSON();
            };
            return function() {
               if (typeof window["geoip2"] !== 'undefined') {
                  window["geoip2"].country(onSuccess, onError);
               } else {
                  console.log("Error:::::: GEOIP2 IS UNDEFINED");
                  then.fetchProductInfoFromJSON();
               }
            };
          }());
          geoipcheck();

     };
     g.onerror = () => {
          console.log("Error loading - " + g.src); // Error loading
          this.fetchProductInfoFromJSON();
     };*/
  }


  fetchProductInfoFromJSON() {
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        var then = this;
        this.httpClientService
          .getJson('products.json?version=' + version)
          .subscribe((response) => {
            response['pricing_data']['plans'].forEach(function (arrayItem) {
              if (arrayItem['productId'] == then.productId) {
                arrayItem['class'] = 'card_highlight  ml_30';
                arrayItem['cartPage'] = true;
              /*  if (then.discountCode != '') {
                  arrayItem['discountCouponPresent'] = true;
                } else {
                  arrayItem['discountCouponPresent'] = false;
                }*/
                arrayItem['discountCouponApplied'] = false;
                then.productData = arrayItem;
                then.productData['price']['after_discount']['second_line'] = "";
              }
            });
            if(this.productData == undefined){
                this.productData =   response['pricing_data']['plans'][0];
                this.productData['productId'] = this.productId;
                this.productData['discountCouponApplied'] = false;
                this.productData['price']['after_discount']['second_line'] = "";
                this.productData['cartPage'] = true;
                this.productData['class'] = 'card_highlight  ml_30';
            }
            this.fetchProductDetailsFromServer();
          });
      }
    });
  }

  sendUserNameToCart(username) {
    this.signedInUser = username;
    this.paymentScreenData['username'] = username;
    if(!this.zeroAmountPurchase){
      this.createPaymentIntent(this.currency);
    }
    else{
      this.isSignedIn = true;
      this.isDataLoading = false;
    }
  }

  fetchProductDetailsFromServer() {
    let productInfo = {
      packageId: this.productId,
      country: this.countryCode,
      discountCopounCode: this.discountCode
    };
    this.httpClientService
      .post('public/fetch/cart/product-details', productInfo)
      .subscribe(
        (data) => {
          console.log("data from server::::::::");
          console.log(data);

          if(data['priceToPay'] == 0){
            this.zeroAmountPurchase = true;
          }
          this.productData['discountCouponPresent'] = false;
          let packageNameArray:any = data["packageName"].trim().split(" ");
          let firstLine = "";
          for(var i=0; i<packageNameArray.length; i++){
            if(i != packageNameArray.length-1)
              firstLine = firstLine +" "+packageNameArray[i];
          }
          this.productData['card_head']['first_line'] = firstLine;
          this.productData['card_head']['second_line'] = packageNameArray[packageNameArray.length-1];

          if(data["durationInMonth"] == 0){
            this.productData['month']['text'] = " Days";
          }
          else if(data["durationInMonth"] == 1)
              this.productData['month']['text'] = " Month";
          else
              this.productData['month']['text'] = " Months";
        //  this.signedInUser = data['username'];
        //  this.paymentScreenData['username'] = data['username'];
          this.currency = data['currency'];
          this.productData['price']['after_discount']['second_line'] = data['priceToPay'];
          if (data['currency'] != 'USD') {
            this.productData['price']['after_discount']['first_line'] = data['currency'];
            this.productData['price']['price'] = data['currency'] + data['price'];
          } else {
            this.productData['price']['price'] = '$' + data['price'];
          }

          this.productData['month']['count'] = data['durationInMonth'] != 0 ? data['durationInMonth'] : data['durationInDays'];

          if (data['discount'] > 0) {
            this.productData['discountCouponApplied'] = true;
            this.productData['discountCode'] = this.discountCode;
            this.paymentScreenData["discountCode"] = (this.productData['discountCouponApplied'] && this.discountCode != '') ? this.discountCode : null
          }

          console.log('PRODUCT DATA:::::');
          console.log(this.productData);

          //check for login user
          this.dataService.getEventScriptStatus.subscribe((status) => {
            if (status) {
              let userId = this.loginService.checkCookieBasedLogin(true);
              if (userId) {
                this.isSignedIn = true;
                this.sendUserNameToCart(userId);
              } else {
                userId = this.getCookie("loggedInAsUser");
                console.log("LOGGED IN AS USER COOKIE::::"+userId);
                if(userId == ""){
                    this.isDataLoading = false;
                }else{
                  this.isSignedIn = true;
                  this.sendUserNameToCart(userId);
                }

              }
            }
          });
          this.startTimer();
          setTimeout(()=>{this.cartLoaded = true;},1000);
        },
        (e) => {
          console.log("Error occured while fetching product details!!!");
        }
      );
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

  startTimer(){
    console.log("in start timer>>>>>>>>>>>>>>>>>>>>>");
    setTimeout(()=>{
      if(this.isDataLoading == true){
          console.log("in start timer>>>>>>>>>>>>>>>>>>>>>initiate signin");
        this.isDataLoading = false;
        this.isSignedIn = false;
      }

    },5000);
  }

  includeStripeFile() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://js.stripe.com/v3/?advancedFraudSignals=false';
    document.getElementsByTagName('head')[0].appendChild(s);
    s.onload = () => {
      this.dataService.updateStripLoaded = true;
    };
  }

  getSuccessFromPaymentScreen(success) {
    if(success){
      this.paymentDone = true;
    }
    this.disableChangePlan = false;
    this.gotoTop();
  }

  getStartedFromPaymentScreen(value) {
      this.disableChangePlan = true;
  }

  createPaymentIntent(currency: string) {
    let productInfo = {
      productId: this.productId,
      username: this.signedInUser,
      purchaseType: 'PURCHASE',
      discountedPrice:
        this.productData['price']['after_discount']['second_line'],
      currency: currency.toLowerCase(),
      discountCode: (this.productData['discountCouponApplied'] && this.discountCode != '') ? this.discountCode : null
    };
    console.log('Payment intent info:::::');
    console.log(productInfo);
    this.httpClientService.post('create-payment-intent', productInfo).subscribe(
      (data) => {
        console.log('in create payment intent!!!');
        console.log(data);
        if (data['success']) {
          this.paymentScreenData['paymentIntentCS'] = data['secretKey'];
          this.paymentScreenData['username'] = this.signedInUser;
          this.paymentScreenData['price'] = this.productData['price']['after_discount']['first_line'] + this.productData['price']['after_discount']['second_line'];
          this.isSignedIn = true;
          this.isDataLoading = false;
        } else {
          this.paymentIntentCreationError = true;
        }
      },
      (e) => {
        this.paymentIntentCreationError = true;
      }
    );
    this.startTimer();
  }

  gotoTop() {
   window.scroll({
     top: 0,
     left: 0,
     behavior: 'smooth'
   });
 }

 zeroDollarAccess(){
   this.grantAccessClicked = true;
   let paymentInfo = {}
   paymentInfo['username'] = this.signedInUser;
   paymentInfo['cardName'] = this.signedInUser;
   paymentInfo['packageId'] = this.productId;
   paymentInfo['currency'] = this.currency;
   paymentInfo['discountCode'] =  this.discountCode != '' ? this.discountCode : null;
   this.httpClientService.post('grant-zero-amount-access',paymentInfo).subscribe((data)=>{
     console.log("in zero dollar access::::");
     console.log(data);
     if(data["success"]){
           setTimeout(()=>{
             this.paymentSuccess = true;
             this.grantAccessClicked = false;
             this.getSuccessFromPaymentScreen(true);
             this.startRedirectTimer();
           },1000);
     }
     else{
       this.paymentFailed = true;
     }
   });
 }

 startRedirectTimer() {
   this.interval = setInterval(() => {
     if(this.timeLeft > 1) {
       this.timeLeft--;
     } else {
       this.router.navigateByUrl('refresh-course-enrollment?target=page/your_study_plan');
     }
   },1000)
}

}
