import { HttpClientService } from './../../Services/http-client.service';
import { DataService } from '../../Services/data.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, OnInit } from '@angular/core';

declare const push_utm_event_propagation: any;
declare const submit_contact: any;

@Component({
  selector: 'app-contact-us-chat',
  templateUrl: './contact-us-chat.component.html',
  styleUrls: ['./contact-us-chat.component.css'],
})
export class ContactUsChatComponent implements OnInit {
  cdn_url = environment.cdn_url;

  is_chat_open = false;
  chat_window_class = 'egmat_chat_window_in';
  chat_focused = false;

  error_submit_query_visible = false;

  textareaEnambled = false;

  query = '';

  step = '';

  query_2 = '';

  editText = false;

  textbox_second_chat_disabled = true;

  email_field_input_disabled = null;

  invalid_query_warning = '';

  email = '';

  allowQuerySubmitCookieName = 'allowSubmitQuery';

  query_sender_email = '';

  form_submitted_success = false;

  constructor(
    private dataService: DataService,
    private httpService: HttpClientService
  ) {}

  ngOnInit(): void {}

  chat_open() {
    if (!this.is_chat_open) {
      this.dataService.getEventScriptStatus.subscribe((status) => {
        if (status) {
          push_utm_event_propagation('begin contact');
        }
      });
      this.is_chat_open = true;
    } else {
      this.close();
    }
  }

  focusOut() {
    if (this.query == '') {
      this.chat_focused = false;
    }
  }
  keyUpTextArea() {
    if (this.error_submit_query_visible) {
      this.error_submit_query_visible = false;
    }
    if (this.query != '' && !this.textareaEnambled) {
      this.textareaEnambled = true;
    } else if (this.query == '') {
      this.textareaEnambled = false;
    }
  }
  greytextAreaSubmit(event) {
    event.preventDefault();
    if (this.query.length > 15) {
      this.error_submit_query_visible = false;
      this.step = 'greyform_left_out';
      this.query_2 = this.query;
    } else {
      this.error_submit_query_visible = true;
      this.query_2 = '';
    }
  }
  edit() {
    this.editText = true;
    this.email_field_input_disabled = true;
    this.textbox_second_chat_disabled = null;
  }

  close() {
    this.is_chat_open = false;
    this.form_submitted_success = false;
    this.chat_focused = false;
    this.step = '';
    this.invalid_query_warning = '';
    this.query_2 = '';
    this.query = '';
    this.email = '';
    this.textareaEnambled = false;
  }

  form_uper_sbmt_final_click() {
    if (this.query_2 != '') {
      this.editText = false;
      this.textbox_second_chat_disabled = true;
      this.email_field_input_disabled = null;
      if (this.query_2.length > 15) {
        this.invalid_query_warning = '';
      }
    }
  }
  bluetextAreaSubmit(event) {
    if (this.email == '') {
      this.invalid_query_warning = '*Please enter your email';
      event.preventDefault();
    } else {
      if (this.query_2.length > 15) {
        var allowSubmitQueryCookieValue = this.readCookie(
          this.allowQuerySubmitCookieName
        );
        if (
          allowSubmitQueryCookieValue == '' ||
          allowSubmitQueryCookieValue == null ||
          allowSubmitQueryCookieValue == undefined
        ) {
          var emailResult = this.checkQueryEmail(this.email);
          if (emailResult && this.checkQueryEmailDomain(this.email)) {
            this.dataService.getEventScriptStatus.subscribe((status) => {
              if (status) {
                submit_contact(this.email);
              }
            });
            event.preventDefault();
            this.httpService.sendMail(this.email, this.query_2).subscribe(
              (response) => {
                this.form_submitted_success = true;
              },
              (error) => {
                console.log('error=================');
                console.log(error);
              }
            );
            this.setCookie(this.allowQuerySubmitCookieName, true, 0.00138889);
            this.query_sender_email = this.email;
            this.form_submitted_success = true;
          } else {
            this.invalid_query_warning = '*Please enter a valid email';
            event.preventDefault();
          }
        } else {
          this.invalid_query_warning = 'You already submitted a query';
          event.preventDefault();
        }
      } else {
        this.invalid_query_warning =
          'Query must be more than 15 characters long.';
        event.preventDefault();
      }
    }
  }
  checkQueryEmailDomain(email) {
    let splitEmail = email.split('@');
    if (
      splitEmail[1].toLowerCase() === 'egmat.com' ||
      splitEmail[1].toLowerCase() === 'e-gmat.com'
    )
      return false;
    return true;
  }
  readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setCookie(name, value, days) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
  checkQueryEmail(email) {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(email);
  }
}
