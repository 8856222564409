import { ActivatedRoute } from '@angular/router';
import { DataService } from './../../Services/data.service';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts-data-comparison',
  templateUrl: './charts-data-comparison.component.html',
  styleUrls: ['./charts-data-comparison.component.css'],
})
export class ChartsDataComparisonComponent implements OnInit {
  tab_type: string = 'monthly';
  score_graph_data: any;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('home.json?version=' + version)
          .subscribe((response) => {
            this.score_graph_data = response['score_graph_data'];
          });
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (
        params['name'] &&
        params['name'] != undefined &&
        params['name'] != ''
      ) {
        switch (params['name']) {
          case 'years':
            this.tab_type = 'yearly';
            break;
          case 'months':
            this.tab_type = 'monthly';
            break;
          case 'weeks':
            this.tab_type = 'weekly';
            break;
          default :
           this.tab_type = params['name'];
           break;
        }
      }
    });
  }
}
