import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-the-speaker',
  templateUrl: './about-the-speaker.component.html',
  styleUrls: ['./about-the-speaker.component.css']
})
export class AboutTheSpeakerComponent implements OnInit {

  @Input() about_speaker_data;

  constructor() { }

  ngOnInit(): void {
  }

}
