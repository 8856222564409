import { SEOService } from './../Services/seo.service';
import { DataService } from './../Services/data.service';
import { HttpClientService } from './../Services/http-client.service';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faq-screen',
  templateUrl: './faq-screen.component.html',
  styleUrls: ['./faq-screen.component.scss'],
})
export class FaqScreenComponent implements OnInit, OnDestroy {
  faqData: any;
  faqDataTemp: any = null;
  headImg: any;
  cdnUrl = environment.cdn_url;
  msgImageUrl = this.cdnUrl + '/assets/images/msg_icon.png';
  mailImageUrl = this.cdnUrl + '/assets/images/mail_icon.png';
  searchImageUrl = this.cdnUrl + '/assets/images/search.png';
  noResultFoundImageUrl =
    this.cdnUrl + '/assets/images/no_result_found_graphic.png';
  searchWord = '';
  searchData = [];
  showSearchResults: boolean = false;
  selectedIndex = 0;
  showAnswer: boolean = false;
  modalData: any;
  relatedTopicsData: any;
  faqDataQuestionIndex: number = 0;
  dataAvailable = false;
  @ViewChild('search') searchElement: ElementRef;
  isValueSearched: boolean = false;
  isInputFeildInFocus: boolean = false;
  answer: any;
  showNewTheme: boolean = false;
  searchVal: string = '';
  link_tags;
  meta_tags;
  constructor(
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}
  ngOnInit(): void {
    this.dataAvailable = true;
    this.activatedRoute.params.subscribe((params) => {
      let appName = params['applicationName'].toLowerCase();
      let questionId = params['questionId'];
      this.dataService.getJsonVersion.subscribe((version) => {
        if (version > 0) {
          this.httpService
            .getJson('faq.json?version=' + version)
            .subscribe((response) => {
              if (response['title']) {
                this.titleService.setTitle(response['title']);
              }
              if (response['meta_tags']) {
                this.meta_tags = response['meta_tags'];
                this.metaService.addTags(response['meta_tags']);
              }
              if(response['link_tags']){
                this.link_tags= response['link_tags'];
                this.seoService.createMultiLinks(response['link_tags']);
              }
              if(response['json_ld']){
                this.seoService.createJsonLd(response['json_ld']);
              }
              this.faqData = response[appName];
              this.faqDataTemp = response[appName];
              if (appName == 'faqs') {
                this.showNewTheme = true;
                this.mailImageUrl = this.cdnUrl + '/assets/images/email_icon_new.png';
              }
              this.headImg = this.cdnUrl + this.faqData['imgHeader'];
              for (let i = 0; i < this.faqData.questionArr.length; i++) {
                for (
                  let j = 0;
                  j < this.faqData.questionArr[i].subQuestions.length;
                  j++
                ) {
                  if (
                    questionId ==
                    this.faqData.questionArr[i].subQuestions[j].questionId
                  ) {
                    this.showAnswerModal(
                      this.faqData.questionArr[i].subQuestions[j],
                      i
                    );
                    break;
                  }
                }
              }
            });
        }
      });
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
  searchValue() {
    this.searchVal = this.searchWord;
    if (!this.searchWord.trim().length) {
      this.searchWord = '';
      this.isValueSearched = true;
      return false;
    }
    this.selectedIndex = 0;
    var search = this.searchWord.toLowerCase();
    var index = 0;
    this.searchData = [];
    this.isValueSearched = true;
    var data = JSON.parse(JSON.stringify(this.faqData));
    for (let i = 0; i < data.questionArr.length; i++) {
      if (data.questionArr[i].categoryQuestion.toLowerCase().includes(search)) {
        this.searchData.push(data.questionArr[i]);
        this.searchData[index].subQuestions = data.questionArr[
          i
        ].subQuestions.filter((obj) =>
          Object.values(obj).some((val) => {
            if (typeof val == 'string') {
              return val.toLowerCase().includes(search);
            } else {
              return false;
            }
          })
        );
        if (this.searchData[index].subQuestions.length == 0) {
          this.searchData[index].subQuestions =
            this.faqData.questionArr[i].subQuestions;
        }
        index = index + 1;
      } else {
        var subQuestionArr = data.questionArr[i].subQuestions.filter((obj) =>
          Object.values(obj).some((val) => {
            if (typeof val == 'string') {
              return val.toLowerCase().includes(search);
            } else {
              return false;
            }
          })
        );
        if (subQuestionArr.length > 0) {
          this.searchData.push(data.questionArr[i]);
          this.searchData[index].subQuestions = subQuestionArr;
          index = index + 1;
        }
      }
    }
    this.showSearchResults = true;
  }

  showAnswerModal(data, index) {
    this.showAnswer = true;
    this.modalData = data;
    this.faqDataQuestionIndex = index;
    this.relatedTopicsData = [];
    this.answer = this.sanitizer.bypassSecurityTrustHtml(this.modalData.answer);
    if (
      this.faqData.questionArr[index].subQuestions.length -
        data.sequenceNumber >=
      3
    ) {
      this.relatedTopicsData.push(
        this.faqData.questionArr[index].subQuestions[data.sequenceNumber]
      );
      this.relatedTopicsData.push(
        this.faqData.questionArr[index].subQuestions[data.sequenceNumber + 1]
      );
      this.relatedTopicsData.push(
        this.faqData.questionArr[index].subQuestions[data.sequenceNumber + 2]
      );
    } else {
      if (
        this.faqData.questionArr[index].subQuestions.length ==
        data.sequenceNumber
      ) {
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[
            this.faqData.questionArr[index].subQuestions.length -
              data.sequenceNumber
          ]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[
            this.faqData.questionArr[index].subQuestions.length -
              data.sequenceNumber +
              1
          ]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[
            this.faqData.questionArr[index].subQuestions.length -
              data.sequenceNumber +
              2
          ]
        );
      }
      if (
        this.faqData.questionArr[index].subQuestions.length -
          data.sequenceNumber ==
        2
      ) {
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[data.sequenceNumber]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[data.sequenceNumber + 1]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[0]
        );
      }
      if (
        this.faqData.questionArr[index].subQuestions.length -
          data.sequenceNumber ==
        1
      ) {
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[data.sequenceNumber]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[0]
        );
        this.relatedTopicsData.push(
          this.faqData.questionArr[index].subQuestions[1]
        );
      }
    }
    window.parent.scroll(0, 0);
  }
  closeAnswerModal() {
    this.showAnswer = false;
  }

  clearSearch() {
    this.searchWord = '';
    this.searchElement.nativeElement.focus();
  }

  showSearchButtons() {
    this.isInputFeildInFocus = true;
    if (this.searchWord.length > 0) {
      this.isValueSearched = false;
    }
  }
  mail() {
    window.location.href = 'mailto:support@e-gmat.com';
  }
  scroll(id) {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView(true);
      window.scrollBy(0, 0);
    }, 10);
    // Adjust scrolling with a negative value here
  }

  showHiddenElems(index) {
    this.faqData.questionArr[index].visible = true;
  }

  hideElems(index) {
    this.faqData.questionArr[index].visible = false;
  }
  toggleAccordian(event, index) {
    var element = event.target;
    this.selectedIndex = index;
    element.classList.toggle('active');
    if (this.searchData[index].isActive) {
      this.searchData[index].isActive = false;
    } else {
      this.searchData[index].isActive = true;
    }
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
