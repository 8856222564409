import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  dayArr = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thrusday',
    'Friday',
    'Saturday',
  ];
  monthArr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  monthShortArr = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  get_browser() {
    var OSName = 'Unknown';
    if (navigator != undefined && navigator != null) {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|Version|safari|firefox|msie|trident(?=\/))\/?\s*(\d(.*)+)/i
          ) || [];
      if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1)
        OSName = 'Windows 10';
      if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1)
        OSName = 'Windows 8';
      if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1)
        OSName = 'Windows 7';
      if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1)
        OSName = 'Windows Vista';
      if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1)
        OSName = 'Windows XP';
      if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1)
        OSName = 'Windows 2000';
      if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac';
      if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
      if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';
      if (window.navigator.userAgent.indexOf('Android') != -1)
        OSName = 'Android';
      if (window.navigator.userAgent.indexOf('like Mac') != -1) OSName = 'iOS';
      if (navigator.platform === 'MacIntel') OSName = 'iOS';

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '', os: OSName };
      }
      if (M[0] === 'Version') {
        tem = ua.match(/\\Version\/\d(.*)+/);
        if (tem != null) {
          let browser_str = tem[1];
          let ver = browser_str;
          return { name: 'Firefox', version: ver, os: OSName };
        }
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d(.*)+)/);
        if (tem != null) {
          return { name: 'Opera', version: tem[1], os: OSName };
        }
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\bEdg\/?(\d(.*)+)/);
        if (tem != null) {
          return { name: 'Edge', version: tem[1], os: OSName };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      // if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      return {
        name: M[0] == 'Version' ? 'Firefox' : M[0],
        version: M[1],
        browser_string: ua,
        os: OSName,
      };
    } else {
      return {
        name: 'Unknown',
        version: 'Unknown',
        browser_string: 'Unknown',
        os: OSName,
      };
    }
  }
  getElementY(query) {
    return (
      window.pageYOffset +
      document.querySelector(query).getBoundingClientRect().top
    );
  }
  doScrolling(element, duration, extraY = 0) {
    var startingY = window.pageYOffset;
    var elementY = this.getElementY(element);
    var targetY =
      document.body.scrollHeight - elementY < window.innerHeight
        ? document.body.scrollHeight - window.innerHeight
        : elementY;
    var diff = targetY + extraY - startingY;
    var start;
    var easing = function (t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };
    if (!diff) {
      return;
    }
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      var time = timestamp - start;
      var percent = Math.min(time / duration, 1);
      percent = easing(percent);
      window.scrollTo(0, startingY + diff * percent);
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  convertToPst(date) {
    return new Date(date).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });
  }

  nth(date) {
    const d = date.getDate();
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
  getMonthName(date){
    return this.monthShortArr[date.getMonth()];
  }
}
