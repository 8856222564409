<div class="JSB-flip-clock">
  <ul>
    <app-flip-clock *ngIf="DaysTensPlace != undefined" [value]="DaysTensPlace"></app-flip-clock>
    <app-flip-clock *ngIf="DaysOnesPlace != undefined" [value]="DaysOnesPlace"></app-flip-clock>

    <app-clock-label *ngIf="DaysOnesPlace != undefined && DaysTensPlace != undefined"></app-clock-label>
    <app-flip-clock [value]="HoursTensPlace"></app-flip-clock>
    <app-flip-clock [value]="HoursOnesPlace"></app-flip-clock>

    <app-clock-label></app-clock-label>
    <app-flip-clock [value]="MinutesTensPlace"></app-flip-clock>
    <app-flip-clock [value]="MinutesOnesPlace"></app-flip-clock>

    <app-clock-label></app-clock-label>
    <app-flip-clock [value]="SecondsTensPlace"></app-flip-clock>
    <app-flip-clock [value]="SecondsOnesPlace"></app-flip-clock>
  </ul>
</div>
