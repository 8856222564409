import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-founders',
  templateUrl: './about-us-founders.component.html',
  styleUrls: ['./about-us-founders.component.css']
})
export class AboutUsFoundersComponent implements OnInit {

  cdn_url = environment.cdn_url;
  constructor() { }

  @Input() founders_data;
  ngOnInit(): void {
  }

}
