<div class="page_container">
  <div class="error_row">
    <div class="col-sm-8">
      <div class="lost_row">Lost..?</div>
      <div class="lost_sub_text">We will help you to reach the right page.</div>
      <div class="lost_img"><img class=""
          [src]="cdn_url+'/assets/images/lost_error.png'"></div>
    </div>
    <div class="col-sm-4">
      <div class="choose_one_link">Please choose one Link from below:</div>
      <div class="lost_link_box">
        <a class="singl_lost_link" routerLink="/">Go to Home Page</a>
        <a class="singl_lost_link" routerLink="/gmat-course-online">Check our Courses</a>
        <a class="singl_lost_link" routerLink="/admits-scholarships">Read our Success Stories</a>
        <a class="singl_lost_link contact_us_pop_open_btn" (click)="openChat()">Contact Us</a>
      </div>
    </div>
  </div>
</div>
