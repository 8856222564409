<div *ngIf="successMetricsData" class="section section_pad section_success_metrics background_config lozad" [ngStyle]="{'background-image': 'url(' + cdn_url + '/assets/images/success_metrics_bg.png'}">
  <div class="page_container">
    <p class="page_head let_spacing_sub_1 text_center extra_bold_font">
      {{successMetricsData['heading']['firstLine']}} <br /><span class="highlight_text">{{successMetricsData['heading']['secondLine']}}</span>
    </p>
    <div class="metrics_blocks_container">
      <div class="metrics_blocks_wrapper">
        <div class="metric odd_position_metric">
          <div class="metrics_head">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/improve_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="metric_tooltip">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsFirstBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric even_position_metric">
          <div class="metrics_head">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/message_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="metric_tooltip">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsSecondBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric odd_position_metric mt_mob_68">
          <div class="metrics_head">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/favorite_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="metric_tooltip">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsThirdBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
        <div class="metric even_position_metric mt_mob_68">
          <div class="metrics_head">
            <div class="metric_logo">
              <img class="lozad" [src]="cdn_url + '/assets/images/group_icon.png'">
            </div>
            <div class="metric_text">
              {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsText']['metricsText1']}}<br>{{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsText']['metricsText2']}}
            </div>
            <div class="metric_tooltip right tooltip_left">
              <div class="metric_tooltip_text">
                {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsTooltipText']}}
              </div>
            </div>
          </div>
          <div class="metric_sub_head">
            {{successMetricsData['metricsBlocks']['metricsLastBlock']['metricsBlockBottomLine']}}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

