import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-page-footer',
  templateUrl: './deal-page-footer.component.html',
  styleUrls: ['./deal-page-footer.component.css','../../deal-pages-common-css.css'],
})
export class DealPageFooterComponent implements OnInit {
  cdn_url = environment.cdn_url;
  constructor() {}

  ngOnInit(): void {}
}
