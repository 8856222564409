<div class="container" [style]="{'background-image' : innerWidth > 600 ? 'url('+ cdn_url+ '/assets/images/ft-gmat-prep-footer.jpg)' : 'url('+ cdn_url+ '/assets/images/ft-gmat-prep-footer-mobile.jpg)'}">
  <div id="color-overlay"></div>
  <div class="e_gmat_logo">
    <div class="logo">
      <img [src]="cdn_url + '/assets/logo/e-gmat-logo-ft-gmat-prep.png'">
      <div class="company_name">e-GMAT</div>
    </div>
    <div class="company_quote">EMPOWERING DREAMS | CHANGING LIVES</div>
  </div>
  <div class="social_media_icons">
    <div>
      Follow Us
    </div>
    <div class="links">
      <a target="_blank" href="https://www.facebook.com/E-Gmat-499275643430980"><img class="media_icon"
          [src]="cdn_url + '/assets/images/facebook_icon_default.png'"></a>
      <a target="_blank" href="https://www.linkedin.com/in/rajat-sadana-ba459a"><img class="media_icon"
          [src]="cdn_url + '/assets/images/linkedin_icon_default.png'"></a>
      <a target="_blank" href="https://www.youtube.com/user/eGMATconcepts"><img class="media_icon"
          [src]="cdn_url + '/assets/images/youtube_icon_default.png'"></a>
      <a target="_blank" href="https://twitter.com/e_GMAT"><img class="media_icon"
          [src]="cdn_url + '/assets/images/twitter_icon_default.png'"></a>
    </div>
  </div>
</div>
