<div *ngIf="gmatJourneyData" id="visualize_journey" class="section section_pad bg-no-repeat section_gmat_journey" [style.backgroundImage]="screenSize >= 769 ? 'url(' +cdn_url + '/Pubsite_Uploads/deal_pages_uploads/Journey_NY_Image_bg.png)': ''">
  <div class="page_container">
    <p class="page_head mob_col_black text_center extra_bold_font" data-aos="slide-up">
      {{gmatJourneyData['heading']['firstPart']}} <span class="highlight_text mob_display_block !text-transparent bg-clip-text !bg-xmasHeadings">{{gmatJourneyData['heading']['restPart']}}</span>
    </p>
    <div class="img_container md:!mt-[-133px]">
      <img *ngIf="screenSize >= 769" class="lozad desktop_graphic" [src]="gmatJourneyData['journeyImage']">
      <img *ngIf="screenSize < 769" class="lozad mobile_graphic" [src]="gmatJourneyData['journeyImageMobile']">
    </div>
    <div class="btn_container buy_now_cta_container text_center"  (click)="routeToCart('CTA_visualize_journey')">
      <div class="hero_cta_btn secondary_section active !bg-ctaBtnBg !border-ctaBtnBg md:!mt-[-65px]" data-cta_name="CTA_timer_ribbon">
        <div class="logo_container">
          <img class="lozad" [src]="cdn_url + '/assets/images/buy_cart_icon.png'">
        </div>
        <div class="price_container">
          <div class="discounted_price page_head extra_bold_font !text-ctaBtn">{{discountedPrice}}</div>
          <span class="original_price">{{originalPrice}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
