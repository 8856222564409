import { SEOService } from './../../Services/seo.service';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClientService } from './../../Services/http-client.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../Services/data.service';

declare const push_viewedCourseNewEvent: any;
declare const push_site_event: any;
@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
})
export class CourseDetailComponent implements OnInit, OnDestroy {
  everything_you_need_data: any;
  proven_methods_data: any;
  personal_gmat_journey_data: any;
  course_comparison_data: any;
  money_back_guarantee_data: any;
  what_our_students_say_data: any;
  link_tags;
  meta_tags;

  constructor(
    private httpService: HttpClientService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta,
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    document.dispatchEvent(new Event('load_home_page'));
    this.dataService.getJsonVersion.subscribe((version) => {
      if (version > 0) {
        this.httpService
          .getJson('course-details.json?version=' + version)
          .subscribe((response) => {
            this.everything_you_need_data =
              response['everything_you_need_data'];
            this.proven_methods_data = response['proven_methods_data'];
            this.personal_gmat_journey_data =
              response['personal_gmat_journey_data'];
            this.course_comparison_data = response['course_comparison_data'];
            this.money_back_guarantee_data =
              response['money_back_guarantee_data'];
            this.what_our_students_say_data =
              response['what_our_students_say_data'];
              if (response['title']) {
                this.titleService.setTitle(response['title']);
              }
              if (response['meta_tags']) {
                this.meta_tags = response['meta_tags'];
                this.metaService.addTags(response['meta_tags']);
              }
              if(response['link_tags']){
                this.link_tags= response['link_tags'];
                this.seoService.createMultiLinks(response['link_tags']);
              }
              if(response['json_ld']){
                this.seoService.createJsonLd(response['json_ld']);
              }
          });
      }
    });

    this.dataService.getEventScriptStatus.subscribe((status) => {
      if (status) {
        push_site_event('accessed site');
        push_viewedCourseNewEvent('GMAT Online');
      }
    });
  }
  ngOnDestroy(): void {
    this.seoService.removeMetaTags(this.meta_tags);
    this.seoService.removeMultiLinks(this.link_tags);
    this.seoService.removeJsonLd();
  }
}
