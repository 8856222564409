<div class="hero_section lozad hero_section_bg_config" *ngIf="gmat_success_story_data" [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/hero_section_bg-jpg.jpg)'}">
  <div class="page_container">
    <div class="hero_sub_section">
      <h1 class="page_head extra_bold_font" data-aos="slide-up">
        {{gmat_success_story_data['header']['first_line']}} <br><span
          class="highlight_text">{{gmat_success_story_data['header']['second_line']}}</span>
      </h1>
      <div class="info_container">
        <div class="info_wrap">
          <ng-container *ngFor="let info of gmat_success_story_data['info'];let i = index;">
            <div class="info_column">
              <div class="info_head" data-aos="slide-up">
                {{info['info_head']['first_line']}}
                <span [attr.id]="'amount_'+i"></span>
                <span>{{info['info_head']['second_line']}}</span>
                <sup class="info_head_sup" *ngIf="info['info_head']['third_line']"> {{info['info_head']['third_line']}}</sup>
              </div>
              <div class="info_desc" data-aos="slide-up">{{info['info_desc']}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="e_gmat_slide_view_container">
      <div class="slide_view_wrap">
        <div class="slides_container source_slides desktop" data-aos="slide-up">
          <ng-container *ngFor="let slide of gmat_success_story_data['slides']">
            <div class="slide">
              <div class="slide_wrap">
                <div class="slide_img_container">
                  <div class="student_image">
                    <img class="lozad" [src]="slide['image_url']">
                  </div>
                  <div class="slide_student_name desktop">
                    {{slide['student_name']}}
                  </div>
                </div>
                <div class="slide_info_container">
                  <div class="slide_student_name mobile">
                    {{slide['student_name']}}
                  </div>
                  <ng-container *ngFor="let slide_info of slide['slide_info']">
                    <div class="slide_info">
                      <span class="slide_info_label">{{slide_info['label']}}</span>
                      <span class="slide_info_desc">{{slide_info['desc']}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="slides_container mobile" data-aos="slide-up">
            <div class="slide target_slide active_class" id="target_slide_id">
              <div class="slide_wrap">
                <div class="slide_img_container">
                  <div class="student_image">
                    <img class="lozad" [src]="gmat_success_story_data['slides'][active_index]['image_url']">
                  </div>
                  <div class="slide_student_name desktop">
                    {{gmat_success_story_data['slides'][active_index]['student_name']}}
                  </div>
                </div>
                <div class="slide_info_container">
                  <div class="slide_student_name mobile">
                    {{gmat_success_story_data['slides'][active_index]['student_name']}}
                  </div>
                  <ng-container *ngFor="let slide_info of gmat_success_story_data['slides'][active_index]['slide_info']">
                    <div class="slide_info">
                      <span class="slide_info_label">{{slide_info['label']}}</span>
                      <span class="slide_info_desc">{{slide_info['desc']}}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="disclaimer_text">
      <span *ngFor="let disclaimer of gmat_success_story_data['disclaimer']"><sup>{{disclaimer['count']}}</sup>{{disclaimer['text']}}</span>
    </div>
  </div>
</div>
