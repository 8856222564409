<ng-container *ngIf="target_score_data">
<div class="cr_header"  [ngStyle]="{'background-image':' url(' + target_score_data['header']['background_image_url'] + ')'}" [ngClass]="subjectName">
  <div class="page_container" [ngClass]="{'background_color_white': target_score_data['background_color'] == 'white'}">
    <p></p>
    <div class="row">
      <div class="col-md-12 section_1_heading text-center"> {{target_score_data['header']['first_line']}}<sup
        *ngIf="target_score_data['header']['first_line_sup']" class="reg_mark">{{target_score_data['header']['first_line_sup']}}</sup>
        <ng-container
        *ngIf="target_score_data['header']['first_line_remaining']">{{target_score_data['header']['first_line_remaining']}}</ng-container><br>
        {{target_score_data['header']['second_line']}}<sup
          class="reg_mark">{{target_score_data['header']['second_line_sup']}}</sup>
        {{target_score_data['header']['third_line']}}
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 section_1_sub_heading text-center"> {{target_score_data['description']['first_line']}}<br>
        {{target_score_data['description']['second_line']}}<br>{{target_score_data['description']['third_line']}}</div>
    </div>
    <div class="row box_row text-center">
      <ng-container *ngFor="let box of target_score_data['boxes']">
        <div class="col-md-3 col-md-offset-2 box" data-toggle="modal" data-target="#startnow"> <span
          class="box_number">{{box['number']}}</span><br><span class="box_text">{{box['text']}}</span></div>
      </ng-container>
    </div>
    <div class="row" *ngIf="canRegister">
      <div class="col-md-12 section_1_cta text-center">
        <button (click)="openModal()" type="button" class="yellow_reserve btn btn-custom cta_track">Register Now</button>
      </div>
    </div>
    <div class="row" *ngIf="!canRegister">
      <div class="col-md-12 section_1_cta text-center">
        <button type="button" class="gray_reserve btn btn-custom cta_track">No Webinar Scheduled</button>
      </div>
    </div>
  </div>
</div>
</ng-container>
