<div class="section section_pad section_top_reasons" id="course_details" *ngIf="topReasonsData">
  <div class="page_container">
    <p class="page_head text_center extra_bold_font">
      {{topReasonsData['headings']['firstLine']}} <br /> {{topReasonsData['headings']['secondLine']}} <br /><span class="highlight_text">{{topReasonsData['headings']['lastLine']['lastLineColorFulWords']}}</span> {{topReasonsData['headings']['lastLine']['lastLineWords']}}
    </p>

    <div class="reasons_section_container">
      <div class="reason_container">

        <div class="reason">
          <div class="reason_head">
            {{topReasonsData['reasons']['firstReason']['heading']}}
          </div>
          <div class="reason_colums_container">
            <div class="reason_column">
              <div class="reason_column_head" data-head="l">
                {{topReasonsData['reasons']['firstReason']['firstColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['firstReason']['firstColumnLines']['firstColumnFirstLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['firstReason']['firstColumnLines']['firstColumnSecondLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['firstReason']['firstColumnLines']['firstColumnThirdLine']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="ll">
                {{topReasonsData['reasons']['firstReason']['secondColumnHeading']}}
              </div>
              <ul>
                <li>
                  <b>{{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnFirstLine']['seondColumnFirstLineBoldWords']}}</b> {{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnFirstLine']['seondColumnFirstLineNormalWords']}}
                </li>
                <li>
                  <b>{{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnSecondLine']['seondColumnSecondLineBoldWords']}}</b> {{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnSecondLine']['seondColumnSecondLineNormalWords']}}
                </li>
                <li>
                  <b>{{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnThirdLine']['seondColumnThirdLineBoldWords']}}</b> {{topReasonsData['reasons']['firstReason']['secondColumnLines']['secondColumnThirdLine']['seondColumnThirdLineNormalWords']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="lll">
                {{topReasonsData['reasons']['firstReason']['lastColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['firstReason']['lastColumnLines']['lastColumnFirstLine']['lastColumnFirstLineFirstPart']}} <b>{{topReasonsData['reasons']['firstReason']['lastColumnLines']['lastColumnFirstLine']['lastColumnFirstLineBoldWords']}}</b> {{topReasonsData['reasons']['firstReason']['lastColumnLines']['lastColumnFirstLine']['lastColumnFirstLineLastPart']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['firstReason']['lastColumnLines']['lastColumnSecondLine']}}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="reason">
          <div class="reason_head">
            {{topReasonsData['reasons']['secondReason']['heading']}}
          </div>
          <div class="reason_colums_container">
            <div class="reason_column">
              <div class="reason_column_head" data-head="l">
                {{topReasonsData['reasons']['secondReason']['firstColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['secondReason']['firstColumnLines']['firstColumnFirstLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['secondReason']['firstColumnLines']['firstColumnSecondLine']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="ll">
                {{topReasonsData['reasons']['secondReason']['secondColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['secondReason']['secondColumnLines']['secondColumnFirstLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['secondReason']['secondColumnLines']['secondColumnSecondLine']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="lll">
                {{topReasonsData['reasons']['secondReason']['lastColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['secondReason']['lastColumnLines']['lastColumnFirstLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['secondReason']['lastColumnLines']['lastColumnSecondLine']}}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="reason">
          <div class="reason_head">
            {{topReasonsData['reasons']['thirdReason']['heading']}}
          </div>
          <div class="reason_colums_container">
            <div class="reason_column">
              <div class="reason_column_head" data-head="l">
                {{topReasonsData['reasons']['thirdReason']['firstColumnHeading']}}
              </div>
              <ul>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['firstColumnLines']['firstColumnFirstLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['firstColumnLines']['firstColumnSecondLine']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="ll">
                {{topReasonsData['reasons']['thirdReason']['secondColumnHeading']}}
              </div>
              <div class="reason_column_content">
                <b>{{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnFirstLine']['seondColumnFirstLineBoldWords']}}</b> {{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnFirstLine']['seondColumnFirstLineNormalWords']}}
              </div>
              <ul class="small_list">
                <li>
                  {{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnSecondLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnThirdLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnForthLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['secondColumnLines']['secondColumnFifthLine']}}
                </li>
              </ul>
            </div>
            <div class="vertical_line"></div>
            <div class="reason_column">
              <div class="reason_column_head" data-head="lll">
                {{topReasonsData['reasons']['thirdReason']['lastColumnHeading']}}
              </div>
              <div class="reason_column_content">
                {{topReasonsData['reasons']['thirdReason']['lastColumnLines']['lastColumnFirstLine']}}
              </div>
              <ul class="small_list">
                <li>
                  {{topReasonsData['reasons']['thirdReason']['lastColumnLines']['lastColumnSecondLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['lastColumnLines']['lastColumnThirdLine']}}
                </li>
                <li>
                  {{topReasonsData['reasons']['thirdReason']['lastColumnLines']['lastColumnForthLine']}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="support_text_container">
      <div class="support_text_head">
        {{topReasonsData['supportText']['heading']}}
      </div>
      <div class="support_text desktop">
        {{topReasonsData['supportText']['content']['firstPart']}} <a href="mailto:support@e-gmat.com"
          class="hover_effect external_link extra_bold_font">{{topReasonsData['supportText']['content']['supportMailId']}}</a> {{topReasonsData['supportText']['content']['restPart']}}
      </div>
      <div class="support_text mobile">
        {{topReasonsData['supportText']['content']['firstPart']}} <br><a href="mailto:support@e-gmat.com"
          class="hover_effect external_link extra_bold_font">{{topReasonsData['supportText']['content']['supportMailId']}}</a> {{topReasonsData['supportText']['content']['restPart']}}
      </div>
    </div>
    <div class="btn_container buy_now_cta_container text_center" (click)="routeToCart('CTA_course_offerings')">
      <div class="hero_cta_btn secondary_section" data-cta_name="CTA_course_offerings">
        <div class="logo_container">
          <img class="lozad" [src]="cdn_url + '/assets/images/buy_cart_icon_white.png'">
        </div>
        <div class="price_container">
          <div class="discounted_price page_head extra_bold_font">{{discountedPrice}}</div>
          <span class="original_price">{{originalPrice}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
