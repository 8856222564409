import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/egmat-home/src/environments/environment';
import { DataService } from '../../../Services/data.service';

declare const createproperties_nonpropagation: any;
declare const push_event_properties: any;

@Component({
  selector: 'app-deal-course-comparison',
  templateUrl: './deal-course-comparison.component.html',
  styleUrls: ['./deal-course-comparison.component.css','../../deal-pages-common-css.css']
})
export class DealCourseComparisonComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService) { }
  @Input() courseComparisonData;
  @Input() originalPrice;
  @Input() discountedPrice;
  @Input() productId;
  @Input() discountCode;
  cdnUrl = environment.cdn_url;
  addExpandClass = false;

  ngOnInit(): void {
    this.dataService.courseComparisonClickedFromNav.subscribe(isClicked => {
      if(isClicked){
        this.addExpandClass = true;
      }
    })
  }

  toggleExpandClass() {
    this.addExpandClass = !this.addExpandClass;
  }

  routeToCart(prop) {
    // this.dataService.updatectaBtnProperty(prop);
    const cta_event_name = "Clicked CTA Button";
    const CTA_clicked_properties = createproperties_nonpropagation(cta_event_name);
    CTA_clicked_properties["Page Name"] = "GMAT_Online_Deal_Page";
    CTA_clicked_properties["CTA_Page_Type"] = "Deal Page";
    if(prop) {
        CTA_clicked_properties["Button Name"] = prop.split("CTA_")[1];
        push_event_properties(cta_event_name, CTA_clicked_properties);
    }
    setTimeout(() => {
      this.router.navigate(['/cart',this.productId],
    { queryParams: { discountCode: this.discountCode, cp: 0 } });
    }, 500);
  }

}
