<div class="section section_5 text_center lozad" *ngIf="course_comparison_data" (click)="close_competitor_menu()"
  [ngStyle]="{'background-image':' url(' + cdn_url + '/assets/images/light_bg_table.png)'}">
  <div class="page_container">
    <div class="row">
      <div class="col-md-12">
        <div class="section_5_head">
          {{course_comparison_data['header']['first_line']}}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="comparison_table center">
          <colgroup>
            <col>
            <ng-container *ngFor="let company of course_comparison_data['companies'];let i = index;">
              <col *ngIf="company['is_visible']" [ngClass]="{'outlined':i==0,'outline_dotted_mobile':i!=0}">
            </ng-container>
            <col class="outline_dotted">
          </colgroup>
          <thead>
            <tr>
              <th class="w_40" id="th_first">
              </th>
              <ng-container *ngFor="let company of course_comparison_data['companies'];let i = index;">
                <th [ngClass]="{'main': i==0, 'competitor': i!=0, 'z_25': company['showCourseComparisonMenu']}" *ngIf="company['is_visible']">
                  <app-course-comparison-menu [companyNameSelected]="companyNameSelected"
                    (close_competitor_menu)="close_competitor_menu($event)"
                    *ngIf="company['showCourseComparisonMenu'] && showCourseComparisonMenu && i!=0" [multi_select]="true"
                    [companies]="course_comparison_data['companies']"></app-course-comparison-menu>
                  <div *ngIf="i != 0 && company['showCourseComparisonMenu']" class="edit_img"
                    (click)="changeStatePopup($event, false, i);">
                    <img class="close_competitor_menu_img lozad" [src]="cdn_url+'/assets/images/Close_pop_up.png'">
                  </div>
                  <img class="competitor_img lozad mt_53" [ngClass]="{'main_img':i==0}" [src]="company['logo_url']">
                  <div *ngIf="i != 0 && !company['showCourseComparisonMenu']" class="edit_img">
                    <img class="img_pencil lozad" [src]="cdn_url+'/assets/images/new_pencil_ellipse.png'"
                      (click)="changeStatePopup($event, true, i);">
                  </div>
                  <div class="mt_5">{{company['name']}}</div>
                  <div class="competitor_price mt_10" [ngClass]="{'main_price':i==0}">{{company['price']}}</div>

                </th>
              </ng-container>
              <th class="add_comp w_14 z_index_add_comp hide_col" *ngIf="addMoreVisible()">
                <app-course-comparison-menu [companyNameSelected]="companyNameSelected"
                    (close_competitor_menu)="close_competitor_menu($event)"
                    *ngIf="course_comparison_data['companies'][0]['showCourseComparisonMenu'] && showCourseComparisonMenu" [multi_select]="true"
                    [companies]="course_comparison_data['companies']"></app-course-comparison-menu>
                <img class="lozad add_compare_button add_compare_img"
                  [ngStyle]="{'transform': 'rotate('+(course_comparison_data['companies'][0]['showCourseComparisonMenu']?45:0)+'deg)'}"
                  (click)="changeStatePopup($event, !showCourseComparisonMenu, 0);"
                  [src]="cdn_url+'/assets/images/Add.png'">
                <div class="mt_5 add_compare_head">Add to compare</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of course_comparison_data['rows']">
              <tr [ngClass]="row['row_type']">
                <td class="text_left text_left_hover" [ngClass]="row['row_type'] == 'head_row'?'pdl_33':'pdl_56'">
                  {{row['row_text']}}
                  <div class="hover_content" *ngIf="row['on_hover_text']">
                    <span class="ipad_close_cross_btn">✕</span>
                    <div class="hover_content_text">{{row['on_hover_text']}}</div>
                  </div>
                </td>
                <ng-container *ngFor="let row_data of row['row_data'];let i = index">
                  <td *ngIf="course_comparison_data['companies'][i]['is_visible']" [ngClass]="{'highlight':i==0}">
                    <ng-container *ngIf="row_data === 'tick'">
                      <span class="tick_container">
                        <div class="tick"></div>
                      </span>
                    </ng-container>
                    <ng-container *ngIf="row_data !== 'tick'">{{row_data}}</ng-container>
                  </td>
                </ng-container>
                <ng-container *ngIf="addMoreVisible()">
                  <td></td>
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row disclaimer_row">
      <div class="col-md-5 text_left pdl_15">1 GMAT Club (2020)</div>
      <div class="col-md-7 text_right pdr_11">*Logos, names, and trademarks are properties of respective companies</div>
    </div>
    <app-buy-now></app-buy-now>
  </div>
</div>
