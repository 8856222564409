import { environment } from 'projects/egmat-home/src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-score-seven-forty-plus',
  templateUrl: './score-seven-forty-plus.component.html',
  styleUrls: ['./score-seven-forty-plus.component.css']
})
export class ScoreSevenFortyPlusComponent implements OnInit {

  cdn_url = environment.cdn_url;
  constructor() { }

  @Input() score_seven_forty_plus_data;

  ngOnInit(): void {
  }

  redirectToUnbounce(url){
    window.open(url);
  }
  markActiveTab(tab_index, card){
    this.score_seven_forty_plus_data['cards'][card]['card_tabs'].forEach((element, index) => {
      if(tab_index == index){
        element['is_active'] = true;
      }else{
        element['is_active'] = false;
      }
    });
  }
}
